import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { getFormatNumber } from "../constants/functions"
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { LoginContext } from "./contexts/LoginContext";
import { useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingLg from "../components/Setting";
import RefreshIcon from '@material-ui/icons/Refresh';
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FullScreenDialogEdit } from "../modal/Edit_entries";
import { Successfully } from "../modal/Successfully";
import BoxDescriptions from "../modal/BoxDescriptions";
// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 440, // Adjust the height as needed
    maxWidth: '100%', // Adjust the width as needed
    overflow: 'auto', // Ensure scrolling
  },
  table: {
    minWidth: 650,
    tableLayout: 'fixed', // Ensures fixed layout for columns
  },
  stickyHeader: {
    // position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.appBar,
  },
  parentDiv: {
    width: '100%',
    height: '500px', // Set the height you want for the parent div
    overflow: 'auto', // Make the div scrollable
    marginTop: 100
  },
}));

export default function ReportallGL() {
  let componentRef = useRef(null)
  const navigate = useNavigate();
  const {
    listaccountname, listcurrency,
    listgl, setListgl, loading, setLoading, company_uid, OnlaodGl, RefresOnlaodGl
  } = useContext(LoginContext);
  console.log("listgl=", listgl)



  const classes = useStyles();
  const [getvalues, setGetvalues] = useState([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [showBox, setShowBox] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [active, setActive] = useState("");
  const [Leave, setLeave] = useState(0);
  const [nameShow, setNameShow] = useState("");
  const [defaultValue, setDefaultValue] = useState("")
  const [defaultValue1, setDefaultValue1] = useState("")
  const [exchage_rate, setExchage_rate] = useState('')
  const Today = moment(new Date()).format("DD-MM-YYYY")
  const [ch_id, setCh_id] = useState("")
  const [open, setOpen] = useState(true);
  const [account, setAccount] = useState(false)
  const [alldates, setAlldates] = useState(false)
  const [journal, setJournal] = useState(false)
  const [getjournal, setGetjournal] = useState('')
  const [errdate, setErrdate] = useState(false)
  const [err, setErr] = useState("0")
  const [showSetting, setShowSetting] = useState(false)
  const [showdebit, setShowdebit] = useState(false)
  const [showcredit, setshowcredit] = useState(false)
  const [foreigndebit, setForeignDebit] = useState(false)
  const [foreigncredit, setForeignCredit] = useState(false)
  const [foreignamount, setForeignAmount] = useState(false)
  const [foreignbalance, setforeignBalance] = useState(false)
  const [currentbalance, setCurrentbalance] = useState(false)
  const [gain_Loss, setGain_loss] = useState(false)
  const [getchart_id, setGechart_id] = useState('')
  const [open_edit_journal_entries, setOpen_edit_journal_entries] = useState(false)
  const [currencystatus, setCurrencystatus] = useState('');
  const [currency, setCurrency] = useState('')
  const [rate, setRate] = useState(false)
  const [show, setShow] = useState(false);
  const [exchangerate, setExchangeRate] = useState(false);
  const [exchange, setExchange] = useState([])
  const [isLoading, setIsLoading,] = useState(false);

  const [exchange_conditions, setExchange_conditions] = useState(false)
  const [current_balance_conditions, setCurrent_balance_conditions] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [images, setImage] = useState([])

  const [data, setData] = useState([
    { name: '', rate: '' },
  ]);
  const [dataList, setDataList] = useState([])
  const [old_data, setOld_data] = useState([])
  const [editcurrency, setEditcurrency] = useState("")
  const [netTotalDebit, setNetTotalDebit] = useState("")
  const [netTotalCrebit, setNetTotalCrebit] = useState("")
  const [thb, setThb] = useState("");
  const [usd, setUsd] = useState("");
  const [journalNo, setJournalNo] = useState("")
  const [oldjournal_no, setOldjournal_no] = useState('')
  const [currency_id, setCurrency_id] = useState("");
  const [defaultValue_edit, setDefaultValue_edit] = useState("")
  const [checkCurrency, setCheckCurrency] = useState('')
  const [successfully, setSuccessfully] = useState(false)
  const [successfullyloading, setSuccessfullyloading] = useState(false)
  const [listimage, setListimage] = useState([])
  const [select_currency, setSelect_currency] = useState('')
  const [checked_logo, setChecked_logo] = useState('')
  const [conditions_curren, setConditions_curren] = useState('')
  const [warring, setWarring] = useState(false)
  const [warring_select_currency, setWarring_select_currency] = useState(false)
  const [warring_exhange_rate, setWarring_exhange_rate] = useState(false)
  const [warring_debit_credit, setWarring_debit_credit] = useState(false)
  const [tr_id, setTr_id] = useState('')
  const Setting = () => {
    setShowSetting(!showSetting)
  }
  const handleClose = () => {
    setShow(false);

  };

  const handleShow = () => {
    setShow(true)
  };


  const EnterDate = (e) => {
    // setDefaultValue(moment(e).format("DD/MM/YYYY"))
    // setErrdate(false)
    // axios.get(`/accounting/api/loss-gain/getdate/${moment(e).format("DD-MM-YYYY")}`).then((data) => {
    //     if (data?.data?.result == 0) {
    //         setData([...clearData])
    //     } else {
    //         setData([...data?.data?.result])
    //         setExchange([...data?.data?.result])
    //     }
    // }).catch((err) => {
    //     console.log(err)
    // })
  }


  const handleCloseOpen = () => {
    setOpen_edit_journal_entries(false);
    setGechart_id('')
  };


  const onBlurCredit = () => {
    setShowSetting(false)
  }
  const Ondebit = () => {
    setShowdebit(!showdebit)
  }
  const Oncredit = () => {
    setshowcredit(!showcredit)
  }
  const OnForeigndebit = () => {
    setLeave(0)
    setForeignDebit(!foreigndebit)
  }
  const Onforeigncredit = () => {
    setLeave(0)
    setForeignCredit(!foreigncredit)
  }
  const Onforeignamount = () => {
    setLeave(0)
    setForeignAmount(!foreignamount)
  }
  const OnForeignbalance = () => {
    setLeave(0)
    setforeignBalance(!foreignbalance)
  }
  const OnRate = () => {
    setLeave(0)
    setRate(!rate)
  }
  const OnExchangerate = () => {
    setExchange_conditions(true)
    setCurrent_balance_conditions(true)
  }


  const OnCurrentBalance = () => {
    setLeave(0)
    setCurrentbalance(!currentbalance)
  }
  const insert = () => {
  }
  const OnGain_loss = () => {
    setLeave(0)
    setGain_loss(!gain_Loss)
  }
  const handleClicks = () => {
    setShowBox(!showBox);
  };
  const Refresh = () => {
    RefresOnlaodGl()
  }
  const handleClick = () => {
    setOpen(!open);
  };
  const handleBlur = () => {
    if (active == null) {
    } else {
      setShowSetting(false)
    }
  };
  const _onShow = (e) => {
    if (e == "custom") {
      setAccount(false)
      setAlldates(false)
    } else if (e == "all") {
      setAlldates(true)
    } else if (e == "today") {
      setErr('')
      setAccount(false)
      const e1 = new Date()
      setAccount(false)
      const startToday = moment(e1).format("DD-MM-YYYY")
      const endToday = moment(e1).format("DD-MM-YYYY")
      setDefaultValue(moment(e1).format("DD-MM-YYYY"))
      setDefaultValue1(moment(e1).format("DD-MM-YYYY"))
      const start = defaultValue
      const end = defaultValue1
      let data;
      if (start == '' || end == '') {
        data = {
          startToday,
          endToday
        }
      } else {
        data = {
          start,
          end
        }
      }
      axios.post("/accounting/api/report/reportGlbydatetwo", data).then((data) => {
        setListgl({ ...data?.data })
      }).catch((err) => {
        console.log(err)
      })
    } else if (e == "account") {
      setJournal(false)
      setAccount(true)
    } else if (e == "journal_no") {
      setAccount(false)
      setJournal(true)
    } else if (e == "exchange_rate") {

    }
    setGetvalues(e)
  }
  const _searchStartdate = (e) => {
    setDefaultValue(e)
    setStart_date(e)
    if (defaultValue === "") {
      setDefaultValue(Today)
    } else {
      setDefaultValue(moment(e).format("DD-MM-YYYY"))
    }
  }

  const getListResult = (data) => {
    return data.map(item => ({
      CHART_ID: item.CHART_ID,
      CURRENCY_CODE: item.CURRENCY_CODE,
      CHART_PARENTS: item.CHART_PARENTS,
      BS_STATUS: item.BS_STATUS,
      ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,

    }));
  };


  const OnEditJournal = (id) => {
    setSuccessfully(true)
    setSuccessfullyloading(true)
    axios.get(`/accounting/api/ledger_entries/Bank/${id}`).then((data) => {
      setSelectedImages([...data?.data.picture])
      const newImages = data?.data?.picture.map(file => ({ NAME_IMAGE: file?.name }));
      const list_result = getListResult([...data?.data?.result]);
      setImage(newImages)
      if ([...data?.data?.result].length > 0) {
        setDataList([...data?.data?.result])
        setOld_data(list_result)
      }
      let inforData = [...data?.data?.result]
      const dateIn = [...data?.data?.result][0].CREATEDATE
      let initialValue = 0;
      let sumdebit = [...data?.data?.result]?.reduce(function (previousValue, currentValue) {
        return parseFloat(previousValue) + (currentValue['DEBIT'] != undefined && currentValue['DEBIT'] != '' ? parseFloat(currentValue['DEBIT'].replaceAll(',', '')) : 0)
      }, initialValue)
      let sumcredit = [...data?.data?.result]?.reduce(function (previousValue, currentValue) {
        return parseFloat(previousValue) + (currentValue['CREDIT'] != undefined && currentValue['CREDIT'] != '' ? parseFloat(currentValue['CREDIT'].replaceAll(',', '')) : 0)
      }, initialValue)
      let money_rate = [...data?.data?.result][0].EXCHNAGE_RATE
      let rate = Number(money_rate).toLocaleString('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      });
      let exchange = rate.replaceAll(',', '')
      if ([...data?.data?.result][0].CURRENCY === 'LAK') {
        let TotalDebit = (parseFloat(sumdebit) / parseFloat(exchange))
        let TotalCredit = (parseFloat(sumcredit) / parseFloat(exchange))
        setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
        setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))

      } else {
        let TotalDebit = (parseFloat(exchange) * parseFloat(sumdebit))
        let TotalCredit = (parseFloat(exchange) * parseFloat(sumcredit))
        setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
        setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
      }
      setEditcurrency([...data?.data?.result][0].CURRENCY)
      setCurrencystatus([...data?.data?.result][0].CURRENCY)
      setExchage_rate(formatMoney(exchange))
      setJournalNo([...data?.data?.result][0].JOURNAL_NO)
      setOldjournal_no([...data?.data?.result][0].JOURNAL_NO)
      setTr_id([...data?.data?.result][0].TR_ID)
      setDefaultValue_edit(moment(dateIn).format("DD-MM-YYYY"))
      const uniqueEntries = Object.values(
        inforData.reduce((acc, entry) => {
          if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
            acc[entry.CURRENCY_CODE] = entry;
          }
          return acc;
        }, {})
      );
      const usdData = uniqueEntries.filter(item => item.CURRENCY_CODE !== 'LAK')
      if (usdData.length === 0) {
        setCurrency_id(false)
        setConditions_curren('LAK')
      } else {
        let filter = listcurrency.filter((el) => el.CURRENCY_NAME.includes(usdData[0].CURRENCY_CODE))
        if (filter[0].CURRENCY_NAME !== 'LAK') {
          setListimage(filter[0].attachments[0])
          setSelect_currency(filter[0].CURRENCY_NAME)
        }
        setChecked_logo(usdData[0].CURRENCY_CODE)
        setConditions_curren(usdData[0].CURRENCY_CODE)
        setCurrency_id(true)
      }
      setSuccessfully(false)
      setSuccessfullyloading(false)
      setOpen_edit_journal_entries(true)

    }).catch((err) => {
      console.log(err)
    })
  }
  const _searchEnddate = (e) => {
    setDefaultValue1(e)
    setEnd_date(e)
    if (defaultValue1 === "") {
      setDefaultValue1(Today)
    } else {
      setDefaultValue1(moment(e).format("DD-MM-YYYY"))
    }
  }
  const Search = (company_uid) => {
    setLoading(false)
    if (getvalues == "all") {
      axios.get(`/accounting/api/ledger_entries/all/${company_uid}`).then((data) => {
        setListgl({ ...data?.data })
        setLoading(true)
      }).catch((err) => {
        console.log(err)
      })
    } else if (getvalues === 'journal_no') {
      let data = {
        journal_no: getjournal
      }
      axios.post("/accounting/api/report/reportGlbyJournalno", data).then((data) => {
        setListgl({ ...data?.data })
      }).catch((err) => {
        console.log(err)
      })
    } else {
      let data = {
        start_date: moment(start_date).format('DD-MM-YYYY'),
        end_date: moment(end_date).format('DD-MM-YYYY'),
        company_uid,
        for_year: moment(start_date).format('YYYY')
      }
      axios.post('/accounting/api/ledger_entries/Search', data).then((data) => {
        setListgl({ ...data?.data })
        setLoading(true)
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  const onGotoEditjournal = (id) => {
    const baseUrl = window.location.pathname;
    navigate(`/Journalpage/${id}`);
  }
  const OnShowAatumaticTransaction = (e) => {
    navigate(`/DetailAutomatic/${e}`)
  }

  const getNameList = (c_id) => {
    axios.get(`/accounting/api/chartofaccounts/all/parents/${c_id}`).then((data) => {
      if (data?.data?.message.length > 0) {
        setCh_id(data?.data.message[0].c_id);
        console.log(data?.data.message[0].c_id)
        const names = data?.data?.message.map((item) => {
          return item.name_eng;
        });
        names.reverse();
        setNameShow(names.join(":"));
        setShowBox(!showBox);
      }
    });
  };
  useEffect(() => {
    _searchStartdate()
    _searchEnddate()
  }, [])
  return (
    <>
      <div style={{
        width: "100%",
        position: 'fixed',
        backgroundColor: 'white'

      }} >
        {
          successfully == true ? (<>
            <Successfully
              successfully={successfully}
              successfullyloading={successfullyloading}
            />
          </>) : null
        }

        <FullScreenDialogEdit
          handleCloseOpen={handleCloseOpen}
          open_edit_journal_entries={open_edit_journal_entries}
          data={dataList}
          setData={setDataList}
          old_data={old_data}
          setOld_data={setOld_data}
          editcurrency={editcurrency}
          setEditcurrency={setEditcurrency}
          netTotalDebit={netTotalDebit}
          setNetTotalDebit={setNetTotalDebit}
          netTotalCrebit={netTotalCrebit}
          setNetTotalCrebit={setNetTotalCrebit}
          thb={thb}
          setThb={setThb}
          usd={usd}
          setUsd={setUsd}
          journalNo={journalNo}
          setJournalNo={setJournalNo}
          oldjournal_no={oldjournal_no}
          setOldjournal_no={setOldjournal_no}
          currency_id={currency_id}
          setCurrency_id={setCurrency_id}
          defaultValue={defaultValue_edit}
          setDefaultValue={setDefaultValue_edit}
          setCheckCurrency={setCheckCurrency}
          checkCurrency={checkCurrency}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          images={images}
          setImage={setImage}
          tr_id={tr_id}
          currency={currency}
          setCurrency={setCurrency}
          listimage={listimage}
          setListimage={setListimage}
          select_currency={select_currency}
          setSelect_currency={setSelect_currency}
          currencystatus={currencystatus}
          setCurrencystatus={setCurrencystatus}
          checked_logo={checked_logo}
          setChecked_logo={setChecked_logo}
          conditions_curren={conditions_curren}
          setConditions_curren={setConditions_curren}
          exchage_rate={exchage_rate}
          setExchage_rate={setExchage_rate}
          warring={warring}
          setWarring={setWarring}
          warring_debit_credit={warring_debit_credit}
          setWarring_debit_credit={setWarring_debit_credit}
          warring_exhange_rate={setWarring_exhange_rate}
          warring_select_currency={warring_select_currency}
          setWarring_select_currency

        />
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ArrowBackIcon style={{ color: '#3f51b5' }} />
          </div>
          <small style={{ fontSize: 20, marginLeft: 5 }}>General Ledger Report</small>
        </div>
        {
          loading ? (<>
            <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
          </>) : (<>

            <LinearProgress style={{ marginTop: 10 }} />
          </>)
        }
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 10,
          justifyContent: 'space-between'

        }}>
          <div style={{ display: 'flex', width: '90%', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <select
              onChange={(e) => _onShow(e.target.value)}
              value={getvalues}
              style={{
                border: '1px solid #ccc',
                borderRadius: 4,
                fontSize: 16,
                outline: 'none',
                fontSize: 16,
                height: 30,
                width: '30%',
                paddingLeft: 0
              }}
            >
              <option value="thismonth">This Month-to-date</option>
              <option value="all">All Dates</option>
              <option value="custom">Custom</option>
            </select>
            {
              alldates == false ? (
                <>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    position: 'relative',
                    width: '20%'
                  }}>
                    <input
                      type="text"

                      defaultValue={defaultValue}
                      onChange={(e) => setDefaultValue(e.target.value)}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: 4,
                        fontSize: 16,
                        outline: 'none',
                        fontSize: 16,
                        height: 30,
                        width: '100%',
                        marginLeft: 2
                      }}
                    />
                    <input
                      type="date"
                      onChange={(e) => { _searchStartdate(e.target.value) }}
                      style={{
                        height: 20,
                        borderRadius: 3,
                        width: 25,
                        position: 'absolute',
                        right: 10,
                        outline: 'none',
                        border: "none",
                        top: 6,
                        cursor: 'pointer',
                        paddingLeft: 10,
                      }}
                    />
                  </div>
                  <span style={{ fontSize: 20, marginLeft: 10, marginRight: 0 }}>To</span>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    position: 'relative',
                    width: '20%'
                  }}>
                    <input
                      type="text"
                      defaultValue={defaultValue1}
                      onChange={(e) => setDefaultValue1(e.target.value)}
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: 4,
                        fontSize: 16,
                        outline: 'none',
                        fontSize: 16,
                        height: 30,
                        width: '100%',
                        marginLeft: 2
                      }}
                    />
                    <input
                      type="date"
                      onChange={(e) => { _searchEnddate(e.target.value) }}
                      style={{
                        height: 20,
                        borderRadius: 3,
                        width: 25,
                        position: 'absolute',
                        right: 10,
                        outline: 'none',
                        border: "none",
                        top: 6,
                        cursor: 'pointer',
                        paddingLeft: 10,
                      }}
                    />
                  </div>
                </>) : null
            }
            <div style={{
              display: 'flex',
              backgroundColor: '#3f51b5',
              border: 'none',
              height: 30,
              borderRadius: 2,
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 5,
              cursor: 'pointer',
              width: '20%'
            }}
              onClick={() => Search(company_uid)}
            >
              <SearchIcon />
              <small>Search</small>
            </div>
            <div style={{
              display: 'flex',
              backgroundColor: "#3f51b5",
              border: "none",
              height: 30,
              borderRadius: 2,
              color: "white",
              alignItems: "center",
              justifyContent: 'center',
              marginLeft: 5,
              width: '15%'

            }}>
              <RotateLeftIcon />
              <small>Reset</small>
            </div>
            <div style={{
              borderRadius: 2,
              width: '13%',
              backgroundColor: '#3f51b5',
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
              marginLeft: 5
            }}
              onClick={() => { Refresh() }}
            >

              <RefreshIcon style={{ color: 'white' }} />
              <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Refresh</small>
            </div>
            <div style={{
              borderRadius: 2,
              width: '13%',
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
              marginLeft: 5
            }}
              onClick={() => { Refresh() }}
            >

              <SettingLg
                Ondebit={Ondebit}
                Oncredit={Oncredit}
                OnForeigndebit={OnForeigndebit}
                Onforeigncredit={Onforeigncredit}
                Onforeignamount={Onforeignamount}
                OnExchangerate={OnExchangerate}
                OnRate={OnRate}
                OnGain_loss={OnGain_loss}
                OnForeignbalance={OnForeignbalance}
                OnCurrentBalance={OnCurrentBalance}
                showdebit={showdebit}
                showcredit={showcredit}
                foreigndebit={foreigndebit}
                foreigncredit={foreigncredit}
                foreignamount={foreignamount}
                foreignbalance={foreignbalance}
                rate={rate}
                currentbalance={currentbalance}
              />
              <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Refresh</small>
            </div>
            {
              loading ? (<>

              </>) : (<>
                <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                  <Spin size="smal" />
                </Flex>
              </>)
            }
          </div>
          {/* <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <SettingLg
              Ondebit={Ondebit}
              Oncredit={Oncredit}
              OnForeigndebit={OnForeigndebit}
              Onforeigncredit={Onforeigncredit}
              Onforeignamount={Onforeignamount}
              OnExchangerate={OnExchangerate}
              OnRate={OnRate}
              OnGain_loss={OnGain_loss}
              OnForeignbalance={OnForeignbalance}
              OnCurrentBalance={OnCurrentBalance}
              showdebit={showdebit}
              showcredit={showcredit}
              foreigndebit={foreigndebit}
              foreigncredit={foreigncredit}
              foreignamount={foreignamount}
              foreignbalance={foreignbalance}
              rate={rate}
              currentbalance={currentbalance}
            />
          </div> */}
        </div>
      </div>
      <div className={classes.parentDiv}>
        <Modal show={show} onHide={handleClose} style={{ paddingTop: 50 }} >
          <Modal.Header closeButton >
            <Modal.Title>
              Enter Exchange Rate
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <small>Enter Date</small>
              <input
                type="text"
                placeholder="dd/MM/yyyy"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                style={{
                  border: '1px solid #ccc',
                  height: 30,
                  borderRadius: 3,
                  width: 100,
                  paddingLeft: 10,
                  marginLeft: 25,
                  textAlign: "right",
                  borderRight: "none",
                }}
              />
              <input
                type="date"
                style={{
                  border: '1px solid #ccc',
                  height: 30,
                  borderRadius: 3,
                  width: 30,
                  paddingLeft: 10,
                }}
                onChange={(e) => EnterDate(e.target.value)}
              />

            </div>
            <div style={{ height: 20, marginLeft: 210 }}>
              {
                errdate === true ? (
                  <>
                    < small style={{ position: "absolute", fontSize: 14, color: "red" }}>Please Inter date</small>
                  </>
                ) : null
              }
            </div>

            {/* {JSON.stringify(data)} */}
            <div style={{ height: 20 }}></div>
            <table width={"100%"} border="1">
              <tr style={{ border: '1px solid #ccc', height: 30 }}>
                <td style={{ paddingLeft: 55 }}>Currency</td>
                <td align="right" style={{ paddingRight: 55 }}>Exchange Rate</td>
              </tr>
              {
                exchange.length == 0 ? (
                  <>
                    {
                      data && data.map((item, index) => {
                        return (
                          <ComponentRateShow
                            key={index}
                            index={index}
                            data={data}
                            setData={setData}
                            item={item}
                          />
                        )
                      })
                    }
                  </>) : (<>
                    {
                      data && data.map((item, index) => {
                        return (
                          <ComponentRate
                            key={index}
                            index={index}
                            data={data}
                            setData={setData}
                            item={item}
                          />
                        )
                      })
                    }
                  </>)
              }
            </table>
            <div style={{ height: 20 }}></div>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
              <Button variant="contained">Skip</Button>
              <Button variant="contained" color="primary" onClick={() => { insert() }}>
                {!isLoading ? (
                  <>
                    Continue
                  </>
                ) : (
                  <>
                    {
                      <Spinner animation="border" variant="light" size='sm' />
                    }
                  </>)
                }
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {showBox && (
          <>
            <Card style={{
              overflowY: "scroll",
              width: 520,
              position: "absolute",
              height: 300,
              marginLeft: 560
            }}>
              <CardActionArea>
                <CardContent>
                  {searchResult.length > 0 ? (
                    <>
                      {searchResult.map((data, index) => {
                        return (
                          <>
                            <Typography
                              variant="body2"
                              key={index}
                              style={{
                                cursor: "pointer",
                                fontWeight:
                                  active === data?.name_eng ? "bold" : "",
                              }}
                              onClick={() => getNameList(data?.c_id)}
                              onMouseOver={() => setActive(data?.name_eng)}
                              onMouseLeave={() => setActive(null)}
                            >
                              {data?.name_eng}- {data?.currencies_name}
                            </Typography>
                            <br />
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {listaccountname && listaccountname.map((data, index) => {
                        return (
                          <>
                            <Typography
                              key={index}
                              variant="body2"
                              style={{
                                cursor: "pointer",
                                fontWeight:
                                  active === data?.name_eng ? "bold" : "",
                              }}
                              onClick={() => getNameList(data?.c_id)}
                              onMouseOver={() => setActive(data?.name_eng)}
                              onMouseLeave={() => setActive(null)}
                            >
                              {data?.name_eng}
                            </Typography>
                            <br />
                          </>
                        );
                      })}
                    </>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </>
        )}
        {
          loading ? (
            <>
              {/* <TableContainer component={Paper} >
                <Table className={classes.table} size="small" aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 500, fontWeight: 'bold' }}>DATE</TableCell>
                      <TableCell align="left" style={{ width: 300, fontWeight: 'bold' }}>TRANSACTION TYPE</TableCell>
                      <TableCell align="left" style={{ width: 100, fontWeight: 'bold' }}>NO</TableCell>
                      <TableCell align="left" style={{ width: 200, fontWeight: 'bold' }}>DESCRIPTION</TableCell>
                      <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>AMOUNT</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold' }}>BALANCE</TableCell>
                      {
                        currentbalance === true ? (<>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>CURRENT BALANCE</TableCell>
                        </>) : null
                      }
                      {
                        showdebit === true ? (
                          <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>Debits</TableCell>
                        ) : null
                      }
                      {
                        showcredit === true ? (
                          <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>Credits</TableCell>
                        ) : null
                      }
                      {
                        foreigndebit === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Debit</TableCell>
                        </>) : null
                      }
                      {
                        foreigncredit === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Credit</TableCell>
                        </>) : null
                      }
                      {
                        foreignamount === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Amount</TableCell>
                        </>) : null
                      }
                      {
                        foreignbalance === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Balance</TableCell>
                        </>) : null
                      }
                      {
                        rate === true ? (<>
                          <TableCell align="right" style={{ width: 100, fontWeight: 'bold' }}>Rate</TableCell>
                        </>) : null
                      }
                      {
                        exchangerate === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Exchange Rate</TableCell>
                        </>) : null
                      }
                      {
                        gain_Loss === true ? (<>
                          <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Gain/Loss</TableCell>
                        </>) : null
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listgl.fist_gldata && listgl.fist_gldata.map((item, index) => {
                      return (
                        <>
                          < GLRowComponent
                            CHART_NAME_ENG={item?.CHART_NAME_ENG}
                            id={item?.CHART_ID}
                            balances={item?.BALANCES}
                            index={index}
                            second={listgl && listgl.second_gldata}
                            childrenFirstFloor={listgl && listgl.firstchildren_data}
                            childrenSecondFloor={listgl && listgl.secondchildren_data}
                            onGotoEditjournal={onGotoEditjournal}
                            OnEditJournal={OnEditJournal}
                            defaultValue={defaultValue}
                            defaultValue1={defaultValue1}
                            getvalues={getvalues}
                            ch_id={ch_id}
                            showdebit={showdebit}
                            showcredit={showcredit}
                            foreigndebit={foreigndebit}
                            foreigncredit={foreigncredit}
                            foreignamount={foreignamount}
                            foreignbalance={foreignbalance}
                            rate={rate}
                            exchangerate={exchangerate}
                            gain_Loss={gain_Loss}
                            currentbalance={currentbalance}
                            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
                            exchange_conditions={exchange_conditions}
                            current_balance_conditions={current_balance_conditions}
                          />
                        </>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer> */}
              <TableContainer className={classes.tableContainer} >
                <Table stickyHeader aria-label="sticky table" size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyHeader} style={{ width: 300, fontWeight: 'bold' }}>Date</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Transaction Type</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 100, fontWeight: 'bold' }} align="left">Number</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Desription</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Debit</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Credit</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Amount</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Currency</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Exchange Rate</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Debit</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Credit</TableCell>
                      <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Amount</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listgl.fist_gldata && listgl.fist_gldata.map((item, index) => {
                      return (
                        <>
                          < GLRowComponent
                            CHART_NAME_ENG={item?.CHART_NAME_ENG}
                            id={item?.CHART_ID}
                            index={index}
                            second={listgl && listgl.second_gldata}
                            childrenFirstFloor={listgl && listgl.firstchildren_data}
                            childrenSecondFloor={listgl && listgl.secondchildren_data}
                            onGotoEditjournal={onGotoEditjournal}
                            OnEditJournal={OnEditJournal}
                            defaultValue={defaultValue}
                            defaultValue1={defaultValue1}
                            getvalues={getvalues}
                            ch_id={ch_id}
                            showdebit={showdebit}
                            showcredit={showcredit}
                            foreigndebit={foreigndebit}
                            foreigncredit={foreigncredit}
                            foreignamount={foreignamount}
                            foreignbalance={foreignbalance}
                            rate={rate}
                            exchangerate={exchangerate}
                            gain_Loss={gain_Loss}
                            currentbalance={currentbalance}
                            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
                            exchange_conditions={exchange_conditions}
                            current_balance_conditions={current_balance_conditions}
                          />
                        </>
                      )
                    })
                    }

                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Skeleton />
          )
        }
      </div>
    </>
  )
}

function CellRowComponent({ CHART_NAME_ENG, id, childrenFirstFloor }) {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow >
        <TableCell onClick={() => { handleClick() }} component="th" scope="row" style={{ width: 300 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
          {CHART_NAME_ENG}
        </TableCell>
        <TableCell style={{ width: 150 }}></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 100 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
      </TableRow>
      {open ? (
        <>
          < RowComponentCell
            CHART_NAME_ENG={CHART_NAME_ENG}
            id={id}
            childrenFirstFloor={childrenFirstFloor}
          />

        </>
      ) : null
      }
    </>
  )
}
function RowComponentCell({ childrenFirstFloor, CHART_NAME_ENG, id }) {
  if (childrenFirstFloor === null) return <></>
  const children = childrenFirstFloor.filter((el) => el.CHART_ID == id);
  return (
    <>
      {children && children.map((item, index) => {
        return (

          <TableRow key={index} >
            <TableCell component="th" scope="row" style={{ width: 300, cursor: 'pointer' }} >
              NAME
            </TableCell>
            <TableCell style={{ width: 150 }}>{moment(item?.CREATEDATE).format('DD-MM-YYYY')}</TableCell>
            <TableCell align="left" style={{ width: 200 }} >Type</TableCell>
            <TableCell align="left" style={{ width: 100 }} >{item?.JOURNAL_NO}</TableCell>
            <TableCell align="left" style={{ width: 200 }} >
              {/* <BoxDescriptions children={item?.DESRIPTIONS} /> */}
            </TableCell>
            <TableCell align="left" style={{ width: 200 }} >
              {/* <ReadMoreAccount children={item?.CHART_NAME_ENG} /> */}

            </TableCell>
            <TableCell align="right" style={{ width: 200 }} >{getFormatNumber(item?.CURRENCIES_DEBIT)}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(item?.CURRENCIES_CREDIT)}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(item?.FOREIGN_AMOUNT)}</TableCell>

            <TableCell align="right" style={{ width: 250, color: 'blue' }} >{getFormatNumber(item?.EXCHNAGE_RATE)}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{item?.TRANSACTIONS_CURRRENCY}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(item?.DEBIT)}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(item?.CREDIT)}</TableCell>
            <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(item?.AMOUNT)}</TableCell>




          </TableRow>
        )
      })}
      <TableRow >
        <TableCell align="left" colspan={6} style={{ fontWeight: 'bold' }}>Total for {CHART_NAME_ENG}</TableCell>
        <TableCell ></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>333₭</TableCell>
        <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>4444₭</TableCell>
        <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>5555₭</TableCell>
      </TableRow>
    </>
  )
}



function GLRowComponent({
  CHART_NAME_ENG,
  id,
  second,
  childrenFirstFloor,
  childrenSecondFloor,
  onGotoEditjournal,
  OnEditJournal,
  defaultValue,
  defaultValue1,
  getvalues,
  ch_id,
  showcredit,
  showdebit,
  foreigndebit,
  foreigncredit,
  foreignamount,
  foreignbalance,
  rate,
  exchangerate,
  gain_Loss,
  currentbalance,
  OnShowAatumaticTransaction,
  exchange_conditions,
  current_balance_conditions,
  balances
}) {
  const [open, setOpen] = useState(true);
  const [netTotal1, setNetTotal1] = useState([])
  let total1 = 0;
  let Totall_all = 0
  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <>

      <TableRow >
        <TableCell onClick={() => { handleClick() }} component="th" scope="row" style={{ width: 300 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
          {CHART_NAME_ENG}
        </TableCell>
        <TableCell style={{ width: 150 }}></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 100 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
      </TableRow>
      {open ? (
        <>
          < RowComponent
            OnEditJournal={OnEditJournal}
            id={id}
            CHART_NAME_ENG={CHART_NAME_ENG}
            childrenFirstFloor={childrenFirstFloor}
            onGotoEditjournal={onGotoEditjournal}
            total1={total1}
            Totall_all={Totall_all}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
            exchange_conditions={exchange_conditions}
            setNetTotal1={setNetTotal1}

          />
          < SecondFloorRowComponent
            OnEditJournal={OnEditJournal}
            level={30}
            second={second}
            id={id}
            onGotoEditjournal={onGotoEditjournal}
            childrenSecondFloor={childrenSecondFloor}
            defaultValue={defaultValue}
            defaultValue1={defaultValue1}
            getvalues={getvalues}
            ch_id={ch_id}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
            exchange_conditions={exchange_conditions}
            current_balance_conditions={current_balance_conditions}
          />
        </>
      ) : null
      }
    </>
  )
}
function RowComponent({ id, CHART_NAME_ENG, OnEditJournal, total1, childrenFirstFloor, showcredit, showdebit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance, exchange_conditions, setNetTotal1, Totall_all }) {
  let debittotal = 0;
  let credittoal = 0;
  let totalforeigndebit = 0;
  let totalforeigncredit = 0;
  let totalForeignamount = 0;
  if (childrenFirstFloor === null) return <></>
  const filter = childrenFirstFloor.filter((el) => el.CHART_ID == id);
  if (filter.length === 0) return <></>;
  const updatedData = [...filter].sort((a, b) => new Date(a.DATE_EXCHANGE_RATE) - new Date(b.DATE_EXCHANGE_RATE));
  updatedData.forEach((data, index) => {
    data.BALANCES = parseFloat(data.AMOUT) + (index > 0 ? updatedData[index - 1].BALANCES : 0);
    data.FOREIGN_BALANCES = parseFloat(data.FOREIGN_AMOUNT) + (index > 0 ? updatedData[index - 1].FOREIGN_BALANCES : 0);
  });
  return (
    <>
      {
        updatedData && updatedData.map((data, index) => {
          Totall_all += parseFloat(data?.AMOUT)
          setNetTotal1(Totall_all)
          if (data?.DEBIT !== '0.00') {
            debittotal += parseFloat(data?.DEBIT)
          }
          if (data?.CREDIT !== '0.00') {
            credittoal += parseFloat(data?.CREDIT)
          }
          if (data?.CURRENCIES_DEBIT !== '0.00') {
            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              totalforeigndebit = 0
            } else {
              totalforeigndebit += parseFloat(data?.CURRENCIES_DEBIT)
            }
          }
          if (data?.CURRENCIES_CREDIT !== '0.00') {
            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              totalforeigncredit = 0
            } else {
              totalforeigncredit += parseFloat(data?.CURRENCIES_CREDIT)
            }
          }
          if (data?.BEGINING_BALANCE != 0) {

            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              total1 = 0
            } else {
              total1 += parseFloat(data?.AMOUT)
            }
          }
          if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
            totalForeignamount = 0
          } else {
            totalForeignamount += parseFloat(data?.FOREIGN_AMOUNT)
          }
          return (
            <>
              <TableRow key={index} >
                <TableCell style={{ cursor: "pointer", justifyContent: 'center' }} onClick={() => { OnEditJournal(data?.TR_ID) }}>{moment(data?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                <TableCell align="left" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }} >Journal Entry</TableCell>
                <TableCell align="left" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{data?.JOURNAL_NO}</TableCell>
                <TableCell align="left" style={{ cursor: "pointer" }}><BoxDescriptions children={data?.LG_DESC} /></TableCell>
                <TableCell align="right" style={{ cursor: "pointer" }} onClick={() => { OnEditJournal(data?.TR_ID) }}>{getFormatNumber(data?.DEBIT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{getFormatNumber(data?.CREDIT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{getFormatNumber(data?.AMOUT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{data?.FOREIGN_CODE}</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer", color: 'blue' }}>{getFormatNumber(data?.EXCHNAGE_RATE)}</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>
                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.CURRENCIES_DEBIT}</>)
                  }

                </TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>

                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.CURRENCIES_CREDIT}</>)
                  }
                </TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>
                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.FOREIGN_AMOUNT}</>)
                  }

                </TableCell>
              </TableRow>
            </>
          )
        })
      }
      <TableRow>
        <TableCell align="left" colspan={3} style={{ fontWeight: 'bold' }}>Total for {CHART_NAME_ENG}</TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="right">{getFormatNumber(debittotal)}</TableCell>
        <TableCell align="right">{getFormatNumber(credittoal)}</TableCell>
        <TableCell align="right">{getFormatNumber(total1)}</TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right">{getFormatNumber(totalforeigndebit)}</TableCell>
        <TableCell align="right">{getFormatNumber(totalforeigncredit)}</TableCell>
        <TableCell align="right">{getFormatNumber(totalForeignamount)}</TableCell>
      </TableRow>
    </>
  )
}
function SecondFloorRowComponent({ level, second, id, OnEditJournal, childrenSecondFloor, defaultValue, defaultValue1, getvalues, ch_id, showcredit, showdebit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance, OnShowAatumaticTransaction, exchange_conditions, current_balance_conditions }) {
  if (second === null) return <></>
  const filter = second.filter((el) => el.CHART_PARENTS == id);
  return (
    <>
      {
        filter && filter.map((data, index) => {

          return (
            <>
              < TableCellComponent
                data={data}
                id={data?.CHART_ID}
                balances={data?.BALANCES}
                level={level}
                index={index}
                second={second}
                OnEditJournal={OnEditJournal}
                childrenSecondFloor={childrenSecondFloor}
                defaultValue={defaultValue}
                defaultValue1={defaultValue1}
                getvalues={getvalues}
                ch_id={ch_id}
                showdebit={showdebit}
                showcredit={showcredit}
                foreigndebit={foreigndebit}
                foreigncredit={foreigncredit}
                foreignamount={foreignamount}
                foreignbalance={foreignbalance}
                rate={rate}
                exchangerate={exchangerate}
                gain_Loss={gain_Loss}
                currentbalance={currentbalance}
                OnShowAatumaticTransaction={OnShowAatumaticTransaction}
                exchange_conditions={exchange_conditions}
                current_balance_conditions={current_balance_conditions}
              />
            </>
          )
        })
      }
    </>
  )
}
function TableCellComponent({ data, level, index, second, OnEditJournal, childrenSecondFloor, showcredit, showdebit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance, OnShowAatumaticTransaction, exchange_conditions,
  current_balance_conditions,
  balances
}) {
  const [open, setOpen] = useState(true);
  const [netTotal1, setNetTotal1] = useState([])
  let Totall_all = 0;
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>

      <TableRow >
        <TableCell onClick={() => { handleClick() }} component="th" scope="row" style={{ width: 300, cursor: "pointer", paddingLeft: level, fontSize: 14 }} colspan={3}>
          {open ? <ExpandLess /> : <ExpandMore />}
          {data?.CHART_NAME_ENG}
        </TableCell>
        <TableCell style={{ width: 150 }}></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 100 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 200 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
        <TableCell align="left" style={{ width: 250 }} ></TableCell>
      </TableRow>
      {open ? (
        <>
          < SecondRowComponent
            id={data?.CHART_ID}
            CHART_NAME_ENG={data?.CHART_NAME_ENG}
            amout={data?.amout}
            level={level}
            OnEditJournal={OnEditJournal}
            childrenSecondFloor={childrenSecondFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
            exchange_conditions={exchange_conditions}
            current_balance_conditions={current_balance_conditions}
            setNetTotal1={setNetTotal1}
            Totall_all={Totall_all}
          />
          < SecondFloorRowComponent
            level={level * 1.5}
            second={second}
            id={data.CHART_ID}
            CHART_NAME_ENG={data?.CHART_NAME_ENG}
            data={data}
            netTotal1={netTotal1}
            index={index}
            OnEditJournal={OnEditJournal}
            childrenSecondFloor={childrenSecondFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
            OnShowAatumaticTransaction={OnShowAatumaticTransaction}
            exchange_conditions={exchange_conditions}
            current_balance_conditions={current_balance_conditions}
            setNetTotal1={setNetTotal1}
            Totall_all={Totall_all}
          />
        </>
      ) : null}
    </>
  )
}
function SecondRowComponent({ id, CHART_NAME_ENG, level, OnEditJournal, childrenSecondFloor, showdebit, showcredit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance, exchange_conditions, Totall_all, setNetTotal1 }) {
  let debittotal = 0;
  let credittoal = 0;
  let totalforeigndebit = 0;
  let totalforeigncredit = 0;
  let totalForeignamount = 0;
  let total = 0

  if (childrenSecondFloor === null) return <></>
  const filter = childrenSecondFloor.filter((el) => el.CHART_ID == id);

  if (filter.length === 0) return <></>;
  const updatedData = [...filter].sort((a, b) => new Date(a.DATE_EXCHANGE_RATE) - new Date(b.DATE_EXCHANGE_RATE));
  updatedData.forEach((data, index) => {
    data.BALANCES = parseFloat(data.AMOUT) + (index > 0 ? updatedData[index - 1].BALANCES : 0);
    data.FOREIGN_BALANCES = parseFloat(data.FOREIGN_AMOUNT) + (index > 0 ? updatedData[index - 1].FOREIGN_BALANCES : 0);
  });
  return (
    <>
      {
        updatedData && updatedData.map((data, index) => {
          Totall_all += parseFloat(data?.AMOUT)
          setNetTotal1(Totall_all)
          if (data?.DEBIT !== '0.00') {
            debittotal += parseFloat(data?.DEBIT)
          }
          if (data?.CREDIT !== '0.00') {
            credittoal += parseFloat(data?.CREDIT)
          }
          if (data?.CURRENCIES_DEBIT !== '0.00') {
            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              totalforeigndebit = 0
            } else {
              totalforeigndebit += parseFloat(data?.CURRENCIES_DEBIT)
            }

          }
          if (data?.CURRENCIES_CREDIT !== '0.00') {
            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              totalforeigncredit = 0
            } else {
              totalforeigncredit += parseFloat(data?.CURRENCIES_CREDIT)
            }

          }
          if (data?.BEGINING_BALANCE != 0) {

            if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
              total = 0
            } else {
              total += parseFloat(data?.AMOUT)
            }
          }
          if (data?.TRANSACTIONS_CURRENCY === 'LAK') {
            totalForeignamount = 0
          } else {
            totalForeignamount += parseFloat(data?.FOREIGN_AMOUNT)
          }

          return (
            <>
              <TableRow key={index} >
                <TableCell style={{ cursor: "pointer", justifyContent: 'center' }} onClick={() => { OnEditJournal(data?.TR_ID) }}>{moment(data?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                <TableCell align="left" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }} >Journal Entry</TableCell>
                <TableCell align="left" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{data?.JOURNAL_NO}</TableCell>
                <TableCell align="left" style={{ cursor: "pointer" }}><BoxDescriptions children={data?.LG_DESC} /></TableCell>
                <TableCell align="right" style={{ cursor: "pointer" }} onClick={() => { OnEditJournal(data?.TR_ID) }}>{getFormatNumber(data?.DEBIT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{getFormatNumber(data?.CREDIT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{getFormatNumber(data?.AMOUT)}₭</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>{data?.FOREIGN_CODE}</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer", color: 'blue' }}>{getFormatNumber(data?.EXCHNAGE_RATE)}</TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>
                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.CURRENCIES_DEBIT}</>)
                  }


                </TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>

                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.CURRENCIES_CREDIT}</>)
                  }
                </TableCell>
                <TableCell align="right" onClick={() => { OnEditJournal(data?.TR_ID) }} style={{ cursor: "pointer" }}>
                  {
                    data?.TRANSACTIONS_CURRENCY === 'LAK' ? (<></>) : (<> {data?.FOREIGN_AMOUNT}</>)
                  }

                </TableCell>
              </TableRow>
            </>
          )
        })}
      <TableRow>
        <TableCell align="left" colspan={3} style={{ fontWeight: 'bold' }}>Total for {CHART_NAME_ENG}</TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="right">{getFormatNumber(debittotal)}</TableCell>
        <TableCell align="right">{getFormatNumber(credittoal)}</TableCell>
        <TableCell align="right">{getFormatNumber(total)}</TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right">{getFormatNumber(totalforeigndebit)}</TableCell>
        <TableCell align="right">{getFormatNumber(totalforeigncredit)}</TableCell>
        <TableCell align="right">{getFormatNumber(totalForeignamount)}</TableCell>
      </TableRow>
    </>
  )
}
function ReadMore({ children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {
        text == null ? (<></>) : (<>
          {isReadMore ? text.slice(0, 10) : text}
          <span onClick={toggleReadMore} className="read-or-hide" style={{ color: "green" }}>
            {isReadMore ? "...read more" : " show less"}
          </span>
        </>)
      }
    </p>
  );
};
function ComponentRateShow({ item, data, index, setData }) {
  const [currency, setCurrency] = useState('')
  const changeText = (value, key, index) => {
    const object = { ...data[index] };
    object[key] = value;
    const cloneData = [...data];
    cloneData[index] = { ...object };
    setData([...cloneData]);
  };
  return (
    <>
      <tr style={{ border: '1px solid #ccc', height: 50 }}>
        <td style={{ paddingLeft: 55 }}>
          <input type="hidden"
            onChange={(e) => setCurrency(e.target.value)}
            value={data?.name} />
          {item?.name}
        </td>
        <td align="right" style={{ paddingRight: 20 }}>
          <input
            type="text"
            value={item?.rate}
            onChange={(e) => changeText(e.target.value, "rate", index)}
            style={{
              border: '0.1px solid #ccc',
              outline: 'none',
              paddingLeft: 10,
              borderRadius: 3,
              height: 35,
              textAlign: "right"
            }}
          /></td>
      </tr>
    </>
  )
}
function ComponentRate({ item, data, index, setData }) {
  const [currency, setCurrency] = useState('')
  const changeText = (value, key, index) => {
    const object = { ...data[index] };
    object[key] = value;
    const cloneData = [...data];
    cloneData[index] = { ...object };
    setData([...cloneData]);
  };
  return (
    <>
      <tr style={{ border: '1px solid #ccc', height: 50 }}>
        <td style={{ paddingLeft: 55 }}>
          <input type="hidden"
            onChange={(e) => setCurrency(e.target.value)}
            value={data?.name} />
          {item?.name}
        </td>
        <td align="right" style={{ paddingRight: 20 }}>
          <input
            type="text"
            value={item?.rate}
            onChange={(e) => changeText(e.target.value, "rate", index)}
            style={{
              border: '0.1px solid #ccc',
              outline: 'none',
              paddingLeft: 10,
              borderRadius: 3,
              height: 35,
              textAlign: "right"
            }}
          /></td>
      </tr>
    </>
  )
}
const formatMoney = (value) => {
  // Remove any non-digit characters except decimal points
  const cleanedValue = value.replace(/[^\d.]/g, '');
  // Split the value into integer and decimal parts
  const parts = cleanedValue.split('.');

  // Format the integer part with commas
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Handle the decimal part
  if (parts[1]) {
    // Check if the decimal part has only zeros after the second digit
    const truncatedDecimal = parts[1].slice(0, 2);
    const remainingDecimal = parts[1].slice(2);
    if (remainingDecimal.match(/^0*$/)) {
      // If there are only zeros after the second digit, truncate to two digits
      parts[1] = truncatedDecimal;
    } else {
      // Remove trailing zeros without truncating to two digits
      parts[1] = parts[1].replace(/0+$/, '');
      // If all zeros are removed and no decimal part is left
      if (parts[1] === '') {
        return parts[0];
      }
    }
    // Join the integer and decimal parts and return the result
    return parts.join('.');
  }
  // If there is no decimal part, return the integer part with commas
  return parts[0];
};


