import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
export const Add_Taxs = (props) => {
   
    const [active, setActive] = useState(null)
    const handleChange_sales = event => {
        if (event.target.checked) {
            props.setSales_conditions(true)
            props.setConditions('Sales')
        } else {
            props.setSales_conditions(false)
            props.setConditions('')
        }

    };
    const handleChange_purchase = event => {
        if (event.target.checked) {

            props.setPurchases_conditions(true)
            props.setConditions('Purchases')

        } else {

            props.setPurchases_conditions(false)
            props.setConditions('')

        }

    };
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.show} onHide={props.handleClose} style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Taxes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Tax name"
                                onChange={(e) => props.setTaxname(e.target.value)}
                                value={props.taxname}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label style={{ fontSize: 20 }}></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Description"
                                onChange={(e) => props.setDescriptions(e.target.value)}
                                value={props.descriptions}

                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                            <Form.Check
                                type="checkbox"
                                label="Sales"
                                onChange={handleChange_sales}
                            />
                        </Form.Group>
                        {
                            props.sales_conditions ? (<>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        placeholder="Sales Rate %"
                                        onChange={(e) => props.setTaxs_amount(e.target.value)}
                                        value={props.taxs_amount}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => props.setAccount_uid(e.target.value)}
                                        value={props.account_uid}

                                    >
                                        <>
                                            <option value='f0060034-bd5b-403e-a051-409e03877669'>Liability</option>
                                            <option value='162840c5-bf5d-42d8-bb42-581ed5b416be'>Expense</option>

                                        </>
                                    </Form.Select>

                                </Form.Group>

                            </>) : null
                        }

                        <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                            <Form.Check
                                type="checkbox"
                                label="Purchases"
                                onChange={handleChange_purchase}
                            />
                        </Form.Group>
                        {
                            props.purchases_conditions ? (<>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        placeholder="Sales Rate %"
                                        onChange={(e) => props.setTaxs_amount(e.target.value)}
                                        value={props.taxs_amount}
                                    />
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => props.setAccount_uid(e.target.value)}
                                        value={props.account_uid}

                                    >
                                        <>
                                            <option value='f0060034-bd5b-403e-a051-409e03877669'>Liability</option>
                                            <option value='162840c5-bf5d-42d8-bb42-581ed5b416be'>Expense</option>

                                        </>
                                    </Form.Select>

                                </Form.Group>

                            </>) : null
                        }

                    </Form>
                </Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        marginRight: 10,
                        width: 100,
                        height: 35,
                        marginBottom: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5,
                        cursor: 'pointer',
                        boxShadow: active === 'save' ? '1px 2px 9px #F4AAB9' : '',

                    }}
                        onMouseOver={() => setActive('save')}
                        onMouseLeave={() => setActive(null)}
                        onClick={()=>{props.CreateTaxs()}}
                    >
                        <small style={{ color: 'white', fontSize: 18 }}>Save</small>
                    </div>

                </div>


            </Modal>

        </div>
    )
}
