import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import moment from 'moment';
import axios from "axios";
import { LoginContext } from "../page/contexts/LoginContext";
import { getFormatNumber } from "../constants/functions";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


export default function ExchangeRate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    OnlaodGl,company_uid,user_session
  } = useContext(LoginContext);
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState("")
  const [exchange, setExchange] = useState([])
  const [currency_code, setCurrency_code] = useState("")
  const [conver_rate, setConver_rate] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)
  const [records, setRecords] = useState([])

  const Onload = () => {
    axios.get("/accounting/api/exchange_rate").then((data) => {
      setRecords([...data?.data?.result])
    }).catch((err) => {
      console.log(err)
    })
  }

  const columns = [
    {
      name: "Date Exchange Rate",
      selector: row => moment(row.CREATEDATE).format('YYYY-MM-DD'),
      sortable: true
    },
    {
      name: "Currency",
      selector: (row) => {
        if (row.FOREIGN_CODE === 'USD') {
          return <img src="/assets/images/USA.png" alt="USD" style={{ width: 30, height: 30, marginTop: 5, borderRadius: '50%' }}/>;
        } else if (row.FOREIGN_CODE === 'THB') {
          return <img src="/assets/images/thailand.png" alt="THB" style={{ width: 30, height: 30, marginTop: 5, borderRadius: '50%' }}/>;
        }
      },
      sortable: true,
    },
{
  name: "Exchange Rate",
    selector: row => row.EXCHANGE_RATE,
      sortable: true
},
{
  name: "Create by",
    selector: row => row.CREATE_BY,
      sortable: true
}
  ]
const goback = () => {
  navigate('/Profitandloss')
}

const EnterDate = (e) => {
  setIsDisabled(false)
  setDefaultValue(moment(e).format("DD-MM-YYYY"))
}
const OnGetvaues = (e) => {
  setCurrency_code(e)


}
const onChangeTextCurrency = (value) => {
  const ratenumber = value.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  setExchange(ratenumber);
  setConver_rate(ratenumber.replaceAll(',', ''))
}
const onBlurCurrency = (value) => {
  let number = value.replaceAll(',', '')
  let format_number = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
  setExchange(format_number.replaceAll('$', ''))
}
const insert = () => {
  let informdata = {
    FOREIGN_CODE_EXCHANG: currency_code,
    CREATEDATE_EXCHANG: defaultValue,
    EXCHANGE_RATE_EXCHANGE: conver_rate,
    COMPANY_UID:company_uid,
    CREATE_BY:user_session
  }
  axios.post('/accounting/api/exchange_rate/Insert', informdata).then((data) => {
    Onload()
    OnlaodGl();
    setDefaultValue('')
    setCurrency_code('')
    setExchange('')
  }).catch((err) => {
    console.log(err)
  })
}
useEffect(() => {
  Onload()
}, [])
return (
  <>
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between'
          }}
        >
          {
            id == 2 ? (<>
              <p
                style={{
                  fontSize: 20,
                  color: "black",
                  fontFamily: "Phetsarath OT",
                  cursor: 'pointer',
                  backgroundColor: "#3f51b5",
                  borderRadius: '50%'
                }}
                onClick={() => { goback() }}
              >
                <ArrowBackIcon style={{ color: "white" }} />
              </p>

            </>) : null
          }
          <h4 style={{ marginLeft: 10, color: 'black' }}>Exchange Rate</h4>
        </div>
      </Breadcrumbs>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <div
        style={{
          height: 5,
          backgroundColor: "#3f51b5",
        }}
      ></div>
      <div
        style={{
          height: 10,
        }}
      ></div>

    </div>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <input
          type="text"
          placeholder="dd/MM/yyyy"
          value={defaultValue}
          onChange={(e) => setDefaultValue(e.target.value)}
          style={{
            border: '1px solid #ccc',
            height: 36,
            borderRadius: 3,
            width: 100,
            paddingLeft: 10,
            marginLeft: 5,
            textAlign: "right",
            borderRight: "none",
          }}
        />
        <input
          type="date"
          style={{
            border: '1px solid #ccc',
            height: 36,
            borderRadius: 3,
            width: 30,
            paddingLeft: 10,
          }}
          onChange={(e) => EnterDate(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, justifyContent: 'flex-start' }}>
        <select
          disabled={isDisabled}
          style={{
            border: '1px solid #ccc',
            height: 36,
            borderRadius: 3,
            width: 130,
            marginRight: 10
          }}
          onChange={(e) => {
            OnGetvaues(e.target.value);
          }}
          value={currency_code}
        >
          <option>SELECT CURRENCY</option>
          <option>USD</option>
          <option>THB</option>
        </select>
        <input
          type="text"
          value={exchange}
          placeholder="Exchange Rate"
          style={{
            border: '0.1px solid #ccc',
            outline: 'none',
            borderRadius: 3,
            height: 36,
            textAlign: 'right'
          }}
          onChange={(e) => onChangeTextCurrency(e.target.value)}
          onBlur={(e) => onBlurCurrency(e.target.value)}
        />
        <Button variant="contained" color="primary" style={{ height: 36, marginLeft: 10 }} onClick={() => { insert() }} >Add</Button>
        <Button variant="contained" color="primary" style={{ height: 36, marginLeft: 10 }} >Clear</Button>
      </div>

    </div>
    <DataTable
      columns={columns}
      data={records}
      selectableRows
      fixedHeader
      pagination
    />
    {/* <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Date Exchange Rate</TableCell>
              <TableCell align="right">Currency</TableCell>
              <TableCell align="right">Exchange Rate</TableCell>
              <TableCell align="right">Create by</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              records && records.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell align="left" >{moment(item?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                  
                      {
                        item?.FOREIGN_CODE === 'USD' ? (
                          <>
                            <TableCell align="right" ><img alt="Logo" src="/assets/images/USA.png" style={{ width: 30, height: 30, marginTop: 5, borderRadius: '50%' }} /></TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" ><img alt="Logo" src="/assets/images/thailand.png" style={{ width: 30, height: 30, marginTop: 5, borderRadius: '50%' }} /></TableCell>
                          </>
                        )
                      }
                      <TableCell align="right" >{getFormatNumber(item?.EXCHANGE_RATE)}</TableCell>
                      <TableCell align="right" >{item?.CREATE_BY}</TableCell>

                    </TableRow>
                  </>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer> */}


  </>

);
}
