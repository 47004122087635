import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ITEM_HEIGHT = 48;
export default function Option_select(props) {

    const open = Boolean(props.anchorEl);

    return (
        <div>


            <Menu
                id="long-menu"
                anchorEl={props.anchorEl}
                keepMounted
                open={open}
                onClose={props.handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25%',
                        marginTop: 50,
                    },
                }}
            >
                <MenuItem onClick={() => { props.showDrawer(props.getindex); }}>Add New</MenuItem>
                {props.listuser.map((item, index) => (
                    <MenuItem
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => { props.select_options(item?.CUSTOMERS_ID, item?.CUSTOMERS_NAME, item?.CURRENCY, props.getindex, props.data); }}
                    >
                        <small>{item?.CUSTOMERS_NAME} - {item?.CURRENCY}</small>
                        <small style={{ color: '#ccc', fontWeight: "bold" }}>{item?.CREATESTATUS}</small>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
