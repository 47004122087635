import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Form } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
const ITEM_HEIGHT = 48;
export default function Add_Currency(props) {
    const open = Boolean(props.anchorElTax);
    return (
        <div>
            <Menu
                id="long-menu"
                anchorEl={props.anchorElTax}
                keepMounted
                open={open}
                onClose={props.handleCloseTaxs}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "25%",
                        marginTop: 70,
                    },
                }}
            >
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
                        <p style={{ fontWeight: 'bold', fontSize: 20 }}>Add Currency</p>
                        <select style={{ border: '1px solid #ccc', height: 35, borderRadius: 3, outline: 'none' }}
                            onChange={(e) => props.setCurrency_id(e.target.value)}
                            value={props.currency_id}
                        >
                            {
                                props.listhome && props.listhome.map((item, index) => {
                                    return (
                                        <>
                                            <option value={item?.CURRENCY_UID} key={index}>{item?.CURRENCY_NAME}- {item?.CURRENCY_CODE}</option>
                                        </>)
                                })
                            }
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                        <div style={{
                            border: '1px solid #ccc',
                            width: '25%',
                            height: 30,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                            onClick={() => { props.handleClick_Close() }}
                        ><small>Cancel</small></div>
                        <div style={{
                            border: '1px solid #ccc',
                            width: '25%',
                            height: 30,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: "#3f51b5",
                            color: 'white',
                            cursor: "pointer"

                        }}
                            onClick={() => { props.Create_Currencies() }}
                        ><small >Add </small></div>

                    </div>

                </div>
            </Menu>
        </div>
    );
}
