import React, { useState, useContext, useEffect } from "react";
import { LoginContext } from "../page/contexts/LoginContext";
import { Modal } from "react-bootstrap";
export default function Add_department(props) {
    console.log("props.show=", props.show)
    const [active, setActive] = useState()
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.show} onHide={props.handleClosebox} size="lg" style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <Modal.Title>New Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <small style={{ marginLeft: 10 }}>Department</small>
                                <input
                                    type="text"
                                    style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                        border: '1px solid #ccc',
                                        outline: 'none',
                                        height: 35,
                                        borderRadius: 3,
                                    }}
                                    onChange={(e) => { props.setDepartment(e.target.value) }}
                                    value={props.department}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {
                                props.showupdate === true ? (<>
                                    <div style={{
                                        backgroundColor: '#3f51b5',
                                        marginRight: 10,
                                        marginTop: 20,
                                        width: '15%',
                                        marginBottom: 10,
                                        height: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        borderRadius: 3,
                                        cursor: 'pointer',
                                        boxShadow: active === 'save' ? '1px 2px 9px #F4AAB9' : '',
                                    }}
                                        onMouseOver={() => setActive('save')}
                                        onMouseLeave={() => setActive(null)}

                                    >
                                        <small >UPDATE</small>
                                    </div>
                                </>) : (<>
                                    <div style={{
                                        backgroundColor: '#3f51b5',
                                        marginRight: 10,
                                        marginTop: 20,
                                        width: '15%',
                                        marginBottom: 10,
                                        height: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        fontSize: 18,
                                        fontWeight: 'bold',
                                        borderRadius: 3,
                                        cursor: 'pointer',
                                        boxShadow: active === 'save' ? '1px 2px 9px #F4AAB9' : '',
                                    }}
                                        onMouseOver={() => setActive('save')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => { props.Create_department() }}
                                    >
                                        <small >SAVE</small>
                                    </div>
                                </>)
                            }


                        </div>


                    </div>

                </Modal.Body>

            </Modal>

        </div>
    )
}
