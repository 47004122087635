import React, { useState, useContext, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import { Modal, Spinner, Form } from "react-bootstrap";
import axios from "axios";
import { LoginContext } from "./contexts/LoginContext";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getFormatNumber } from "../constants/functions"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import FirstLongMenu from "../components/FirstLongMenu";
import SecondLongMenu from "../components/SecondLongMenu";
import { httpErrorHandler } from "../middleware/httpErrorHandler";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Already_exists } from "../modal/Already_exists";
import { Deactive_account } from "../modal/Deactive_account";
import { History } from "../modal/History"
import { v4 as uuidv4 } from 'uuid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});
const ChartAccounts = () => {
  const {
    listaccount,
    listcurrency,
    OnloadAllChart_of_Account,

    OnlaodGl,
    datatype,
    company_uid,
    user_session,
    setOpen,
    history,
    setHistory,
    fullscreen,
    setFullscreen,
    listchilden,
    setListchilden,


  } = useContext(LoginContext);

  const navigate = useNavigate();
  const Gotodetailaccount = (id) => {

    navigate(`/ReportGL/${id}`);
  }
  const uuid = uuidv4()
  const [show, setShow] = useState(false)
  const classes = useStyles2();
  const [ac_type, setAc_type] = useState("");
  const [errname, setErrname] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errorcurrency, setErrorcurrency] = useState(false)
  const [errorparrens, setErrorparrens] = useState(false)
  const [already, setAlready] = useState(false)
  const [prentid, setPrentid] = useState(0);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [nameShow, setNameShow] = useState("");
  const [accountname, setAccountname] = useState("")
  const [showBox, setShowBox] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [active, setActive] = useState("");
  const [disblebtn, setDisblebtn] = useState(true);
  const [checkedCurrency, setCheckedCurrency] = useState([])
  const [open1, setOpen1] = useState(true);
  const [checked, setChecked] = useState([]);
  const [currency, setCurrency] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [radiodebit, setRadiodebit] = useState(false);
  const [beginningBalance, setBeginningbalance] = useState(false)
  const [radiocredit, setRadiocredit] = useState(false);
  const [balance, setBalance] = useState("0");
  const [balancelak, setBalancelak] = useState("0");
  const [currencystatus, setCurrencystatus] = useState('');
  const [openshow, setOpenshow] = useState(false);
  const [exchangeRate, setExchangeRate] = useState("1")
  const [debit, setDebit] = useState(null);
  const [credit, setCredit] = useState(null);
  const [getid, setGetid] = useState("")
  const [onFucused, setOnFocused] = useState(false);
  const [onClose, setOnClose] = useState(false);
  const [isLoading, setIsLoading,] = useState(false);
  const [isLoading_reduces, setIsLoading_reduces] = useState(false);
  const [isLoadingupdating, setIsLoadingupdating] = useState(false)
  const [errexchangeRate, setErrexchangeRate] = useState(false);
  const [errcheckdebitcredit, seterrcheckdebitcredit] = useState(false)
  const [errbalance, setErrbalance] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [checkDisabled, setCheckDisabled] = useState(false)
  const [valuedate, setValuedate] = useState("")
  const [conditionsdata, setConditionsdata] = useState(false)
  const [listChart_Aaccount, setListChart_Aaccount] = useState([])
  const [datafilter, setDatafilter] = useState('')
  const [bs_id, setBs_id] = useState([])
  const [ac_symbol, setAc_symbol] = useState([])
  const [checked_number, setChecked_number] = useState('')
  const handleShow = () => setShow(true);

  const [readmore, setReadmore] = useState([])
  const [limit, setLimit] = useState(25)
  const [openingsetting, setOpeningsetting] = useState(false)
  const [openingDeactivate, setOpeningDeactivate] = useState(false)
  const [conditions_status, setConditions_status] = useState('')
  const [retained, setRetained] = useState(false)
  const [retained_yes, setRetained_yes] = useState(false)
  const [retained_no, setRetained_no] = useState(false)
  const [values_retained, setValues_retained] = useState('')
  const [reduces_chart_id, setReduces_chart_id] = useState('')
  const [getchart_id, setGetchart_id] = useState(null)
  const [conditions, setConditions] = useState('')
  const [statusCurrency, setStatusCurrency] = useState('')
  const [ispageloading, setIspageloading] = useState(false)
  const [loading, setLoading] = useState(false);
  const [listhistories, setListhistories] = useState([])
  const [listdetailType, setListdetailType] = useState([])
  const [titlecashflow_id, setTitlecashflow_id] = useState([])
  const [detail_Type_id, setDetail_Type_id] = useState('')
  const [disabled_currency, setDisabled_currency] = useState(false)
  const [condition_detail_type_id, setCondition_detail_type_id] = useState('')

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, listchilden.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setShow(false);
    setAc_type("");
    setShowUpdate(false);
    setName("");
    setDescription("");
    setPrentid(0);
    setNameShow("");
    setIsDisabled(false);
    setDisblebtn(true);
    setAccountname("")
    setShowBox(false);
    setChecked("");
    setRadiocredit(false);
    setRadiodebit(false);
    setBeginningbalance(false);
    setOpenshow(false);
    setBalancelak('')
    setCurrencystatus('')
    setBalance("0")
    setErrorcurrency('')
    setCurrency('')
    setErrorparrens('')
    setAlready('')
    setErrexchangeRate('')
    setValuedate('')
    setExchangeRate('')
    setCheckDisabled(false)
    setCheckedCurrency('')
    setRetained('')
    setRetained_no(false)
    setRetained_yes(false)
    setValues_retained('')
    setOpeningsetting(false)
    setDetail_Type_id('')
    setListdetailType([])
    setCondition_detail_type_id('')
  };
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const reduces_usage = (CHART_ID) => {
    setReduces_chart_id(CHART_ID)
    setOpeningDeactivate(true)
  }
  const handleCloseReduces_usage = () => {
    setOpeningDeactivate(false)
  }
  const Gohistory = (chart_id) => {
    setLoading(true)
    setGetchart_id(chart_id)
    setHistory(true)
    setOpen(false)
    axios.get(`/accounting/api/histories/${company_uid}/${chart_id}`).then((data) => {
      if (data?.data?.result?.length > 0) {
        setConditions(data?.data?.result[0].ACCOUNT_TYPE_STATUS)
        setStatusCurrency(data?.data?.result[0].FOREIGN_CODE)
        setListhistories([...data?.data?.result])
      }
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const GoClosehistory = () => {
    setHistory(false)
    setFullscreen(true)
    setStatusCurrency('')
    setConditions('')
  }
  const closeaccount = (reduces_chart_id) => {
    setIsLoading_reduces(true)
    let datauser = {
      V_CHART_ID: reduces_chart_id,
      CREATE_BY_USER: user_session,
      MODIFY_DATE_BY_USER: moment(new Date()).format('DD-MM-YYYY')
    }
    axios.post('/accounting/api/chartAccount/bank/Reduces_usage', datauser).then((data) => {
      OnloadChart_Of_Account()
      // OnloadChart_when_completed()
      setIsLoading_reduces(false)

      handleCloseReduces_usage()
    }).catch((err) => {
      console.log(err)
    })
  }
  const exportToExcel = () => {
    const header = ['NAME', 'TYPE', 'DETAIL TYPE', 'CURRENCY', 'BALANCE'];
    // Helper function to handle recursive mapping
    const mapData = (items, level = 0) => {
      return items.flatMap(item => {
        const prefix = '  '.repeat(level); // Indent child items based on their level
        const mainRow = [
          `${prefix}${item?.CHART_NAME_ENG}`,
          item?.ACCOUNT_TYPE_NAME_ENG,
          item?.DETAIL_TYPE_NAME_ENG,
          item?.CHART_CURRENCY_CODE,
          item?.BALANCES
        ];
        const childrenRows = item.children ? mapData(item.children, level + 1) : [];
        return [mainRow, ...childrenRows];
      });
    };

    const data = mapData(listchilden);

    // Add headers and data to worksheet
    const worksheetData = [header, ...data];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Accounts');
    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'Accounts.xlsx');
  };

  const _onsearchaccountid = (ac_type, company_uid) => {

    setAc_type(ac_type)
    let data = listaccount.filter((el) => el.ACCOUNT_TYPE_UID.includes(ac_type))
    console.log("exp=", data)


    setChecked(data[0].ACCOUNT_CREATESTATUS)
    setRetained(data[0].ACCOUNT_TYPE_AC_SYMBOL)
    const conditions = data[0].ACCOUNT_CREATESTATUS;
    if (conditions == 'In' || conditions == 'Ex') {
      console.log("dddd")
      setCurrencystatus('LAK')
      let listcurren = listcurrency.filter((item) => item.CURRENCY_NAME === 'LAK')
      console.log("listcurren=", listcurren)
      setCurrency(listcurren[0].CURRENCY_UID)

      let resulut_data = datatype.filter((e) => e.BS_UID.includes(ac_type))
      console.log("resulut_data=", resulut_data)

      setBs_id(resulut_data[0].BS_ID)
    } else {
      setBs_id(data[0].BS_ID)
    }
    setAc_symbol(data[0].ACCOUNT_TYPE_AC_SYMBOL)
    axios.get(`/accounting/api/chartAccount/bank/Type/${ac_type}/${company_uid}`).then((data) => {
      let list = [...data?.data?.result];
      const generateTree = (items, parentId) => {
        const tree = [];
        items.forEach(item => {
          if (item.CHART_PARENTS === parentId) {
            const children = generateTree(items, item.CHART_ID);
            const node = {
              CHART_ID: item.CHART_ID,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              CHART_PARENTS: item.CHART_PARENTS,
              CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
              children: children.length ? children : [],
            };
            tree.push(node);
          }
        });
        return tree;
      };

      setListChart_Aaccount(generateTree(list, 0))
    }).catch((err) => {
      console.log(err)
    })
    axios.get(`/accounting/api/chartAccount/detail_type/${ac_type}`).then((data) => {
      setListdetailType([...data?.data?.result])
      setCondition_detail_type_id(data?.data?.result[0].DETAIL_TYPE_ID)
      setTitlecashflow_id(data?.data?.result[0].CASHFLOWS_ID)
    }).catch((err) => {
      console.log(err)
    })
  }

  const _ondetail_Type = (detail_Type_id) => {
    setDetail_Type_id(detail_Type_id)
    setCondition_detail_type_id(detail_Type_id)
    // Filter data based on DETAIL_TYPE_ID
    let data = listdetailType.filter((e) => {
      // Check if DETAIL_TYPE_ID is an array
      if (Array.isArray(e.DETAIL_TYPE_ID)) {
        return e.DETAIL_TYPE_ID.includes(detail_Type_id);
      }
      // Check if DETAIL_TYPE_ID is a string
      else if (typeof e.DETAIL_TYPE_ID === 'string') {
        return e.DETAIL_TYPE_ID === detail_Type_id;
      }
      // Check if DETAIL_TYPE_ID is a number
      else if (typeof e.DETAIL_TYPE_ID === 'number') {
        // Convert number to string for comparison
        return e.DETAIL_TYPE_ID === detail_Type_id;
      }
      // Handle other cases where DETAIL_TYPE_ID is not of a supported type
      else {
        console.error('Unsupported DETAIL_TYPE_ID type:', typeof e.DETAIL_TYPE_ID);
        return false; // Or handle this case based on your requirements
      }
    });


    // console.log("datadata=",data)
    // console.log("data[0].CASHFLOWS_ID=",data[0].CASHFLOWS_ID)
    if (data.length > 0) {
      setTitlecashflow_id(data[0].CASHFLOWS_ID)
    }
    // console.log("Cashflows=",data)
  }

  const _ongetCurrencyvalues = (e) => {
    setCurrency(e)
    let data = listcurrency.filter((el) => el.CURRENCY_UID.includes(e))
    setCurrencystatus(data[0].CURRENCY_NAME)
  }

  const handleChange = event => {
    if (event.target.checked) {
      setDisblebtn(false);
      setPrentid(prentid)
    } else {
      setDisblebtn(true);
      setPrentid(0)
      setNameShow('')
    }
    setIsSubscribed(current => !current);
  };

  const handleretained_yes = event => {
    if (event.target.checked) {
      setRetained_yes(true)
      setRetained_no(false)
      setValues_retained(12)
    } else {
      setRetained_yes(false)
      setValues_retained('')

    }
  }
  const handleretained_no = event => {
    if (event.target.checked) {
      setRetained_no(true)
      setRetained_yes(false)
      setValues_retained(13)

    } else {
      setRetained_no(false)
      setValues_retained('')

    }
  }
  const BeginningBalance = e => {
    if (e.target.checked) {
      setConditionsdata(true)
    } else {
      setBalancelak('')
      setBalance("0")
      setRadiodebit(false)
      setRadiocredit(false)
      setExchangeRate("1")
      setDebit("")
      setCredit("")
      setConditionsdata(false)
    }
  }
  const handleClick = () => {
    setActive(null)
    setOpenshow(!openshow);
  };
  const changeText = (e) => {
    setErrexchangeRate('')
    // const value = e.target.value.replace(/\D/g, '');
    const value = e.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    setExchangeRate(value)
  };
  const OnblurChangeText = (e) => {
    let number = e.replaceAll(',', '')
    let rate = e.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // let format_number = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
    // let rate = format_number.replaceAll('$', '')
    setExchangeRate(rate)
    let sum = parseFloat(rate.replaceAll(',', '') * parseFloat(balance.replaceAll(',', '')))
    let sumbalancelak = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sum)
    setBalancelak(sumbalancelak.replaceAll('$', ''))
  }
  const TextonChange = (e) => {
    setAlready("")
    setErrname(false)
    setName(e)
  }
  const _searchstartdate = (e) => {


    setValuedate(e)
  }
  const changeTextbalance = (e) => {
    setErrbalance('')
    // const value = e.target.value.replace(/\D/g, '');
    const value = e.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    setBalance(value)
  };
  const OnblurTextbalance = (e) => {
    let number = e.replaceAll(',', '')
    let rate = e.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    setBalance(rate)
    let sum = parseFloat(rate.replaceAll(',', '') * parseFloat(exchangeRate.replaceAll(',', '')))
    let sumbalancelak = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sum)
    if (!exchangeRate) {
      setBalancelak("0.00")
    } else {
      setBalancelak(sumbalancelak.replaceAll('$', ''))
    }
  }
  const filterdata_sub = async (e, ac_type) => {
    setNameShow(e)
    setDatafilter(e);
    if (!e) {
      setSearchResult([]);
    } else {
      try {
        axios.get(`/accounting/api/chartAccount/bank/filterlist/${e}/${ac_type}/${company_uid}`).then((data) => {
          let list = [...data?.data?.result];
          const generateTreeSecond = (items) => {
            const buildTree = (items, parentId) => {
              const currentNode = items.find(item => item.CHART_ID === parentId);
              if (!currentNode) {
                return null; // Return null for non-existent parent (or handle accordingly)
              }
              const node = {
                CHART_ID: currentNode.CHART_ID,
                CHART_NAME_ENG: currentNode.CHART_NAME_ENG,
                CHART_PARENTS: parentId === 0 ? null : parentId, // Set to null for root nodes
                children: [],
              };
              const children = items.filter(item => item.CHART_PARENTS === parentId);
              children.forEach(child => {
                const childNode = buildTree(items, child.CHART_ID);
                if (childNode) {
                  node.children.push(childNode);
                }
              });
              return node;
            };
            const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
            const result = roots.map(root => buildTree(items, root.CHART_ID));
            return result;
          };
          setSearchResult(generateTreeSecond(list));
        }).catch((err) => {
          console.log(err)
        })
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getNameList1 = (c_id) => {
    axios.get(`/accounting/api/chartAccount/bank/listchart_of_account/${c_id}`).then((data) => {
      if (data?.data?.result.length > 0) {
        setPrentid(data?.data.result[0].CHART_ID)
        setCheckedCurrency(data?.data?.result[0].CHART_CURRENCY_CODE)
        const names = data?.data?.result.map((item) => {
          return item.CHART_NAME_ENG;
        });
        names.reverse();
        setNameShow(names.join(":"));
        setAccountname(names.join(":"));
        setShowBox(!showBox);
      }
    });
  };
  const editbeginningbalancefirst = (CHART_ID, balances, createdate, account_ac_symbol, bs_id) => {
    setGetid(CHART_ID)
    setBs_id(bs_id)
    setShowBox(false)
    axios.get(`/accounting/api/chartAccount/bank/ChartID/${CHART_ID}`).then((data) => {
      if (data?.data?.result.length > 0) {
        setPrentid(data?.data?.result[0].CHART_PARENTS)
        setTitlecashflow_id(data?.data?.result[0].TITLE_CASHFLOWS_ID)
        const names = data?.data?.result.map((item) => {
          return item.CHART_NAME_ENG;
        });
        names.reverse();
        if (data?.data?.result[0].CHART_PARENTS != 0) {
          setIsDisabled(true)
          setDisblebtn(false);
          setNameShow(names.join(":"));
        }
        setCheckedCurrency(data?.data?.result[0].CHART_CURRENCY_CODE)
        if (data?.data?.count_number[0].COUNTS == 1) {
          if (data?.data?.result[0].CHART_PARENTS == 0) {
            setDisabled_currency(false)
          } else {
            setDisabled_currency(true)
          }
        } else {
          setDisabled_currency(true)
        }
      }
      if (data?.data?.result_begin.length > 0) {
        if (data?.data?.result_begin[0].LG_DESC == 'debit') {
          setDebit(data?.data?.result_begin[0].LG_DESC)
          setCredit(null)
        } else {
          setCredit(data?.data?.result_begin[0].LG_DESC)
          setDebit(null)
        }
        setBeginningbalance(true);
        setOpenshow(true)
        _searchstartdate(moment(createdate).format('DD-MM-YYYY'))
        setExchangeRate(getFormatNumber(data?.data?.result_begin[0].EXCHNAGE_RATE))
        setBalance(getFormatNumber(balances).replaceAll("-", ''))
        setBalancelak(getFormatNumber(balances * data?.data?.result_begin[0].EXCHNAGE_RATE).replaceAll("-", ''))
        if (account_ac_symbol === 'CA' || account_ac_symbol === 'LT') {
          if (balances < 0) {
            setRadiocredit(true)
            setChecked_number(-1)
          } else {
            setRadiodebit(true)
            setChecked_number(1)
          }
        } else {
          if (balances < 0) {
            setRadiodebit(true)
            setChecked_number(-1)
          } else {
            setRadiocredit(true)
            setChecked_number(1)
          }
        }
      }
    });
  }
  const functionCheckDebit = (e) => {
    setChecked_number('')
    if (ac_symbol === 'CA' || ac_symbol === 'LT') {
      setChecked_number(1)

    } else {
      setChecked_number(-1)
    }
    setDebit('debit')
    setCredit(null)
    seterrcheckdebitcredit(false)
  }
  const functionCheckCredit = (e) => {
    setChecked_number('')
    if (ac_symbol === 'CA' || ac_symbol === 'LT') {
      setChecked_number(-1)
    } else {
      setChecked_number(1)
    }
    setCredit('credit')
    setDebit(null)

    seterrcheckdebitcredit(false)
  }
  const CreateChartAccount = () => {
    let retained_stauts = '';
    let type_id = 0;
    const currentDate = new Date();
    const padZero = (num) => (num < 10 ? '0' + num : num);
    const formattedTime = `${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;
    if (!ac_type) {
      setConditions_status(100)
      setOpeningsetting(true)
      return;
    }
    if (!name) {
      setConditions_status(101)
      setOpeningsetting(true)
      return;
    }
    let datatime;
    if (disblebtn === false) {
      if (currencystatus != checkedCurrency) {
        setConditions_status(401)
        setOpeningsetting(true)
        return;
      }
    }
    if (conditionsdata === true) {
      if (!valuedate) {
        alert("date");
        return;
      }
    }
    if (valuedate === '') {
      datatime = moment(new Date()).format('YYYY-MM-DD');
    } else {
      datatime = valuedate
    }
    if (checked === 'Eq' || checked === 'As' || checked === 'Li') {
      if (!currency) {
        setConditions_status(402)
        setOpeningsetting(true)
        return;
      }
    }
    if (retained === 'SH') {
      if (!values_retained) {
        alert('retained')
        return;
      } else {
        if (values_retained === 12) {
          retained_stauts = 20
        } else {
          retained_stauts = '';
        }
      }
    }

    setIsLoading(true)
    let data = {
      ACCOUNT_TYPE_UID: ac_type,
      COMPANY_UID: company_uid,
      CHART_NAME_ENG: name.trim(),
      CHART_PARENTS: prentid,
      OPENING_BALANCES: balance.replaceAll(',', ''),
      CHART_CREATEDATE: datatime,
      CREATEDATE_EXCHANGE_RATE: datatime + ':' + formattedTime,
      OPENING_STATUS: 1,
      CHART_CREATED_BY: user_session,
      ACCOUNT_UID: '',
      CHART_CURRENCY_CODE: currencystatus,
      CHART_RATE: exchangeRate.replaceAll(',', ''),
      BS_ID: bs_id,
      CURRENCY_UID: currency,
      STATUS_DEBIT_CREDIT: checked_number,
      STATUS_OWRER_EQUITY: retained_stauts,
      CHART_UID: uuid,
      DEBIT: debit,
      CREDIT: credit,
      DETAIL_TYPE_ID: condition_detail_type_id,
      TITLE_CASHFLOWS_ID: titlecashflow_id
    }

    axios.post("/accounting/api/chartAccount/bank/Insert", data)
      .then((data) => {
        OnloadAllChart_of_Account()
        OnloadChart_when_completed()
        setAc_type('');
        setShowUpdate(false);
        setConditionsdata(false)
        setName('');
        setDescription('');
        setPrentid(0);
        setNameShow('');
        setIsDisabled(false);
        setDisblebtn(true);
        setCurrency('')
        setBeginningbalance(false);
        setRadiocredit('');
        setRadiodebit('');
        setOpenshow('');
        setCurrencystatus('');
        setBalance("0");
        setBalancelak('');
        setErrexchangeRate('')
        setExchangeRate("1")
        setDebit('')
        setCredit('')
        setCheckedCurrency('')
        setShow(false)
        setRetained('')
        setChecked('');
        setRetained_no(false)
        setRetained_yes(false)
        setValues_retained('')
        setDetail_Type_id('')
        setDisabled_currency(false)
        if (data?.data?.statusCode === 400) {
          setConditions_status(400)
          setShow(false);
          setOpeningsetting(true)
        }
      }
      ).catch((err) => {
        httpErrorHandler(err)
      }).finally(() => {
        setIsLoading(false);
      })
  };
  const Updatechartofaccount = () => {
    setIsLoadingupdating(true)
    let data = {
      CHARTID: getid,
      COMPANY_UID: company_uid,
      CHARTNAME_ENG: name.trim(),
      CHARTRATE: exchangeRate.replaceAll(',', ''),
      OPENING_BALANCES: balance.replaceAll(',', ''),
      CHARTPARENTS: prentid,
      CHART_CREATEDATE: valuedate,
      V_CHART_CURRENCY_CODE: currencystatus,
      OPENING_STATUS: 1,
      CHART_CREATED_BY: user_session,
      STATUS_DEBIT_CREDIT: checked_number,
      BS_ID: bs_id,
      V_CURRENCY_UID: currency,
      DEBIT: debit,
      CREDIT: credit,
      V_DETAIL_TYPE_UID: detail_Type_id,
      V_TITLE_CASHFLOWS_ID: titlecashflow_id
    }
    setIsLoadingupdating(false)

    axios.post("/accounting/api/chartAccount/bank/Update", data).then((data) => {
      OnloadAllChart_of_Account()
      OnloadChart_when_completed()
      setAc_type('');
      setShowUpdate(false);
      setConditionsdata(false)
      setName('');
      setDescription('');
      setPrentid(0);
      setNameShow('');
      setIsDisabled(false);
      setDisblebtn(true);
      setCurrency('')
      setBeginningbalance(false);
      setRadiocredit('');
      setRadiodebit('');
      setOpenshow('');
      setCurrencystatus('');
      setBalance("0");
      setBalancelak('');
      setErrexchangeRate('')
      setExchangeRate("1")
      setDebit('')
      setCredit('')
      setCheckedCurrency('')
      setShow(false)
      setRetained('')
      setChecked('');
      setRetained_no(false)
      setRetained_yes(false)
      setValues_retained('')
      setDetail_Type_id('')
      setIsLoadingupdating(false)
      setDisabled_currency(false)
      if (data?.data?.statusCode === 400) {
        setConditions_status(400)
        setShow(false);
        setOpeningsetting(true)
      }
    })
  }
  const OnloadChart_Of_Account = () => {
    if (listchilden?.length > 0) return
    setIspageloading(true)
    axios.get(`/accounting/api/chartAccount/bank/get/${limit}/${company_uid}`).then((data) => {
      let list = [...data?.data?.result];
      const generateTree = (items, parentId) => {
        const tree = [];
        items.forEach(item => {
          if (item.CHART_PARENTS === parentId) {
            const children = generateTree(items, item.CHART_ID);
            const node = {
              CHART_ID: item.CHART_ID,
              ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
              DETAIL_TYPE_ID: item.DETAIL_TYPE_ID,
              TITLE_CASHFLOWS_ID: item.TITLE_CASHFLOWS_ID,
              CURRENCY_UID: item.CURRENCY_UID,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              CHART_PARENTS: item.CHART_PARENTS,
              ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
              DETAIL_TYPE_NAME_ENG: item.DETAIL_TYPE_NAME_ENG,
              BALANCES: item.BALANCES,
              CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
              ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
              ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
              CHART_CREATEDATE: moment(item.CHART_CREATEDATE).format('DD-MM-YYYYY'),
              BS_ID: item.BS_ID,
              children: children.length ? children : [],
            };
            tree.push(node);
          }
        });
        return tree;
      };
      setListchilden(generateTree(list, 0));
      setIspageloading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const ReadMore_data = (e) => {
    setReadmore(e)
    // axios.get(`/accounting/api/chartAccount/bank/get/${e}/${company_uid}`).then((data) => {
    //   let list = [...data?.data?.result];
    //   const generateTree = (items, parentId) => {
    //     const tree = [];
    //     items.forEach(item => {
    //       if (item.CHART_PARENTS === parentId) {
    //         const children = generateTree(items, item.CHART_ID);
    //         const node = {
    //           CHART_ID: item.CHART_ID,
    //           ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
    //           DETAIL_TYPE_ID: item.DETAIL_TYPE_ID,
    //           TITLE_CASHFLOWS_ID: item.TITLE_CASHFLOWS_ID,
    //           CURRENCY_UID: item.CURRENCY_UID,
    //           CHART_NAME_ENG: item.CHART_NAME_ENG,
    //           CHART_PARENTS: item.CHART_PARENTS,
    //           ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
    //           DETAIL_TYPE_NAME_ENG: item.DETAIL_TYPE_NAME_ENG,
    //           BALANCES: item.BALANCES,
    //           CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
    //           ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
    //           ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
    //           CHART_CREATEDATE: moment(item.CHART_CREATEDATE).format('DD-MM-YYYYY'),
    //           BS_ID: item.BS_ID,
    //           children: children.length ? children : [],
    //         };
    //         tree.push(node);
    //       }
    //     });
    //     return tree;
    //   };
    //   setListchilden(generateTree(list, 0));
    // }).catch((err) => {
    //   console.log(err)
    // })
  }
  const filterSearch = async (e) => {
    setDatafilter(e);
    if (!e) {
      OnloadChart_Of_Account()
    } else {
      try {
        axios.get(`/accounting/api/chartAccount/bank/filter/${e}/${company_uid}`).then((data) => {
          let list = [...data?.data?.result];
          const generateTreeSecond = (items) => {
            const buildTree = (items, parentId) => {
              const currentNode = items.find(item => item.CHART_ID === parentId);
              if (!currentNode) {
                return null; // Return null for non-existent parent (or handle accordingly)
              }
              const node = {
                CHART_ID: currentNode.CHART_ID,
                ACCOUNT_TYPE_UID: currentNode.ACCOUNT_TYPE_UID,
                DETAIL_TYPE_ID: currentNode.DETAIL_TYPE_ID,
                TITLE_CASHFLOWS_ID: currentNode.TITLE_CASHFLOWS_ID,

                CURRENCY_UID: currentNode.CURRENCY_UID,
                CHART_NAME_ENG: currentNode.CHART_NAME_ENG,
                ACCOUNT_TYPE_NAME_ENG: currentNode.ACCOUNT_TYPE_NAME_ENG,
                DETAIL_TYPE_NAME_ENG: currentNode.DETAIL_TYPE_NAME_ENG,
                CHART_PARENTS: parentId === 0 ? null : parentId, // Set to null for root nodes
                BALANCES: currentNode.BALANCES,
                CHART_CURRENCY_CODE: currentNode.CHART_CURRENCY_CODE,
                ACCOUNT_TYPE_AC_SYMBOL: currentNode.ACCOUNT_TYPE_AC_SYMBOL,
                ACCOUNT_CREATESTATUS: currentNode.ACCOUNT_CREATESTATUS,
                CHART_CREATEDATE: moment(currentNode.CHART_CREATEDATE).format('DD-MM-YYYYY'),
                BS_ID: currentNode.BS_ID,
                children: [],
              };
              const children = items.filter(item => item.CHART_PARENTS === parentId);
              children.forEach(child => {
                const childNode = buildTree(items, child.CHART_ID);
                if (childNode) {
                  node.children.push(childNode);
                }
              });
              return node;
            };
            const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
            const result = roots.map(root => buildTree(items, root.CHART_ID));
            return result;
          };
          // const results = generateTreeSecond(list);
          setListchilden(generateTreeSecond(list));
        }).catch((err) => {
          console.log(err)
        })

      } catch (err) {
        console.log(err);
      }
    }
  };
  const Refresh_account = () => {
    OnloadChart_when_completed()
  }
  const OnloadChart_when_completed = () => {
    setIspageloading(true)
    axios.get(`/accounting/api/chartAccount/bank/get/${limit}/${company_uid}`).then((data) => {
      let list = [...data?.data?.result];
      const generateTree = (items, parentId) => {
        const tree = [];
        items.forEach(item => {
          if (item.CHART_PARENTS === parentId) {
            const children = generateTree(items, item.CHART_ID);
            const node = {
              CHART_ID: item.CHART_ID,
              ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
              DETAIL_TYPE_ID: item.DETAIL_TYPE_ID,
              TITLE_CASHFLOWS_ID: item.TITLE_CASHFLOWS_ID,
              CURRENCY_UID: item.CURRENCY_UID,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              CHART_PARENTS: item.CHART_PARENTS,
              ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
              DETAIL_TYPE_NAME_ENG: item.DETAIL_TYPE_NAME_ENG,
              BALANCES: item.BALANCES,
              CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
              ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
              ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
              CHART_CREATEDATE: item.CHART_CREATEDATE,
              BS_ID: item.BS_ID,
              children: children.length ? children : [],
            };
            tree.push(node);
          }
        });
        return tree;
      };
      setListchilden(generateTree(list, 0));
      setIspageloading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    OnloadChart_Of_Account()

  }, [])
  return (
    <div style={{ width: '100%' }}>
      <Already_exists
        openingsetting={openingsetting}
        handleCloseOpenting={handleCloseOpenting}
        conditions_status={conditions_status}
      />
      <Deactive_account
        openingDeactivate={openingDeactivate}
        handleCloseReduces_usage={handleCloseReduces_usage}
        reduces_chart_id={reduces_chart_id}
        closeaccount={closeaccount}
        isLoading_reduces={isLoading_reduces}
      />
      {
        getchart_id != null ? (<>
          <History
            GoClosehistory={GoClosehistory}
            history={history}
            fullscreen={fullscreen}
            getchart_id={getchart_id}
            company_uid={company_uid}
            conditions={conditions}
            statusCurrency={statusCurrency}
            loading={loading}
            listhistories={listhistories}
          />

        </>) : null
      }
      <Modal
        show={show}
        onHide={handleClose}
        style={{ paddingTop: 50 }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Account</Modal.Title>
        </Modal.Header>
        <div style={{
          position: 'fixed',
          width: '80%',
          marginLeft: 250
        }}>
        </div>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* {JSON.stringify(ac_symbol)} */}
            <div style={{ width: "100%" }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: 20 }}>Account Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"

                  onChange={(e) => _onsearchaccountid(e.target.value, company_uid)}
                  value={ac_type}
                >
                  <>
                    <option>SELECT ACCOUNT TYPE </option>
                    {listaccount &&
                      listaccount.map((data, index) => {
                        return (
                          <option key={index} value={data?.ACCOUNT_TYPE_UID}>
                            {data?.ACCOUNT_TYPE_NAME_ENG}
                          </option>
                        );
                      })}
                  </>
                </Form.Select>

              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: 20 }}>Detail Type </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => _ondetail_Type(e.target.value)}

                  value={detail_Type_id}
                >
                  <>
                    {
                      listdetailType?.length == 0 ? (<>
                        <option>SELECT DETAIL TYPE </option>
                      </>) : (<>
                        {listdetailType &&
                          listdetailType?.map((data, index) => {
                            return (
                              <option key={index} value={data?.DETAIL_TYPE_ID}>
                                {data?.DETAIL_TYPE_NAME_ENG}
                              </option>
                            );
                          })}

                      </>)
                    }






                  </>
                </Form.Select>

              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: 20 }}></Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
              {
                retained === 'SH' ? (
                  <>
                    <small style={{ fontSize: 20 }}>Retained Earnings account ?</small>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                      <input
                        type="checkbox"
                        checked={retained_yes}
                        onChange={handleretained_yes}

                      /><small style={{ marginLeft: 10 }}>Yes</small>
                      <input
                        style={{ marginLeft: 10 }}
                        type="checkbox"
                        checked={retained_no}
                        value={retained}
                        onChange={handleretained_no}
                      /><small style={{ marginLeft: 10 }}>NO</small>
                    </div>
                  </>) : null
              }
            </div>
            <div style={{ width: 15 }}></div>
            <div style={{ width: "100%" }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: 20 }}>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  autoFocus
                  onChange={(e) => TextonChange(e.target.value)}
                  value={name}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontSize: 20 }}>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  autoFocus
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </Form.Group>

              {
                checked == 'As' || checked == 'li' || checked == 'Eq' ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                          style={{ width: 150 }}
                        >
                          <Form.Label style={{ fontSize: 20 }}>CURRENCY</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => _ongetCurrencyvalues(e.target.value)}
                            value={currency}
                            disabled={disabled_currency}
                          >
                            <option>SELECT CURRENCY</option>
                            {listcurrency &&
                              listcurrency.map((data, index) => {
                                return (
                                  <option key={index} value={data?.CURRENCY_UID}>{data?.CURRENCY_NAME} - {data?.CURRENCY_CODE}
                                  </option>
                                );
                              })}
                          </Form.Select>

                        </Form.Group>
                      </div>
                      {
                        openshow ? (
                          <>
                            <div
                              style={{ marginLeft: 10 }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingTop: 35
                                }}
                              >
                                {
                                  currencystatus == "USD" ? (
                                    <>
                                      <small style={{ marginTop: 10 }}>1USD</small>
                                      <img alt="Logo" src="/assets/images/USA.png" style={{ width: 25, height: 20, marginTop: 8 }} />
                                      <small style={{ marginTop: 10 }}> =</small>
                                      <input
                                        onChange={(e) => { changeText(e.target.value) }}
                                        onBlur={(e) => { OnblurChangeText(e.target.value) }}
                                        value={exchangeRate}
                                        disabled={checkDisabled}
                                        style={{
                                          border: '1px solid #ccc',
                                          height: 37,
                                          borderRadius: 3,
                                          width: 100,
                                          paddingLeft: 10,
                                          textAlign: "right",
                                          marginLeft: 5,
                                          outline: 'none',
                                        }}
                                      />
                                      <small style={{ marginTop: 10 }}>LAK</small>
                                      <img alt="Logo" src="/assets/images/laos.png" style={{ width: 25, height: 20, marginTop: 8 }} />
                                    </>
                                  ) : null
                                }
                                {
                                  currencystatus == "THB" ? (
                                    <>

                                      <small style={{ marginTop: 10 }}>1THB</small>
                                      <img alt="Logo" src="/assets/images/thailand.png" style={{ width: 25, height: 20, marginTop: 8 }} />
                                      <small style={{ marginTop: 10 }}> =</small>
                                      <input
                                        onChange={(e) => { changeText(e.target.value) }}
                                        onBlur={(e) => { OnblurChangeText(e.target.value) }}
                                        value={exchangeRate}
                                        disabled={checkDisabled}
                                        style={{
                                          border: '1px solid #ccc',
                                          height: 37,
                                          borderRadius: 3,
                                          width: 100,
                                          paddingLeft: 10,
                                          textAlign: "right",
                                          marginLeft: 5,
                                          outline: 'none',
                                        }}
                                      />
                                      <small style={{ marginTop: 10 }}>LAK</small>
                                      <img alt="Logo" src="/assets/images/laos.png" style={{ width: 25, height: 20, marginTop: 8 }} />
                                    </>
                                  ) : null
                                }
                              </div>
                            </div>
                          </>
                        ) : null
                      }
                      {
                        errexchangeRate == true ? (
                          <>
                            <small style={{ position: "absolute", marginTop: 80, marginLeft: 200, color: "red", fontSize: 16 }}>Please enter changeRate</small>
                          </>
                        ) : null
                      }
                    </div>
                  </>
                ) : null
              }
              <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                <Form.Check
                  type="checkbox"
                  checked={isDisabled}
                  disabled={checkDisabled}
                  value={isSubscribed}
                  onChange={handleChange}
                  id="subscribe"
                  name="subscribe"
                  label="Is sub-account"
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <input
                    type="text"
                    disabled={disblebtn}
                    autoFocus
                    onChange={(e) => filterdata_sub(e.target.value, ac_type)}
                    value={nameShow}
                    style={{
                      border: "1px solid #ccc",
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                      borderRight: "none",
                      flex: 1,
                      height: 40,
                      outline: "none",
                      paddingLeft: 10,
                    }}
                    onClick={() => setShowBox(!showBox)}
                  />
                  <div
                    style={{
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      borderLeft: "none",
                      paddingTop: 5,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4
                    }}
                  // onClick={() => { setShowBox(!showBox); handleClick1() }}
                  >
                    {/* {open1 ? <ExpandLess /> : <ExpandMore />} */}
                  </div>
                </div>
              </Form.Group>

              {showBox && (
                <>
                  <Card style={{
                    overflowY: "scroll",
                    width: 380,
                    position: "absolute",
                    height: 300,
                  }}>
                    <CardActionArea>
                      <CardContent>
                        {searchResult.length > 0 ? (
                          <>
                            {searchResult.map((data, index) => {
                              return (
                                <div key={index} style={{ width: '100%' }}>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight:
                                        active === data?.CHART_NAME_ENG ? "bold" : "",
                                    }}
                                    onClick={() => { getNameList1(data?.CHART_ID) }}
                                    onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                                    onMouseLeave={() => setActive(null)}
                                  >
                                    {data?.CHART_NAME_ENG}
                                  </Typography>
                                  <RowComponentSearchList
                                    children={data?.children}
                                    active={active}
                                    level={30}
                                    getNameList1={getNameList1}
                                    setActive={setActive}
                                  />
                                  <br />
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {listChart_Aaccount && listChart_Aaccount?.map((data, index) => {
                              return (
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} key={index} >
                                  <Typography variant="body2" style={{
                                    cursor: "pointer",
                                    fontWeight:
                                      active === data?.CHART_NAME_ENG ? "bold" : "",
                                  }}
                                    onClick={() => { getNameList1(data?.CHART_ID) }}
                                    onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                                    onMouseLeave={() => setActive(null)}
                                  >{data?.CHART_NAME_ENG}</Typography>
                                  <ComponentList
                                    children={data?.children}
                                    active={active}
                                    level={30}
                                    getNameList1={getNameList1}
                                    setActive={setActive}

                                  />
                                </div>
                              );
                            })}
                          </>
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </>
              )}


              {
                openshow ? (
                  <>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}>
                      {
                        currencystatus == "LAK" ? (
                          <>
                            <div>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label style={{ fontSize: 20 }}>Balance - {currencystatus}</Form.Label>
                                <Form.Control
                                  type="text"
                                  disabled={checkDisabled}
                                  autoFocus
                                  onChange={(e) => changeTextbalance(e.target.value)}
                                  onBlur={(e) => OnblurTextbalance(e.target.value)}
                                  value={balance}
                                  style={{ width: 150, textAlign: "right" }}
                                />
                              </Form.Group>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label style={{ fontSize: 20 }}>Balance - {currencystatus}</Form.Label>
                                <Form.Control
                                  type="text"
                                  autoFocus
                                  onChange={(e) => changeTextbalance(e.target.value)}
                                  onBlur={(e) => OnblurTextbalance(e.target.value)}
                                  value={balance}
                                  style={{ width: 150, textAlign: "right" }}
                                  disabled={checkDisabled}
                                />
                              </Form.Group>
                            </div>

                          </>
                        )
                      }
                      {
                        errbalance == true ? (
                          <>
                            <small style={{ position: "absolute", color: "red", fontSize: 14, marginTop: 80 }}>Please enter balance</small>
                          </>
                        ) : null
                      }
                      <div style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="date"
                            placeholder="Description"
                            autoFocus
                            onChange={(e) => _searchstartdate(e.target.value)}
                            value={valuedate}
                            style={{ width: 140, marginTop: 37 }}
                          />

                        </Form.Group>
                      </div>


                    </div>

                    <Form.Group>
                      <Form.Label style={{ fontSize: 20 }}>Please choose debit or credit ?</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        <Form.Check
                          style={{ cursor: "pointer" }}
                          inline
                          label="debit"
                          checked={radiodebit}
                          type="radio"
                          onChange={(e) => functionCheckDebit(e.target.value)}
                          value={debit}
                          disabled={checkDisabled}
                          onClick={() => {
                            setRadiodebit(!radiodebit);
                            setRadiocredit(false);

                          }}
                        />
                        <Form.Check
                          style={{ cursor: "pointer" }}
                          checked={radiocredit}
                          inline
                          label="credit"
                          onChange={(e) => functionCheckCredit(e.target.value)}
                          value={credit}
                          type="radio"
                          disabled={checkDisabled}
                          onClick={() => {
                            setRadiocredit(!radiocredit);
                            setRadiodebit(false);

                          }}
                        />
                      </div>
                      {
                        errcheckdebitcredit == true ? (
                          <>
                            <small style={{ position: "absolute", color: "red", fontSize: 14 }}>Please choose debit or credit</small>
                          </>
                        ) : null
                      }
                    </Form.Group>
                    <div style={{ height: 15 }}></div>
                    <div style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}>
                      <div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label style={{ fontSize: 20 }}>Balance-LAK</Form.Label>
                          <Form.Control
                            type="text"
                            autoFocus
                            onChange={(e) => setBalancelak(e.target.value)}
                            value={balancelak}
                            style={{ width: 150, textAlign: "right" }}
                            disabled={true}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </>
                ) : null
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose}
            onMouseOver={() => setOnClose(true)}
            onMouseLeave={() => setOnClose(false)}
            style={{
              backgroundColor: '#0d6efd',
              border: 'none',
              padding: '10px 30px 10px 30px',
              opacity: onClose ? 0.9 : 2,
              height: 35,
              color: "white"
            }}
          >
            Close
          </button>
          {showUpdate ? (
            <button
              onClick={Updatechartofaccount}
              // onMouseOver={() => setOnFocused(true)}
              // onMouseLeave={() => setOnFocused(false)}
              style={{
                backgroundColor: '#0d6efd',
                border: 'none',
                padding: '10px 30px 10px 30px',
                opacity: onFucused ? 0.9 : 2,
                height: 35,
                color: "white"
              }}
            >
              {!isLoadingupdating ? (
                <>
                  update and Close
                </>
              ) : (
                <>
                  {
                    <Spinner animation="border" variant="light" size='sm' />
                  }
                </>)
              }
            </button>
          ) : (
            <>
              <button
                onClick={CreateChartAccount}
                onMouseOver={() => setOnFocused(true)}
                onMouseLeave={() => setOnFocused(false)}
                style={{
                  backgroundColor: '#0d6efd',
                  border: 'none',
                  padding: '10px 30px 10px 30px',
                  opacity: onFucused ? 0.9 : 2,
                  height: 35,
                  color: "white",
                }} >
                {!isLoading ? (
                  <>
                    Save and Close
                  </>
                ) : (
                  <>
                    {
                      <Spinner animation="border" variant="light" size='sm' />
                    }
                  </>)
                }
              </button>

            </>
          )}
        </Modal.Footer>
      </Modal>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 10,

      }}>
        <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ArrowBackIcon style={{ color: '#3f51b5' }} />
        </div>
        <small style={{ fontSize: 20, marginTop: 2, marginLeft: 5 }}>Chart of Accounts</small>
        <div style={{
          borderRadius: 2,
          width: '17%',
          backgroundColor: '#3f51b5',
          height: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          marginTop: 4,
          marginLeft: 10
        }}
          onClick={() => {
            handleShow();
          }}
        >
          <AddIcon style={{ color: 'white' }} />
          <small style={{ color: 'white', fontWeight: 'bold' }}>Add New Accounting Codes</small>
        </div>
        <div style={{
          borderRadius: 2,
          width: '22%',
          backgroundColor: '#3f51b5',
          height: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          marginTop: 4,
          marginLeft: 5
        }}
          onClick={() => {
            handleShow();
          }}
        >
          <CloudUploadIcon style={{ color: 'white' }} />
          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Create Accounting Codes by Excel</small>
        </div>
        <div style={{
          borderRadius: 2,
          width: '12%',
          backgroundColor: '#3f51b5',
          height: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          marginTop: 4,
          marginLeft: 5
        }}
          onClick={exportToExcel}
        >
          <GetAppIcon style={{ color: 'white' }} />
          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Export To Excel</small>

        </div>
        <div style={{
          borderRadius: 2,
          width: '6%',
          backgroundColor: '#3f51b5',
          height: 30,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          marginTop: 4,
          marginLeft: 5
        }}
          onClick={() => {
            handleShow();
          }}
        >
          <PrintIcon style={{ color: 'white' }} />
          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Print</small>
        </div>
        {
          ispageloading == true ? (<>
            <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
              <Spin size="smal" />
            </Flex>
          </>) : null
        }
      </div>
      {
        ispageloading == true ? (<>
          <LinearProgress style={{ marginTop: 10 }} />
        </>) : (<>
          <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>

        </>)
      }


      <div style={{ paddingTop: 20, paddingBottom: 50 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          position: 'relative',
        }}>
          <div style={{ position: 'absolute', left: 10, top: 6 }}>
            < SearchIcon style={{ color: '#ccc' }} />
          </div>
          <input type="text"
            placeholder="Filter by name or number"
            style={{
              border: '1px solid #ccc',
              borderRadius: 4,
              fontSize: 16,
              outline: 'none',
              fontSize: 16,
              height: 35,
              width: '30%',
              paddingLeft: 40
            }}
            onChange={(e) => filterSearch(e.target.value)}
            value={datafilter}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '50%' }}>
            <div style={{
              borderRadius: 2,
              width: '13%',
              backgroundColor: '#3f51b5',
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
              marginTop: 4,
              marginLeft: 5
            }}
            >
              <SettingsIcon style={{ color: 'white' }} />
              <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Setting</small>
            </div>
            <div style={{
              borderRadius: 2,
              width: '13%',
              backgroundColor: '#3f51b5',
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
              marginTop: 4,
              marginLeft: 5
            }}
              onClick={() => { Refresh_account() }}
            >
              <RefreshIcon style={{ color: 'white' }} />
              <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Refresh</small>
            </div>

          </div>
        </div>
        {
          ispageloading == true ? (<>
            <Skeleton />
          </>) : (
            <>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell align="left">TYPE</TableCell>
                      <TableCell align="left">DETAIL TYPE</TableCell>
                      <TableCell align="left">CURRENCY</TableCell>
                      <TableCell align="right">BANLANCE</TableCell>
                      <TableCell align="right">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      (rowsPerPage > 0
                        ? listchilden?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : listchilden
                      )?.map((item, index) => {
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell>{item?.CHART_NAME_ENG}</TableCell>
                              <TableCell align="left">{item?.ACCOUNT_TYPE_NAME_ENG}</TableCell>
                              <TableCell align="left">{item?.DETAIL_TYPE_NAME_ENG}</TableCell>
                              <TableCell align="left">{item?.CHART_CURRENCY_CODE}</TableCell>
                              <TableCell align="right">{getFormatNumber(item?.BALANCES)}</TableCell>
                              <TableCell align="right">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                  {
                                    item?.ACCOUNT_CREATESTATUS == "In" || item?.ACCOUNT_CREATESTATUS == "Ex" ? (
                                      <>
                                        {/* <span
                                          style={{ cursor: "pointer", color: "green", marginTop: 15 }}

                                        >
                                          (Run report)
                                        </span> */}
                                      </>
                                    ) : (
                                      <>
                                        {/* <span
                                          style={{ cursor: "pointer", color: "green", marginTop: 15 }}
                                          onClick={() => { Gohistory(item?.CHART_ID) }}
                                        >
                                          (Account history)
                                        </span> */}
                                      </>
                                    )
                                  }
                                  <FirstLongMenu
                                    handleShow={handleShow}
                                    name_eng={item?.CHART_NAME_ENG}
                                    chart_id={item?.CHART_ID}
                                    setName={setName}
                                    parent={item?.CHART_PARENTS}
                                    editbeginningbalancefirst={editbeginningbalancefirst}
                                    balances={item?.BALANCES}
                                    bs_id={item?.BS_ID}
                                    showUpdate={showUpdate}
                                    setShowUpdate={setShowUpdate}
                                    currency_uid={item?.CURRENCY_UID}
                                    createdate={item?.CHART_CREATEDATE}
                                    account_ac_symbol={item?.ACCOUNT_TYPE_AC_SYMBOL}
                                    account_type_uid={item?.ACCOUNT_TYPE_UID}
                                    _onsearchaccountid={_onsearchaccountid}
                                    _ongetCurrencyvalues={_ongetCurrencyvalues}
                                    company_uid={company_uid}
                                    reduces_usage={reduces_usage}
                                    detail_Type_id={item?.DETAIL_TYPE_ID}
                                    _ondetail_Type={_ondetail_Type}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                            <RowComponent
                              children={item?.children}
                              detail_Type_id={item?.DETAIL_TYPE_ID}
                              level={30}
                              handleShow={handleShow}
                              setName={setName}
                              editbeginningbalancefirst={editbeginningbalancefirst}
                              setShowUpdate={setShowUpdate}
                              showUpdate={showUpdate}
                              _onsearchaccountid={_onsearchaccountid}
                              company_uid={company_uid}
                              _ongetCurrencyvalues={_ongetCurrencyvalues}
                              Gotodetailaccount={Gotodetailaccount}
                              reduces_usage={reduces_usage}
                              Gohistory={Gohistory}
                              _ondetail_Type={_ondetail_Type}



                            />
                          </>
                        )
                      })
                    }

                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{ fontWeight: 'bold', marginLeft: 10, marginTop: 5 }}>LimitPage:</small>
                        <select style={{ marginLeft: 5, width: 60, border: '1px solid #ccc', borderRadius: 3, outline: 'none' }}
                          onChange={(e) => { ReadMore_data(e.target.value) }}
                          value={readmore}
                        >
                          <option value="100">10</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                          <option value="300">300</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={5}
                        count={listchilden?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

            </>)
        }

      </div>
    </div>
  )
}
export default ChartAccounts

function RowComponent({ children, level, handleShow, setName, editbeginningbalancefirst, setShowUpdate, _onsearchaccountid, company_uid, _ongetCurrencyvalues, Gotodetailaccount, reduces_usage, Gohistory,
  _ondetail_Type,
  showUpdate


}) {
  if (children.length === 0) return <></>;
  return (
    <>
      {children.map((data, index) => {
        return (
          <>
            <TableRow key={index}>
              <TableCell style={{
                paddingLeft: level,
              }}>
                {data?.CHART_NAME_ENG}
              </TableCell>
              <TableCell align="left">{data?.ACCOUNT_TYPE_NAME_ENG}</TableCell>
              <TableCell align="left">{data?.DETAIL_TYPE_NAME_ENG}</TableCell>
              <TableCell align="left">{data?.CHART_CURRENCY_CODE}</TableCell>
              <TableCell align="right">{getFormatNumber(data?.BALANCES)}</TableCell>
              <TableCell align="right">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  {
                    data?.ACCOUNT_CREATESTATUS == "In" || data?.ACCOUNT_CREATESTATUS == "Ex" ? (
                      <>
                        {/* <span
                          style={{ cursor: "pointer", color: "green", marginTop: 15 }}
                        >
                          (Run report)
                        </span> */}
                      </>
                    ) : (
                      <>
                        {/* <span
                          style={{ cursor: "pointer", color: "green", marginTop: 15 }}
                          onClick={() => { Gohistory(data?.CHART_ID) }}

                        >
                          (Account history)
                        </span> */}
                      </>
                    )
                  }
                  <SecondLongMenu
                    handleShow={handleShow}
                    name_eng={data?.CHART_NAME_ENG}
                    chart_id={data?.CHART_ID}
                    setName={setName}
                    parent={data?.CHART_PARENTS}
                    editbeginningbalancefirst={editbeginningbalancefirst}
                    balances={data?.BALANCES}
                    setShowUpdate={setShowUpdate}
                    showUpdate={showUpdate}
                    currency_uid={data?.CURRENCY_UID}
                    createdate={data?.CHART_CREATEDATE}
                    account_ac_symbol={data?.ACCOUNT_TYPE_AC_SYMBOL}
                    account_type_uid={data?.ACCOUNT_TYPE_UID}
                    _onsearchaccountid={_onsearchaccountid}
                    company_uid={company_uid}
                    _ongetCurrencyvalues={_ongetCurrencyvalues}
                    Gotodetailaccount={Gotodetailaccount}
                    reduces_usage={reduces_usage}
                    detail_Type_id={data?.DETAIL_TYPE_ID}
                    _ondetail_Type={_ondetail_Type}
                  />
                </div>
              </TableCell>
            </TableRow>
            <RowComponent
              children={data?.children}
              level={level * 2 - 20}
              handleShow={handleShow}
              setName={setName}
              parent={data?.CHART_PARENTS}
              editbeginningbalancefirst={editbeginningbalancefirst}
              setShowUpdate={setShowUpdate}
              _onsearchaccountid={_onsearchaccountid}
              company_uid={company_uid}
              _ongetCurrencyvalues={_ongetCurrencyvalues}
              reduces_usage={reduces_usage}
              Gohistory={Gohistory}
              detail_Type_id={data?.DETAIL_TYPE_ID}
              _ondetail_Type={_ondetail_Type}

            />
          </>
        );
      })}
    </>
  );
}

function ComponentList({ children, level, getNameList1, setActive, active }) {
  if (children.length === 0) return <></>;
  return (
    <>
      {children.map((data, index) => {
        return (
          <>
            <Typography key={index}
              style={{
                paddingLeft: level,
                cursor: "pointer",
                fontWeight:
                  active === data?.CHART_NAME_ENG ? "bold" : "",
              }}
              onClick={() => { getNameList1(data?.CHART_ID) }}
              onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
              onMouseLeave={() => setActive(null)}
            >{data?.CHART_NAME_ENG}</Typography>
            <ComponentList
              children={data?.children}
              level={level * 2 - 20}
              getNameList1={getNameList1}
              setActive={setActive}
              active={active}

            />
          </>
        );
      })}
    </>
  );
}
function RowComponentSearchList({ children, level, getNameList1, setActive, active }) {
  if (children.length === 0) return <></>;
  return (
    <>
      {children.map((data, index) => {
        return (
          <>
            <Typography key={index}
              style={{
                paddingLeft: level,
                cursor: "pointer",
                fontWeight:
                  active === data?.CHART_NAME_ENG ? "bold" : "",
              }}
              onClick={() => getNameList1(data?.CHART_ID)}
              onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
              onMouseLeave={() => setActive(null)}
            >{data?.CHART_NAME_ENG}</Typography>
            <ComponentList
              children={data?.children}
              level={level * 2 - 20}
              getNameList1={getNameList1}
              setActive={setActive}
              active={active}
            />
          </>
        );
      })}
    </>
  );
}
function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}













