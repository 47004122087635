import React, { useState, useEffect, useContext,useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { getFormatNumber } from "../constants/functions"
import ReactToPrint from "react-to-print";
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import Spinner from 'react-bootstrap/Spinner';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { LoginContext } from "../page/contexts/LoginContext";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { useParams } from "react-router-dom";
import moment from 'moment';
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ViewUnrealisedgain_or_loss() {
    let componentRef = useRef(null)
    const navigate = useNavigate();
    const { REFERENCE_PARENT, FOR_YEARS } = useParams();
    console.log("yearssss=",FOR_YEARS)
    const [list, setList] = useState({})
    const [rate, setRate] = useState(false)
    const [foreignbalance, setforeignBalance] = useState(false)

    const [showSetting, setShowSetting] = useState(false)
    const [loading, setLoading] = useState(false);
    const [net_total, setNetTotal] = useState(0)
    const {gain_loss,company_uid,setGain_loss} = useContext(LoginContext);
    const OnloadListData = () => {
        axios.get(`/accounting/api/profilandloss/gainloss/${REFERENCE_PARENT}/${FOR_YEARS}/${gain_loss}/${company_uid}`).then((data) => {
            setGain_loss(0)
            const result = data?.data?.result || [];
            setList({ ...data?.data })
            // const total = result.reduce((acc, curr) => acc + curr.GAINLOSS, 0);
            setLoading(true)
            setGain_loss(0)
        }).catch((err) => {
            console.log(err)
        })
    }
    const Setting = () => {
        setShowSetting(!showSetting)
    }
    const OnForeignbalance = () => {
        setforeignBalance(!foreignbalance)
    }
    const OnRate = () => {
        setRate(!rate)
    }
    useEffect(() => {
        OnloadListData()
    }, [])

    const goback = () => {
        navigate("/Profitandloss");
    }
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p
                        style={{
                            fontSize: 20,
                            color: "black",
                            fontFamily: "Phetsarath OT",
                            cursor: 'pointer',
                            backgroundColor: "#3f51b5",
                            borderRadius: '50%'
                        }}
                        onClick={() => { goback() }}
                    >
                        <ArrowBackIcon style={{ color: "white" }} />
                    </p>
                    <h4 style={{ marginLeft: 10, color: 'black' }}> Detail Unrealised gain or loss </h4>

                </div>
                <div>
                    <ReactToPrint
                        trigger={() =>
                            <Button variant="contained" color="primary" style={{
                                border: "none",
                                height: 30,
                                borderRadius: 2,
                                paddingLeft: 5,
                                paddingRight: 5,
                                color: "white",
                                alignItems: "center",
                                marginLeft: 10,
                            }}>
                                <PrintIcon />
                            </Button>
                        }
                        content={() => componentRef}
                        style={{ marginLeft: 10 }}
                    />
                    <button
                        onClick={() => { Setting() }}
                        // onBlur={() => { onBlurSetting() }}
                        style={{
                            backgroundColor: "#3f51b5",
                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            paddingLeft: 10,
                            paddingRight: 10,
                            color: "white",
                            alignItems: "center",
                            marginLeft: 10,
                        }}
                    >
                        <SettingsIcon style={{ cursor: 'pointer' }}
                        />
                    </button>

                    {showSetting ?
                        (
                            <>
                                <div style={{
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    right: 30,
                                    border: '1px solid #ccc',
                                    borderRadius: 3,
                                    width: 300,
                                    height: 200,
                                }}>
                                    <h4 style={{ marginTop: 20, marginLeft: 10 }}>Display density</h4>
                                    <div style={{ height: 20 }}></div>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                                            <input type="checkbox"
                                            // onClick={() => { Onforeignamount() }}
                                            // onMouseLeave={() => { setLeave(null) }}
                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Balances</small>
                                        </div>
                                        <div style={{ marginRight: 28, display: 'flex', flexDirection: 'row' }}>
                                            <input type="checkbox"
                                            // onClick={() => { OnForeignbalance() }}
                                            // onMouseLeave={() => { setLeave(null) }}

                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Current Balance</small>
                                        </div>
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                                            <input type="checkbox"
                                                onClick={() => { OnRate() }}
                                            // onMouseLeave={() => { setLeave(null) }}

                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Rate</small>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 26 }}>
                                            <input type="checkbox"
                                                onClick={() => { OnForeignbalance() }}
                                            // onMouseLeave={() => { setLeave(null) }}

                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Foreign Balance</small>
                                        </div>
                                    </div>
                                    <div style={{ height: 10 }}></div>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                                            <input type="checkbox"
                                            // onClick={() => { OnForeignbalance() }}
                                            // onMouseLeave={() => { setLeave(null) }}
                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Gain/Loss</small>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 22 }}>
                                            {/* <input type="checkbox"
                                                // onClick={() => { OnCurrentBalance() }}
                                                // onMouseLeave={() => { setLeave(null) }}
                                            /> */}
                                            {/* <small style={{ marginLeft: 5, marginTop: 2 }}>Current balance</small> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </div>

            </div>
            <div
                style={{
                    height: 10,
                }}
            ></div>
            <div
                style={{
                    height: 5,
                    backgroundColor: "#3f51b5",
                }}
            ></div>
            <div
                style={{
                    height: 10,
                }}
            ></div>
            {
                loading ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>ACCOUNT</TableCell>
                                        <TableCell>CURRENCY</TableCell>
                                        <TableCell align="right">FOREIGN BALANCE</TableCell>
                                        <TableCell align="right">EXCHANGE RATE</TableCell>
                                        <TableCell align="right">ADJUSTED BALANCE</TableCell>
                                        <TableCell align="right">CURRENT BALANCE</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">GAIN/LOSS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        list.result && list.result.map((data, index) => {
                                            return (
                                                <>
                                                    <TableCellComponent
                                                        key={index}
                                                        data={data}
                                                        chart_id={data?.REFERENCE_PARENT}
                                                        secondList={list?.result_children}

                                                    />
                                                </>
                                            )
                                        })
                                    }
                                </TableBody>
                                <TableHead>
                              
                                    <TableRow>
                                        {
                                            net_total < 0 ? (
                                                <>
                                                    <TableCell>Total Unrealised  Losses</TableCell>
                                                </>) : (<>
                                                    <TableCell>Total Unrealised  Gains</TableCell>

                                                </>)
                                        }
                                        <TableCell></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right" style={{fontWeight:'b'}}>{getFormatNumber(net_total)}₭</TableCell>
                                    </TableRow>

                                </TableHead>
                            </Table>
                        </TableContainer>

                    </>) : (
                    <>
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner animation="border" variant="primary" style={{ width: 100, height: 100, marginTop: 100 }} />
                            </div>
                        </>

                    </>)
            }



        </>
    );
}
function TableCellComponent({ data, chart_id, secondList }) {
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const handleClick = () => {
        setOpen(!open);
    };
    const filter = secondList.filter((el) => el.REFERENCE_PARENT == chart_id);
    return (
        <>
            <React.Fragment>
                <TableRow className={classes.root} size="small">
                    <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }} >
                        {
                            data?.CHART_NAME_ENG
                        }
                    </TableCell>
                    <TableCell align="right">{data?.FOREIGN_CODE}</TableCell>
                    <TableCell align="right">{getFormatNumber(data?.FOREIGN_BALANCES)}</TableCell>
                    <TableCell align="right">{getFormatNumber(data?.EXCHNAGE_RATE)}</TableCell>
                    <TableCell align="right">{getFormatNumber(data?.ADJUSTED_BALACNES)}</TableCell>
                    <TableCell align="right">{getFormatNumber(data?.BALANCES)}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(data?.GAINLOSS)}₭</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>

                                <Table size="small" aria-label="purchases">
                                    <TableHead >
                                        <TableRow style={{ fontWeight: 'bold' }}>
                                            <TableCell>Account</TableCell>
                                            <TableCell align="right">Debit</TableCell>
                                            <TableCell align="right">Credit</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filter && filter.map((data, index) => {
                                                return (
                                                    <>
                                                        <TableRow key={index}>

                                                            <TableCell>{data?.CHART_NAME_ENG}</TableCell>
                                                            {
                                                                data?.DEBIT == null ? (
                                                                    <TableCell align="right"></TableCell>
                                                                ) : (
                                                                    <TableCell align="right">{getFormatNumber(data?.DEBIT)}₭</TableCell>
                                                                )
                                                            }
                                                            {
                                                                data?.CREDIT == null ? (
                                                                    <TableCell align="right"></TableCell>
                                                                ) : (
                                                                    <TableCell align="right">{getFormatNumber(data?.CREDIT)}₭</TableCell>
                                                                )
                                                            }

                                                        </TableRow>
                                                    </>
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        </>
    )
}
