import { Modal } from "react-bootstrap";
import WarningIcon from '@material-ui/icons/Warning';
export const ModalExchange = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.openingExchange} onHide={props.handleCloseOpenting} style={{ paddingTop: 50 }} >
            <Modal.Header closeButton> 
            </Modal.Header>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ border: '3px solid red', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <WarningIcon style={{ color: 'red', marginLeft: 10, marginTop: 10 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',marginBottom:20 }}>
                    <small style={{ fontSize: 20 }}>ອັດຕາແລກປ່ຽນເງີນຕາທ້າຍເດື້ອນ {props.for_month}/{props.for_year} ຍັງບໍ່ມີ.</small>
                </div>
            </Modal>
        </div>
    )
}
