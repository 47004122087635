import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { getFormatNumber } from "../constants/functions"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { LoginContext } from "./contexts/LoginContext";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export default function DetailFitandLoss() {
  const navigate = useNavigate();
  const goback = () => {
    navigate("/Profitandloss");
  }
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { ACCOUNT_TYPE_UID, CHART_UID } = useParams();
  const [listgl, setListgl] = useState({})
  const [getvalues, setGetvalues] = useState([]);
  const [defaultValue, setDefaultValue] = useState("")
  const [defaultValue1, setDefaultValue1] = useState("")
  const [showdebit, setShowdebit] = useState(false)
  const [showcredit, setshowcredit] = useState(false)
  const [foreigndebit, setForeignDebit] = useState(false)
  const [foreigncredit, setForeignCredit] = useState(false)
  const [foreignamount, setForeignAmount] = useState(false)
  const [foreignbalance, setforeignBalance] = useState(false)
  const [currentbalance, setCurrentbalance] = useState(false)
  const [rate, setRate] = useState(false)
  const [exchangerate, setExchangeRate] = useState(false);
  const [gain_Loss, setGain_loss] = useState(false)

  const [ch_id, setCh_id] = useState("")
  const date = moment(new Date).format("DD-MM-YYYY")
  const {
    EditJournal,detail_profitandloss,setDetail_profitandloss,company_uid
  } = useContext(LoginContext);
  const ViewUnrealised = (REFERENCE_PARENT,FOR_YEARS) => {
    console.log("FOR_YEARS=",FOR_YEARS)
    navigate(`/ViewUnrealisedgain_or_loss/${REFERENCE_PARENT}/${FOR_YEARS}`);
  }
  const _onLoad = () => {
    axios.get(`/accounting/api/profilandloss/detail/${ACCOUNT_TYPE_UID}/${CHART_UID}/${detail_profitandloss}/${company_uid}`).then((data) => {
      console.log('Detail=',data)
      setDetail_profitandloss(0)
      setListgl({ ...data?.data })
    }).catch((err) => {
      console.log(err)
    })
  }
  const _searchstartdate = (e) => {
    if (defaultValue == "") {
      setDefaultValue(date)
    } else {
      setDefaultValue(moment(e).format("DD-MM-YYYY"))
    }
  }
  const onGotoEditjournal = (id) => {
    const baseUrl = window.location.pathname;
    navigate(`/Journalpage/${id}`);
  }
  const Onloadreset = () => {
    window.location.reload();
  }
  const _onShow = (e) => {
    // if (e == "today") {
    //   const e1 = new Date()
    //   const startToday = moment(e1).format("DD-MM-YYYY")
    //   const endToday = moment(e1).format("DD-MM-YYYY")
    //   setDefaultValue(moment(e1).format("DD-MM-YYYY"))
    //   setDefaultValue1(moment(e1).format("DD-MM-YYYY"))
    //   const start = defaultValue
    //   const end = defaultValue1
    //   let data;
    //   if (start == '' || end == '') {
    //     data = {
    //       c_id: chart_id,
    //       startToday,
    //       endToday
    //     }
    //   } else {
    //     data = {
    //       c_id: chart_id,
    //       start,
    //       end
    //     }
    //   }
    //   axios.post("/accounting/api/report/runreportSeachBydate", data).then((data) => {
    //     setFirst([...data?.data?.firstFloor])
    //     setChildren([...data?.data?.children])
    //   }).catch((err) => {
    //     console.log(err)
    //   })

    // } else if (e == "all") {
    //   _onLoad();

    // }

    setGetvalues(e)
  }
  const Search = () => {
    // if (getvalues == "custom") {
    //   let data = {
    //     c_id: chart_id,
    //     start: defaultValue,
    //     end: defaultValue1
    //   }
    //   axios.post(`/accounting/api/report/runreportSeachBydate`, data).then((data) => {
    //     setFirst([...data?.data?.firstFloor])
    //     setChildren([...data?.data?.children])
    //   }).catch((err) => {
    //     console.log(err)
    //   })

    // } else {

    // }
  }
  const _searchbydate = (e) => {
    setDefaultValue1(e)
    if (defaultValue1 == "") {
      setDefaultValue1(date)
    } else {
      setDefaultValue1(moment(e).format("DD-MM-YYYY"))
    }
  }
  useEffect(() => {
    _onLoad();
    _onShow();
    _searchstartdate();
    _searchbydate();

  }, [])
  return (
    <>
      <div>
        <ChevronLeftIcon style={{ color: "#3f51b5", cursor: "pointer" }} />
        <span style={{ color: "#3f51b5", cursor: "pointer" }}
          onClick={() => { goback() }}
        >Back to Profit and loss</span><br />
      </div>
      <span>Report period</span><br />
      <div style={{ display: 'flex', flexDirection: "row", width: "100%" }} >
        <select
          onChange={(e) => _onShow(e.target.value)}
          value={getvalues}
          style={{
            border: '1px solid #ccc',
            height: 30,
            borderRadius: 3,
            width: 200,
            outline: 'none'
          }}
        >
          <option value="all">All Dates</option>
          <option value="custom">Custom</option>
          <option value="today">Today</option>

        </select>
        <input
          type="text"
          defaultValue={defaultValue}
          onChange={(e) => setDefaultValue(e.target.value)}
          style={{
            border: '1px solid #ccc',
            height: 30,
            borderRadius: 3,
            width: 100,
            paddingLeft: 10,
            marginLeft: 25,
            textAlign: "right",
            borderRight: "none",
          }}
        />
        <input
          type="date"
          onChange={(e) => _searchstartdate(e.target.value)}
          style={{
            border: '1px solid #ccc',
            height: 30,
            borderRadius: 3,
            width: 30,
            paddingLeft: 10,
          }}
        />
        <span style={{ marginLeft: 10, paddingTop: 5 }}>To</span>
        <input
          type="text"
          defaultValue={defaultValue1}
          onChange={(e) => setDefaultValue1(e.target.value)}
          style={{
            border: '1px solid #ccc',
            height: 30,
            borderRadius: 3,
            width: 100,
            paddingLeft: 10,
            marginLeft: 25,
            textAlign: "right",
            borderRight: "none",
          }}
        />
        <input
          type="date"
          onChange={(e) => _searchbydate(e.target.value)}
          style={{
            border: '1px solid #ccc',
            height: 30,
            borderRadius: 3,
            width: 30,
            paddingLeft: 10,
          }}
        />
        <button
          style={{
            backgroundColor: "#3f51b5",
            border: "none",
            height: 30,
            borderRadius: 2,
            paddingLeft: 10,
            paddingRight: 10,
            color: "white",
            alignItems: "center",
            marginLeft: 10,
          }}
          onClick={() => Search()}
        >
          <SearchIcon />
        </button>
        <button
          style={{
            backgroundColor: "#3f51b5",
            border: "none",
            height: 30,
            borderRadius: 2,
            paddingLeft: 10,
            paddingRight: 10,
            color: "white",
            alignItems: "center",
            marginLeft: 10,
          }}
          onClick={() => Onloadreset()}
        >
          Reset
        </button>
      </div>
      <div style={{ height: 20 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 500, fontWeight: 'bold' }}>DATE</TableCell>
              <TableCell align="left" style={{ width: 300, fontWeight: 'bold' }}>TRANSACTION TYPE</TableCell>
              <TableCell align="left" style={{ width: 100, fontWeight: 'bold' }}>NO</TableCell>
              <TableCell align="left" style={{ width: 200, fontWeight: 'bold' }}>DESCRIPTION</TableCell>
              <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>AMOUNT</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>BALANCE</TableCell>
              {
                currentbalance === true ? (<>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>CURRENT BALANCE</TableCell>
                </>) : null
              }
              {
                showdebit === true ? (
                  <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>Debit</TableCell>
                ) : null
              }
              {
                showcredit === true ? (
                  <TableCell align="right" style={{ width: 200, fontWeight: 'bold' }}>Credit</TableCell>
                ) : null
              }
              {
                foreigndebit === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Debit</TableCell>
                </>) : null
              }
              {
                foreigncredit === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Credit</TableCell>
                </>) : null
              }
              {
                foreignamount === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Amount</TableCell>
                </>) : null
              }
              {
                foreignbalance === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Foreign Balance</TableCell>
                </>) : null
              }
              {
                rate === true ? (<>
                  <TableCell align="right" style={{ width: 100, fontWeight: 'bold' }}>Rate</TableCell>
                </>) : null
              }
              {
                exchangerate === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Exchange Rate</TableCell>
                </>) : null
              }
              {
                gain_Loss === true ? (<>
                  <TableCell align="right" style={{ width: 500, fontWeight: 'bold' }}>Gain/Loss</TableCell>
                </>) : null
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {listgl.fist_gldata && listgl.fist_gldata.map((item, index) => {
              return (
                <>
                  < GLRowComponent
                    CHART_NAME_ENG={item?.CHART_NAME_ENG}
                    id={item?.CHART_ID}
                    index={index}
                    second={listgl && listgl.second_gldata}
                    childrenFirstFloor={listgl && listgl.firstchildren_data}
                    showdebit={showdebit}
                    showcredit={showcredit}
                    foreigndebit={foreigndebit}
                    foreigncredit={foreigncredit}
                    foreignamount={foreignamount}
                    foreignbalance={foreignbalance}
                    rate={rate}
                    exchangerate={exchangerate}
                    gain_Loss={gain_Loss}
                    currentbalance={currentbalance}
                    ViewUnrealised={ViewUnrealised}
                  />
                </>
              )
            })
            }

          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
function GLRowComponent({
  CHART_NAME_ENG,
  id,
  second,
  childrenFirstFloor,
  showcredit,
  showdebit,
  foreigndebit,
  foreigncredit,
  foreignamount,
  foreignbalance,
  rate,
  exchangerate,
  gain_Loss,
  currentbalance,
  ViewUnrealised
}) {
  const [open, setOpen] = useState(true);
  const [netTotal1, setNetTotal1] = useState([])

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow>
        <TableCell onClick={() => { handleClick() }} style={{ cursor: "pointer", width: '50%' }} colspan={3}>
          {open ? <ExpandLess /> : <ExpandMore />}
          {CHART_NAME_ENG}
        </TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        {
          open ? (
            <TableCell align="left"></TableCell>
          ) : (

            <TableCell align="right" >{getFormatNumber(netTotal1)}₭</TableCell>
          )
        }
        {
          currentbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showdebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showcredit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigndebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigncredit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreignamount === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreignbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          rate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          exchangerate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          gain_Loss === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
      </TableRow>
      {open ? (
        <>
          < RowComponent

            id={id}
            CHART_NAME_ENG={CHART_NAME_ENG}
            childrenFirstFloor={childrenFirstFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
            ViewUnrealised={ViewUnrealised}

          />
          < SecondFloorRowComponent
            level={30}
            second={second}
            id={id}
            childrenSecondFloor={childrenFirstFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
          />
        </>
      ) : null
      }
    </>
  )
}

function RowComponent({
  id,
  CHART_NAME_ENG,
  childrenFirstFloor,
  showcredit,
  showdebit,
  foreigndebit,
  foreigncredit,
  foreignamount,
  foreignbalance,
  rate,
  exchangerate,
  gain_Loss,
  currentbalance,
  ViewUnrealised
}) {
  let debittotal = 0;
  let credittoal = 0;
  if (childrenFirstFloor === null) return <></>
  const filter = childrenFirstFloor.filter((el) => el.CHART_ID == id);
  if (filter.length === 0) return <></>;
  const updatedData = [...filter].sort((a, b) => new Date(a.DATE_EXCHANGE_RATE) - new Date(b.DATE_EXCHANGE_RATE));
  updatedData.forEach((data, index) => {
    data.BALANCES = parseFloat(data.AMOUT) + (index > 0 ? updatedData[index - 1].BALANCES : 0);
    data.FOREIGN_BALANCES = parseFloat(data.FOREIGN_AMOUNT) + (index > 0 ? updatedData[index - 1].FOREIGN_BALANCES : 0);
  });
  return (
    <>
      {
        updatedData && updatedData.map((data, index) => {
          // if (data?.BEGINING_BALANCE != 0) {
          //   total1 += parseFloat(data?.AMOUT)
          // }
          if (data?.DEBIT !== '0.00') {
            debittotal += parseFloat(data?.DEBIT)
          }
          if (data?.CREDIT !== '0.00') {
            credittoal += parseFloat(data?.CREDIT)
          }
          return (
            <>
              <TableRow key={index} >
                {
                  data?.BEGINING_BALANCE == '0' ? (<>
                    <TableCell>
                      <div style={{ width: '100%' }}>
                        <small style={{ fontWeight: 'bold', fontSize: 15 }}>Beginning Balance</small>

                      </div>
                    </TableCell>
                  </>) : (<>
                    <TableCell style={{ cursor: "pointer", justifyContent: 'center' }}>{moment(data?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                  </>)
                }

                {
                  data?.BEGINING_BALANCE == '0' ? (
                    <TableCell align="left"></TableCell>
                  ) : (
                    <TableCell align="left" style={{ cursor: "pointer" }} >Journal Entry</TableCell>
                  )
                }
                <TableCell align="left" style={{ cursor: "pointer" }}>{data?.JOURNAL_NO}</TableCell>
                <TableCell align="left" style={{ cursor: "pointer" }}><ReadMore children={data?.LG_DESC} /></TableCell>

                {
                  data?.BEGINING_BALANCE == '0' ? (<>
                    <TableCell align="right" style={{ cursor: "pointer" }}></TableCell></>) :
                    (<>
                      <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.AMOUT)}₭</TableCell>
                    </>)
                }
                {
                  data?.CHART_UID ==  null ? (<>
                    <TableCell align="right" style={{ cursor: "pointer" }} onClick={()=>ViewUnrealised(data?.REFERENCE_PARENT,moment(data?.CREATEDATE).format("YYYY"))}>
                      {getFormatNumber(data?.BALANCES)}₭
                      </TableCell>
                  </>) : (<>
                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                  </>)
                }

                {
                  currentbalance === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE == 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: 'red' }} >{getFormatNumber(data?.FOREIGN_BALANCES * data?.EXCHNAGE_RATE)}k</TableCell>
                          </>) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }

                    </>) : null
                }

                {
                  showdebit === true ? (
                    <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.DEBIT)}₭</TableCell>
                  ) : null
                }
                {
                  showcredit === true ? (
                    <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.CREDIT)}₭</TableCell>
                  ) : null
                }
                {
                  foreigndebit === true ? (
                    <>
                      {data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                        <>
                          {
                            data?.FOREIGN_CODE === 'USD' ? (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_DEBIT)}$</TableCell>
                              </>) : (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_DEBIT)}฿</TableCell>
                              </>)
                          }

                        </>) : (
                        <>
                          <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                        </>)}
                    </>
                  ) : null
                }
                {
                  foreigncredit === true ? (
                    <>
                      {data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                        <>
                          {
                            data?.FOREIGN_CODE === 'USD' ? (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_CREDIT)}$</TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_CREDIT)}฿</TableCell>
                              </>)
                          }
                        </>
                      ) : (
                        <>
                          <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                        </>
                      )}
                    </>
                  ) : null
                }
                {
                  foreignamount === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            {
                              data?.FOREIGN_CODE === 'USD' ?
                                (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_AMOUNT)}$</TableCell>

                                  </>
                                ) : (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_AMOUNT)}฿</TableCell>

                                  </>
                                )
                            }
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>
                        )
                      }
                    </>
                  ) : null
                }
                {
                  foreignbalance === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ?
                          (
                            <>
                              {
                                data?.FOREIGN_CODE === 'USD' ? (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_BALANCES)}$</TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_BALANCES)}฿</TableCell>
                                  </>
                                )
                              }
                            </>
                          ) : (
                            <>
                              <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                            </>
                          )
                      }
                    </>) : null
                }
                {
                  rate === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: '#0d6efd' }} >
                              {
                                data?.EXCHNAGE_RATE
                              }
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
                {
                  exchangerate === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: 'green' }} >

                              {
                                data?.money_rate
                              }

                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
                {
                  gain_Loss === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber((data?.FOREIGN_BALANCES * data?.EXCHNAGE_RATE) - (data?.BALANCES))}₭</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
              </TableRow>

            </>
          )
        })
      }
      <TableRow>
        <TableCell align="left" colspan={3}>Total for {CHART_NAME_ENG}</TableCell>
        <TableCell align="left"></TableCell>
        {/* {
          getFormatNumber(total1) == '0.00' ? (<>
            <TableCell align="right"></TableCell>
          </>) : (<>
            <TableCell align="right">{getFormatNumber(total1)}₭</TableCell>
          </>)
        } */}

        <TableCell align="left"></TableCell>
        {
          currentbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showdebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showcredit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigndebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigncredit === true ? (
            <>
              <TableCell align="left"></TableCell>
            </>) : null
        }
        {
          foreignamount === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreignbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          rate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          exchangerate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          gain_Loss === true ? (<>
            <TableCell align="left" ></TableCell>
          </>) : null
        }

      </TableRow>
    </>
  )
}
function SecondFloorRowComponent({
  level,
  second,
  id,
  childrenSecondFloor,
  showdebit,
  showcredit,
  foreigndebit,
  foreigncredit,
  foreignamount,
  foreignbalance,
  rate,
  exchangerate,
  gain_Loss,
  currentbalance

}) {
  if (second === null) return <></>
  const filter = second.filter((el) => el.CHART_PARENTS == id);
  return (
    <>
      {
        filter.map((data, index) => {
          return (
            <>
              < TableCellComponent
                data={data}
                level={level}
                index={index}
                second={second}
                childrenSecondFloor={childrenSecondFloor}
                showdebit={showdebit}
                showcredit={showcredit}
                foreigndebit={foreigndebit}
                foreigncredit={foreigncredit}
                foreignamount={foreignamount}
                foreignbalance={foreignbalance}
                rate={rate}
                exchangerate={exchangerate}
                gain_Loss={gain_Loss}
                currentbalance={currentbalance}
              />
            </>
          )
        })
      }
    </>
  )
}
function TableCellComponent({ data, level, index, second, childrenSecondFloor, showcredit, showdebit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance }) {

  const [open, setOpen] = useState(true);
  const [netTotal1, setNetTotal1] = useState([])

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow key={index}>
        <TableCell onClick={() => { handleClick() }} style={{ cursor: "pointer", paddingLeft: level, fontSize: 14 }} colspan={3}> {open ? <ExpandLess /> : <ExpandMore />}
          {data?.CHART_NAME_ENG}
        </TableCell>
        <TableCell align="left"></TableCell>
        <TableCell align="left"></TableCell>
        {
          open ? (
            <TableCell align="left"></TableCell>
          ) : (
            <>
              <TableCell align="right">{getFormatNumber(netTotal1)}₭</TableCell>
            </>
          )
        }
        {
          currentbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showdebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          showcredit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigndebit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreigncredit === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreignamount === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          foreignbalance === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          rate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          exchangerate === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
        {
          gain_Loss === true ? (<>
            <TableCell align="left"></TableCell>
          </>) : null
        }
      </TableRow>
      {open ? (
        <>
          < SecondRowComponent
            id={data?.CHART_ID}
            name_eng={data?.CHART_NAME_ENG}
            level={level}
            childrenSecondFloor={childrenSecondFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
          />
          < SecondFloorRowComponent
            data={data}
            id={data?.CHART_ID}
            level={level * 1.5}
            second={second}
            childrenSecondFloor={childrenSecondFloor}
            showdebit={showdebit}
            showcredit={showcredit}
            foreigndebit={foreigndebit}
            foreigncredit={foreigncredit}
            foreignamount={foreignamount}
            foreignbalance={foreignbalance}
            rate={rate}
            exchangerate={exchangerate}
            gain_Loss={gain_Loss}
            currentbalance={currentbalance}
          />
        </>
      ) : null}
    </>
  )
}
function SecondRowComponent({ id, name_eng, level, childrenSecondFloor, showdebit, showcredit, foreigndebit, foreigncredit, foreignamount, foreignbalance, rate, exchangerate, gain_Loss, currentbalance }) {
  let debittotal = 0;
  let credittoal = 0;

  if (childrenSecondFloor === null) return <></>
  const filter = childrenSecondFloor.filter((el) => el.CHART_ID == id);
  var total = 0;
  if (filter.length === 0) return <></>;
  const updatedData = [...filter].sort((a, b) => new Date(a.DATE_EXCHANGE_RATE) - new Date(b.DATE_EXCHANGE_RATE));
  updatedData.forEach((data, index) => {
    data.BALANCES = parseFloat(data.AMOUT) + (index > 0 ? updatedData[index - 1].BALANCES : 0);
    data.FOREIGN_BALANCES = parseFloat(data.FOREIGN_AMOUNT) + (index > 0 ? updatedData[index - 1].FOREIGN_BALANCES : 0);
  });
  return (
    <>
      {
        updatedData && updatedData.map((data, index) => {


          if (data?.BEGINING_BALANCE != '0') {
            total += parseFloat(data?.AMOUT)
          }
          if (data?.DEBIT !== '0') {
            debittotal += parseFloat(data?.DEBIT)
          }
          if (data?.CREDIT !== '0') {
            credittoal += parseFloat(data?.CREDIT)
          }
          return (
            <>
              <TableRow key={index} >
                {
                  data?.BEGINING_BALANCE == '0' ? (<>
                    <TableCell>
                      <div style={{ width: '100%' }}>
                        <small style={{ marginLeft: level, fontWeight: 'bold', fontSize: 15 }}>Beginning Balance</small>

                      </div>
                    </TableCell>
                  </>) : (<>
                    <TableCell style={{ paddingLeft: level, cursor: "pointer", justifyContent: 'center' }}>{moment(data?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                  </>)
                }

                {
                  data?.BEGINING_BALANCE == '0' ? (
                    <TableCell align="left"></TableCell>
                  ) : (
                    <TableCell align="left" style={{ cursor: "pointer" }} >Journal Entry</TableCell>
                  )
                }
                <TableCell align="left" style={{ cursor: "pointer" }}>{data?.JOURNAL_NO}</TableCell>
                <TableCell align="left" style={{ cursor: "pointer" }}><ReadMore children={data?.LG_DESC} /></TableCell>

                {
                  data?.BEGINING_BALANCE == '0' ? (<>
                    <TableCell align="right" style={{ cursor: "pointer" }}></TableCell></>) :
                    (<>
                      <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.AMOUT)}₭</TableCell>
                    </>)
                }
                <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                {
                  currentbalance === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE == 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: 'red' }} >{getFormatNumber(data?.FOREIGN_BALANCES * data?.EXCHNAGE_RATE)}k</TableCell>

                          </>) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }

                    </>) : null
                }

                {
                  showdebit === true ? (
                    <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.DEBIT)}₭</TableCell>
                  ) : null
                }
                {
                  showcredit === true ? (
                    <TableCell align="right" style={{ cursor: "pointer" }}>{getFormatNumber(data?.CREDIT)}₭</TableCell>
                  ) : null
                }
                {
                  foreigndebit === true ? (
                    <>
                      {data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                        <>
                          {
                            data?.FOREIGN_CODE === 'USD' ? (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_DEBIT)}$</TableCell>
                              </>) : (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_DEBIT)}฿</TableCell>
                              </>)
                          }

                        </>) : (
                        <>
                          <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                        </>)}
                    </>
                  ) : null
                }
                {
                  foreigncredit === true ? (
                    <>
                      {data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                        <>
                          {
                            data?.FOREIGN_CODE === 'USD' ? (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_CREDIT)}$</TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.CURRENCIES_CREDIT)}฿</TableCell>
                              </>)
                          }
                        </>
                      ) : (
                        <>
                          <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                        </>
                      )}
                    </>
                  ) : null
                }
                {
                  foreignamount === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            {
                              data?.FOREIGN_CODE === 'USD' ?
                                (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_AMOUNT)}$</TableCell>

                                  </>
                                ) : (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_AMOUNT)}฿</TableCell>

                                  </>
                                )
                            }
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>
                        )
                      }
                    </>
                  ) : null
                }
                {
                  foreignbalance === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ?
                          (
                            <>
                              {
                                data?.FOREIGN_CODE === 'USD' ? (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_BALANCES)}$</TableCell>
                                  </>
                                ) : (
                                  <>
                                    <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber(data?.FOREIGN_BALANCES)}฿</TableCell>
                                  </>
                                )
                              }
                            </>
                          ) : (
                            <>
                              <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                            </>
                          )
                      }
                    </>) : null
                }
                {
                  rate === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: '#0d6efd' }} >
                              {
                                data?.EXCHNAGE_RATE
                              }
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
                {
                  exchangerate === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer", color: 'green' }} >

                              {
                                data?.money_rate
                              }

                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
                {
                  gain_Loss === true ? (
                    <>
                      {
                        data?.FOREIGN_CODE === 'USD' || data?.FOREIGN_CODE === 'THB' ? (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} >{getFormatNumber((data?.FOREIGN_BALANCES * data?.EXCHNAGE_RATE) - (data?.BALANCES))}₭</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right" style={{ cursor: "pointer" }} ></TableCell>
                          </>)
                      }
                    </>) : null
                }
              </TableRow>
            </>
          )
        })}
      <TableRow>
        <TableCell style={{ fontWeight: "bold", color: "back", paddingLeft: level, fontSize: 11 }} colspan={3}>Total for {name_eng}</TableCell>
        <TableCell align="left"></TableCell>
        {
          getFormatNumber(total) === '0.00' ? (<>
            <TableCell align="right"></TableCell>
          </>) : (<>
            <TableCell align="right">{getFormatNumber(total)}</TableCell>
          </>)
        }

        <TableCell align="left"></TableCell>
        {
          currentbalance === true ? (<>
            <TableCell align="right"></TableCell>
          </>) : null
        }
        {
          showdebit === true ? (
            <TableCell align="right">{getFormatNumber(debittotal)}₭</TableCell>
          ) : null
        }
        {
          showcredit === true ? (
            <TableCell align="right">{getFormatNumber(credittoal)}₭</TableCell>
          ) : null
        }
        {
          foreigndebit === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          foreigncredit === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          foreignamount === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          foreignbalance === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          rate === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          exchangerate === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
        {
          gain_Loss === true ? (
            <TableCell align="right"></TableCell>
          ) : null
        }
      </TableRow>
    </>
  )
}

function ReadMore({ children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {
        text == null ? (<></>) : (<>
          {isReadMore ? text.slice(0, 10) : text}
          <span onClick={toggleReadMore} className="read-or-hide" style={{ color: "green" }}>
            {isReadMore ? "...read more" : " show less"}
          </span>
        </>)
      }
    </p>
  );
};