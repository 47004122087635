import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AccountCategory from "./page/AccountCategory";
import Chartofaccounts from "./page/ChartAccounts";
import { api } from "./page/contexts/api";
import { LoginContext } from "./page/contexts/LoginContext";

import Home from "./template/home";
import axios from "axios";
import moment from 'moment';
import Test from "./page/test";
import TestList from "./page/TestList"

import ReportGL from "./page/ReportGL";
import ReportTrialbalances from "./page/ReportTrialbalances";
import ReportAllGL from "./page/ReportallGL";
import BalanceSheet from "./page/BalanceSheet";
import Index from "./page/index";
import Cookies from 'js-cookie';
import Login from "./page/Login";
import setAuthToken from "./setAuthToken"
import UnrealisedGainsAndLosses from "./page/UnrealisedGainsAndLosses";
import Profitandloss from "./page/Profitandloss";
import DetailFitandLoss from "./page/DetailFitandLoss";
import ViewUnrealisedgain_or_loss from "./page/ViewUnrealisedgain_or_loss";
import ExchangeRate from "./page/ExchangeRate";
import Cashflows from "./page/Cashflows";
import DetailType from "./page/DetailType";
import Taxes from "./page/Taxs";
import Transactions_Type from "./page/Transactions_Type";
import Currencies from "./page/Currencies";
import SettingCurrency from "./page/SettingCurrency"
import ReportBycustomer from "./page/ReportBycustomer";
import Customer from "./page/Customer"
import Department from "./page/Department";
import Forbidden from "./page/Forbidden";
import { httpErrorHandler } from "./middleware/httpErrorHandler";
axios.defaults.baseURL = api;
function App() {
  const [listaccount, setListaccount] = useState([]);
  const [listtitle, setListtitle] = useState([])
  const [listcurrency, setListcurrency] = useState([])
  const [showfullscreen, setShowfullscreen] = useState(false);
  const [showEditJournal, setShowEditJournal] = useState(false);
  const [listfistaccount, setListfistaccount] = useState([]);
  const [listsecondaccount, setListsecondaccount] = useState([])
  const [listchart_of_account, setListchart_of_account] = useState([])
  const [listchart_of_account_type, setlistchart_of_account_type] = useState([])
  const [start_date, setStart_date] = useState('')
  const [end_date, setEnd_date] = useState('')
  const [conditionsbydate, setConditionsbydate] = useState(false)
  const [checkConditions, setCheckConditions] = useState(false)
  const [ex_conditions, setEx_conditions] = useState(false)
  const [loading, setLoading] = useState(false);
  const [listgl, setListgl] = useState({})
  const [id, setID] = useState('')
  const [list, setList] = useState([])
  const [datatype, setDatatype] = useState([])
  const [gain_loss, setGain_loss] = useState(0)
  const [getchart_id, setGechart_id] = useState(0)
  const [detail_profitandloss, setDetail_profitandloss] = useState(0)
  const [company_uid, setCompany_uid] = useState(2)
  const [user_session, setUser_session] = useState('bounmeeher')
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState(false)
  const [listchilden, setListchilden] = useState([])
  const [conditions, setConditions] = useState(false)
  const [heading, setHeading] = useState([]);
  const [settax, setSettax] = useState(false)
  const [listuser, setListuser] = useState([])
  const [listtransactions_type, setListtransactions_type] = useState([])
  const [list_trans, setList_trans] = useState([])
  const [listcustomer, setListcustomer] = useState([])
  const [count_number, setCount_number] = useState([])
  const [journalNo, setJournalNo] = useState('')
  const [fullscreen, setFullscreen] = useState(true);
  const [listdepartment, setListdepartment] = useState([])
  const EditJournal = (id) => {
    setID(id)
    setShowEditJournal(true)
  }
  const onloadaccountType = () => {
    axios
      .get("/accounting/api/accountType")
      .then((data) => {
        setListaccount([...data?.data.result]);
        setDatatype([...data?.data?.result_balnaces_sheet])
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onloadCurrency = () => {
    axios.get(`/accounting/api/settingcurrency/Bank/${company_uid}`).then((data) => {
      setListcurrency([...data?.data?.result])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const Onload_titlecashflows = () => {
    axios.get('/accounting/api/cashflow').then((data) => {
      setListtitle([...data?.data?.result])

    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const onloadaccount = () => {
    axios.get(`/accounting/api/accounts`).then((data) => {
      console.log("result=", data)
      setList([...data?.data?.result])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const OnloadChart_Of_Account = () => {
    axios.get(`/accounting/api/chartAccount`).then((data) => {
      setListfistaccount([...data?.data?.result_first])
      setListsecondaccount([...data?.data?.result_second])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const generateTree = (items, parentId) => {
    const tree = [];
    items.forEach(item => {
      if (item.CHART_PARENTS === parentId) {
        const children = generateTree(items, item.CHART_ID);
        const node = {
          CHART_ID: item.CHART_ID,
          CHART_NAME_ENG: item.CHART_NAME_ENG,
          CHART_PARENTS: item.CHART_PARENTS,
          children: children.length ? children : [],
          CURRENCY_UID: item.CURRENCY_UID
        };
        tree.push(node);
      }
    });
    return tree;
  };
  const OnloadAllChart_of_Account = () => {
    axios.get(`/accounting/api/chartAccount/bank/All/${company_uid}`).then((data) => {
      let list = [...data?.data?.result];
      setlistchart_of_account_type([...data?.data?.result])
      setListchart_of_account(generateTree(list, 0))
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const OnlaodGl = () => {
    axios.get(`/accounting/api/ledger_entries/Current/${company_uid}`).then((data) => {
      setListgl({ ...data?.data })
      setLoading(true)
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const RefresOnlaodGl = () => {
    setLoading(false)
    axios.get(`/accounting/api/ledger_entries/Current/${company_uid}`).then((data) => {
      setListgl({ ...data?.data })
      setLoading(true)
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const getcount = () => {
    axios.get(`/accounting/api/ledger_entries//Bank/getcounts/${company_uid}`).then((data) => {
      setCount_number([...data?.data?.result])
      setJournalNo([...data?.data?.result][0].COUNTNUMER)
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const OnChecked = () => {
    axios.get(`/accounting/api/taxs/get/${company_uid}`).then((data) => {

      if ([...data?.data?.result][0].COUNTS > 0) {
        setSettax(true)
        return;

      }
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const onLoaduser = () => {
    axios.get(`/accounting/api/customer/${company_uid}`).then((respone) => {
      setListuser([...respone?.data?.result])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const OnloadDepartment = () => {
    axios.get('/accounting/api/department').then((responses) => {

      setListdepartment([...responses?.data?.result])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  const onLoadTransactions_type = () => {
    axios.get(`/accounting/api/transactionsType/${company_uid}`).then((respone) => {

      setListtransactions_type([...respone?.data?.result])
    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })

  }
  const onLoadTransactions_List = () => {
    axios.get(`/accounting/api/customer/report/current/${company_uid}`).then((respone) => {
      let result = [...respone?.data?.result]
      let children = [...respone?.data?.children]
      setListcustomer([...respone?.data.result])
      let result_total = result.map(result => {
        let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
        let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
        let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
        let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
        let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
        let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
        let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);
        return {
          ...result,
          children: customerTransactions,
          total: totalAmount,
          totalDebit: totalDebit,
          totalCrebit: totalCrebit,
          totalCurrencies_debit: totalCurrencies_debit,
          totalCurrencies_credit: totalCurrencies_credit,
          totalForeignamount: totalForeignamount
        };
      });
      setList_trans(result_total)

    }).catch((error) => {
      httpErrorHandler(error, setCheckConditions)
    })
  }
  useEffect(() => {
    let users = Cookies.get("user");


    if (!users) {
      window.location.assign('https://psvsystem.com/');
    } else {
      let data = JSON.parse(users)
      if (data.token) {
        setAuthToken(data.token, setCheckConditions)
      }
    }
    onloadaccount();
    onloadaccountType();
    OnloadAllChart_of_Account();
    onloadCurrency()
    OnlaodGl();
    Onload_titlecashflows()
    OnChecked()
    onLoaduser()
    onLoadTransactions_type()
    onLoadTransactions_List()
    getcount()
    OnloadDepartment()


  }, []);
  // if(!login){
  //   return <Login setLogin={setLogin}/>
  // }
  // if(login === false){
  //   return(
  //     <Login 
  //     setLogin={setLogin}

  //     />
  //   )
  // }
  if (checkConditions === true) {
    return <Forbidden />
  }
  return (
    <div>
      <LoginContext.Provider
        value={{
          listfistaccount,
          listsecondaccount,
          EditJournal,
          id,
          OnloadChart_Of_Account,
          OnloadAllChart_of_Account,
          onloadaccountType,
          listcurrency,
          setShowfullscreen,
          showfullscreen,
          showEditJournal,
          setShowEditJournal,
          listchart_of_account,
          listgl,
          list,
          listaccount,
          setListgl,
          OnlaodGl,
          setLoading,
          loading,
          datatype,
          start_date,
          setStart_date,
          end_date,
          conditionsbydate,
          ex_conditions,
          setEx_conditions,
          setConditionsbydate,
          setEnd_date,
          gain_loss,
          setGain_loss,
          getchart_id,
          setGechart_id,
          detail_profitandloss,
          setDetail_profitandloss,
          company_uid,
          user_session,
          open,
          setOpen,
          history,
          setHistory,
          fullscreen,
          setFullscreen,
          RefresOnlaodGl,
          listchilden,
          setListchilden,
          conditions,
          setConditions,
          listtitle,
          listchart_of_account_type,
          heading,
          setHeading,
          settax,
          OnChecked,
          onLoaduser,
          listuser,
          listtransactions_type,
          setListtransactions_type,
          onLoadTransactions_List,
          list_trans,
          setList_trans,
          listcustomer,
          setListcustomer,
          count_number,
          getcount,
          onloadCurrency,
          journalNo,
          setJournalNo,
          listdepartment,
          OnloadDepartment,
          checkConditions,
          setCheckConditions
        }}
      >
        <Router>
          <Home>
            <Routes>
              <Route exact path="/Index" element={<Index />} />
              <Route exact path="/Currency" element={<Currencies />} />
              <Route exact path="/Customer" element={<Customer />} />
              <Route exact path="/Department" element={<Department />} />
              <Route exact path="/SettingCurrency" element={<SettingCurrency />} />
              <Route exact path="/AccountCategory" element={<AccountCategory />}></Route>
              <Route exact path="/Chartofaccounts" element={<Chartofaccounts />}></Route>
              <Route exact path="/ReportAllGL" element={< ReportAllGL />}></Route>
              <Route exact path="/Test" element={< Test />}></Route>
              <Route exact path="/Taxs" element={< Taxes />}></Route>
              <Route exact path="/ReportBycustomer" element={< ReportBycustomer />}></Route>
              <Route exact path="/DetailType" element={<DetailType />}></Route>
              <Route exact path="/ExchangeRate" element={< ExchangeRate />}></Route>
              <Route exact path="/TestList" element={< TestList />}></Route>
              <Route exact path="/ReportGL/:id" element={< ReportGL />}></Route>
              <Route exact path="/Balancesheet" element={< BalanceSheet />}></Route>
              <Route exact path="/Profitandloss" element={<Profitandloss />}></Route>
              <Route exact path="/ReportTrialbalances" element={<ReportTrialbalances />}></Route>
              <Route exact path="/Cashflows" element={< Cashflows />}></Route>
              <Route exact path="/Transactions_Type" element={< Transactions_Type />}></Route>
              <Route exact path="/DetailFitandLoss/:ACCOUNT_TYPE_UID/:CHART_UID" element={<DetailFitandLoss />}></Route>
              <Route exact path="/ViewUnrealisedgain_or_loss/:REFERENCE_PARENT/:FOR_YEARS" element={<ViewUnrealisedgain_or_loss />}></Route>
            </Routes>
          </Home>
        </Router>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
