import React from 'react'
import axios from 'axios'
export const httpErrorHandler = (error, setCheckConditions) => {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error?.response
    const request = error?.request
    const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)
    if (error.code === 'ERR_NETWORK') {
      // setCheckConditions(true)
      console.log('connection problems..')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    } else if (error.code === 'ERR_BAD_REQUEST') {
      console.log('Connections2 ')
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status

      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      } else if (statusCode === 401) {
        console.log('Please login to access this resource')
        //redirect user to login
      } else if (statusCode === 403) {
        setCheckConditions(true)
        // window.location.assign('https://secure.phongsavanhgroup.com/');
        // console.log("statusCode403")
        // setTimeout(() => {
        //   window.location.assign('https://secure.phongsavanhgroup.com/');
        // }, 2000);
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
}
// %7B%22token%22%3A%22eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJvdW5tZWVoZXJibCIsInVzZXJJZCI6IlBTVi0wMC0wMDAxNTAiLCJpYXQiOjE3MTkzODE0MDgsImV4cCI6MTcxOTQxMDIwOH0.tSvPNNcMuP4hytd3-ZggNtnUeLUEnMhxsLu3RChlkks%22%2C%22id%22%3A%22PSV-00-000150%22%2C%22expiresIn%22%3A3600%2C%22roles%22%3A%5B%22User%22%2C%22OfficeAdmin%22%2C%22HR%22%5D%7D