import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const ITEM_HEIGHT = 48;
export default function Options_department(props) {
    const open = Boolean(props.anchorElTax_dep);
    return (
        <div>
            <Menu
                id="long-menu"
                anchorEl={props.anchorElTax_dep}
                keepMounted
                open={open}
                onClose={props.handleCloseDepartment}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25%',
                        marginTop: 50,
                    },
                }}
            >
                {/* <MenuItem onClick={() => { props.handleShowbox() }}>
                    <small>
                        add New
                    </small>
                </MenuItem> */}

                {props.listdepartment.map((item, index) => (

                    <MenuItem
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => {
                            props.select_options_department(
                                item?.DEPARTMENT_ID,
                                item?.DEPARTMENT_NAME,
                                props.getindex,
                                props.data
                            );
                        }}
                    >
                        <small>
                            {item?.DEPARTMENT_NAME}
                        </small>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
