import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'react-bootstrap/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getFormatNumber } from "../constants/functions"
import moment from 'moment';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { LoginContext } from "../page/contexts/LoginContext";
import { ModalExchange } from "../components/ModalExchange";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function BalanceSheet() {
    const navigate = useNavigate();
    let componentRef = useRef(null)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [getvalues, setGetvalues] = useState('')

    const [defaultValue, setDefaultValue] = useState("")
    const [defaultValue1, setDefaultValue1] = useState("")
    const [loading, setLoading] = useState(false);
    const [showSetting, setShowSetting] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    const [isChecked, setIsChecked] = useState(true)
    const classes = useStyles();
    const [ispageloading, setIspageloading] = useState(false)
    const [isloadsearch, setIsloadsearch] = useState(false)
    const Today = moment(new Date()).format("DD-MM-YYYY")
    const structure_assets = [{ 'CREATESTATUS': 1, 'BS_NAME': 'Assets' }]
    const structure_liabilities = [{ 'CREATESTATUS': 2, 'BS_NAME': 'Liabilities and shareholder`s equity' }]
    const [openingExchange, setOpeningExchange] = useState(false)
    const {
        setTra_balance, start_date, setStart_date, end_date, setEnd_date, setConditionsbydate,
        setEx_conditions, setGain_loss, setDetail_profitandloss, company_uid, user_session, heading, setHeading
    } = useContext(LoginContext);
    const OnloadBalancesheet = () => {
        setIspageloading(true)
        axios.get(`/accounting/api/balancesheet/${company_uid}`).then((data) => {
            setHeading({ ...data?.data })
            setDataLoaded(true)
            setLoading(true)
            setIspageloading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleCloseOpenting = () => {
        setOpeningExchange(false)
    }
    const ReportExchange = () => {
        setIsloadsearch(true)
        let data = {
            created_by: user_session,
            company_uid: company_uid,
            start_date: moment(start_date).format('DD-MM-YYYY'),
            end_date: moment(end_date).format('DD-MM-YYYY'),
            for_year: moment(end_date).format('YYYY'),
            for_month: moment(end_date).format('MM'),
            v_conditions: 'TWO'
        }
        axios.post('/accounting/api/balancesheet/insert_gainloss', data).then((data) => {
            if (data?.data?.statusCode === 409) {
                setOpeningExchange(true)
            } else {
                setHeading({ ...data?.data })
            }
            setConditionsbydate(true)
            setDetail_profitandloss(2)
            setGain_loss(2)
            setEx_conditions(true)
            setIsloadsearch(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    const _searchEnddate = (e) => {
        setDefaultValue1(e)
        setEnd_date(e)
        if (defaultValue1 === "") {
            setDefaultValue1(Today)
        } else {
            setDefaultValue1(moment(e).format("DD-MM-YYYY"))
        }
    }
    const _searchstartdate = (e) => {
        setDefaultValue(e)
        setStart_date(e)
        if (defaultValue === "") {
            setDefaultValue(Today)
        } else {
            setDefaultValue(moment(e).format("DD-MM-YYYY"))
        }

    }
    const _onShow = (e) => {
        setGetvalues(e)
    }
    const Setting = () => {
        setShowSetting(!showSetting)
    }
    const GotoProfitandlossofconditions = (e) => {
        navigate('/Profitandloss')
    }
    const handleChange1 = event => {
        if (event.target.checked) {
            setTra_balance(false)
            setIsDisabled(false)
        }
    };
    const Search = () => {
        setIsloadsearch(true)
        if (getvalues == "all") {
        } else {
            let data = {
                start_date: moment(start_date).format('DD-MM-YYYY'),
                end_date: moment(end_date).format('DD-MM-YYYY'),
                company_uid: company_uid,
                v_year: moment(start_date).format('YYYY'),
                v_conditions: 'ONE'
            }
            axios.post("/accounting/api/balancesheet/search", data).then((data) => {
                setHeading({ ...data?.data })
                setConditionsbydate(true)
                setGain_loss(1)
                setDetail_profitandloss(1)
                setIsloadsearch(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    // useEffect(() => {
    //     OnloadBalancesheet();
    //     _searchEnddate();
    //     _searchstartdate();
    // }, [])
    useEffect(() => {
        if (!dataLoaded) {
            OnloadBalancesheet();
            _searchEnddate();
            _searchstartdate();
        }
    }, [dataLoaded]);
    return (

        <div style={{ width: '100%' }}>

            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginLeft: 5 }}>BalanceSheet</small>
            </div>
            {
                ispageloading == true ? (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>) : (isloadsearch == true) ? (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>) : (<>
                    <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
                </>)
            }

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginTop: 10,
                justifyContent: 'space-between'
            }}>
                <div
                    style={{
                        display: 'flex',
                        width: '90%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'

                    }}
                >
                    <select
                        onChange={(e) => _onShow(e.target.value)}
                        value={getvalues}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '20%',

                        }}
                    >
                        <option value="all"> This Year-to-date</option>
                        <option value="all">All Dates</option>
                        <option value="today">Today</option>
                        <option value="custom">Custom</option>
                    </select>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%',
                        marginLeft: 5

                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue}
                            onChange={(e) => setDefaultValue(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                height: 30,
                                width: '100%',

                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchstartdate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <span style={{ fontSize: 20, marginLeft: 5, marginRight: 0 }}>To</span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%'
                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue1}
                            onChange={(e) => setDefaultValue1(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                fontSize: 16,
                                height: 30,
                                width: '100%',
                                marginLeft: 2
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchEnddate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '8%'
                    }}
                        onClick={() => Search()}
                    >
                        <SearchIcon />
                        <small>Search</small>
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '8%'
                    }}
                    >
                        <RotateLeftIcon />
                        <small>Reset</small>
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '12%'
                    }}
                        onClick={() => { ReportExchange() }}

                    >
                        {
                            isloadsearch == true ? (<>
                                <Spinner />
                            </>) : (<>
                                <AssessmentIcon />

                            </>)
                        }

                        <small>Run Exchange</small>
                    </div>
                    <div style={{
                        borderRadius: 2,
                        width: '9%',
                        backgroundColor: '#3f51b5',
                        height: 30,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        marginLeft: 5
                    }}
                    >
                        <RefreshIcon style={{ color: 'white' }} />
                        <small style={{ color: 'white', fontWeight: 'bold' }}>Refresh</small>
                    </div>
                    {
                        ispageloading == true ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>
                        </>) : null
                    }
                    {
                        isloadsearch == true ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>

                        </>) : null
                    }
                </div>
                <SettingsIcon
                    style={{
                        marginRight: 10,
                        cursor: 'pointer',
                        marginTop: 5
                    }}
                />
            </div>

            <div style={{ height: 30 }}>
            </div>
            {
                openingExchange === true ? (
                    <>
                        <ModalExchange
                            openingExchange={openingExchange}
                            handleCloseOpenting={handleCloseOpenting}
                            for_month={moment(start_date).format('MM')}
                            for_year={moment(start_date).format('YYYY')}
                        />
                    </>) : null
            }
            {
                loading ? (
                    <>
                        <TableContainer component={Paper} ref={(el) => (componentRef = el)}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">TOTAL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        structure_assets.map((data, index) => {
                                            return (
                                                <>
                                                    <GLRowComponent
                                                        key={index}
                                                        id={data?.CREATESTATUS}
                                                        name={data?.BS_NAME}
                                                        structure_balancesheet={heading?.structure_balancesheet_assets}
                                                        childrenFirst={heading?.children_fist}
                                                        childrenSecond={heading?.children_second}



                                                    />
                                                </>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>

                            <div style={{ border: '1px solid black' }}></div>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {
                                        structure_liabilities && structure_liabilities.map((data, index) => {

                                            return (
                                                <>
                                                    <GLRowComponent2
                                                        key={index}
                                                        id={data?.CREATESTATUS}
                                                        name={data?.BS_NAME}
                                                        structure_balancesheet={heading?.structure_balancesheet_liabilities}
                                                        structure_balancesheet_ShareHolders={heading?.structure_balancesheet_ShareHolders}
                                                        GetTransfer_Balances={heading?.GetTransfer_Balances}
                                                        childrenFirst={heading?.children_fist}
                                                        childrenSecond={heading?.children_second}
                                                        GotoProfitandlossofconditions={GotoProfitandlossofconditions}

                                                    />
                                                </>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <Skeleton />
                )
            }
        </div>



    );
}

function GLRowComponent({ name, id, structure_balancesheet, index, childrenFirst, childrenSecond }) {

    const [open, setOpen] = useState(true);


    const First_filteredData = childrenFirst.filter(item => item.BS_ID === 1 || item.BS_ID === 2);
    const Second_filteredData = childrenSecond.filter(item => item.BS_ID === 1 || item.BS_ID === 2);

    const First_totalBalances = First_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances = Second_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);

    let NetTotal = parseFloat(First_totalBalances) + parseFloat(Second_totalBalances)

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow key={index}>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                    {name}
                </TableCell>
                {open ? (<>
                    <TableCell align="right"></TableCell>
                </>) : (
                    <>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{getFormatNumber(NetTotal)}₭</TableCell>
                    </>)}
            </TableRow>
            {open ? (
                <>
                    < Componentchild
                        id={id}
                        size={25}
                        structure_balancesheet={structure_balancesheet}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}



                    />
                    <TableRow>
                        <TableCell component="th" scope="row"  >
                            Total{name}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{getFormatNumber(NetTotal)}₭</TableCell>
                    </TableRow>
                </>
            ) : null
            }
        </>
    )
}
function GLRowComponent2({ name, id, structure_balancesheet, structure_balancesheet_ShareHolders, GetTransfer_Balances, childrenFirst, childrenSecond, GotoProfitandlossofconditions }) {

    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    let netIncome = GetTransfer_Balances[0].BALANCES

    const First_filteredData = childrenFirst.filter(item => item.BS_ID === 3 || item.BS_ID === 4 || item.BS_ID === 5);
    const Second_filteredData = childrenSecond.filter(item => item.BS_ID === 3 || item.BS_ID === 4 || item.BS_ID === 5);
    const First_totalBalances = First_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances = Second_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);

    let NetTotal = parseFloat(First_totalBalances) + parseFloat(Second_totalBalances) + parseFloat(netIncome)
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}>{open ? <ExpandLess /> : <ExpandMore />}
                    {name}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right"></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(NetTotal)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {open ? (
                <>
                    < Componentchild2
                        id={id}
                        size={25}
                        structure_balancesheet={structure_balancesheet}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}

                    />
                    <ComponentOwner
                        structure_balancesheet_ShareHolders={structure_balancesheet_ShareHolders}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}
                        GetTransfer_Balances={GetTransfer_Balances}
                        size={30}
                        GotoProfitandlossofconditions={GotoProfitandlossofconditions}
                    />
                    <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            Total{name}
                        </TableCell>

                        <TableCell align="right" style={{ fontWeight: "bold" }}>{getFormatNumber(NetTotal)}₭</TableCell>
                    </TableRow>
                </>
            ) : null
            }
        </>
    )
}
function ComponentOwner({ structure_balancesheet_ShareHolders, childrenFirst, childrenSecond, size, GetTransfer_Balances, GotoProfitandlossofconditions }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const First_filteredData = childrenFirst.filter(item => item.BS_ID === 5);
    const Second_filteredData = childrenSecond.filter(item => item.BS_ID === 5);
    const First_totalBalances = First_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances = Second_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);

    let NetTotal = parseFloat(First_totalBalances) + parseFloat(Second_totalBalances)

    return (<>
        <TableRow>
            <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: size, cursor: "pointer" }} >{open ? <ExpandLess /> : <ExpandMore />}
                {structure_balancesheet_ShareHolders[0]?.BS_NAME}
            </TableCell>
            {
                open ? (<>
                    <TableCell align="right"></TableCell>
                </>) : (<>

                    <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(NetTotal + GetTransfer_Balances[0].BALANCES)}₭</TableCell>
                </>)
            }
        </TableRow>
        {
            open ? (<>
                <TableRow>
                    <TableCell component="th" style={{ paddingLeft: 65 }} >
                        Net Income
                    </TableCell>
                    <TableCell align="right" style={{ cursor: 'pointer', color: 'red' }} onClick={() => { GotoProfitandlossofconditions() }}>{getFormatNumber(GetTransfer_Balances[0].BALANCES)}₭</TableCell>
                </TableRow>
                <ComponetOwner
                    childrenFirst={childrenFirst}
                    childrenSecond={childrenSecond}
                    id_Owner={structure_balancesheet_ShareHolders[0]?.BS_ID}

                />
                <TableRow>
                    <TableCell component="th" scope="row" style={{ paddingLeft: size, cursor: "pointer", fontWeight: 'bold' }}>
                        Total: Shareholders equity
                    </TableCell>

                    <TableCell align="right" style={{ fontWeight: "bold" }}>{getFormatNumber(NetTotal + GetTransfer_Balances[0].BALANCES)}₭</TableCell>

                </TableRow>
            </>) : null
        }
    </>)
}
function ComponetOwner({ childrenFirst, id_Owner, childrenSecond }) {
    if (childrenFirst === null) return <></>
    const filter = childrenFirst.filter((el) => el.BS_ID == id_Owner);
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <ComponetOwnerFirstChild
                                key={index}
                                data={data}
                                id={data?.CHART_ID}
                                childrenSecond={childrenSecond}


                            />
                        </>
                    )
                })
            }


        </>
    )
}

function ComponetOwnerFirstChild({ data, id, childrenSecond }) {

    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);





    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 65, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {open ? (
                <>
                    < ComponetOwnerSecondChild
                        id={id}
                        childrenSecond={childrenSecond}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 65, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                            </TableRow>
                        </>)
                    }
                </>

            ) : null
            }


        </>
    )
}
function ComponetOwnerSecondChild({ id, childrenSecond, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;

    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}


                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner({ data, childrenSecond, id }) {

    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);



    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 80, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner1
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}

                    />
                    {
                        checkvalues === 0 ? (<>
                        </>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 80, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )
}
function TableCellComponentOwner1({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner2
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}

                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner2({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 90, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner3
                        setCheckvalues={setCheckvalues}
                        childrenSecond={childrenSecond}
                        id={id}

                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 90, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }
        </>
    )
}
function TableCellComponentOwner3({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner4
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}



                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner4({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);




    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 100, cursor: "pointer" }} >

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)} ₭ </TableCell>
                    </>) : (<>
                        <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner5
                        setCheckvalues={setCheckvalues}
                        childrenSecond={childrenSecond}
                        id={id}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 100, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }
        </>
    )
}
function TableCellComponentOwner5({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner6
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}



                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner6({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);



    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 110, cursor: "pointer" }} >

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭ </TableCell>
                    </>) : (<>
                        <TableCell align="right"> {getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner7
                        setCheckvalues={setCheckvalues}
                        childrenSecond={childrenSecond}
                        id={id}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 110, fontWeight: "bold" }} >
                                    Total: {data?.name_eng}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )
}
function TableCellComponentOwner7({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner8
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}



                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner8({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 120, cursor: "pointer" }} >

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }}>
                            {getFormatNumber(data?.BALANCES)}₭ </TableCell>
                    </>) : (<>
                        <TableCell align="right"> {getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner9
                        setCheckvalues={setCheckvalues}
                        childrenSecond={childrenSecond}

                        id={id}

                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 120, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )
}
function TableCellComponentOwner9({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner10
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}

                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner10({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 130, cursor: "pointer" }} >

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭ </TableCell>
                    </>) : (<>
                        <TableCell align="right"> {getFormatNumber(net_total)}₭ </TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentOwner11
                        setCheckvalues={setCheckvalues}
                        childrenSecond={childrenSecond}

                        id={id}

                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 130, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )
}
function TableCellComponentOwner11({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentOwner12
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}

                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentOwner12({ data, id, childrenSecond }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 140, cursor: "pointer" }} >


                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭ </TableCell>
                    </>) : (<>
                        <TableCell align="right">{getFormatNumber(net_total)}₭ </TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 140, fontWeight: "bold" }} >
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>

                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )
}
function Componentchild({ id, size, structure_balancesheet, childrenFirst, childrenSecond }) {
    const filter = structure_balancesheet.filter((el) => el.BS_STATUS == id);
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((item, index) => {
                    return (
                        <>
                            < Component
                                item={item}
                                size={size}
                                index={index}
                                id={item?.BS_ID}
                                childrenFirst={childrenFirst}
                                childrenSecond={childrenSecond}
                            />
                        </>
                    )
                })
            }
        </>
    )
}
function Componentchild2({ id, size, structure_balancesheet, childrenFirst, childrenSecond }) {
    const filter = structure_balancesheet.filter((el) => el.BS_STATUS == id);
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter.map((item, index) => {
                    return (
                        <>
                            < Component2
                                item={item}
                                size={size}
                                key={index}
                                id={item?.BS_ID}
                                childrenFirst={childrenFirst}
                                childrenSecond={childrenSecond}


                            />
                        </>
                    )
                })
            }
        </>
    )
}
function Component({ id, item, size, index, childrenFirst, childrenSecond }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };


    const First_filteredData = childrenFirst.filter(item => item.BS_ID === id);
    const Second_filteredData = childrenSecond.filter(item => item.BS_ID === id);
    const First_totalBalances = First_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances = Second_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);


    let NetTotal = parseFloat(First_totalBalances) + parseFloat(Second_totalBalances)

    return (
        <>
            <TableRow key={index}>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: size, cursor: "pointer" }} >{open ? <ExpandLess /> : <ExpandMore />}
                    {item?.BS_NAME}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right"></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(NetTotal)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {open ? (
                <>
                    <Componentsub
                        id={id}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}
                        subject_name={item?.BS_NAME}
                        NetTotal={NetTotal}




                    />
                </>
            ) : null

            }
        </>
    )
}

function Componentsub({ id, childrenFirst, childrenSecond, subject_name, NetTotal }) {
    if (childrenFirst === null) return <></>
    const filter = childrenFirst.filter((el) => el.BS_ID == id);
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {

                    return (<>
                        <Componetfirst
                            data={data}
                            key={index}
                            id={data?.CHART_ID}
                            childrenSecond={childrenSecond}

                        />
                    </>)
                })
            }
            <TableRow>
                <TableCell component="th" scope="row" style={{ paddingLeft: 25, fontWeight: "bold" }} >
                    Total: {subject_name}
                </TableCell>

                <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(NetTotal)}₭</TableCell>


            </TableRow>
        </>
    )
}
function Componetfirst({ data, id, childrenSecond }) {
    const [checkvalues, setCheckvalues] = useState('')
    const [open, setOpen] = useState(true);
    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }

        let totalBalance = 0;

        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 39, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>{open ? <ExpandLess /> : <ExpandMore />}</>)
                    }{data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        {
                            getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                <TableCell align="right" ></TableCell>
                            </>) : (<>

                                <TableCell align="right" >{getFormatNumber(data?.BALANCES)}₭</TableCell>

                            </>)
                        }
                    </>) : (<>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    < Componetsecond
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}



                    />
                    {
                        checkvalues === 0 ? (
                            <>
                            </>) : (
                            <>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ paddingLeft: 35, fontWeight: "bold" }} >
                                        Total:{data?.CHART_NAME_ENG}
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(net_total)}₭</TableCell>

                                </TableRow>
                            </>)
                    }
                </>) : null
            }
        </>)
}
function Componetsecond({ childrenSecond, id, setCheckvalues }) {

    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);

    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }


    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            < TableCellComponent
                                data={data}
                                id={data?.CHART_ID}
                                key={index}
                                childrenSecond={childrenSecond}



                            />
                        </>
                    )
                })
            }
        </>
    )
}

function TableCellComponent({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)




    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick(); }} style={{ paddingLeft: 50, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)
                            }
                        </>) : (<>

                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponent1
                        childrenSecond={childrenSecond}
                        id={data?.CHART_ID}
                        setCheckvalues={setCheckvalues}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 50, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}> {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent1({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS === id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }


    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            < TableCellComponent2
                                key={index}
                                data={data}
                                id={data?.CHART_ID}
                                childrenSecond={childrenSecond}




                            />
                        </>
                    )
                })
            }
        </>
    )
}
function TableCellComponent2({ id, childrenSecond, data }) {
    const [checkvalues, setCheckvalues] = useState(0)



    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row"
                    onClick={() => { handleClick() }}
                    style={{
                        paddingLeft: 70, cursor: "pointer"
                    }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }} ></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }} >
                                        {getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)

                            }


                        </>
                    ) : (<>
                        <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }

            </TableRow>
            {
                open ? (<>
                    < TableCellComponent3
                        id={id}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}



                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent3({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS === id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponent4
                                key={index}
                                data={data}
                                id={data?.CHART_ID}
                                childrenSecond={childrenSecond}


                            />
                        </>
                    )
                })
            }
        </>
    )
}
function TableCellComponent4({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 80, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)
                            }


                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }

            </TableRow>
            {
                open ? (<>
                    <TableCellComponent5
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}



                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 80, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent5({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponent6
                                data={data}
                                key={index}
                                childrenSecond={childrenSecond}
                                id={data?.CHART_ID}


                            />
                        </>
                    )
                })

            }
        </>
    )
}
function TableCellComponent6({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);

    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 90, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)
                            }


                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponent7
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}



                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 90, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent7({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            < TableCellComponent8
                                key={index}
                                data={data}
                                childrenSecond={childrenSecond}
                                id={data?.CHART_ID}


                            />
                        </>
                    )
                })
            }
        </>
    )
}
function TableCellComponent8({ data, childrenSecond, id }) {

    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 110, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)
                            }


                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponent9
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 110, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent9({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponent10
                                data={data}
                                key={index}
                                id={data?.CHART_ID}
                                childrenSecond={childrenSecond}

                            />
                        </>
                    )
                })
            }
        </>
    )
}
function TableCellComponent10({ data, childrenSecond, id }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);



    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 105, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                getFormatNumber(data?.BALANCES) === '0.00' ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>
                                </>) : (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                                </>)
                            }


                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }
            </TableRow>

            {
                open ? (<>
                    <TableCellComponent11
                        id={data?.c_id}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 110, fontWeight: "bold" }} >
                                    Total:  {data?.name_eng}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponent11({ id, childrenSecond, setCheckvalues }) {
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (<>

                        <TableCellComponent12
                            data={data}
                            key={index}
                            id={data?.CHART_ID}



                        />
                    </>)
                })
            }
        </>
    )
}
function TableCellComponent12({ data }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 115, cursor: "pointer" }} >{open ? <ExpandLess /> : <ExpandMore />}
                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right">{getFormatNumber(data?.BALANCES)}₭</TableCell>
            </TableRow>
        </>
    )
}
function Component2({ item, size, id, childrenFirst, childrenSecond }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };


    const First_filteredData = childrenFirst.filter(item => item.BS_ID === id);
    const Second_filteredData = childrenSecond.filter(item => item.BS_ID === id);
    const First_totalBalances = First_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances = Second_filteredData.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    let NetTotal = parseFloat(First_totalBalances) + parseFloat(Second_totalBalances)


    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: size, cursor: "pointer" }} >{open ? <ExpandLess /> : <ExpandMore />}
                    {item?.BS_NAME}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right"></TableCell>
                    </>) : (<>
                        <TableCell align="right">{getFormatNumber(NetTotal)}₭</TableCell>

                    </>)
                }
            </TableRow>

            {open ? (
                <>
                    <ComponetfirstLiability
                        id={id}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}


                    />

                    <TableRow>
                        <TableCell component="th" scope="row" style={{ paddingLeft: size, fontWeight: "bold" }} >
                            Total:{item?.BS_NAME}
                        </TableCell>

                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(NetTotal)}₭</TableCell>

                    </TableRow>
                </>

            ) : null

            }


        </>
    )


}


function ComponetfirstLiability({ id, childrenFirst, childrenSecond }) {

    if (childrenFirst === null) return <></>
    const filter = childrenFirst.filter((el) => el.BS_ID == id);
    if (filter.length === 0) return <></>;
    return (<>
        {
            filter && filter.map((data, index) => {
                return (<>
                    <TableCellComponentFirstLiability
                        key={index}
                        data={data}
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}


                    />
                </>)
            })
        }
    </>)
}
function TableCellComponentFirstLiability({ data, childrenSecond, id }) {

    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)



    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 45, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<>

                        </>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>

                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }

            </TableRow>
            {
                open ? (<>
                    < ComponetSecondLiability
                        id={data?.CHART_ID}
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}


                    />
                    {
                        checkvalues === 0 ? (<>
                        </>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 45, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>


                            </TableRow>
                        </>)
                    }


                </>) : null
            }

        </>
    )


}
function ComponetSecondLiability({ id, childrenSecond, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <TableCellComponentLiability
                            key={index}
                            data={data}
                            id={data?.CHART_ID}
                            childrenSecond={childrenSecond}


                        />
                    )
                })
            }
        </>
    )
}
function TableCellComponentLiability({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)

    const handleClick = () => {
        setOpen(!open);
    };


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)




    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 60, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentLiability1
                        childrenSecond={childrenSecond}
                        setCheckvalues={setCheckvalues}
                        id={id}


                    />
                    {
                        checkvalues === 0 ? (<>

                        </>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 60, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>

                            </TableRow>
                        </>)
                    }

                </>) : null
            }


        </>
    )


}
function TableCellComponentLiability1({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {

                    return (<>
                        <TableCellComponentLiability2
                            data={data}
                            index={index}
                            id={data?.CHART_ID}
                            childrenSecond={childrenSecond}


                        />
                    </>)
                })
            }
        </>
    )
}
function TableCellComponentLiability2({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)



    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick(); }} style={{ paddingLeft: 70, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentLiability3
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}


                    />

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }

        </>
    )
}
function TableCellComponentLiability3({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (<>
                        < TableCellComponentLiability4
                            data={data}
                            key={index}
                            childrenSecond={childrenSecond}
                            id={data?.CHART_ID}



                        />
                    </>)
                })
            }
        </>
    )
}
function TableCellComponentLiability4({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 75, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<>
                        </>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                        </>) : (<>
                            <TableCell align="right">
                                {getFormatNumber(net_total)}₭
                            </TableCell>
                        </>)
                }
            </TableRow>

            {
                open ? (<>
                    < TableCellComponentLiability5
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}




                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(data?.net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }

                </>) : null
            }

        </>
    )
}
function TableCellComponentLiability5({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentLiability6
                                data={data}
                                key={index}
                                childrenSecond={childrenSecond}
                                id={data?.CHART_ID}



                            />
                        </>
                    )
                })
            }

        </>
    )
}
function TableCellComponentLiability6({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)


    const handleClick = () => {
        setOpen(!open);
    };


    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 75, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>

            {
                open ? (<>
                    <TableCellComponentLiability7
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}

                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow></>)
                    }

                </>) : null
            }


        </>
    )
}
function TableCellComponentLiability7({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentLiability8
                                data={data}
                                key={index}
                                childrenSecond={childrenSecond}

                                id={data?.CHART_ID}


                            />
                        </>
                    )
                })
            }


        </>
    )
}
function TableCellComponentLiability8({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)
    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 75, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>


            {
                open ? (<>
                    <TableCellComponentLiability9
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponentLiability9({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.parents == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentLiability10
                                data={data}
                                key={index}
                                childrenSecond={childrenSecond}
                                id={data?.CHART_ID}

                            />
                        </>
                    )
                })
            }


        </>
    )
}
function TableCellComponentLiability10({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)

    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.balances) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 75, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>

                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>

            {
                open ? (<>
                    <TableCellComponentLiability11
                        childrenSecond={childrenSecond}
                        id={id}
                        setCheckvalues={setCheckvalues}


                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:{data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: "bold" }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>

                        </>)
                    }
                </>) : null
            }
        </>
    )
}
function TableCellComponentLiability11({ childrenSecond, id, setCheckvalues }) {
    if (childrenSecond === null) return <></>
    const filter = childrenSecond.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentLiability12
                                data={data}
                                key={index}
                                childrenSecond={childrenSecond}
                                id={data?.CHART_ID}


                            />
                        </>
                    )
                })
            }


        </>
    )
}
function TableCellComponentLiability12({ data, childrenSecond, id }) {
    const [open, setOpen] = useState(true);
    const [checkvalues, setCheckvalues] = useState(0)

    function sumBalances(childrenSecond, parentId) {
        const children = childrenSecond.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecond, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecond, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: 75, cursor: "pointer" }} >
                    {
                        checkvalues === 0 ? (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>) : (<></>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right" style={{ cursor: 'pointer' }} >{getFormatNumber(data?.BALANCES)}₭</TableCell>
                    </>) : (<>
                        <TableCell align="right">
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </>)
                }
            </TableRow>
            <TableCellComponentLiability11
                childrenSecond={childrenSecond}
                id={id}

                setCheckvalues={setCheckvalues}

            />
            {
                checkvalues === 0 ? (<></>) : (<>
                    <TableRow>
                        <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                            Total:{data?.name_eng}
                        </TableCell>

                        <TableCell align="right" style={{ fontWeight: "bold" }}>
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </TableRow>
                </>)
            }

        </>
    )
}





