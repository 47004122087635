import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
export const Add_transactions_type = (props) => {

    const [active, setActive] = useState(null)
    const handleChange_sales = event => {
        if (event.target.checked) {
            props.setSales_conditions(true)
            props.setConditions('Sales')
        } else {
            props.setSales_conditions(false)
            props.setConditions('')
        }

    };
    const handleChange_purchase = event => {
        if (event.target.checked) {

            props.setPurchases_conditions(true)
            props.setConditions('Purchases')

        } else {

            props.setPurchases_conditions(false)
            props.setConditions('')

        }

    };
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.show} onHide={props.handleClose} style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Transactions Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Transactions Type"
                                onChange={(e) => props.setTransactions_name(e.target.value)}
                                value={props.transactions_name}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label style={{ fontSize: 20 }}></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Description"
                                onChange={(e) => props.setDescriptions(e.target.value)}
                                value={props.descriptions}

                            />
                        </Form.Group>


                    </Form>
                </Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        marginRight: 10,
                        width: 100,
                        height: 35,
                        marginBottom: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5,
                        cursor: 'pointer',
                        boxShadow: active === 'save' ? '1px 2px 9px #F4AAB9' : '',

                    }}
                        onMouseOver={() => setActive('save')}
                        onMouseLeave={() => setActive(null)}
                    >
                        {
                            props.update === true ? (<>
                                <small style={{ color: 'white', fontSize: 18 }} onClick={() => { props.Updatetransactions_type() }}>Update</small>
                            </>) : (<>
                                <small style={{ color: 'white', fontSize: 18 }} onClick={() => { props.CreateTaxs() }}>Save</small>
                            </>)
                        }


                    </div>

                </div>


            </Modal>

        </div>
    )
}
