import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
export const Deactive_account = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.openingDeactivate} onHide={props.handleCloseReduces_usage} style={{ paddingTop: 50 }} >
                <Modal.Header closeButton>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',

                        width: '100%'


                    }}>
                        <small style={{ fontWeight: 'bold', fontSize: 20 }}>Deativate this account?</small>
                    </div>
                </Modal.Header>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10,
                    marginLeft: 10
                }}>
                    <small style={{ marginTop: 40, fontSize: 18 }}>This completes all transactions associated with the accounts.</small>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: 20,
                    marginLeft: 10
                }}>
                    <div style={{
                        backgroundColor: '#0d6efd',
                        marginRight: 20,
                        borderRadius: 5,
                        height: 30,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                        onClick={() => { props.closeaccount(props.reduces_chart_id) }}
                    >
                        <small style={{ fontSize: 14, marginLeft: 20, marginRight: 10, fontWeight: 'bold', color: 'white' }}>

                            {!props.isLoading_reduces ? (
                                <>
                                    Yes,make inactive
                                </>
                            ) : (
                                <>
                                    {
                                        <Spinner animation="border" variant="light" size='sm' />
                                    }
                                </>)
                            }

                        </small>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
