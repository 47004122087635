

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
const ITEM_HEIGHT = 50;
export default function Box_edit(props) {
    const open = Boolean(props.anchorEl);

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={props.handleClick}
            >
                <ExpandMoreIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={props.anchorEl}
                keepMounted
                open={open}
                onClose={props.handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '10%',
                        marginTop: 50,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {

                        props.handleShow();
                        props.optionsCurrency(
                            props.CURRENCY_UID,
                        );
                        props.setDisplayname(props.CUSTOMERS_NAME)
                        props.setCompany(props.COMPANY_NAME)
                        props.setEmail(props.CUSTOMER_EMAIL)
                        props.setPhonenumber(props.CUSTOMER_PHONE_NUMBER)
                        props.setMobilenumber(props.CUSTOMER_PHONE_NUMBER)
                        props.setOld_id(props.CUSTOMERS_ID)
                        props.setShowupdate(true)


                    }} >Edit</MenuItem>
            </Menu>
        </div>
    );
}
