import React, { useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import axios from 'axios';
import { getFormatNumber } from "../constants/functions"





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}





const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'name' },
  { id: 'calories', numeric: true, disablePadding: false, label: 'type' },
  { id: 'fat', numeric: true, disablePadding: false, label: 'currency' },
  { id: 'carbs', numeric: true, disablePadding: false, label: 'balances' },
  { id: 'protein', numeric: true, disablePadding: false, label: 'actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TestList() {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listfistaccount, setListfistaccount] = useState([])
  const [listsecondaccount, setListsecondaccount] = useState([])
  const [listchilden, setListchilden] = useState([])
  const [datafilter, setDatafilter] = useState('')
  const [limit, setLimit] = useState(5)


  function createData(CHART_NAME_ENG, ACCOUNT_TYPE_NAME_ENG, CHART_CURRENCY_CODE, BALACES) {
    return { CHART_NAME_ENG, ACCOUNT_TYPE_NAME_ENG, CHART_CURRENCY_CODE, BALACES };
  }
  const rows = [
    createData(listfistaccount),

  ];



  const data = [
    { CHART_ID: 1, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 1', CHART_PARENTS: 0 },
    { CHART_ID: 2, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 2', CHART_PARENTS: 0 },
    { CHART_ID: 4, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 1.1', CHART_PARENTS: 1 },
    { CHART_ID: 5, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 1.2', CHART_PARENTS: 1 },
    { CHART_ID: 6, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 2.1', CHART_PARENTS: 2 },
    { CHART_ID: 7, ACCOUNT_TYPE_UID: "fasdfsdfasd", CURRENCY_UID: "m0909899", ACCOUNT_TYPE_NAME_ENG: "fdsafsad", BALACES: "fsdfasd", CHART_CURRENCY_CODE: 'lak', ACCOUNT_TYPE_AC_SYMBOL: 'as', ACCOUNT_CREATESTATUS: '90', CHART_NAME_ENG: 'Node 2.2', CHART_PARENTS: 2 },
  ];

  // const generateTreeSecond = (items) => {
  //   const buildTree = (items, parentId) => {
  //     const node = {
  //       CHART_ID: parentId,
  //       ACCOUNT_TYPE_UID: ACCOUNT_TYPE_UID,
  //       CHART_NAME_ENG: items.find(item => item.CHART_ID === parentId).CHART_NAME_ENG,
  //       CHART_PARENTS: parentId,
  //       children: [],
  //     };

  //     const children = items.filter(item => item.CHART_PARENTS === parentId);
  //     children.forEach(child => {
  //       node.children.push(buildTree(items, child.CHART_ID));
  //     });

  //     return node;
  //   };

  //   const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
  //   const result = roots.map(root => buildTree(items, root.CHART_ID));

  //   return result;
  // };

  // const results = generateTreeSecond(data);
  // console.log("results2000=", results);




  const generateTreeSecond = (items) => {
    const buildTree = (items, parentId) => {
      const currentNode = items.find(item => item.CHART_ID === parentId);

      if (!currentNode) {
        return null; // Return null for non-existent parent (or handle accordingly)
      }

      const node = {
        CHART_ID: currentNode.CHART_ID,
        ACCOUNT_TYPE_UID: currentNode.ACCOUNT_TYPE_UID,
        CURRENCY_UID: currentNode.CURRENCY_UID,
        CHART_NAME_ENG: currentNode.CHART_NAME_ENG,
        ACCOUNT_TYPE_NAME_ENG: currentNode.ACCOUNT_TYPE_NAME_ENG,
        CHART_PARENTS: parentId === 0 ? null : parentId, // Set to null for root nodes
        BALACES: currentNode.BALACES,
        CHART_CURRENCY_CODE: currentNode.CHART_CURRENCY_CODE,
        ACCOUNT_TYPE_AC_SYMBOL: currentNode.ACCOUNT_TYPE_AC_SYMBOL,
        ACCOUNT_CREATESTATUS: currentNode.ACCOUNT_CREATESTATUS,
        children: [],
      };

      const children = items.filter(item => item.CHART_PARENTS === parentId);
      children.forEach(child => {
        const childNode = buildTree(items, child.CHART_ID);
        if (childNode) {
          node.children.push(childNode);
        }
      });

      return node;
    };
    const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
    const result = roots.map(root => buildTree(items, root.CHART_ID));
    return result;
  };
  const results = generateTreeSecond(data);
  console.log("results=", results);

  const AllLoandChart_of_account = () => {
    axios.get(`/accounting/api/chartAccount/get/${limit}`).then((data) => {
      let list = [...data?.data?.result];

      const generateTree = (items, parentId) => {
        const tree = [];
        items.forEach(item => {
          if (item.CHART_PARENTS === parentId) {
            const children = generateTree(items, item.CHART_ID);
            const node = {
              CHART_ID: item.CHART_ID,
              ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
              CURRENCY_UID: item.CURRENCY_UID,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              CHART_PARENTS: item.CHART_PARENTS,
              ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
              BALANCES: item.BALANCES,
              CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
              ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
              ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
              children: children.length ? children : [],
            };
            tree.push(node);
          }
        });
        return tree;
      };
      setListchilden(generateTree(list, 0));
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadAllGL = () => {
    axios.get('/accounting/api/ledger_entries/all').then((data) => {
      console.log("Result_data=", data)
      let dataList = [...data?.data?.result]

      const resultList = dataList.reduce((acc, item) => {
        const existingItem = acc.find((group) => group.CHART_ID === item.CHART_ID);
        if (existingItem) {
          existingItem.children.push({
            CHART_ID: item.CHART_ID,
            CHART_PARENTS: item.CHART_PARENTS,
            CHART_NAME_ENG: item.CHART_NAME_ENG,
            AMOUT: item.AMOUT,
            FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
          });
        } else {
          const newItem = {
            CHART_ID: item.CHART_ID,
            CHART_PARENTS: item.CHART_PARENTS,
            CHART_NAME_ENG: item.CHART_NAME_ENG,
            AMOUT: item.AMOUT,
            FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
            children: [],
            second_floor: [],
          };

          // Only add to children array if CHART_PARENTS is 0 and AMOUT is not null
          if (item.CHART_PARENTS === 0 && item.AMOUT !== null) {
            newItem.children.push({
              CHART_ID: item.CHART_ID,
              CHART_PARENTS: item.CHART_PARENTS,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              AMOUT: item.AMOUT,
              FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
            });
          }

          acc.push(newItem);
        }

        return acc;
      }, []);

      // Separate items into main and second floor based on CHART_PARENTS
      const mainFloor = resultList.filter((item) => item.CHART_PARENTS === 0);
      const secondFloor = resultList.filter((item) => item.CHART_PARENTS !== 0);
      console.log("secondFloor=", secondFloor)

      // Assign second floor items to their corresponding main floor items
      secondFloor.forEach((item) => {
        const parentItem = mainFloor.find((parent) => parent.CHART_ID === item.CHART_PARENTS);
        console.log("parentItem=", parentItem)
        if (parentItem) {
          parentItem.second_floor.push(item);
        }
      });
      console.log("mainFloorsss=", mainFloor)


    }).catch((err) => {
      console.log(err)
    })
  }
  const filterdata = async (e) => {
    setDatafilter(e);
    if (!e) {
      AllLoandChart_of_account();
    } else {
      try {
        axios.get(`/accounting/api/chartAccount/filter/${e}`).then((data) => {
          let list = [...data?.data?.result];
          const generateTreeSecond = (items) => {
            const buildTree = (items, parentId) => {
              const currentNode = items.find(item => item.CHART_ID === parentId);
              if (!currentNode) {
                return null; // Return null for non-existent parent (or handle accordingly)
              }
              const node = {
                CHART_ID: currentNode.CHART_ID,
                ACCOUNT_TYPE_UID: currentNode.ACCOUNT_TYPE_UID,
                CURRENCY_UID: currentNode.CURRENCY_UID,
                CHART_NAME_ENG: currentNode.CHART_NAME_ENG,
                ACCOUNT_TYPE_NAME_ENG: currentNode.ACCOUNT_TYPE_NAME_ENG,
                CHART_PARENTS: parentId === 0 ? null : parentId, // Set to null for root nodes
                BALANCES: currentNode.BALANCES,
                CHART_CURRENCY_CODE: currentNode.CHART_CURRENCY_CODE,
                ACCOUNT_TYPE_AC_SYMBOL: currentNode.ACCOUNT_TYPE_AC_SYMBOL,
                ACCOUNT_CREATESTATUS: currentNode.ACCOUNT_CREATESTATUS,
                children: [],
              };
              const children = items.filter(item => item.CHART_PARENTS === parentId);
              children.forEach(child => {
                const childNode = buildTree(items, child.CHART_ID);
                if (childNode) {
                  node.children.push(childNode);
                }
              });
              return node;
            };
            const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
            const result = roots.map(root => buildTree(items, root.CHART_ID));
            return result;
          };
          // const results = generateTreeSecond(list);
          setListchilden(generateTreeSecond(list));
        }).catch((err) => {
          console.log(err)
        })

      } catch (err) {
        console.log(err);
      }
    }
  };

  let resultList = [
    { CHART_ID: 91, CHART_PARENTS: 0, CHART_NAME_ENG: 'Expenses-lak', AMOUT: 1900000, FOREIGN_AMOUNT: 100 },
    { CHART_ID: 92, CHART_PARENTS: 91, CHART_NAME_ENG: 'Expenses-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 92, CHART_PARENTS: 91, CHART_NAME_ENG: 'Expenses-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 94, CHART_PARENTS: 93, CHART_NAME_ENG: 'Expenses-lak-03', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 85, CHART_PARENTS: 0, CHART_NAME_ENG: 'Current assets-lak', AMOUT: null, FOREIGN_AMOUNT: null },
    { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },

  ]

  let CHART_OF_ACCOUNTS = [
    { CHART_ID: 91, CHART_PARENTS: 0, CHART_NAME_ENG: 'Expenses-lak', AMOUT: 1900000, FOREIGN_AMOUNT: 100 },
    { CHART_ID: 85, CHART_PARENTS: 0, CHART_NAME_ENG: 'Current assets-lak', AMOUT: null, FOREIGN_AMOUNT: null },
  ]
  let LEDGER_ENTRIES = [
    { CHART_ID: 92, CHART_PARENTS: 91, CHART_NAME_ENG: 'Expenses-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 92, CHART_PARENTS: 91, CHART_NAME_ENG: 'Expenses-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 94, CHART_PARENTS: 93, CHART_NAME_ENG: 'Expenses-lak-03', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
    { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },

  ]



  const groupedData = resultList.reduce((acc, item) => {
    const key = item.CHART_ID;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);

    return acc;
  }, {});

  console.log("groupedData=", groupedData)

  const result = [];

  const addToResult = (parentChartId, item) => {
    const parent = result.find(entry => entry.CHART_ID === parentChartId);

    if (!parent) {
      return;
    }

    const newItem = {
      CHART_ID: item.CHART_ID,
      CHART_PARENTS: item.CHART_PARENTS,
      CHART_NAME_ENG: item.CHART_NAME_ENG,
      AMOUT: item.AMOUT,
      FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
      children: [],
      second_floor: []
    };
    if (item.CHART_ID === item.CHART_PARENTS) {
      parent.children.push(newItem);
    } else {
      parent.second_floor.push(newItem);
    }

    return newItem;
  };

  resultList.forEach(item => {
    const newItem = addToResult(item.CHART_PARENTS, item);
    if (!newItem && item.CHART_PARENTS === 0) {
      result.push({
        CHART_ID: item.CHART_ID,
        CHART_PARENTS: item.CHART_PARENTS,
        CHART_NAME_ENG: item.CHART_NAME_ENG,
        AMOUT: item.AMOUT,
        FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
        children: [],
        second_floor: []
      });
    }
  });

  console.log("result=", result);







  // let resultList = [
  //   {
  //     CHART_ID: 91, CHART_PARENTS: 0, CHART_NAME_ENG: 'Expenses-lak', AMOUT: 1900000, FOREIGN_AMOUNT: 100,
  //     children: [
  //       {
  //         CHART_ID: 91, CHART_PARENTS: 0, CHART_NAME_ENG: 'Expenses-lak', AMOUT: 1900000, FOREIGN_AMOUNT: 100,
  //       }],
  //     second_floor:[
  //       { CHART_ID: 92, CHART_PARENTS: 91, CHART_NAME_ENG: 'Expenses-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000,
  //       children: [],
  //       second_floor:[
  //         { CHART_ID: 93, CHART_PARENTS: 92, CHART_NAME_ENG: 'Expenses-lak-02', AMOUT: 10000, FOREIGN_AMOUNT: 7000,
  //         children: [],
  //         second_floor:[
  //           { CHART_ID: 94, CHART_PARENTS: 93, CHART_NAME_ENG: 'Expenses-lak-03', AMOUT: 10000, FOREIGN_AMOUNT: 7000,
  //           children: [],
  //           second_floor:[
  //             { CHART_ID: 93, CHART_PARENTS: 92, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000,
  //             children: [
  //               { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
  //               { CHART_ID: 95, CHART_PARENTS: 94, CHART_NAME_ENG: 'Expenses-lak-04', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
  //             ],
  //             second_floor:[]

  //           },

  //           ]

  //         },

  //         ]

  //       },

  //       ]

  //     },
  //     ]
  //   },
  //   { CHART_ID: 85, CHART_PARENTS: 0, CHART_NAME_ENG: 'Current assets-lak', AMOUT: null, FOREIGN_AMOUNT: null,
  //    children: [],
  //    second_floor:[
  //     { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000,
  //     children: [  
  //       { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
  //       { CHART_ID: 90, CHART_PARENTS: 85, CHART_NAME_ENG: 'Current assets-lak-01', AMOUT: 10000, FOREIGN_AMOUNT: 7000 },
  //   ],
  //   },
  //   ]
  // },
  // ]
  // const resultList = dataList.reduce((acc, item) => {
  //   const existingItem = acc.find((group) => group.CHART_ID === item.CHART_ID);

  //   if (existingItem) {
  //     existingItem.children.push({
  //       CHART_ID: item.CHART_ID,
  //       CHART_PARENTS: item.CHART_PARENTS,
  //       CHART_NAME_ENG: item.CHART_NAME_ENG,
  //       AMOUT: item.AMOUT,
  //       FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
  //     });
  //   } else {
  //     const newItem = {
  //       CHART_ID: item.CHART_ID,
  //       CHART_PARENTS: item.CHART_PARENTS,
  //       CHART_NAME_ENG: item.CHART_NAME_ENG,
  //       AMOUT: item.AMOUT,
  //       FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
  //       children: [],
  //       second_floor: [],
  //     };

  //     // Only add to children array if CHART_PARENTS is 0 and AMOUT is not null
  //     if (item.CHART_PARENTS === 0 && item.AMOUT !== null) {
  //       newItem.children.push({
  //         CHART_ID: item.CHART_ID,
  //         CHART_PARENTS: item.CHART_PARENTS,
  //         CHART_NAME_ENG: item.CHART_NAME_ENG,
  //         AMOUT: item.AMOUT,
  //         FOREIGN_AMOUNT: item.FOREIGN_AMOUNT,
  //       });
  //     }

  //     acc.push(newItem);
  //   }

  //   return acc;
  // }, []);

  // // Separate items into main and second floor based on CHART_PARENTS
  // const mainFloor = resultList.filter((item) => item.CHART_PARENTS === 0);
  // const secondFloor = resultList.filter((item) => item.CHART_PARENTS !== 0);

  // // Assign second floor items to their corresponding main floor items
  // secondFloor.forEach((item) => {
  //   const parentItem = mainFloor.find((parent) => parent.CHART_ID === item.CHART_PARENTS);
  //   if (parentItem) {
  //     parentItem.second_floor.push(item);
  //   }
  // });



  // console.log("dataResult=", mainFloor)

  const ReadMore = () => {
    let limitpage = 5
    // setLimit(prevLimit => prevLimit + 10);
    setLimit(prevLimit => prevLimit + 1);
    axios.get(`/accounting/api/chartAccount/get/${limitpage + limit}`).then((data) => {
      let list = [...data?.data?.result];
      const generateTree = (items, parentId) => {
        const tree = [];
        items.forEach(item => {
          if (item.CHART_PARENTS === parentId) {
            const children = generateTree(items, item.CHART_ID);
            const node = {
              CHART_ID: item.CHART_ID,
              ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
              CURRENCY_UID: item.CURRENCY_UID,
              CHART_NAME_ENG: item.CHART_NAME_ENG,
              CHART_PARENTS: item.CHART_PARENTS,
              ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
              BALACES: item.BALACES,
              CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
              ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
              ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
              children: children.length ? children : [],
            };
            tree.push(node);
          }
        });
        return tree;
      };
      setListchilden(generateTree(list, 0));
    }).catch((err) => {
      console.log(err)
    })
  }
  const generateTree = (items, parentId) => {
    const tree = [];
    items.forEach(item => {
      if (item.CHART_PARENTS === parentId) {
        const children = generateTree(items, item.CHART_ID);
        const node = {
          CHART_ID: item.CHART_ID,
          ACCOUNT_TYPE_UID: item.ACCOUNT_TYPE_UID,
          CURRENCY_UID: item.CURRENCY_UID,
          CHART_NAME_ENG: item.CHART_NAME_ENG,
          CHART_PARENTS: item.CHART_PARENTS,
          ACCOUNT_TYPE_NAME_ENG: item.ACCOUNT_TYPE_NAME_ENG,
          BALANCES: item.BALANCES,
          CHART_CURRENCY_CODE: item.CHART_CURRENCY_CODE,
          ACCOUNT_TYPE_AC_SYMBOL: item.ACCOUNT_TYPE_AC_SYMBOL,
          ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,
          children: children.length ? children : [],
        };
        tree.push(node);
      }
    });
    return tree;
  };
  useEffect(() => {
    AllLoandChart_of_account()
    OnloadAllGL()
  }, [])
  return (
    <div className={classes.root}>
      {/* <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={listfistaccount.length}
            />
            <TableBody>
              {stableSort(listfistaccount, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log("row=", row)
                  const isItemSelected = isSelected(row.CHART_NAME_ENG);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.CHART_NAME_ENG)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.CHART_NAME_ENG}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.CHART_NAME_ENG}
                      </TableCell>
                      <TableCell align="right">{row.CHART_NAME_ENG}</TableCell>
                      <TableCell align="right">{row.CHART_NAME_ENG}</TableCell>
                      <TableCell align="right">{row.CHART_NAME_ENG}</TableCell>
                      <TableCell align="right">{row.CHART_NAME_ENG}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <input type="text"
          onChange={(e) => filterdata(e.target.value)}
          value={datafilter}
        />

      </div>
      {JSON.stringify(limit)}
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell align="left">TYPE</TableCell>
              <TableCell align="left">CURRENCY</TableCell>
              <TableCell align="right">BANLANCE</TableCell>
              <TableCell align="right">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              listchilden && listchilden.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell>{item?.CHART_NAME_ENG}</TableCell>
                      <TableCell align="left">{item?.ACCOUNT_TYPE_NAME_ENG}</TableCell>
                      <TableCell align="left">{item?.CHART_CURRENCY_CODE}</TableCell>
                      <TableCell align="right">{getFormatNumber(item?.BALANCES)}</TableCell>
                      <TableCell align="right">
                      </TableCell>
                    </TableRow>
                    <RowComponent
                      children={item?.children}
                      level={30}
                    />
                  </>
                )
              })
            }

          </TableBody>
        </Table>

      </TableContainer>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <div style={{ cursor: 'pointer' }} onClick={() => { ReadMore() }}>
          <small>ReadMore</small>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell rowspan={2}>NAME</TableCell>
              <TableCell align="center" colspan={2}>Opening Balance</TableCell>
              <TableCell align="center" colspan={2}>Activities list</TableCell>
              <TableCell align="center" colspan={2}>Closing Balances</TableCell>

            </TableRow>
            <TableRow>
              <TableCell align="left">Debit</TableCell>
              <TableCell align="right">Credit</TableCell>
              <TableCell align="left">Debit</TableCell>
              <TableCell align="right">Credit</TableCell>
              <TableCell align="left">Debit</TableCell>
              <TableCell align="right">Credit</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell>afasf</TableCell>
              <TableCell align="left">fsadfsadf</TableCell>
              <TableCell align="left">fsdafasf</TableCell>
              <TableCell align="right">sdfasdf</TableCell>

            </TableRow>

          </TableBody>
        </Table>

      </TableContainer>
    </div>
  );
}
function RowComponent({ children, level }) {
  if (children.length === 0) return <></>;
  return (
    <>
      {children.map((data, index) => {
        return (
          <>
            <TableRow key={index}>
              <TableCell style={{
                paddingLeft: level,
              }}>
                {data?.CHART_NAME_ENG}
              </TableCell>

              <TableCell align="left">{data?.ACCOUNT_TYPE_NAME_ENG}</TableCell>
              <TableCell align="left">{data?.CHART_CURRENCY_CODE}</TableCell>
              <TableCell align="right">{getFormatNumber(data?.BALANCES)}</TableCell>
              <TableCell align="right">

              </TableCell>
            </TableRow>
            <RowComponent
              children={data.children}
              level={level * 2 - 20}
            />

          </>
        );
      })}
    </>
  );
}
