import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { getFormatNumber } from "../constants/functions"
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { LoginContext } from "./contexts/LoginContext";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingLg from "../components/Setting";
import RefreshIcon from '@material-ui/icons/Refresh';
import TablePagination from '@material-ui/core/TablePagination';
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FullScreenDialogEdit } from "../modal/Edit_entries";
import { Successfully } from "../modal/Successfully";
import BoxDescriptions from "../modal/BoxDescriptions";
import GetAppIcon from '@material-ui/icons/GetApp';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import setAuthToken from "../setAuthToken"

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { httpErrorHandler } from "../middleware/httpErrorHandler";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 440, // Adjust the height as needed
        maxWidth: '100%', // Adjust the width as needed
        overflow: 'auto', // Ensure scrolling
    },
    table: {
        minWidth: 650,
        tableLayout: 'fixed', // Ensures fixed layout for columns
    },
    stickyHeader: {
        // position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.appBar,
    },
    parentDiv: {
        width: '100%',
        height: '500px', // Set the height you want for the parent div
        overflow: 'auto', // Make the div scrollable
        marginTop: 100
    },
}));

export default function ReportBycustomer() {
    const navigate = useNavigate();
    const {
        listcustomer,
        list_trans,
        listtransactions_type,
        setCheckConditions,
        loading, listcurrency, setLoading, setList_trans, company_uid, RefresOnlaodGl, setListcustomer, listaccount,
        listdepartment
    } = useContext(LoginContext);


    const classes = useStyles();
    const [conditions_type, setConditions_type] = useState('')
    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");
    const [showBox, setShowBox] = useState(false);
    const [active, setActive] = useState("");
    const [Leave, setLeave] = useState(0);
    const [nameShow, setNameShow] = useState("");
    const [defaultValue, setDefaultValue] = useState("")
    const [defaultValue1, setDefaultValue1] = useState("")
    const Today = moment(new Date()).format("DD-MM-YYYY")
    const [ch_id, setCh_id] = useState("")
    const [open, setOpen] = useState(true);
    const [account, setAccount] = useState(false)
    const [alldates, setAlldates] = useState(false)
    const [journal, setJournal] = useState(false)
    const [getjournal, setGetjournal] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [errdate, setErrdate] = useState(false)
    const [err, setErr] = useState("0")
    const [currency, setCurrency] = useState('')
    const [showSetting, setShowSetting] = useState(false)
    const [showdebit, setShowdebit] = useState(false)
    const [showcredit, setshowcredit] = useState(false)
    const [foreigndebit, setForeignDebit] = useState(false)
    const [foreigncredit, setForeignCredit] = useState(false)
    const [foreignamount, setForeignAmount] = useState(false)
    const [foreignbalance, setforeignBalance] = useState(false)
    const [currentbalance, setCurrentbalance] = useState(false)
    const [gain_Loss, setGain_loss] = useState(false)
    const [getchart_id, setGechart_id] = useState('')
    const [open_edit_journal_entries, setOpen_edit_journal_entries] = useState(false)
    const [currencystatus, setCurrencystatus] = useState('');
    const [rate, setRate] = useState(false)
    const [show, setShow] = useState(false);
    const [exchangerate, setExchangeRate] = useState(false);
    const [exchange, setExchange] = useState([])
    const [isLoading, setIsLoading,] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [exchange_conditions, setExchange_conditions] = useState(false)
    const [current_balance_conditions, setCurrent_balance_conditions] = useState(false)
    const [selectedImages, setSelectedImages] = useState([])
    const [images, setImage] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [dataList, setDataList] = useState([])
    const [old_data, setOld_data] = useState([])
    const [editcurrency, setEditcurrency] = useState("")
    const [netTotalDebit, setNetTotalDebit] = useState("")
    const [netTotalCrebit, setNetTotalCrebit] = useState("")
    const [thb, setThb] = useState("");
    const [usd, setUsd] = useState("");
    const [journalNo, setJournalNo] = useState("")
    const [oldjournal_no, setOldjournal_no] = useState('')
    const [currency_id, setCurrency_id] = useState("");
    const [defaultValue_edit, setDefaultValue_edit] = useState("")
    const [checkCurrency, setCheckCurrency] = useState('')
    const [successfully, setSuccessfully] = useState(false)
    const [successfullyloading, setSuccessfullyloading] = useState(false)
    // const [list_trans, setList_trans] = useState([])
    const [tr_id, setTr_id] = useState('')
    const [custom, setCustom] = useState(false)
    const [conditions_none, setConditions_none] = useState(false)
    const [checkDisabled, setCheckDisabled] = useState(false)
    const [show_box_name, setShow_box_name] = useState(false)
    const [listfilter, setListfilter] = useState([])
    const [get_customer, listGet_customer] = useState('')
    const [customer_id, setcustomer_id] = useState('')
    const [role_id, setRole_id] = useState('')
    const [transactions_type_id, setTransactions_type_id] = useState('')
    const [listimage, setListimage] = useState([])
    const [select_currency, setSelect_currency] = useState('')
    const [checked_logo, setChecked_logo] = useState('')
    const [conditions_curren, setConditions_curren] = useState('')
    const [exchage_rate, setExchage_rate] = useState('')
    const [warring, setWarring] = useState(false)
    const [warring_select_currency, setWarring_select_currency] = useState(false)
    const [warring_exhange_rate, setWarring_exhange_rate] = useState(false)
    const [warring_debit_credit, setWarring_debit_credit] = useState(false)
    const [byaccount_type, setByaccount_type] = useState('')
    const [department_id, setDepartment_id] = useState('')

    const [page, setPage] = useState(0);
    const Setting = () => {
        setShowSetting(!showSetting)
    }
    const handleClose = () => {
        setAnchorEl(false);

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const onClickShow = () => {
        setShow_box_name(!show_box_name)
    }
    const handleOpenDrawer = () => {
        setAnchorEl(null);
    };
    const handleExport = () => {
        const processData = (data) => {
            const flatData = [];
            data.forEach(parent => {
                parent.children.forEach(child => {
                    flatData.push({
                        Name: child.CUSTOMERS_NAME + '-' + child.CURRENCY,
                        Date: moment(child.CREATEDATE).format('DD-MM-YYYY'),
                        'Transaction Type': child.TRANSACTIONS_TYPE,
                        Number: child.TRANSACTIONS_NUMBER,
                        Description: child.DESRIPTIONS || '',
                        Account: child.CHART_NAME_ENG || '',
                        'Account Type': child.ACCOUNT_TYPE_NAME_ENG,
                        'Department Name': child.DEPARTMENT_NAME,
                        'Currency': child.CURRENCY,
                        'Exchange Rate': child.EXCHNAGE_RATE,
                        'Foreing Debit': child.TRANSACTIONS_CURRRENCY === 'LAK' ? '' : child.CURRENCIES_DEBIT,
                        'Foreing Credit': child.TRANSACTIONS_CURRRENCY === 'LAK' ? '' : child.CURRENCIES_CREDIT,
                        'Foreign Amount': child.TRANSACTIONS_CURRRENCY === 'LAK' ? '' : child.FOREIGN_AMOUNT,
                        Debit: child.DEBIT,
                        Credit: child.CREDIT,
                        Amount: child.AMOUNT,
                    });
                });
                flatData.push({
                    Name: `Total ${parent.CUSTOMERS_NAME}-${parent.CURRENCY}`,
                    Date: '',
                    Number: '',
                    Description: '',
                    Account: '',
                    'Account Type': '',
                    'Department Name': '',
                    Currency: '',
                    'Exchange Rate': '',
                    'Foreing Debit': parent.totalCurrencies_debit,
                    'Foreing Credit': parent.totalCurrencies_credit,
                    'Foreign Amount': parent.totalForeignamount,
                    Debit: parent.totalDebit,
                    Credit: parent.totalCrebit,
                    Amount: parent.total
                });
            });
            return flatData;
        };

        const exportData = processData(list_trans);

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'ExportedData.xlsx');
    };

    const handleShow = () => {
        setShow(true)
    };



    const handleCloseOpen = () => {
        setOpen_edit_journal_entries(false);
        setGechart_id('')
    };


    const onBlurCredit = () => {
        setShowSetting(false)
    }
    const Ondebit = () => {
        setShowdebit(!showdebit)
    }
    const Oncredit = () => {

        setshowcredit(!showcredit)
    }
    const OnForeigndebit = () => {

        setForeignDebit(!foreigndebit)
    }
    const Onforeigncredit = () => {

        setForeignCredit(!foreigncredit)
    }
    const Onforeignamount = () => {
        setForeignAmount(!foreignamount)
    }
    const OnForeignbalance = () => {
        setforeignBalance(!foreignbalance)
    }
    const OnRate = () => {
        setRate(!rate)
    }
    const OnExchangerate = () => {
        setExchange_conditions(true)
        setCurrent_balance_conditions(true)
    }


    const OnCurrentBalance = () => {
        setLeave(0)
        setCurrentbalance(!currentbalance)
    }
    const insert = () => {
    }
    const OnGain_loss = () => {
        setLeave(0)
        setGain_loss(!gain_Loss)
    }
    const handleClicks = () => {
        setShowBox(!showBox);
    };
    const Refresh = () => {
        RefresOnlaodGl()
    }
    const handleClick = () => {
        setOpen(!open);
    };
    const handleBlur = () => {
        if (active == null) {
        } else {
            setShowSetting(false)
        }
    };

    const filterdata_sub = async (e) => {
        listGet_customer(e)
        if (!e) {
            setSearchResult([]);
        } else {
            let filter_data = listfilter.filter((item) => item.CUSTOMERS_NAME.toLowerCase().includes(e.toLowerCase()))
            setSearchResult(filter_data)
        }
    };

    const onClickbyfilter = (customer_id, customer_name, user_status) => {
        setcustomer_id(customer_id)
        setRole_id(user_status)
        listGet_customer(customer_name)
        setShow_box_name(false)
    }
    const transactions_type = (e) => {
        setTransactions_type_id(e)
    }
    const tran_department = (e) => {
        setDepartment_id(e)
    }
    const customs = (e) => {
        setConditions_none(e)
        if (e === 'none') {
            setCheckDisabled(true)
        } else {
            setCheckDisabled(false)
        }
    }
    const Search = () => {
        if (conditions_type === 'all_date' || conditions_type === 'thismonth') {
            if (conditions_type === 'all_date') {
                setLoading(false)
                axios.get(`/accounting/api/customer/report/${company_uid}`).then((respone) => {

                    let result = [...respone?.data?.result]
                    let children = [...respone?.data?.children]
                    setListcustomer([...respone?.data.result])
                    let result_total = result.map(result => {
                        let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                        let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                        let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                        let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                        let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                        let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                        let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);
                        return {
                            ...result,
                            children: customerTransactions,
                            total: totalAmount,
                            totalDebit: totalDebit,
                            totalCrebit: totalCrebit,
                            totalCurrencies_debit: totalCurrencies_debit,
                            totalCurrencies_credit: totalCurrencies_credit,
                            totalForeignamount: totalForeignamount
                        };
                    });
                    setList_trans(result_total)
                    setLoading(true)

                }).catch((error) => {
                    httpErrorHandler(error, setCheckConditions)
                    setLoading(true)
                })
            } else {
                setLoading(false)
                axios.get(`/accounting/api/customer/report/current/${company_uid}`).then((respone) => {

                    let result = [...respone?.data?.result]
                    let children = [...respone?.data?.children]
                    setListcustomer([...respone?.data.result])
                    let result_total = result.map(result => {
                        let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                        let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                        let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                        let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                        let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                        let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                        let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);
                        return {
                            ...result,
                            children: customerTransactions,
                            total: totalAmount,
                            totalDebit: totalDebit,
                            totalCrebit: totalCrebit,
                            totalCurrencies_debit: totalCurrencies_debit,
                            totalCurrencies_credit: totalCurrencies_credit,
                            totalForeignamount: totalForeignamount
                        };
                    });
                    setList_trans(result_total)
                    setLoading(true)

                }).catch((error) => {
                    httpErrorHandler(error, setCheckConditions)
                    setLoading(true)
                })

            }

        } else {
            if (!conditions_none) {
                alert("ddd")
                return;
            }
            if (conditions_type === 'Customers') {
                if (conditions_none == 'none') {
                    setLoading(false)
                    let data = {
                        V_CUSTOMER_SATUS: conditions_type,
                        V_COMPANY_UID: company_uid
                    }
                    axios.post('/accounting/api/customer/Searchbycustomer', data).then((response) => {
                        let result = [...response?.data?.result]
                        let children = [...response?.data?.children]
                        setListcustomer([...response?.data.result])
                        let result_total = result.map(result => {
                            let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                            let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                            let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                            let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                            let totalCurrencies_debit = customerTransactions.reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0)
                            let totalCurrencies_credit = customerTransactions.reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0)
                            let totalForeignamount = customerTransactions.reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0)
                            return {
                                ...result,
                                children: customerTransactions,
                                total: totalAmount,
                                totalDebit: totalDebit,
                                totalCrebit: totalCrebit,
                                totalCurrencies_debit: totalCurrencies_debit,
                                totalCurrencies_credit: totalCurrencies_credit,
                                totalForeignamount: totalForeignamount
                            };
                        });
                        setList_trans(result_total)
                        setLoading(true)

                    }).catch((err) => {
                        console.log(err)
                    })
                } else {
                    setLoading(false)
                    let data = {
                        customer_id: customer_id,
                        start_date: defaultValue,
                        end_date: defaultValue1,
                        transactions_type_id: transactions_type_id,
                        byaccount_type: byaccount_type,
                        department_id: department_id
                    }
                    if (data.customer_id && data.transactions_type_id && data.start_date && data.end_date) {

                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_TAXS_ID: transactions_type_id
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_all_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                        // Condition where customer_id, transactions_type_id, start_date, and end_date all have values
                    } else if (data.customer_id && data.transactions_type_id) {
                        const params = {
                            V_TAXS_ID: transactions_type_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/both', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.department_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }
                        console.log("dddd")
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer/Account_type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })


                    } else if (data.customer_id && data.department_id) {
                        const params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id
                        }
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.department_id && data.transactions_type_id) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_TAXS_ID: transactions_type_id,

                        }
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer/Transactions_type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    }
                    else if (data.customer_id && data.byaccount_type && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }
                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type_Cus_By_date`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type,
                            V_CUSTOMER_ID: customer_id,
                        }
                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type_Cus`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.customer_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.transactions_type_id) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_TAXS_ID: transactions_type_id
                        };
                        axios.post('/accounting/api/customer/Searchbycustomer/Transactions_Type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                        // Fallback case if none of the above conditions are met
                    } else if (data.department_id && data.customer_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_customer_department_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.department_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_DEPARTMENT_ID: department_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_department_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                    else if (data.department_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_DEPARTMENT_ID: department_id,
                            V_ACCOUNT_TYPE_UID: byaccount_type,

                        }

                        axios.post(`/accounting/api/customer/SearchbydepartmentBycustomer/Account_type_department`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }

                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id) {
                        let params = {
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        };
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.department_id) {

                        let params = {
                            V_CUSTOMER_SATUS: conditions_type,
                            V_COMPANY_UID: company_uid,
                            V_DEPARTMENT_ID: department_id
                        };
                        console.log("params=", params)
                        axios.post("/accounting/api/customer/Searchbydepartment", params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_only_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }
            } else if (conditions_type === 'Supplier') {
                if (conditions_none == 'none') {
                    setLoading(false)
                    let data = {
                        V_CUSTOMER_SATUS: conditions_type,
                        V_COMPANY_UID: company_uid
                    }
                    axios.post('/accounting/api/customer/Searchbycustomer', data).then((response) => {
                        let result = [...response?.data?.result]
                        let children = [...response?.data?.children]
                        setListcustomer([...response?.data.result])
                        let result_total = result.map(result => {
                            let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);

                            let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                            let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                            let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                            let totalCurrencies_debit = customerTransactions.reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0)
                            let totalCurrencies_credit = customerTransactions.reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0)
                            let totalForeignamount = customerTransactions.reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0)
                            return {
                                ...result,
                                children: customerTransactions,
                                total: totalAmount,
                                totalDebit: totalDebit,
                                totalCrebit: totalCrebit,
                                totalCurrencies_debit: totalCurrencies_debit,
                                totalCurrencies_credit: totalCurrencies_credit,
                                totalForeignamount: totalForeignamount
                            };
                        });
                        setList_trans(result_total)
                        setLoading(true)

                    }).catch((err) => {
                        console.log(err)
                    })
                } else {
                    setLoading(false)
                    let data = {
                        customer_id: customer_id,
                        start_date: defaultValue,
                        end_date: defaultValue1,
                        transactions_type_id: transactions_type_id,
                        byaccount_type: byaccount_type,
                        department_id: department_id
                    }
                    if (data.customer_id && data.transactions_type_id && data.start_date && data.end_date) {

                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_TAXS_ID: transactions_type_id
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_all_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                        // Condition where customer_id, transactions_type_id, start_date, and end_date all have values
                    } else if (data.customer_id && data.transactions_type_id) {
                        const params = {
                            V_TAXS_ID: transactions_type_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/both', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.department_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }
                        console.log("dddd")
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer/Account_type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })


                    } else if (data.customer_id && data.department_id) {
                        const params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id
                        }
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.department_id && data.transactions_type_id) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_TAXS_ID: transactions_type_id,

                        }
                        axios.post('/accounting/api/customer/SearchbydepartmentBycustomer/Transactions_type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    }
                    else if (data.customer_id && data.byaccount_type && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }
                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type_Cus_By_date`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type,
                            V_CUSTOMER_ID: customer_id,
                        }
                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type_Cus`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.customer_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.transactions_type_id) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_TAXS_ID: transactions_type_id
                        };
                        axios.post('/accounting/api/customer/Searchbycustomer/Transactions_Type', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                        // Fallback case if none of the above conditions are met
                    } else if (data.department_id && data.customer_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_CUSTOMER_ID: customer_id,
                            V_DEPARTMENT_ID: department_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_customer_department_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.department_id && data.start_date && data.end_date) {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_DEPARTMENT_ID: department_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_department_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                    else if (data.department_id && data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_DEPARTMENT_ID: department_id,
                            V_ACCOUNT_TYPE_UID: byaccount_type,

                        }

                        axios.post(`/accounting/api/customer/SearchbydepartmentBycustomer/Account_type_department`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else if (data.byaccount_type) {
                        let params = {
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                            V_ACCOUNT_TYPE_UID: byaccount_type
                        }

                        axios.post(`/accounting/api/customer/Searchbyaccount/account_type`, params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.customer_id) {
                        let params = {
                            V_CUSTOMER_ID: customer_id,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type
                        };
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    } else if (data.department_id) {

                        let params = {
                            V_CUSTOMER_SATUS: conditions_type,
                            V_COMPANY_UID: company_uid,
                            V_DEPARTMENT_ID: department_id
                        };
                        console.log("params=", params)
                        axios.post("/accounting/api/customer/Searchbydepartment", params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })

                    } else {
                        let params = {
                            V_STARTDATE: defaultValue,
                            V_ENDDATE: defaultValue1,
                            V_COMPANY_UID: company_uid,
                            V_CUSTOMER_SATUS: conditions_type,
                        }
                        axios.post('/accounting/api/customer/Searchbycustomer/Customs_by_only_date', params).then((response) => {
                            let result = [...response?.data?.result]
                            let children = [...response?.data?.children]
                            setListcustomer([...response?.data.result])
                            let result_total = result.map(result => {
                                let customerTransactions = children.filter(transaction => transaction.CUSTOMERS_ID === result.CUSTOMERS_ID);
                                let totalAmount = customerTransactions.reduce((sum, transaction) => sum + transaction.AMOUNT, 0);
                                let totalDebit = customerTransactions.reduce((sum, transaction) => sum + transaction.DEBIT, 0)
                                let totalCrebit = customerTransactions.reduce((sum, transaction) => sum + transaction.CREDIT, 0)
                                let totalCurrencies_debit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_DEBIT, 0);
                                let totalCurrencies_credit = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.CURRENCIES_CREDIT, 0);
                                let totalForeignamount = customerTransactions.filter(transaction => transaction.TRANSACTIONS_CURRRENCY !== 'LAK').reduce((sum, transaction) => sum + transaction.FOREIGN_AMOUNT, 0);

                                return {
                                    ...result,
                                    children: customerTransactions,
                                    total: totalAmount,
                                    totalDebit: totalDebit,
                                    totalCrebit: totalCrebit,
                                    totalCurrencies_debit: totalCurrencies_debit,
                                    totalCurrencies_credit: totalCurrencies_credit,
                                    totalForeignamount: totalForeignamount
                                };
                            });
                            setList_trans(result_total)
                            setLoading(true)

                        }).catch((err) => {
                            console.log(err)
                        })
                    }

                }
            }
        }
    }
    const report_list = (e) => {
        setConditions_type(e)
        if (e === 'Customers' || e === 'Supplier') {
            setCustom(true)
            let filter_data = listcustomer.filter((item) => item.USER_STATUS === e)
            setListfilter([...filter_data])
        } else if (e === 'thismonth') {
            setCustom(false)
        } else if (e === 'all_date') {
            setCustom(false)
        }

    }

    const _searchStartdate = (e) => {
        setDefaultValue(moment(e).format("DD-MM-YYYY"))
    }

    const getListResult = (data) => {
        return data.map(item => ({
            CHART_ID: item.CHART_ID,
            CURRENCY_CODE: item.CURRENCY_CODE,
            CHART_PARENTS: item.CHART_PARENTS,
            BS_STATUS: item.BS_STATUS,
            ACCOUNT_CREATESTATUS: item.ACCOUNT_CREATESTATUS,

        }));
    };

    const OnEditJournal = (id) => {
        setSuccessfully(true)
        setSuccessfullyloading(true)
        axios.get(`/accounting/api/ledger_entries/Bank/${id}`).then((data) => {
            setSelectedImages([...data?.data.picture])
            const newImages = data?.data?.picture.map(file => ({ NAME_IMAGE: file?.name }));
            const list_result = getListResult([...data?.data?.result]);
            setImage(newImages)
            if ([...data?.data?.result].length > 0) {
                setDataList([...data?.data?.result])
                setOld_data(list_result)
            }
            let inforData = [...data?.data?.result]
            const dateIn = [...data?.data?.result][0].CREATEDATE
            let initialValue = 0;
            let sumdebit = [...data?.data?.result]?.reduce(function (previousValue, currentValue) {
                return parseFloat(previousValue) + (currentValue['DEBIT'] != undefined && currentValue['DEBIT'] != '' ? parseFloat(currentValue['DEBIT'].replaceAll(',', '')) : 0)
            }, initialValue)
            let sumcredit = [...data?.data?.result]?.reduce(function (previousValue, currentValue) {
                return parseFloat(previousValue) + (currentValue['CREDIT'] != undefined && currentValue['CREDIT'] != '' ? parseFloat(currentValue['CREDIT'].replaceAll(',', '')) : 0)
            }, initialValue)
            let money_rate = [...data?.data?.result][0].EXCHNAGE_RATE
            let rate = Number(money_rate).toLocaleString('en-US', {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
            });
            let exchange = rate.replaceAll(',', '')

            if ([...data?.data?.result][0].CURRENCY === 'LAK') {
                let TotalDebit = (parseFloat(sumdebit) / parseFloat(exchange))
                let TotalCredit = (parseFloat(sumcredit) / parseFloat(exchange))
                setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
                setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))

            } else {
                let TotalDebit = (parseFloat(exchange) * parseFloat(sumdebit))
                let TotalCredit = (parseFloat(exchange) * parseFloat(sumcredit))
                setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
                setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
            }
            setEditcurrency([...data?.data?.result][0].CURRENCY)
            setCurrencystatus([...data?.data?.result][0].CURRENCY)
            setExchage_rate(formatMoney(exchange))
            setJournalNo([...data?.data?.result][0].JOURNAL_NO)
            setOldjournal_no([...data?.data?.result][0].JOURNAL_NO)
            setTr_id([...data?.data?.result][0].TR_ID)
            setDefaultValue_edit(moment(dateIn).format("DD-MM-YYYY"))
            const uniqueEntries = Object.values(
                inforData.reduce((acc, entry) => {
                    if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
                        acc[entry.CURRENCY_CODE] = entry;
                    }
                    return acc;
                }, {})
            );
            const usdData = uniqueEntries.filter(item => item.CURRENCY_CODE !== 'LAK')
            if (usdData.length === 0) {
                setCurrency_id(false)
                setConditions_curren('LAK')
            } else {
                let filter = listcurrency.filter((el) => el.CURRENCY_NAME.includes(usdData[0].CURRENCY_CODE))
                if (filter[0].CURRENCY_NAME !== 'LAK') {
                    setListimage(filter[0].attachments[0])
                    setSelect_currency(filter[0].CURRENCY_NAME)
                }
                setChecked_logo(usdData[0].CURRENCY_CODE)
                setConditions_curren(usdData[0].CURRENCY_CODE)
                setCurrency_id(true)
            }
            setSuccessfully(false)
            setSuccessfullyloading(false)
            setOpen_edit_journal_entries(true)

        }).catch((err) => {
            console.log(err)
        })
    }

    const formatMoney = (value) => {
        // Remove any non-digit characters except decimal points
        const cleanedValue = value.replace(/[^\d.]/g, '');
        // Split the value into integer and decimal parts
        const parts = cleanedValue.split('.');

        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Handle the decimal part
        if (parts[1]) {
            // Check if the decimal part has only zeros after the second digit
            const truncatedDecimal = parts[1].slice(0, 2);
            const remainingDecimal = parts[1].slice(2);
            if (remainingDecimal.match(/^0*$/)) {
                // If there are only zeros after the second digit, truncate to two digits
                parts[1] = truncatedDecimal;
            } else {
                // Remove trailing zeros without truncating to two digits
                parts[1] = parts[1].replace(/0+$/, '');
                // If all zeros are removed and no decimal part is left
                if (parts[1] === '') {
                    return parts[0];
                }
            }
            // Join the integer and decimal parts and return the result
            return parts.join('.');
        }
        // If there is no decimal part, return the integer part with commas
        return parts[0];
    };

    const _searchEnddate = (e) => {
        setDefaultValue1(moment(e).format("DD-MM-YYYY"))
    }

    const onGotoEditjournal = (id) => {
        const baseUrl = window.location.pathname;
        navigate(`/Journalpage/${id}`);
    }
    const OnShowAatumaticTransaction = (e) => {
        navigate(`/DetailAutomatic/${e}`)
    }


    const getNameList = (c_id) => {
        axios.get(`/accounting/api/chartofaccounts/all/parents/${c_id}`).then((data) => {
            if (data?.data?.message.length > 0) {
                setCh_id(data?.data.message[0].c_id);
                console.log(data?.data.message[0].c_id)
                const names = data?.data?.message.map((item) => {
                    return item.name_eng;
                });
                names.reverse();
                setNameShow(names.join(":"));
                setShowBox(!showBox);
            }
        });
    };
    useEffect(() => {
        let users = Cookies.get("user");

        if (!users) {
            window.location.assign('https://psvsystem.com/');
        } else {
            let data = JSON.parse(users)
            if (data.token) {
                setAuthToken(data.token, setCheckConditions)
            }
        }
    }, [])


    return (
        <>
            <div style={{
                width: "100%",
                position: 'fixed',
                backgroundColor: 'white'
            }} >
                {
                    successfully == true ? (<>
                        <Successfully
                            successfully={successfully}
                            successfullyloading={successfullyloading}
                        />
                    </>) : null
                }
                <FullScreenDialogEdit
                    handleCloseOpen={handleCloseOpen}
                    open_edit_journal_entries={open_edit_journal_entries}
                    data={dataList}
                    setData={setDataList}
                    old_data={old_data}
                    setOld_data={setOld_data}
                    editcurrency={editcurrency}
                    setEditcurrency={setEditcurrency}
                    netTotalDebit={netTotalDebit}
                    setNetTotalDebit={setNetTotalDebit}
                    netTotalCrebit={netTotalCrebit}
                    setNetTotalCrebit={setNetTotalCrebit}
                    thb={thb}
                    setThb={setThb}
                    usd={usd}
                    setUsd={setUsd}
                    journalNo={journalNo}
                    setJournalNo={setJournalNo}
                    oldjournal_no={oldjournal_no}
                    setOldjournal_no={setOldjournal_no}
                    currency_id={currency_id}
                    setCurrency_id={setCurrency_id}
                    defaultValue={defaultValue_edit}
                    setDefaultValue={setDefaultValue_edit}
                    setCheckCurrency={setCheckCurrency}
                    checkCurrency={checkCurrency}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    images={images}
                    setImage={setImage}
                    tr_id={tr_id}
                    currency={currency}
                    setCurrency={setCurrency}
                    listimage={listimage}
                    setListimage={setListimage}
                    select_currency={select_currency}
                    setSelect_currency={setSelect_currency}
                    currencystatus={currencystatus}
                    setCurrencystatus={setCurrencystatus}
                    checked_logo={checked_logo}
                    setChecked_logo={setChecked_logo}
                    conditions_curren={conditions_curren}
                    setConditions_curren={setConditions_curren}
                    exchage_rate={exchage_rate}
                    setExchage_rate={setExchage_rate}
                    warring={warring}
                    setWarring={setWarring}
                    warring_debit_credit={warring_debit_credit}
                    setWarring_debit_credit={setWarring_debit_credit}
                    warring_exhange_rate={setWarring_exhange_rate}
                    warring_select_currency={warring_select_currency}
                    setWarring_select_currency

                />
                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowBackIcon style={{ color: '#3f51b5' }} />
                    </div>
                    <small style={{ fontSize: 20, marginLeft: 5 }}>Transaction List</small>
                    <select
                        onChange={(e) => report_list(e.target.value)}
                        value={conditions_type}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '10%',
                            marginLeft: 10
                        }}
                    >
                        <option value="thismonth">This Month-to-date</option>
                        <option value="all_date">All Dates</option>
                        <option value="Customers">Customer</option>
                        <option value="Supplier">Supplier</option>
                    </select>
                    <select
                        onChange={(e) => customs(e.target.value)}
                        value={conditions_none}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '10%',
                            marginLeft: 10
                        }}
                    >
                        <option value="">Please Choose</option>
                        <option value="none">None</option>
                        <option value="custom">Custom</option>
                    </select>
                    {
                        custom === true ? (<>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '12%' }}>
                                <input
                                    type="text"
                                    value={get_customer}
                                    disabled={checkDisabled}
                                    onChange={(e) => filterdata_sub(e.target.value)}
                                    placeholder="Choose Name"
                                    style={{
                                        border: '1px solid #ccc',
                                        borderRadius: 4,
                                        fontSize: 16,
                                        outline: 'none',
                                        fontSize: 16,
                                        height: 30,
                                        width: '100%',
                                        marginLeft: 10
                                    }}
                                    onClick={() => { onClickShow() }}
                                />
                                {
                                    searchResult.length > 0 ? (
                                        <div style={{
                                            border: '1px solid #ccc', marginLeft: 10, display: 'flex',
                                            position: 'fixed', flexDirection: 'column', backgroundColor: 'white',
                                            marginTop: 35, width: '18.2%'
                                        }}>
                                            {searchResult && searchResult.map((data, index) => {
                                                return (
                                                    <small key={index} style={{
                                                        fontSize: 15,
                                                        cursor: 'pointer',
                                                        marginLeft: 5,
                                                        marginTop: 10,
                                                        marginBottom: 10,
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                        onClick={() => { onClickbyfilter(data?.CUSTOMERS_ID, data?.CUSTOMERS_NAME, data?.USER_STATUS) }}
                                                    >{data?.CUSTOMERS_NAME}<small style={{ color: '#ccc', fontWeight: 'bold' }}>  -  {data?.USER_STATUS}</small></small>
                                                )
                                            })}

                                        </div>
                                    ) : (<>
                                        {
                                            show_box_name ? (

                                                <div style={{
                                                    border: '1px solid #ccc', marginLeft: 10, display: 'flex',
                                                    position: 'fixed', flexDirection: 'column', backgroundColor: 'white',
                                                    marginTop: 35, width: '18.2%'
                                                }}>
                                                    {listfilter && listfilter.map((data, index) => {
                                                        return (
                                                            <small key={index} style={{
                                                                fontSize: 15,
                                                                cursor: 'pointer',
                                                                marginLeft: 5,
                                                                marginTop: 10,
                                                                marginBottom: 10,
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                                onClick={() => { onClickbyfilter(data?.CUSTOMERS_ID, data?.CUSTOMERS_NAME, data?.USER_STATUS) }}
                                                            >{data?.CUSTOMERS_NAME}-{data?.CURRENCY}<small style={{ color: '#ccc', fontWeight: 'bold' }}>  -  {data?.USER_STATUS}</small></small>
                                                        )
                                                    })}

                                                </div>

                                            ) : null
                                        }

                                    </>)
                                }

                            </div>

                        </>) : null
                    }
                    <select
                        onChange={(e) => setByaccount_type(e.target.value)}
                        value={byaccount_type}
                        disabled={checkDisabled}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '10%',
                            marginLeft: 20
                        }}
                    >
                        <option >Account Type</option>
                        {listaccount &&
                            listaccount.map((data, index) => {
                                return (
                                    <option key={index} value={data?.ACCOUNT_TYPE_UID}>
                                        {data?.ACCOUNT_TYPE_NAME_ENG}
                                    </option>
                                );
                            })}
                    </select>
                    <select
                        onChange={(e) => tran_department(e.target.value)}
                        value={department_id}
                        disabled={checkDisabled}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '12%',
                            marginLeft: 20
                        }}
                    >
                        <option >Department Name</option>
                        {
                            listdepartment && listdepartment.map((item, index) => {
                                return (
                                    <>
                                        <option key={index} value={item?.DEPARTMENT_ID}>{item?.DEPARTMENT_NAME}</option>

                                    </>)
                            })
                        }
                    </select>

                    <select
                        onChange={(e) => transactions_type(e.target.value)}
                        value={transactions_type_id}
                        disabled={checkDisabled}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '10%',
                            marginLeft: 20
                        }}
                    >
                        <option >Transactions Type</option>
                        {
                            listtransactions_type && listtransactions_type.map((item, index) => {
                                return (
                                    <>
                                        <option key={index} value={item?.TRANSACTIONS_TYPE_ID}>{item?.TRANSACTIONS_NAME}</option>

                                    </>)
                            })
                        }
                    </select>


                </div>
                {
                    loading ? (<>
                        <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
                    </>) : (<>

                        <LinearProgress style={{ marginTop: 10 }} />
                    </>)
                }
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 10,
                    justifyContent: 'flex-start'
                }}>
                    <small style={{ fontSize: 20, marginLeft: 5 }}>Date:</small>
                    <div style={{ display: 'flex', width: '90%', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            position: 'relative',
                            width: '15%'
                        }}>
                            <input
                                type="text"
                                value={defaultValue}
                                disabled={checkDisabled}
                                placeholder="DD-MM-YYYY"
                                onChange={(e) => setDefaultValue(e.target.value)}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: 4,
                                    fontSize: 16,
                                    outline: 'none',
                                    fontSize: 16,
                                    height: 30,
                                    width: '100%',
                                    marginLeft: 2
                                }}
                            />

                            <input
                                type="date"
                                disabled={checkDisabled}
                                onChange={(e) => { _searchStartdate(e.target.value) }}
                                style={{
                                    height: 20,
                                    borderRadius: 3,
                                    width: 25,
                                    position: 'absolute',
                                    right: 10,
                                    outline: 'none',
                                    border: "none",
                                    top: 6,
                                    cursor: 'pointer',
                                    paddingLeft: 10,
                                }}
                            />
                        </div>
                        <span style={{ fontSize: 20, marginLeft: 10, marginRight: 0 }}>To</span>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            position: 'relative',
                            width: '15%'
                        }}>
                            <input
                                type="text"
                                value={defaultValue1}
                                disabled={checkDisabled}
                                placeholder="DD-MM-YYYY"
                                onChange={(e) => setDefaultValue1(e.target.value)}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: 4,
                                    fontSize: 16,
                                    outline: 'none',
                                    fontSize: 16,
                                    height: 30,
                                    width: '100%',
                                    marginLeft: 2
                                }}
                            />
                            <input
                                type="date"
                                disabled={checkDisabled}

                                onChange={(e) => { _searchEnddate(e.target.value) }}
                                style={{
                                    height: 20,
                                    borderRadius: 3,
                                    width: 25,
                                    position: 'absolute',
                                    right: 10,
                                    outline: 'none',
                                    border: "none",
                                    top: 6,
                                    cursor: 'pointer',
                                    paddingLeft: 10,
                                }}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            backgroundColor: '#3f51b5',
                            border: 'none',
                            height: 30,
                            borderRadius: 2,
                            color: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 5,
                            cursor: 'pointer',
                            width: '10%'
                        }}
                            onClick={() => Search()}
                        >
                            <SearchIcon />
                            <small>Search</small>
                        </div>
                        <div style={{
                            display: 'flex',
                            backgroundColor: "#3f51b5",
                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            color: "white",
                            alignItems: "center",
                            justifyContent: 'center',
                            marginLeft: 5,
                            width: '10%'

                        }}>
                            <RotateLeftIcon />
                            <small>Reset</small>
                        </div>
                        <div style={{
                            display: 'flex',
                            backgroundColor: "#3f51b5",
                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            color: "white",
                            alignItems: "center",
                            justifyContent: 'center',
                            marginLeft: 5,
                            width: '10%',
                            cursor: 'pointer'
                        }}
                            onClick={handleExport}

                        >
                            <GetAppIcon />
                            <small>Export To Excel</small>
                        </div>
                        <div style={{
                            borderRadius: 2,
                            width: '10%',
                            backgroundColor: '#3f51b5',
                            height: 30,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            marginLeft: 5
                        }}
                            onClick={() => { Refresh() }}
                        >

                            <RefreshIcon style={{ color: 'white' }} />
                            <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>Refresh</small>
                        </div>
                        {
                            loading ? (<>

                            </>) : (<>
                                <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                    <Spin size="smal" />
                                </Flex>
                            </>)
                        }
                    </div>

                </div>
            </div>
            <div className={classes.parentDiv} >
                {
                    loading ? (
                        <>

                            <TableContainer className={classes.tableContainer} >
                                <Table aria-label="sticky table" size="small" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.stickyHeader} style={{ width: 300, fontWeight: 'bold' }}>Name</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 150, fontWeight: 'bold' }}>Date</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Transaction Type</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 100, fontWeight: 'bold' }} align="left">Number</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Desription</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Account</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="left">Account Type</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="left">Department Name</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Currency</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Exchange Rate</TableCell>

                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Debit</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Credit</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Foreign Amount</TableCell>

                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Debit</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Credit</TableCell>
                                            <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Amount</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            list_trans && list_trans.map((item, index) => {
                                                return (
                                                    < CellRowComponent
                                                        key={index}
                                                        customer_name={item?.CUSTOMERS_NAME}
                                                        currency_name={item?.CURRENCY}
                                                        children={item?.children}
                                                        total={item?.total}
                                                        OnEditJournal={OnEditJournal}
                                                        anchorEl={anchorEl}
                                                        showdebit={showdebit}
                                                        showcredit={showcredit}
                                                        foreigndebit={foreigndebit}
                                                        foreigncredit={foreigncredit}
                                                        rate={rate}
                                                        foreignamount={foreignamount}
                                                        totalDebit={item?.totalDebit}
                                                        totalCrebit={item?.totalCrebit}
                                                        totalCurrencies_debit={item?.totalCurrencies_debit}

                                                        totalCurrencies_credit={item?.totalCurrencies_credit}
                                                        totalForeignamount={item?.totalForeignamount}
                                                    />)
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <Skeleton />
                    )
                }
            </div>
        </>
    )
}


function ReadMore({ children }) {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {
                text == null ? (<></>) : (<>
                    <small > {isReadMore ? text.slice(0, 5) : text}</small>
                    <BoxDescriptions children={children} />
                </>)
            }
        </div>
    );
};
function ReadMoreAccount({ children }) {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {
                text == null ? (<></>) : (<>
                    <small > {isReadMore ? text.slice(0, 5) : text}</small>
                    <BoxDescriptions children={children} />
                </>)
            }
        </div>
    );
};

function CellRowComponent({
    customer_name,
    currency_name,
    children,
    total,
    OnEditJournal,
    anchorEl,
    showdebit,
    showcredit,
    foreigndebit,
    foreigncredit,
    rate,
    foreignamount,
    totalDebit,
    totalCrebit,
    totalCurrencies_debit,
    totalCurrencies_credit,
    totalForeignamount,
}) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow >
                <TableCell onClick={() => { handleClick() }} component="th" scope="row" style={{ width: 300 }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                    {customer_name}-{currency_name}
                </TableCell>
                <TableCell style={{ width: 150 }}></TableCell>
                <TableCell align="left" style={{ width: 200 }} ></TableCell>
                <TableCell align="left" style={{ width: 100 }} ></TableCell>
                <TableCell align="left" style={{ width: 200 }} ></TableCell>
                <TableCell align="left" style={{ width: 200 }} ></TableCell>
                <TableCell align="left" style={{ width: 200 }} ></TableCell>
                <TableCell align="left" style={{ width: 250 }} ></TableCell>
                <TableCell align="left" style={{ width: 250 }} ></TableCell>
                <TableCell align="left" style={{ width: 250 }} ></TableCell>

                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(totalCurrencies_debit)}</TableCell>
                    </>)
                }
                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(totalCurrencies_credit)}</TableCell>
                    </>)
                }
                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(totalForeignamount)}</TableCell>
                    </>)
                }
                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(totalDebit)}</TableCell>
                    </>)
                }
                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(totalCrebit)}</TableCell>
                    </>)
                }

                {
                    open ? (<>
                        <TableCell align="right" style={{ width: 250 }} ></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ width: 250 }} >{getFormatNumber(total)}</TableCell>

                    </>)
                }

            </TableRow>
            {open ? (
                <>
                    < RowComponentCell
                        customer_name={customer_name}
                        currency_name={currency_name}
                        children={children}
                        total={total}
                        OnEditJournal={OnEditJournal}
                        anchorEl={anchorEl}
                        showdebit={showdebit}
                        showcredit={showcredit}
                        foreigndebit={foreigndebit}
                        foreigncredit={foreigncredit}
                        rate={rate}
                        foreignamount={foreignamount}
                        totalDebit={totalDebit}
                        totalCrebit={totalCrebit}
                        totalCurrencies_debit={totalCurrencies_debit}

                        totalCurrencies_credit={totalCurrencies_credit}
                        totalForeignamount={totalForeignamount}

                    />

                </>
            ) : null
            }
        </>
    )
}
function RowComponentCell({
    customer_name,
    currency_name,
    children,
    total,
    OnEditJournal,
    totalCurrencies_credit,
    totalCurrencies_debit,
    totalForeignamount,
    totalDebit,
    totalCrebit
}) {
    return (
        <>
            {children && children.map((item, index) => {
                return (

                    <TableRow key={index} >
                        <TableCell component="th" scope="row" style={{ width: 300, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>
                            {item?.CUSTOMERS_NAME}-{item?.CURRENCY}
                        </TableCell>
                        <TableCell style={{ width: 150, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }} >{moment(item?.CREATEDATE).format('DD-MM-YYYY')}</TableCell>
                        <TableCell align="left" style={{ width: 200 }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{item?.TRANSACTIONS_TYPE}</TableCell>
                        <TableCell align="left" style={{ width: 100 }} >{item?.TRANSACTIONS_NUMBER}</TableCell>
                        <TableCell align="left" style={{ width: 200 }} >
                            <BoxDescriptions children={item?.DESRIPTIONS} />
                        </TableCell>
                        <TableCell align="left" style={{ width: 200 }} >
                            {item?.CHART_NAME_ENG}


                        </TableCell>
                        <TableCell align="left" style={{ width: 250 }} >
                            {item?.ACCOUNT_TYPE_NAME_ENG}


                        </TableCell>
                        <TableCell align="left" style={{ width: 250 }} >
                            {item?.DEPARTMENT_NAME
                            }


                        </TableCell>
                        <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{item?.CURRENCY}</TableCell>
                        <TableCell align="right" style={{ width: 250, color: 'blue', cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.EXCHNAGE_RATE)}</TableCell>

                        {item?.CURRENCIES_DEBIT === null ? (<>
                            <TableCell align="right" style={{ width: 200 }} >0.00</TableCell>
                        </>) : (
                            <>
                                {
                                    item?.TRANSACTIONS_CURRRENCY === 'LAK' ?
                                        (
                                            <TableCell align="right" style={{ width: 200 }} >0.00</TableCell>
                                        ) : (

                                            <TableCell align="right" style={{ width: 200, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.CURRENCIES_DEBIT)}{item?.CURRENCY_SYMBOL}</TableCell>
                                        )
                                }

                            </>
                        )}
                        {item?.CURRENCIES_CREDIT === null ? (<>
                            <TableCell align="right" style={{ width: 250 }} >0.00</TableCell>
                        </>) : (<>
                            {
                                item?.TRANSACTIONS_CURRRENCY === 'LAK' ? (
                                    <TableCell align="right" style={{ width: 250 }} >0.00</TableCell>

                                ) : (<>
                                    <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.CURRENCIES_CREDIT)}{item?.CURRENCY_SYMBOL}</TableCell>
                                </>)
                            }

                        </>)}
                        {
                            item?.TRANSACTIONS_CURRRENCY === 'LAK' ? (
                                <TableCell align="right" style={{ width: 250 }} >0.00</TableCell>
                            ) : (
                                <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.FOREIGN_AMOUNT)}{item?.CURRENCY_SYMBOL}</TableCell>
                            )

                        }

                        <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.DEBIT)}</TableCell>
                        <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.CREDIT)}</TableCell>
                        <TableCell align="right" style={{ width: 250, cursor: 'pointer' }} onClick={(e) => { OnEditJournal(item?.TR_ID) }}>{getFormatNumber(item?.AMOUNT)}</TableCell>

                    </TableRow>
                )
            })}
            <TableRow >
                <TableCell align="left" colspan={6} style={{ fontWeight: 'bold' }}>Total {customer_name} - {currency_name}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(totalCurrencies_debit)}$</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(totalCurrencies_credit)}$</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(totalForeignamount)}$</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(totalDebit)}₭</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(totalCrebit)}₭</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', color: 'green' }}>{getFormatNumber(total)}₭</TableCell>
            </TableRow>
        </>
    )
}


