import React, { useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import Collapse from "@material-ui/core/Collapse";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import SearchIcon from '@material-ui/icons/Search';
import AppsIcon from '@material-ui/icons/Apps';
import { LoginContext } from "../page/contexts/LoginContext";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { FullScreenDialog } from "../modal/Journal_entries";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default function Home(props) {
  const Navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [listOpent, setListOpent] = useState(false);
  const [reportOpent, setReportOpent] = useState(false)
  const [open_journal_entries, setOpen_journal_entries] = useState(false)

  const {
    open,
    setOpen,
    setHistory,
    setFullscreen
  } = useContext(LoginContext);
  const GoClosehistory = () => {
    setHistory(false)
    setFullscreen(true)
  }

  const handleClickOpen = () => {
    setOpen_journal_entries(true);
  };

  const handleClose = () => {
    setOpen_journal_entries(false);
  };



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setListOpent(!listOpent);
  };
  const handleClickreport = () => {
    setReportOpent(!reportOpent)
  }
  return (
    <div>
      <FullScreenDialog
        open_journal_entries={open_journal_entries}
        handleClose={handleClose}


      />

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}

        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <Typography variant="h6" noWrap >
                PSVB
              </Typography>
              <Typography variant="h6" noWrap>
                <SearchIcon style={{ cursor: "pointer" }} />
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={() => Navigate("/Index")}>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <small style={{ fontSize: 20, fontWeight: 'bold' }}>Dashboard</small>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              Settings
              <ListItemText />
            </ListItem>
            <Collapse in={listOpent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem button className={classes.nested} onClick={() => { Navigate("/Currency"); GoClosehistory() }} >
                  <ListItemIcon>
                  </ListItemIcon>
                  Add Currencies
                  <ListItemText />
                </ListItem> */}
                <ListItem button className={classes.nested} onClick={() => { Navigate("/SettingCurrency"); GoClosehistory() }} >
                  <ListItemIcon>
                  </ListItemIcon>
                  Manage Currencies
                  <ListItemText />
                </ListItem>

                <ListItem button className={classes.nested} onClick={() => { Navigate("/Customer"); GoClosehistory() }} >
                  <ListItemIcon>
                  </ListItemIcon>
                  Customers
                  <ListItemText />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { Navigate("/Department"); GoClosehistory() }} >
                  <ListItemIcon>
                  </ListItemIcon>
                  Department
                  <ListItemText />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { Navigate("/Transactions_Type"); GoClosehistory() }} >
                  <ListItemIcon>
                  </ListItemIcon>
                  Transactions Type
                  <ListItemText />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button
              onClick={() => { Navigate("/Chartofaccounts"); GoClosehistory() }}
            >
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              Chart of accounts
              <ListItemText />
            </ListItem>
            <ListItem button onClick={() => {
              // ShowfullscreenJournal(true); 
              handleClickOpen()
              GoClosehistory()
            }}
            >
              <ListItemIcon>
                <PostAddIcon />
              </ListItemIcon>
              Journal entry
              <ListItemText />
            </ListItem>
            <ListItem button onClick={handleClickreport}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              Report
              <ListItemText />
            </ListItem>
            <Collapse in={reportOpent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem button className={classes.nested}
                  onClick={() => { Navigate("ReportAllGL"); GoClosehistory() }}
                >
                  <ListItemIcon>
                  </ListItemIcon>
                  General Ledger
                  <ListItemText />
                </ListItem> */}

                <ListItem button className={classes.nested}
                  onClick={() => { Navigate("ReportBycustomer"); GoClosehistory() }}
                >
                  <ListItemIcon>
                  </ListItemIcon>
                  Transaction List
                  <ListItemText />
                </ListItem>
                {/* <ListItem button className={classes.nested}
                onClick={() => { Navigate("BalanceSheet"); GoClosehistory() }}
                >
                  <ListItemIcon>
                  </ListItemIcon>
                  BalanceSheet
                  <ListItemText />
                </ListItem> */}
                {/* <ListItem button className={classes.nested}

                onClick={() => { Navigate("Profitandloss"); GoClosehistory() }}

                >
                  <ListItemIcon>
                  </ListItemIcon>
                  Profit and loss
                  <ListItemText />
                </ListItem> */}
                {/* <ListItem button className={classes.nested}
                onClick={() => { Navigate("ReportTrialbalances"); GoClosehistory() }}

                >
                  <ListItemIcon>
                  </ListItemIcon>
                  Trail balance
                  <ListItemText />
                </ListItem> */}
                {/* <ListItem button className={classes.nested}

                onClick={() => { Navigate("Cashflows"); GoClosehistory() }}

                >
                  <ListItemIcon>
                  </ListItemIcon>
                  Cash Flows
                  <ListItemText />
                </ListItem> */}
                {/* <ListItem button className={classes.nested}
                  onClick={() => { Navigate("Test"); GoClosehistory() }}

                >
                  <ListItemIcon>
                  </ListItemIcon>
                  Test
                  <ListItemText />
                </ListItem> */}
              </List>
            </Collapse>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </div>
    </div>

  );
}

