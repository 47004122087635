import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import Cookies from 'js-cookie';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import Box_edit from "../modal/Box_edit";
import AddIcon from "@material-ui/icons/Add";
import Add_customer from "../modal/Add_customer"
import { LoginContext } from "../page/contexts/LoginContext";
import setAuthToken from "../setAuthToken";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function DenseTable() {
    const {
        company_uid,
        onLoaduser, listcurrency,
        setCheckConditions
    } = useContext(LoginContext);


    const classes = useStyles();
    const [list, setList] = useState([])
    const [datafilter, setDatafilter] = useState([])
    const [show, setShow] = useState(false)



    const [getvaluesoptions, setGetvaluesoptions] = useState('')
    const [conditions, setConditions] = useState(0)
    const [selectedImages, setSelectedImages] = useState([])
    const [active, setActive] = useState("");

    const [displayname, setDisplayname] = useState()
    const [title, setTitle] = useState('')
    const [first_name, setFirst_name] = useState('')
    const [last_name, setLast_name] = useState('')
    const [uploading, setUploading] = useState(false)
    const [email, setEmail] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [mobilenumber, setMobilenumber] = useState('')
    const [village, setVillage] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [notes, setNotes] = useState('')
    const [attachments, setAttachments] = useState('')
    const [payment, setPayment] = useState('')
    const [terms, setTerms] = useState('')
    const [registertaxs, setRegistertaxs] = useState('')
    const [opening, setOpening] = useState('')
    const [openingdate, setOpeningdate] = useState('')
    const [id, setID] = useState('')
    const [options_name, setOptions_name] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [account_no, setAccount_no] = useState('')
    const [default_account, setDefault_account] = useState('')
    const [social_insurance_no, setSocial_insurance_no] = useState('')
    const [add_currency, setAdd_Currency] = useState()
    const [employee_ID_number, setEmployee_ID_number] = useState('')
    const [employee_id, setEmployee_id] = useState('')
    const [customer_type, setCustomer_type] = useState('')
    const [company, setCompany] = useState('')
    const [account_number, setAccount_numner] = useState('')
    const [getcurrency, setGetcurrency] = useState('')
    const [currencies_name, setCurrencies_name] = useState('')
    const [images, setImage] = useState([])
    const [showupdate, setShowupdate] = useState(false)
    const [defaultgetcurrency, setDefaultgetcurrency] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [old_id, setOld_id] = useState('')

    const handleClosebox = () => {
        setShow(false)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const fileRemove = (file) => {
        const updatedList = [...selectedImages];
        updatedList.splice(selectedImages.indexOf(file), 1);
        setSelectedImages(updatedList);
    }
    const handleShow = () => {
        setAnchorEl(null);

        setShow(true)
    }
    const onSelectFile = async (event) => {
        const selectedFiles = event.target.files;
        const file_attachments = Array.from(selectedFiles);
        setSelectedImages((prevImages) => [...prevImages, ...file_attachments]);
        let formData = new FormData();
        for (const key of Object.keys(selectedFiles)) {
            formData.append("file_attachment", selectedFiles[key]);
        }
        formData.append("file_attachment", selectedFiles);
        setUploading(true)
        let profileImageReturnName = await axios.post("/accounting/api/ledger_entries/upload", formData);
        const result = profileImageReturnName.data.files.map(file => ({ NAME_IMAGE: file }));
        setImage(result)
        setUploading(false)
    };
    const optionsCurrency = (e) => {
        setGetcurrency(e)
        let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(e))
        setCurrencies_name(filter[0].CURRENCY_NAME)
        setDefaultgetcurrency(1)
    }
    const Onload = () => {
        axios.get(`/accounting/api/manage`).then((responses) => {
            console.log("mange=", responses)
            setList([...responses?.data?.result])
        }).catch((err) => {
            console.log(err)
        })
    }
    const filterSearch = async (e) => {
        setDatafilter(e);
        if (!e) {
            Onload()
        } else {

        }
    };
    const options = (e) => {

        setGetvaluesoptions(e)
        setConditions(1)
        setSelectedImages([])
        setDisplayname('')
        setTitle('')
        setFirst_name('')
        setLast_name('')
    }

    const Oncreate_user = () => {
        if (!getcurrency) {
            alert("currency");
            return;
        }

        let user_conditions;
        if (conditions == 0) {
            user_conditions = 'Customers'
        } else {
            user_conditions = getvaluesoptions
        }
        const data = {
            customers_type_id: customer_type,
            customers_name: displayname,
            customers_genders: gender,
            customers_email: email,
            customers_phone_number: phonenumber,
            customers_village: village,
            customers_district: district,
            customers_provinces: province,
            image_and_file: images,
            status_role: user_conditions,
            company_uid: company_uid,
            company_name: company,
            currency_id: currencies_name,
            accountnumber: account_number,
            get_currency: getcurrency
        }

        axios.post('/accounting/api/customer/Bank/Insert', data).then((respone) => {
            Onload()
            onLoaduser()
            handleClosebox()

        }).catch((err) => {
            console.log(err)
        })
    }
    const Onupdate_user = () => {

        let user_conditions;
        if (conditions == 0) {
            user_conditions = 'Customers'
        } else {
            user_conditions = getvaluesoptions
        }
        const data = {
            customers_type_id: customer_type,
            customers_name: displayname,
            customers_genders: gender,
            customers_email: email,
            customers_phone_number: phonenumber,
            customers_village: village,
            customers_district: district,
            customers_provinces: province,
            image_and_file: images,
            status_role: user_conditions,
            company_uid: company_uid,
            company_name: company,
            currency_id: currencies_name,
            get_currency: getcurrency,
            customer_old_id: old_id,
        }

        axios.post('/accounting/api/customer/Bank/Update', data).then((respone) => {
            Onload()
            onLoaduser()
            handleClosebox()

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        let users = Cookies.get("user");
        console.log("users=", users)

        if (!users) {
            window.location.assign('https://psvsystem.com/');
        } else {
            let data = JSON.parse(users)
            if (data.token) {
                setAuthToken(data.token, setCheckConditions)
            }
        }
        Onload()
    }, [])
    return (
        <>
            <Add_customer
                show={show}
                handleClosebox={handleClosebox}
                displayname={displayname}
                setDisplayname={setDisplayname}
                title={title}
                setTitle={setTitle}
                first_name={first_name}
                setFirst_name={setFirst_name}
                last_name={last_name}
                setLast_name={setLast_name}
                uploading={uploading}
                onSelectFile={onSelectFile}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                active={active}
                setActive={setActive}
                fileRemove={fileRemove}
                email={email}
                setEmail={setEmail}
                phonenumber={phonenumber}
                setPhonenumber={setPhonenumber}
                mobilenumber={mobilenumber}
                setMobilenumber={setMobilenumber}
                village={village}
                setVillage={setVillage}
                district={district}
                setDistrict={setDistrict}
                province={province}
                setProvince={setProvince}
                notes={notes}
                setNotes={setNotes}
                attachments={attachments}
                setAttachments={setAttachments}
                payment={payment}
                setPayment={setPayment}
                terms={terms}
                setTerms={setTerms}
                registertaxs={registertaxs}
                setRegistertaxs={setRegistertaxs}
                opening={opening}
                setOpening={setOpening}
                openingdate={openingdate}
                setOpeningdate={setOpeningdate}
                id={id}
                setID={setID}
                options_name={options_name}
                setOptions_name={setOptions_name}
                birthday={birthday}
                setBirthday={setBirthday}
                gender={gender}
                setGender={setGender}
                account_no={account_no}
                setAccount_no={setAccount_no}
                default_account={default_account}
                setDefault_account={setDefault_account}
                social_insurance_no={social_insurance_no}
                setSocial_insurance_no={setSocial_insurance_no}
                add_currency={add_currency}
                setAdd_Currency={setAdd_Currency}
                employee_ID_number={employee_ID_number}
                setEmployee_ID_number={setEmployee_ID_number}
                employee_id={employee_id}
                setEmployee_id={setEmployee_id}
                customer_type={customer_type}
                setCustomer_type={setCustomer_type}
                getvaluesoptions={getvaluesoptions}
                setGetvaluesoptions={setGetvaluesoptions}
                conditions={conditions}
                setConditions={setConditions}
                options={options}
                Oncreate_user={Oncreate_user}
                company={company}
                setCompany={setCompany}
                account_number={account_number}
                setAccount_numner={setAccount_numner}
                getcurrency={getcurrency}
                setGetcurrency={setGetcurrency}
                setCurrencies_name={setCurrencies_name}
                optionsCurrency={optionsCurrency}
                defaultgetcurrency={defaultgetcurrency}
                showupdate={showupdate}
                Onupdate_user={Onupdate_user}


            />

            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginLeft: 5 }}>Customers List</small>
                <div style={{
                    borderRadius: 2,
                    width: '10%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginLeft: 10,

                }}
                    onClick={() => { handleShow() }}
                >
                    <AddIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold' }}>New customer</small>
                </div>
            </div>
            <div style={{ paddingTop: 20, paddingBottom: 50 }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    position: 'relative',
                }}>
                    <div style={{ position: 'absolute', left: 10, top: 6 }}>
                        < SearchIcon style={{ color: '#ccc' }} />
                    </div>
                    <input type="text"
                        placeholder="Filter by name or number"
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 35,
                            width: '30%',
                            paddingLeft: 40
                        }}
                        onChange={(e) => filterSearch(e.target.value)}
                        value={datafilter}
                    />
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Company Name</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Phone</TableCell>
                                <TableCell align="right">Currency</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                list.map((row, index) => {
                                    return (<>
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row?.CUSTOMERS_NAME}
                                            </TableCell>
                                            <TableCell align="right">{row?.COMPANY_NAME}</TableCell>
                                            <TableCell align="right">{row?.CUSTOMER_EMAIL}</TableCell>
                                            <TableCell align="right">{row?.CUSTOMER_PHONE_NUMBER}</TableCell>
                                            <TableCell align="right">{row?.CURRENCY}</TableCell>
                                            <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <small style={{ color: 'blue', marginRight: 10 }}>Create invoice</small> || <small><Box_edit

                                                    CUSTOMERS_ID={row?.CUSTOMERS_ID}
                                                    CUSTOMERS_NAME={row?.CUSTOMERS_NAME}
                                                    COMPANY_NAME={row?.COMPANY_NAME}
                                                    CUSTOMER_EMAIL={row?.CUSTOMER_EMAIL}
                                                    CUSTOMER_PHONE_NUMBER={row?.CUSTOMER_PHONE_NUMBER}
                                                    CURRENCY_UID={row?.CURRENCY_UID}
                                                    optionsCurrency={optionsCurrency}
                                                    handleShow={handleShow}
                                                    anchorEl={anchorEl}
                                                    handleClick={handleClick}
                                                    handleClose={handleClose}
                                                    setDisplayname={setDisplayname}
                                                    setCompany={setCompany}
                                                    setEmail={setEmail}
                                                    setPhonenumber={setPhonenumber}
                                                    setMobilenumber={setMobilenumber}
                                                    setOld_id={setOld_id}
                                                    setShowupdate={setShowupdate}
                                                /></small>
                                            </TableCell>
                                        </TableRow>
                                    </>)
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>

        </>

    );
}
