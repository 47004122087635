import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Flex, Spin, Skeleton } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getFormatNumber } from "../constants/functions"
import moment from 'moment';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export const History = (props) => {
    const classes = useStyles();
    return (
        <div style={{ width: '100%' }}>
            <Modal
                show={props.history} fullscreen={props.fullscreen} onHide={props.GoClosehistory} style={{ paddingTop: 55 }}
            >
                <div style={{ width: '100%' }}>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-start', paddingLeft: 100 }}>
                        <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => { props.GoClosehistory() }}
                        >
                            <ArrowBackIcon style={{ color: '#3f51b5', cursor: 'pointer' }} />
                        </div>
                        <small style={{ fontSize: 20, marginLeft: 5 }}>Asset Account History</small>
                    </div>
                </div>
                {
                    props.loading == true ? (
                        <LinearProgress style={{ marginTop: 10, marginLeft: 100 }} />
                    ) : (
                        <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10, marginLeft: 100 }}></div>
                    )
                }
                {
                    props.loading == true ? (
                        <>
                            <Skeleton style={{ marginLeft: 100 }} />
                        </>
                    ) : (<>
                        <div style={{ paddingTop: 20, paddingLeft: 100 }}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: 150 }}>Date</TableCell>
                                            <TableCell align="right" style={{ width: 200 }}>Type || NO</TableCell>
                                            <TableCell align="right" style={{ width: 300 }}>Description</TableCell>
                                            <TableCell align="right" style={{ width: 200 }}>Exchange Rate</TableCell>
                                            {
                                                props.conditions == "CASH" ? (
                                                    <>
                                                        <TableCell align="right" style={{ width: 200 }}>Payment({props.statusCurrency})</TableCell>
                                                        <TableCell align="right" style={{ width: 200 }}>Deposit({props.statusCurrency})</TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell align="right" style={{ width: 200 }}>Decrease({props.statusCurrency})</TableCell>
                                                        <TableCell align="right" style={{ width: 200 }}>Increase({props.statusCurrency})</TableCell>

                                                    </>
                                                )
                                            }
                                            <TableCell align="right" style={{ width: 200 }}>Balances({props.statusCurrency})</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            props.listhistories && props.listhistories.map((data, index) => {
                                                let BALANCES = data?.BALANCES
                                                let usingObjectAssign = Object.assign([], BALANCES);
                                                let conditons = usingObjectAssign[0]
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="right">{moment(data?.CREATEDATE).format("DD-MM-YYYY")}</TableCell>
                                                        <TableCell align="right"> {data?.JOURNAL_NO}</TableCell>
                                                        {
                                                            data?.BEGINING_BALANCE == 0 ? (
                                                                <TableCell align="right">Beginning Balance</TableCell>
                                                            ) : (
                                                                <TableCell align="right">{data?.LG_DESC}</TableCell>
                                                            )
                                                        }
                                                        <TableCell align="right">
                                                            {
                                                                data?.FOREIGN_CODE == 'LAK' ? (
                                                                    <>
                                                                        {
                                                                            data?.TRANSACTIONS_CURRENCY == 'USD' ?
                                                                                (
                                                                                    <>

                                                                                    </>) : (
                                                                                    <>

                                                                                    </>
                                                                                )
                                                                        }

                                                                    </>

                                                                ) : (
                                                                    <>
                                                                        {
                                                                            data?.FOREIGN_CODE == 'USD' || data?.FOREIGN_CODE == 'THB' ? (
                                                                                <>
                                                                                    ₭ {getFormatNumber(data?.AMOUT)}
                                                                                    <br />
                                                                                    1 {data?.FOREIGN_CODE}
                                                                                    = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                </>) : null
                                                                        }
                                                                    </>
                                                                )
                                                            }



                                                        </TableCell>
                                                        {
                                                            data?.FOREIGN_CODE == 'LAK' ? (
                                                                <>
                                                                   {
                                                                        data?.ACCOUNT_TYPE_STATUS == 'CASH' ? (
                                                                            <>
                                                                                {
                                                                                    data?.AMOUT > 0 ? (<>
                                                                                        <TableCell align="right">0.00</TableCell>
                                                                                        <TableCell align="right">{getFormatNumber(data?.AMOUT)}{data?.FOREIGN_CODE}</TableCell>

                                                                                    </>) : null
                                                                                }

                                                                                {
                                                                                    data?.AMOUT < 0 ? (<>

                                                                                        <TableCell align="right">{getFormatNumber(-1 * data?.AMOUT)}{data?.FOREIGN_CODE}</TableCell>
                                                                                        <TableCell align="right">0.00</TableCell>

                                                                                    </>) : null
                                                                                }


                                                                            </>) : (<>

                                                                            </>)
                                                                    }
                                                                </>) : (

                                                                <>
                                                                    {
                                                                        data?.ACCOUNT_TYPE_STATUS == 'CASH' ? (
                                                                            <>
                                                                                {
                                                                                    data?.FOREIGN_AMOUNT > 0 ? (<>
                                                                                        <TableCell align="right">0.00</TableCell>
                                                                                        <TableCell align="right">{getFormatNumber(data?.FOREIGN_AMOUNT)}{data?.FOREIGN_CODE}</TableCell>

                                                                                    </>) : null
                                                                                }

                                                                                {
                                                                                    data?.FOREIGN_AMOUNT < 0 ? (<>

                                                                                        <TableCell align="right">{getFormatNumber(-1 * data?.FOREIGN_AMOUNT)}{data?.FOREIGN_CODE}</TableCell>
                                                                                        <TableCell align="right">0.00</TableCell>

                                                                                    </>) : null
                                                                                }


                                                                            </>) : (<>

                                                                            </>)
                                                                    }


                                                                </>)
                                                        }



                                                        <TableCell align="right">{getFormatNumber(data?.FOREIGN_BALANCES)}</TableCell>
                                                        {/* {
                                                            data?.FOREIGN_CODE == "LAK" ? (
                                                                <>
                                                                    {
                                                                        data?.BEGINING_BALANCE == '0' ? (
                                                                            <>
                                                                                <TableCell align="right"></TableCell>
                                                                                {
                                                                                    conditons == '-' ?
                                                                                        (
                                                                                            <>
                                                                                                <TableCell align="right">{getFormatNumber(data?.BALANCES.replaceAll("-", ''))}</TableCell>
                                                                                                <TableCell align="right"></TableCell>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <TableCell align="right"></TableCell>
                                                                                                <TableCell align="right">{getFormatNumber(data?.BALANCES)}</TableCell>
                                                                                            </>)
                                                                                }


                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <TableCell align="right"></TableCell>
                                                                                <TableCell align="right">

                                                                                    {
                                                                                        data?.CREDIT == '0' ? (
                                                                                            <>
                                                                                                0.00
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                ₭{getFormatNumber(data?.CREDIT)}
                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    {
                                                                                        data?.DEBIT == '0' ? (
                                                                                            <>
                                                                                                0.00
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                ₭ {getFormatNumber(data?.DEBIT)}

                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                </TableCell>
                                                                            </>
                                                                        )
                                                                    }
                                                                </> 
                                                            ) : (data?.FOREIGN_CODE == "USD") ? (
                                                                <>
                                                                    {
                                                                        data?.BEGINING_BALANCE == '0' ? (
                                                                            <>
                                                                                <TableCell align="right">
                                                                                    1 $
                                                                                    = {getFormatNumber(data?.EXCHNAGE_RATE)}
                                                                                    <br />
                                                                                    ₭ {getFormatNumber((parseFloat(data?.FOREIGN_AMOUNT * data?.EXCHNAGE_RATE)))}

                                                                                </TableCell>
                                                                                <TableCell align="right">FASFA</TableCell>
                                                                                <TableCell align="right">DFSDA</TableCell>
                                                                                <TableCell align="right">DFSDAFA</TableCell>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    data?.DEBIT == '0' ? (
                                                                                        <>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                            <TableCell align="right">
                                                                                                {
                                                                                                    data?.EXCHNAGE_RATE == 0 ? (
                                                                                                        <>

                                                                                                        </>

                                                                                                    ) : (
                                                                                                        <>
                                                                                                            ₭ {getFormatNumber(data?.DEBIT)}
                                                                                                            <br />
                                                                                                            1 {data?.FOREIGN_CODE}
                                                                                                            = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                                        </>

                                                                                                    )
                                                                                                }SAFSADFSADF

                                                                                            </TableCell>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    data?.DEBIT == '0' ? (
                                                                                        <>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                            <TableCell align="right">
                                                                                                {
                                                                                                    data?.EXCHNAGE_RATE == null ? (
                                                                                                        <>
                                                                                                        </>

                                                                                                    ) : (
                                                                                                        <>
                                                                                                            ₭ {getFormatNumber(data?.DEBIT)}
                                                                                                            <br />
                                                                                                            1 {data?.FOREIGN_CODE}
                                                                                                            = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                            </TableCell>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    data?.DEBIT =='0' ?
                                                                                        (
                                                                                            <>
                                                                                                <TableCell align="right">0.00</TableCell>

                                                                                            </>) : (
                                                                                            <>
                                                                                                <TableCell align="right">${getFormatNumber(parseFloat(data?.DEBIT) / parseFloat(data?.EXCHNAGE_RATE))}</TableCell>

                                                                                            </>)
                                                                                }
                                                                                {
                                                                                    data?.DEBIT == '0' ? (
                                                                                        <>
                                                                                            <TableCell align="right">0.00</TableCell>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <TableCell align="right">$ {getFormatNumber(parseFloat(data?.DEBIT) / parseFloat(data?.EXCHNAGE_RATE))}</TableCell>

                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (data?.FOREIGN_CODE == "THB") ? (
                                                                <>
                                                                    {
                                                                        data?.BEGINING_BALANCE == '0' ? (
                                                                            <>
                                                                                <TableCell align="center">


                                                                                    1 ฿
                                                                                    = {getFormatNumber(data?.EXCHNAGE_RATE)}
                                                                                    <br />
                                                                                    ₭ {getFormatNumber((parseFloat(data?.FOREIGN_AMOUNT * data?.EXCHNAGE_RATE)))}
                                                                                </TableCell>
                                                                                <TableCell align="right"></TableCell>
                                                                                <TableCell align="center"></TableCell>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    data?.debit == "0.00" ? (
                                                                                        <>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                            <TableCell align="right">
                                                                                                {
                                                                                                    data?.EXCHNAGE_RATE == 0 ? (
                                                                                                        <>

                                                                                                        </>

                                                                                                    ) : (
                                                                                                        <>
                                                                                                            ฿ {getFormatNumber(data?.DEBIT)}
                                                                                                            <br />
                                                                                                            1 {data?.FOREIGN_CODE}
                                                                                                            = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                                        </>

                                                                                                    )
                                                                                                }

                                                                                            </TableCell>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    data?.CREDIT == '0' ? (
                                                                                        <>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                            <TableCell align="right">
                                                                                                {
                                                                                                    data?.EXCHNAGE_RATE == '0' ? (
                                                                                                        <>

                                                                                                        </>

                                                                                                    ) : (
                                                                                                        <>
                                                                                                            ฿ {getFormatNumber(data?.CREDIT)}
                                                                                                            <br />
                                                                                                            1 {data?.FOREIGN_CODE}
                                                                                                            = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                                        </>

                                                                                                    )
                                                                                                }

                                                                                            </TableCell>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                <TableCell align="right">฿ {getFormatNumber(parseFloat(data?.CREDIT) / parseFloat(data?.EXCHNAGE_RATE))}</TableCell>
                                                                                <TableCell align="right">฿ {getFormatNumber(parseFloat(data?.DEBIT) / parseFloat(data?.EXCHNAGE_RATE))}</TableCell>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        data?.DEBIT == "0.00" ? (
                                                                            <>
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                                <TableCell align="right">
                                                                                    {
                                                                                        data?.EXCHNAGE_RATE == '0' ? (
                                                                                            <>

                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                ฿ {getFormatNumber(data?.DEBIT)}
                                                                                                <br />
                                                                                                1 {data?.FOREIGN_CODE}
                                                                                                = {getFormatNumber(data?.EXCHNAGE_RATE)}

                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </TableCell>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        data?.CREDIT == '0' ? (
                                                                            <>
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                                <TableCell align="right">
                                                                                    {
                                                                                        data?.EXCHNAGE_RATE == '0' ? (
                                                                                            <>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                ฿ {getFormatNumber(data?.CREDIT)}
                                                                                                <br />
                                                                                                1 {data?.FOREIGN_CODE}
                                                                                                = {getFormatNumber(data?.EXCHNAGE_RATE)}
                                                                                            </>

                                                                                        )
                                                                                    }

                                                                                </TableCell>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <TableCell align="left">{getFormatNumber(data?.DEBIT)}</TableCell>
                                                                    <TableCell align="left">{getFormatNumber(data?.CREDIT)}</TableCell>
                                                                </>)
                                                        } */}


                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>


                                </Table>


                            </TableContainer>

                        </div>
                    </>)
                }





            </Modal >

        </div >
    )

}
