import React, { useState, useContext, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import SettingsIcon from "@material-ui/icons/Settings";
import { Modal } from "react-bootstrap";
import { LoginContext } from "../page/contexts/LoginContext";
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RestoreIcon from '@material-ui/icons/Restore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Slide from '@material-ui/core/Slide';
import WarningIcon from '@material-ui/icons/Warning';
import { Button } from "@material-ui/core";
import Cookies from 'js-cookie';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Flex, Spin } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Create_user from "./Create_user";
import Option_select from "./Option_select";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Options_taxs from "./Options_taxs";
import { message } from 'antd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Options_department from "./Options_department";
import AlertDialog from "./Add_customize_department";
import setAuthToken from "../setAuthToken";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 440, // Adjust the height as needed
        maxWidth: '100%', // Adjust the width as needed
        overflow: 'auto', // Ensure scrolling
    },
    table: {
        minWidth: 650,
        tableLayout: 'fixed', // Ensures fixed layout for columns
    },
    stickyHeader: {
        // position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.appBar,
    },
    parentDiv: {
        width: '100%',
        height: '500px', // Set the height you want for the parent div
        overflow: 'auto', // Make the div scrollable
        marginTop: 100
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialogEdit = (props) => {

    const {
        listcurrency, company_uid, user_session,
        listuser,
        taxs_options,
        onLoaduser,
        settax,
        onLoadTransactions_List,
        listtransactions_type,
        OnlaodGl,
        listdepartment,
        setCheckConditions


    } = useContext(LoginContext);
    const classes = useStyles();



    const [messageApi, contextHolder] = message.useMessage();

    const [clearData, setClearData] = useState([
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
        { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    ])

    const today = new Date();
    const date = today.setDate(today.getDate());
    const [currency, setCurrency] = useState("");
    const [defaultValue, setDefaultValue] = useState("")
    const Today = moment(new Date()).format("DD-MM-YYYY")

    const [isLoading, setIsLoading,] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [something, setSomething] = useState(false);
    const [start_date, setStart_date] = useState(new Date());

    const [more, setMore] = useState(false)

    const [showBoxEdit, setShowBoxEdit] = useState(false)

    const [uid, setUid] = useState([])
    const [checkcondition, setCheckcondition] = useState("")
    const [shows, setShows] = useState(false);
    const [showleave, setShowleave] = useState(false);
    const [active, setActive] = useState("");
    const [copy, setCopy] = useState("")
    const [showcp, setShowcp] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [getindex, setGeindex] = useState('')
    const [anchorElTax, setAnchorElTax] = useState()

    const [show, setShow] = useState(false);
    const [file, setFile] = useState();
    const [showpicture, setShowpicture] = useState(false)
    const [displayname, setDisplayname] = useState()
    const [title, setTitle] = useState('')
    const [first_name, setFirst_name] = useState('')
    const [last_name, setLast_name] = useState('')
    const [id, setID] = useState('')

    const [email, setEmail] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [mobilenumber, setMobilenumber] = useState('')
    const [village, setVillage] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [notes, setNotes] = useState('')
    const [attachments, setAttachments] = useState('')
    const [payment, setPayment] = useState('')
    const [terms, setTerms] = useState('')
    const [registertaxs, setRegistertaxs] = useState('')
    const [opening, setOpening] = useState('')
    const [openingdate, setOpeningdate] = useState('')
    const [options_name, setOptions_name] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [account_no, setAccount_no] = useState('')
    const [default_account, setDefault_account] = useState('')
    const [social_insurance_no, setSocial_insurance_no] = useState('')
    const [add_currency, setAdd_Currency] = useState()
    const [employee_ID_number, setEmployee_ID_number] = useState('')
    const [employee_id, setEmployee_id] = useState('')
    const [customer_type, setCustomer_type] = useState('')
    const [getvaluesoptions, setGetvaluesoptions] = useState('')
    const [openDrawerTax, setOpenDrawerTax] = useState()
    const [conditions, setConditions] = useState(0)
    const [anchorElTax_dep, setAnchorElTax_dep] = useState('')
    const [showboxdepartment, setShowboxdepartment] = useState(false)
    const [allNotEmpty, setAllNotEmpty] = useState(false)
    const [transactions_type, setTransactions_type] = useState('')
    const [open, setOpen] = React.useState(false);
    const [anchorElboxedit, setAnchorElboxedit] = useState(false)
    const [uploading, setUploading] = useState(false)

    const handlImage = () => setShowpicture(true);
    const handleShow = () => {

        setShow(true)
    };
    const handleShowLeave = () => {
        if (checkcondition == '1') {
            setShowleave(true)
        } else {
            props.CloseShoFullScrreen(false)
            setCheckcondition('')
        }
    };
    const handleClosedel = () => {
        setShows(false);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleCloseClickOpen = () => {
        setOpen(false);
    };
    const handleEditbox = () => {
        setAnchorElboxedit(true)
    }
    const handlCloseEditbox = () => {
        setAnchorElboxedit(false)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseDepartment = () => {
        setAnchorElTax_dep(null);
    };
    const handleOpenDrawer = () => {
        setAnchorEl(null);
        setOpenDrawer(true)
    };
    const success = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Action in progress..',
                duration: 2.5,
            })
            .then(() => message.success('Loading finished', 2.5))
    };
    const ClearAllLines = () => {
        props.setCurrency_id('')
        props.setThb('')
        props.setUsd('')
        props.setData([...clearData])
        props.netTotalCrebit('')
        props.netTotalDebit('')
    }
    const ClaseJournal_entries = () => {
        props.handleCloseOpen();
    }

    const addLines = (index) => {
        const cloneData = [...props.data]
        cloneData.splice(index, 0, { name: '', debit: '', credit: '', description: '', Tax: '', Employee: '' })
        props.setData([...cloneData])
    }


    const copyData = (index) => {
        setShowcp(true)
        const cloneData = [...props.data][index]
        setCopy(cloneData)
    }
    const paste = (index) => {
        setShowcp(false)
        const cloneData = [...props.data]
        cloneData.splice(index, 1, copy)
        props.setData([...cloneData])
    }
    const fileRemove = (file) => {
        const updatedList = [...props.selectedImages];

        updatedList.splice(props.selectedImages.indexOf(file), 1);
        props.setSelectedImages(updatedList);
        const result = updatedList.map(file => ({ NAME_IMAGE: file.name }));

        // setImage(result)

    }

    const onSelectFile = async (event) => {

        const selectedFiles = event.target.files;

        const file_attachments = Array.from(selectedFiles);
        props.setSelectedImages((prevImages) => [...prevImages, ...file_attachments]);
        setFile(event.target.files);
        let formData = new FormData();
        for (const key of Object.keys(selectedFiles)) {
            formData.append("file_attachment", selectedFiles[key]);
        }
        formData.append("file_attachment", selectedFiles);
        setUploading(true)
        let profileImageReturnName = await axios.post("/accounting/api/ledger_entries/upload", formData);
        const result = profileImageReturnName.data.files.map(file => ({ NAME_IMAGE: file }));
        props.setImage((prevImages) => [...prevImages, ...result])
        setUploading(false)
    };
    const handleLeaveClosedel = () => {
        setShowleave(false)
    }
    const Closeleave = () => {
        props.CloseShoFullScrreen(false)
    }
    const handleClick_Options = (event, index) => {
        setAnchorEl(event.currentTarget);
        setGeindex(index)
    };
    const handleClick_OptionsTax = (event, index) => {
        setAnchorElTax(event.currentTarget);
        setGeindex(index)
    };
    const handleCloseTaxs = () => {
        setAnchorElTax(null);
    };
    const select_options = (cus_id, cus_name, index, da) => {
        da[index]["EMPLOYEE_ID"] = cus_id.toString()
        da[index]['EMPLOYEE'] = cus_name
        setAnchorEl(null);
    }
    const select_options_taxs = (cus_id, cus_name, index, da) => {
        da[index]["TAXS_ID"] = cus_id.toString()
        da[index]['TAX'] = cus_name
        setAnchorElTax(null);
    }
    const select_options_department = (department_id, department_name, index, da) => {
        da[index]['DEPARTMENT_ID'] = department_id.toString()
        da[index]['DEPARTMENT_NAME'] = department_name
        setAnchorElTax_dep(null);
    }
    const handleShowbox = () => {

        setShowboxdepartment(true)
    }
    const handleClick_OptionsDepartment = (event, index) => {
        setAllNotEmpty(false)
        setAnchorElTax_dep(event.currentTarget);
        setGeindex(index)
    };
    const options = (e) => {
        setGetvaluesoptions(e)
        setConditions(1)
        props.setSelectedImages([])
        setDisplayname('')
        setTitle('')
        setFirst_name('')
        setLast_name('')
    }
    const handleOpenDrawerTaxs = () => {
        setAnchorElTax(null)
        setOpenDrawerTax(true)
    }
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        setDisplayname('')
        setTitle('')
        setFirst_name('')
        setLast_name('')
    }
    const sumData = (key) => {
        let initialValue = 0
        let sum = props.data?.reduce(function (previousValue, currentValue) {
            return parseFloat(previousValue) + (currentValue[key] != undefined && currentValue[key] != '' ? parseFloat(currentValue[key].replaceAll(',', '')) : 0)
        }, initialValue)
        return sum;
    }
    let debit = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sumData('DEBIT'))
    let credit = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sumData('CREDIT'))
    let convertdebit = debit.replaceAll('$', '')
    let convertcredit = credit.replaceAll('$', '')
    let agconvertdebit = convertdebit.replaceAll(',', '')
    let agconvertcredit = convertcredit.replaceAll(',', '')
    const deleteTransaction = (e) => {
        let data = {
            V_TR_ID: e
        }
        axios.post("/accounting/api/ledger_entries/Bank/Deleted", data).then((data) => {
            onLoadTransactions_List()
            ClaseJournal_entries()


        }).catch((err) => {
            console.log(err)
        })
    }

    const deletechange = (index) => {
        const cloneData = [...props.data]
        cloneData.splice(index, 1, { name: '', debit: '', credit: '', description: '', Tax: '', Employee: '' })
        props.setData([...cloneData])
    }
    const blurHandler = (value, key, index, exchage_rate, agconvertdebit, agconvertcredit) => {
        const object = { ...props.data[index] };
        if (key == 'DEBIT' || key == 'CREDIT') {
            let number = value.replaceAll(',', '')
            let format_number = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
            object[key] = value != '' ? format_number.replaceAll('$', '') : ''
        } else {
            object[key] = value;
        }
        const cloneData = [...props.data];
        cloneData[index] = { ...object };
        props.setData([...cloneData]);
        if (key == 'DEBIT') {
            const convert_usd = exchage_rate.replaceAll(',', '')
            let TotalDebit = parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertdebit)
            props.setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
        } else {
            const convert_usd = exchage_rate.replaceAll(',', '')
            let TotalCredit = (parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertcredit))
            props.setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
        }
    }
    const changeText = (value, key, index) => {
        const object = { ...props.data[index] };
        if (key == 'DEBIT' || key == 'CREDIT') {
            object[key] = value.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        } else {
            object[key] = value;
        }
        const cloneData = [...props.data];
        cloneData[index] = { ...object };
        props.setData([...cloneData]);
    };
    const OnloadSelectCurrencies = (e, usd, thb, uid, x, y) => {
        props.setCurrency(e)
        let TotalDebit
        let TotalCredit
        let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(e))
        if (filter[0].CURRENCY_NAME !== 'LAK') {
            props.setListimage(filter[0].attachments[0])
            props.setSelect_currency(filter[0].CURRENCY_NAME)
        }
        const uniqueEntries = Object.values(
            props.data.reduce((acc, entry) => {
                if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
                    acc[entry.CURRENCY_CODE] = entry;
                }
                return acc;
            }, {})
        );
        const currencyCodes = uniqueEntries.map(entry => entry.CURRENCY_CODE).filter(CURRENCY_CODE => CURRENCY_CODE !== undefined);
        let resu = currencyCodes.every(item => item === filter[0].CURRENCY_NAME);
        if (resu) {
            if (filter[0].CURRENCY_NAME == 'LAK') {
                props.setCurrency_id(false)
            } else {
                props.setCurrency_id(true)
            }
        } else {
            props.setCurrency_id(true)
        }
        props.setCurrencystatus(filter[0].CURRENCY_NAME)
        props.setChecked_logo(filter[0].CURRENCY_NAME)
        props.setConditions_curren(filter[0].CURRENCY_NAME)

    }
    const onBlurCurrency = (value, x, y) => {
        let number = value.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        const formattedValue = formatMoney(value);
        let exchange = number.replaceAll(',', '')
        props.setExchage_rate(formattedValue)
        let convert_x = x.replaceAll(',', '')
        let TotalDebit = (parseFloat(exchange) * parseFloat(convert_x))
        let convert_y = y.replaceAll(',', '')
        let TotalCredit = (parseFloat(exchange) * parseFloat(convert_y))
        props.setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
        props.setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))

    }
    const _searchstartdate = (e) => {

        props.setDefaultValue(moment(e).format("DD-MM-YYYY"))
    }

    const onChangeTextCurrency = (value) => {
        let formattedValue = 0
        formattedValue = formatMoney(value);
        props.setExchage_rate(formattedValue)
    }
    const formatMoney = (value) => {
        // Remove any non-digit characters except decimal points
        const cleanedValue = value.replace(/[^\d.]/g, '');
        // Split the value into integer and decimal parts
        const parts = cleanedValue.split('.');
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Join the integer and decimal parts and return the result
        return parts.join('.');
    };

    const addMore = () => {
        props.setData([...props.data, {}]);
    };
    const resetlines = () => {
        props.setData([])
    }
    function refreshPage() {
        window.location.reload();
    }
    const changeOpent = () => {
        setShowBoxEdit(!showBoxEdit);
    }

    const createdata = async () => {
        let maincurrency
        const currentDate = new Date();
        const debit = sumData('debit')
        const credit = sumData('credit')
        setSomething('')
        let journaldata;
        if (!props.currencystatus) {
            props.setWarring_select_currency(true)
            return;
        }
        if (props.currencystatus !== 'LAK') {
            if (!props.exchage_rate) {
                props.setWarring_exhange_rate(true)
                return;
            }
        }
        if (!props.exchage_rate) {
            maincurrency = "1"
        } else {
            maincurrency = props.exchage_rate
        }
        if (!props.exchage_rate)
            if (!props.currencystatus) {
                props.setWarring_select_currency(true)
                return;
            }

        const uniqueEntries = Object.values(
            props.data.reduce((acc, entry) => {
                if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
                    acc[entry.CURRENCY_CODE] = entry;
                }
                return acc;
            }, {})
        );
        const currencyCodes = uniqueEntries.map(entry => entry.CURRENCY_CODE).filter(CURRENCY_CODE => CURRENCY_CODE !== undefined);
        let result = currencyCodes.filter(item => item !== 'LAK');
        let result_lak = currencyCodes.filter(item => item === 'LAK');
        if (result.length > 1) {
            props.setWarring(true)
            return;
        }
        if (result_lak.length !== 1) {
            let result_all = result.concat(result);
            let resu = result_all.includes(props.currencystatus);
            if (!resu) {
                props.setWarring(true)
                return;
            }
        } else {
            let result_con = result.concat(result_lak);
            let resu = result_con.includes(props.currencystatus);
            if (!resu) {
                props.setWarring(true)
                return;
            }

        }

        if (debit != credit) {
            props.setWarring_debit_credit(true)
            setIsLoading(false);
            setSomething(true)
        } else {
            const getResult = (oldData, newData) => {
                return oldData.filter(oldItem =>
                    !newData.some(newItem => newItem.CHART_ID === oldItem.CHART_ID)
                );
            };
            const result = getResult(props.old_data, props.data);
            const padZero = (num) => (num < 10 ? '0' + num : num);
            const formattedTime = `${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;
            let listdatas = props.data
            let array = [];
            for (let i = 0; i < listdatas.length; i++) {
                if (listdatas[i].ACCOUNT_CREATESTATUS !== undefined) {
                    array.push(listdatas[i].ACCOUNT_CREATESTATUS);
                }
            }
            if (array.every(status => status === 'Ex' || status === 'In')) {
                let dataClone = [...props.data];
                for (let i = 0; i < dataClone.length; i++) {
                    const object = dataClone[i];
                    object['BS_STATUS'] = 5;
                }
                props.setData(dataClone);
            } else {
                let dataClone = [...props.data];
                for (let i = 0; i < dataClone.length; i++) {
                    const object = dataClone[i];
                    object['BS_STATUS'] = 6;
                }
                props.setData(dataClone);
            }
            setIsLoading(true);
            journaldata = {
                journal_no: props.journalNo,
                oldjournal_no: props.oldjournal_no,
                tr_id: props.tr_id,
                createdate: props.defaultValue,
                createdate_exchange_rate: props.defaultValue + ':' + formattedTime,
                currency: props.currencystatus,
                money_rate: maincurrency.replaceAll(',', ''),
                createby: user_session,
                company_uid: company_uid,
                for_year: moment(props.defaultValue).format('YYYY'),
                informdata: props.data,
                odl_informdata: result,
                file_attachment: props.images,
            }
            // console.log("update=", journaldata)
            // setIsLoading(false);
            console.log("update=", journaldata)
            axios.post("/accounting/api/ledger_entries/Bank/Update", journaldata).then((data) => {
                onLoadTransactions_List()
                OnlaodGl()
                success()
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }
    const Oncreate_user = (index, da) => {
        da[index]['EMPLOYEE'] = displayname
        let user_conditions;
        if (conditions == 0) {
            user_conditions = 'Customers'
        } else {
            user_conditions = getvaluesoptions

        }
        const data = {
            customers_type_id: customer_type,
            customers_name: displayname,
            customers_genders: gender,
            customers_email: email,
            customers_phone_number: phonenumber,
            customers_village: village,
            customers_district: district,
            customers_provinces: province,
            image_and_file: props.images,
            status_role: user_conditions,
            company_uid: company_uid

        }
        axios.post('/accounting/api/customer/Insert', data).then((respone) => {
            da[index]['EMPLOYEE_ID'] = respone?.data?.result
            onLoaduser()
            handleCloseDrawer()
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        let users = Cookies.get("user");
        if (!users) {
            window.location.assign('https://psvsystem.com/');
        } else {
            let data = JSON.parse(users)
            if (data.token) {

                setAuthToken(data.token, setCheckConditions)

            }
        }
    }, [])


    return (
        <div>
            {contextHolder}



            <Dialog fullScreen open={props.open_edit_journal_entries} onClose={props.ClaseJournal_entries} TransitionComponent={Transition}>
                <AlertDialog
                    open={open}
                    handleCloseClickOpen={handleCloseClickOpen}
                    tr_id={props.tr_id}
                    deleteTransaction={deleteTransaction}

                />
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <div
                        style={{
                            position: 'fixed',
                            paddingTop: 20,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            width: '100%',
                            backgroundColor: "#f8f9fa"
                        }}
                    >
                        <Create_user
                            openDrawer={openDrawer}
                            handleCloseDrawer={handleCloseDrawer}
                            displayname={displayname}
                            setDisplayname={setDisplayname}
                            title={title}
                            setTitle={setTitle}
                            first_name={first_name}
                            setFirst_name={setFirst_name}
                            last_name={last_name}
                            setLast_name={setLast_name}
                            uploading={uploading}
                            onSelectFile={onSelectFile}
                            selectedImages={props.selectedImages}
                            setSelectedImages={props.setSelectedImages}
                            active={active}
                            setActive={setActive}
                            fileRemove={fileRemove}
                            email={email}
                            setEmail={setEmail}
                            phonenumber={phonenumber}
                            setPhonenumber={setPhonenumber}
                            mobilenumber={mobilenumber}
                            setMobilenumber={setMobilenumber}
                            village={village}
                            setVillage={setVillage}
                            district={district}
                            setDistrict={setDistrict}
                            province={province}
                            setProvince={setProvince}
                            notes={notes}
                            setNotes={setNotes}
                            attachments={attachments}
                            setAttachments={setAttachments}
                            payment={payment}
                            setPayment={setPayment}
                            terms={terms}
                            setTerms={setTerms}
                            registertaxs={registertaxs}
                            setRegistertaxs={setRegistertaxs}
                            opening={opening}
                            setOpening={setOpening}
                            openingdate={openingdate}
                            setOpeningdate={setOpeningdate}
                            id={id}
                            setID={setID}
                            options_name={options_name}
                            setOptions_name={setOptions_name}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            gender={gender}
                            setGender={setGender}
                            account_no={account_no}
                            setAccount_no={setAccount_no}
                            default_account={default_account}
                            setDefault_account={setDefault_account}
                            social_insurance_no={social_insurance_no}
                            setSocial_insurance_no={setSocial_insurance_no}
                            add_currency={add_currency}
                            setAdd_Currency={setAdd_Currency}
                            employee_ID_number={employee_ID_number}
                            setEmployee_ID_number={setEmployee_ID_number}
                            employee_id={employee_id}
                            setEmployee_id={setEmployee_id}
                            customer_type={customer_type}
                            setCustomer_type={setCustomer_type}
                            getvaluesoptions={getvaluesoptions}
                            setGetvaluesoptions={setGetvaluesoptions}
                            conditions={conditions}
                            setConditions={setConditions}
                            options={options}
                            Oncreate_user={Oncreate_user}
                            getindex={getindex}
                            data={props.data}

                        />
                        <div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                                <RestoreIcon />
                                <span style={{ fontSize: 20, fontWeight: 'bold' }}>Journal EntryRecent no.{props.journalNo}</span>
                            </div>
                        </div>
                        <div style={{ marginRight: 40, display: 'flex', flexDirection: 'row' }}>
                            <SettingsIcon style={{ fontSize: 30, marginRight: 20, cursor: 'pointer' }} />
                            <HelpOutlineIcon style={{ fontSize: 30, marginRight: 20, cursor: 'pointer' }} />
                            <div onClick={() => { ClaseJournal_entries() }}>
                                <CloseIcon style={{ fontSize: 30, cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>

                    <div style={{ height: 100 }}></div>
                    <div style={{
                        position: 'fixed',
                        width: '80%',
                        marginLeft: 250
                    }}>

                    </div>
                    {/* {JSON.stringify(props.data)} */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: "100%"
                    }}>
                        <div>
                            <span>Currency</span><br />
                            <select
                                style={{
                                    border: '1px solid #ccc',
                                    height: 30,
                                    borderRadius: 3,
                                    width: 235,
                                    paddingLeft: 10,
                                    outline: 'none'
                                }}
                                onChange={(e) => {
                                    OnloadSelectCurrencies(e.target.value, props.usd, props.thb, uid, convertdebit, convertcredit);
                                }}
                                value={props.currency}
                            >
                                <option value="">{props.editcurrency}</option>
                                {listcurrency &&
                                    listcurrency.map((data, index) => {
                                        return (
                                            <option key={index} value={data?.CURRENCY_UID}>{data?.CURRENCY_NAME} - {data?.CURRENCY_CODE}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>

                        <div style={{ paddingTop: 20, }}>
                            {
                                props.currency_id ? (<>
                                    {
                                        props.currency_id === true ? (<>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div style={{ marginTop: 5, paddingLeft: 10 }}>1 {props.select_currency}</div>
                                                <div style={{ marginLeft: 5 }}>
                                                    {
                                                        props.currency_id === true ? (
                                                            <>
                                                                {
                                                                    props.checked_logo !== 'LAK' ? (<>
                                                                        <img alt="Logo" src={props.listimage} style={{ width: 40, height: 20, marginTop: 5 }} /> {props.select_currency}
                                                                    </>) : (<>
                                                                        <img alt="Logo" src={props.listimage} style={{ width: 40, height: 20, marginTop: 5 }} /> {props.select_currency}
                                                                    </>)
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                </div>
                                                <div style={{ marginTop: 5 }}>=</div>
                                                <input
                                                    value={props.exchage_rate}
                                                    style={{
                                                        border: '1px solid #ccc',
                                                        height: 30,
                                                        borderRadius: 3,
                                                        width: 150,
                                                        paddingLeft: 10,
                                                        outline: 'none',
                                                        textAlign: "right"
                                                    }}
                                                    onChange={(e) => onChangeTextCurrency(e.target.value)}


                                                    onBlur={(e) => onBlurCurrency(e.target.value, agconvertdebit, agconvertcredit)}
                                                />
                                                <div style={{ marginLeft: 5 }}>
                                                    <img alt="Logo" src="/assets/images/laos.png" style={{ width: 40, height: 20, marginTop: 5 }} />
                                                </div>
                                                <div style={{ marginTop: 5, paddingLeft: 10 }}>LAK</div>
                                            </div>
                                        </>) : null
                                    }
                                </>) : null
                            }

                            <div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <div style={{ flex: 1 }}>
                            <span>Journal date</span><br />
                            <input
                                type="text"
                                value={props.defaultValue}
                                onChange={(e) => props.setDefaultValue(e.target.value)}


                                style={{
                                    border: '1px solid #ccc',
                                    height: 30,
                                    borderRadius: 3,
                                    width: 100,
                                    paddingLeft: 10,
                                    textAlign: "right",
                                    borderRight: "none",
                                }}
                            />
                            <input
                                type="date"
                                onChange={(e) => _searchstartdate(e.target.value)}
                                style={{
                                    border: '1px solid #ccc',
                                    height: 30,
                                    borderRadius: 3,
                                    width: 30,
                                    paddingLeft: 10,
                                }}
                            />
                        </div>
                        {
                            props.warring === true ? (<>
                                <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                                    <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                                        Something's not quite right
                                    </small>
                                    <small style={{ marginLeft: 10 }}>Transactions can have only one foreign currency at a time</small>
                                </div>

                            </>) : props.warring_exhange_rate === true ? (<>
                                <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                                    <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                                        Something's not quite right
                                    </small>
                                    <small style={{ marginLeft: 10 }}>Please Inter Exchange Rate</small>
                                </div>

                            </>) : props.warring_select_currency === true ? (<>
                                <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                                    <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                                        Something's not quite right
                                    </small>
                                    <small style={{ marginLeft: 10 }}>Please Choose Currency</small>
                                </div>
                            </>) : props.warring_debit_credit === true ? (<>
                                <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                                    <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                                        Something's not quite right
                                    </small>
                                    <small style={{ marginLeft: 10 }}>Please check balance debits and credits</small>
                                </div>
                            </>) : null
                        }
                        <div>
                            <span>Journal no</span><br />
                            <input
                                style={{
                                    border: '1px solid #ccc',
                                    height: 30,
                                    borderRadius: 3,
                                    width: 400,
                                    paddingLeft: 10,
                                    outline: 'none'
                                }}
                                onChange={(e) => {
                                    props.setJournalNo(e.target.value);
                                }}
                                value={props.journalNo}
                            />
                        </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <TableContainer className={classes.tableContainer} >
                        <Table aria-label="sticky table" size="small" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.stickyHeader} style={{ width: 50, fontWeight: 'bold' }}>#</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 50, fontWeight: 'bold' }} align="left">No</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Account</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Debit</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Credit</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Desription</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Department</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Name</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Transactions Type</TableCell>
                                    <TableCell className={classes.stickyHeader} style={{ width: 100, fontWeight: 'bold' }} align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.data && props.data?.map((item, index) => {
                                        return (
                                            <RowComponentEdit
                                                item={item}
                                                key={index}
                                                index={index}
                                                data={props.data}
                                                setData={props.etData}
                                                date={date}
                                                refreshPage={refreshPage}
                                                currency={currency}
                                                deletechange={deletechange}
                                                changeText={changeText}
                                                blurHandler={blurHandler}
                                                usd={props.usd}
                                                thb={props.thb}
                                                agconvertdebit={agconvertdebit}
                                                agconvertcredit={agconvertcredit}
                                                setCheckcondition={setCheckcondition}
                                                setActive={setActive}
                                                active={active}
                                                addLines={addLines}
                                                copyData={copyData}
                                                paste={paste}
                                                showcp={showcp}
                                                sumData={sumData}
                                                setNetTotalDebit={props.etNetTotalDebit}
                                                setNetTotalCrebit={props.setNetTotalCrebit}
                                                setCurrency_id={props.setCurrency_id}
                                                setCheckCurrency={props.setCheckCurrency}
                                                editcurrency={props.editcurrency}
                                                handleClose={handleClose}
                                                showDrawer={handleOpenDrawer}
                                                anchorEl={anchorEl}
                                                listuser={listuser}
                                                taxs_options={taxs_options}
                                                handleClick_Options={handleClick_Options}
                                                getindex={getindex}
                                                select_options={select_options}
                                                settax={settax}
                                                handleCloseTaxs={handleCloseTaxs}
                                                anchorElTax={anchorElTax}
                                                showDrawerTax={handleOpenDrawerTaxs}
                                                select_options_taxs={select_options_taxs}
                                                handleClick_OptionsTax={handleClick_OptionsTax}
                                                listtransactions_type={listtransactions_type}
                                                handleShow={handleShow}
                                                show={show}
                                                setWarring={props.setWarring}
                                                listcurrency={listcurrency}
                                                setListimage={props.setListimage}
                                                setSelect_currency={props.setSelect_currency}
                                                exchage_rate={props.exchage_rate}
                                                setChecked_logo={props.setChecked_logo}
                                                handleCloseDepartment={handleCloseDepartment}
                                                anchorElTax_dep={anchorElTax_dep}
                                                listdepartment={listdepartment}
                                                select_options_department={select_options_department}
                                                handleShowbox={handleShowbox}
                                                handleClick_OptionsDepartment={handleClick_OptionsDepartment}
                                                setTransactions_type={setTransactions_type}
                                                transactions_type={transactions_type}

                                            />

                                        )
                                    })
                                }

                            </TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>Total</TableCell>
                                <TableCell ></TableCell>
                                <TableCell align="right">{debit.replaceAll('$', '')}</TableCell>
                                <TableCell align="right">{debit.replaceAll('$', '')}</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                            {
                                props.conditions_curren !== 'LAK' ? (<>
                                    <TableRow>
                                        <TableCell colSpan={2}>NetTotal</TableCell>
                                        <TableCell ></TableCell>

                                        {
                                            props.netTotalDebit.replaceAll('$', '') === 'NaN' ? (
                                                <>
                                                    <TableCell align="right">0</TableCell>
                                                </>) : (<>
                                                    <TableCell align="right">{props.netTotalDebit.replaceAll('$', '')}</TableCell>

                                                </>)
                                        }

                                        {
                                            props.netTotalCrebit.replaceAll('$', '') === 'NaN' ? (
                                                <>
                                                    <TableCell align="right">0</TableCell>

                                                </>) : (<>
                                                    <TableCell align="right">{props.netTotalCrebit.replaceAll('$', '')}</TableCell>


                                                </>)
                                        }
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>

                                    </TableRow>
                                </>) : null
                            }
                        </Table>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <button style={{
                                border: '1px solid #ccc',
                                borderRadius: 3,
                                paddingLeft: 20, paddingRight: 20,
                                backgroundColor: 'white'
                            }} onClick={addMore}>Add lines</button>
                            <button style={{
                                border: '1px solid #ccc',
                                borderRadius: 3,
                                paddingLeft: 20, paddingRight: 20,
                                marginLeft: 20, backgroundColor: 'white'
                            }} onClick={resetlines}>Clear lines </button>
                            <button style={{
                                border: '1px solid #ccc',
                                borderRadius: 3,
                                paddingLeft: 20, paddingRight: 20,
                                marginLeft: 20, backgroundColor: 'white'
                            }} onClick={ClearAllLines}>Clear All </button>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                            <small>Memo</small>
                            <textarea style={{ width: 350, height: 50, outline: 'none', border: '1px solid #ccc' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <label style={{ display: 'flex', flexDirection: 'row' }}>
                                    <AttachFileIcon />
                                    <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                                    <input
                                        type="file"
                                        name="images"
                                        onChange={onSelectFile}
                                        multiple
                                        style={{ display: "none" }}
                                    />

                                </label>
                                <div style={{ marginLeft: 20 }}>
                                    <small>Maximum size:20MB</small>
                                </div>
                                {
                                    uploading ? (<>
                                        <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                                            <Spin size="smal" />
                                        </Flex>

                                    </>) : null
                                }
                            </div>
                            <div style={{ border: '1px solid #ccc', width: '50%', height: 150, textAlign: "right", marginTop: 5, marginBottom: 30 }}>
                                {
                                    uploading ? (<>
                                        <LinearProgress />
                                    </>) : null
                                }
                                {props.selectedImages &&
                                    props.selectedImages.map((data, index) => {

                                        let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                        return (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>

                                                <small style={{ fontSize: 15 }}

                                                >

                                                    {data?.name}({sizes}) kb</small>
                                                <a href={data?.attachments}><p>Download</p></a>
                                                <span
                                                    style={{
                                                        color: "red",
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: '50%',
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        boxShadow: 12,
                                                        cursor: "pointer",
                                                        marginLeft: 30,
                                                    }} onClick={() => fileRemove(data)}>
                                                    x
                                                </span>
                                            </div>
                                        );
                                    })}

                            </div>
                        </div>
                        <div>
                            <div style={{
                                display: 'block',
                                padding: '20px',
                                height: '60px',
                                width: '100%',
                            }}>
                                <div style={{
                                    backgroundColor: "#3f51b5",
                                    borderTop: "1px solid #E7E7E7",
                                    textAlign: "center",
                                    padding: "20px",
                                    position: "fixed",
                                    left: "0",
                                    bottom: "0",
                                    height: "60px",
                                    width: "100%",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: "space-between"

                                }}>
                                    <div style={{ marginLeft: 10 }}>
                                        <div style={{
                                            border: '1px solid #ccc',
                                            borderRadius: 3,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            marginLeft: 20,
                                            height: 30,
                                            color: "white",
                                            cursor: "pointer",
                                            paddingTop: 5
                                        }}

                                            onClick={() => { handleShowLeave() }}
                                        >Cancel</div>

                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <div
                                            style={{
                                                border: '1px solid #ccc',
                                                position: "absolute",
                                                height: 30,
                                                marginTop: -10
                                            }}
                                        >
                                        </div>
                                        <small style={{
                                            cursor: "pointer",
                                            fontSize: 16,
                                            color: "white",
                                            opacity: more ? 0.9 : 2,
                                            marginLeft: 10
                                        }}
                                            onClick={() => { changeOpent() }}
                                            onMouseOver={() => setMore(true)}
                                            onMouseLeave={() => setMore(false)}
                                        >More</small>
                                        {
                                            showBoxEdit == true ?
                                                (
                                                    <>
                                                        <div style={{
                                                            border: '1px solid #ccc',
                                                            position: "absolute",
                                                            borderRadius: 3,
                                                            paddingLeft: 10,
                                                            height: 100,
                                                            width: 200,
                                                            marginTop: -150,
                                                            backgroundColor: '#CBC7C7'
                                                        }} >
                                                            <div style={{ display: "flex", flexDirection: "column" }}
                                                                onClick={() => { handleClickOpen() }}
                                                            >
                                                                <small style={{ paddingTop: 20, fontSize: 16, cursor: "pointer", textAlign: "start" }}
                                                                >Deletes</small>
                                                                <small style={{ paddingTop: 10, fontSize: 16, cursor: "pointer", textAlign: "start" }}>Transaction journal</small>
                                                            </div>
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                    </>)
                                        }
                                    </div>
                                    <div style={{ marginLeft: 50 }}>
                                        <div style={{
                                            border: '1px solid #ccc',
                                            borderRadius: 3,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            marginLeft: 20,
                                            height: 30,
                                            color: "white",
                                            cursor: "pointer",
                                            paddingTop: 5
                                        }}
                                            onClick={createdata}
                                        >
                                            {!isLoading ? (
                                                <>
                                                    Save
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        <Spinner animation="border" variant="primary" size='sm' />
                                                    }
                                                </>)
                                            }
                                        </div>
                                    </div>
                                </div >
                            </div>
                        </div>
                    </TableContainer>

                </div>




            </Dialog>
        </div>
    );
}
function RowComponentEdit({ changeText, deletechange, item, index, data, blurHandler, agconvertdebit, agconvertcredit, setCheckcondition, setActive, active, addLines, showcp, paste, copyData, setCurrency_id, setCheckCurrency, sumData, setNetTotalCrebit, setNetTotalDebit, handleClose, showDrawer, anchorEl, listuser,
    handleShow, handleClick_Options, getindex, select_options, show, handleCloseTaxs, showDrawerTax, anchorElTax, listtransactions_type,
    handleClick_OptionsTax,
    setWarring,
    listcurrency,
    setListimage,
    setSelect_currency,
    exchage_rate,
    setChecked_logo,
    select_options_taxs,
    handleCloseDepartment,
    anchorElTax_dep,
    listdepartment,
    select_options_department,
    handleShowbox,
    handleClick_OptionsDepartment,
    setTransactions_type,
    transactions_type,

}) {
    const { listchart_of_account } = useContext(LoginContext);
    const [searchResult, setSearchResult] = useState([]);
    const [showBox, setShowBox] = useState(false);
    let number;
    const handleClick = () => {
        setActive(null)
        setShowBox(!showBox);
    };
    const onBlurClose = (active) => {
        if (active == null) {
            setShowBox(false);
        } else {
        }
    }
    const getNameList = (c_id, CURRENCY_UID) => {
        let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(CURRENCY_UID))
        if (filter[0].CURRENCY_NAME !== 'LAK') {
            setListimage(filter[0].attachments[0])
            setSelect_currency(filter[0].CURRENCY_NAME)
        }

        axios.get(`/accounting/api/chartAccount/bank/listchart_of_account/${c_id}`).then((respone) => {
            if (respone?.data?.message.length > 0) {
                const chart_name = respone?.data?.result.map((item) => {
                    return item.CHART_NAME_ENG;
                });
                chart_name.reverse();
                chart_name.reverse();
                if ((index % 2 != 0) && index > 0 && data[index - 1]?.DEBIT) {
                    data[index]['CREDIT'] = data[index - 1]?.DEBIT;
                    const credit = sumData('CREDIT')
                    let exchange = exchage_rate.replaceAll(',', '')
                    let number = parseFloat(exchange) * parseFloat(credit)
                    let creditnumber = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
                    let convertcredit = creditnumber.replaceAll('$', '')
                    setNetTotalCrebit(convertcredit)
                } else if ((index % 2 != 0) && index > 0) {
                    data[index]['DEBIT'] = data[index - 1]?.CREDIT
                    const debit = sumData('DEBIT')
                    let exchange = exchage_rate.replaceAll(',', '')
                    let number = parseFloat(exchange) * parseFloat(debit)
                    let creditnumber = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
                    let convertdebit = creditnumber.replaceAll('$', '')
                    setNetTotalDebit(convertdebit)
                }
                data[index]["CHART_ID"] = respone?.data?.result[0].CHART_ID
                data[index]["CURRENCY_CODE"] = respone?.data?.result[0].CHART_CURRENCY_CODE
                data[index]["NAME_ENG"] = respone?.data?.result[0].CHART_NAME_ENG
                data[index]['NAME_ENG_CODITIONS'] = chart_name.join(":")
                data[index]["AC_SYMBOL"] = respone?.data?.result[0].ACCOUNT_TYPE_AC_SYMBOL
                data[index]["ACCOUNT_CREATESTATUS"] = respone?.data?.result[0].ACCOUNT_CREATESTATUS
                data[index]['CHART_PARENTS'] = respone?.data?.result[0].CHART_PARENTS
                data[index]['BS_STATUS'] = 0
                changeText(chart_name.join(":"), "NAME_ENG");
                const uniqueEntries = Object.values(
                    data.reduce((acc, entry) => {
                        if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
                            acc[entry.CURRENCY_CODE] = entry;
                        }
                        return acc;
                    }, {})
                );
                const usdData = uniqueEntries.filter(item => item.CURRENCY_CODE !== 'LAK');
                if (usdData.length === 0) {
                    setCurrency_id(false)

                } else {

                    setChecked_logo(usdData[0].CURRENCY_CODE)
                    setCurrency_id(true)
                }
                setShowBox(!showBox);
                if (usdData.length > 1) {
                    setWarring(true)
                    return;
                }


            }
        });

    };
    const _onSearchList = (e) => {
        // data[index]['name'] = e
        // let searchName = listaccountname.filter((el) => el.name_eng.toLowerCase().includes(e.toLowerCase()));
        // if (!e) {
        //     setSearchResult([]);
        // } else {
        //     setSearchResult([...searchName]);
        // }
    };
    return (
        <>
            {/* <tr style={{ border: '1px solid #ccc', height: 50 }}>
                <td align="right"><AddCircleOutlineIcon onClick={() => { addLines(index + 1) }} style={{ color: "green", width: 40, height: 40, cursor: "pointer" }} /></td>
                <td style={{ width: 30 }} align="center">
                    {index + 1}
                </td>
                <td >
                    <input
                        value={item?.NAME_ENG}
                        onChange={(e) => {
                            _onSearchList(e.target.value, index);
                        }}
                        onClick={() => handleClick(true)}
                        onBlur={() => onBlurClose(active)}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35
                        }}
                    />
                    {showBox && (
                        <div
                            style={{
                                overflowY: "scroll",
                                height: 100,
                                paddingTop: 5,
                                paddingLeft: 10,
                                position: 'absolute',
                                backgroundColor: 'white'
                            }}
                        >
                            {searchResult.length > 0 ? (
                                <>
                                    {searchResult.map((data, index1) => {
                                        return (
                                            <>
                                                <span
                                                    key={index1}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                                                    }}
                                                    onClick={() => getNameList(data?.CHART_ID, data?.CURRENCY_UID)}
                                                    onMouseOver={() => setActive(data?.NAME_ENG)}
                                                    onMouseLeave={() => setActive(null)}
                                                >
                                                    {data?.name_eng}
                                                </span>
                                                <br />
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {listchart_of_account.map((data, index1) => {
                                        return (
                                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }} key={index1}>
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                                                    }}
                                                    onClick={() => { getNameList(data?.CHART_ID, data?.CURRENCY_UID) }}
                                                    onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                                                    onMouseLeave={() => setActive(null)}
                                                >
                                                    {data?.CHART_NAME_ENG}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    )}
                </td>
                <td>
                    <input
                        value={data[index].DEBIT}
                        onBlur={(e) => blurHandler(e.target.value, "DEBIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
                        onChange={(e) => changeText(e.target.value, "DEBIT", index)}
                        disabled={data[index]?.CREDIT ? true : false}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35,
                            textAlign: "right"
                        }}
                    />
                </td>
                <td>
                    <input
                        value={data[index].CREDIT}
                        onBlur={(e) => blurHandler(e.target.value, "CREDIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
                        onChange={(e) => changeText(e.target.value, "CREDIT", index)}
                        disabled={data[index]?.DEBIT ? true : false}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35,
                            textAlign: "right"
                        }}
                    />
                </td>
                <td>
                    <input
                        value={item?.DESCRIPTION}
                        onChange={(e) => changeText(e.target.value, "DESCRIPTION", index)}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35
                        }}
                    />
                </td>

                <td>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <input
                            value={item?.EMPLOYEE}
                            onChange={(e) => changeText(e.target.value, "EMPLOYEE", index)}
                            style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                paddingLeft: 10,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                borderRight: 'none',
                                height: 35
                            }}
                        />
                        <div style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                            backgroundColor: '#f8f9fa',
                            cursor: 'pointer',


                        }}

                        >

                            <Option_select
                                handleClose={handleClose}
                                showDrawer={showDrawer}
                                anchorEl={anchorEl}
                                getindex={getindex}
                                listuser={listuser}
                                select_options={select_options}
                                data={data}

                            />
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { handleClick_Options(event, index) }}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </td>
                <td>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <input
                            value={item?.TAX}
                            onChange={(e) => changeText(e.target.value, "TAX", index)}
                            style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                paddingLeft: 10,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                borderRight: 'none',
                                height: 35
                            }}
                        />
                        <div style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                            backgroundColor: '#f8f9fa',
                            cursor: 'pointer',


                        }}

                        >
                            <Options_taxs
                                handleCloseTaxs={handleCloseTaxs}
                                showDrawerTax={showDrawerTax}
                                anchorElTax={anchorElTax}
                                getindex={getindex}
                                listtransactions_type={listtransactions_type}
                                select_options_taxs={select_options_taxs}
                                data={data}
                                show={show}
                                handleShow={handleShow}

                            />
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { handleClick_OptionsTax(event, index) }}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </td>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    < DeleteIcon style={{ height: 50, cursor: "pointer", color: "green", width: 40, height: 40 }} 
                    onClick={() => {
                        deletechange(index); setCheckcondition('1')
                    }} />
                    {
                        showcp == true ?
                            (
                                <>
                                    <div style={{ cursor: 'pointer', border: '1px solid #ccc', borderRadius: 3, marginTop: 4, paddingLeft: 5, paddingRight: 5 }} onClick={() => { paste(index) }}>paste</div>
                                </>) : (
                                <>
                                    <div style={{ cursor: 'pointer', border: '1px solid #ccc', borderRadius: 3, marginTop: 4, paddingLeft: 5, paddingRight: 5 }} onClick={() => { copyData(index) }}>Copy</div>
                                </>
                            )
                    }
                </div>
            </tr> */}

            <TableRow >
                <TableCell align="left" style={{ width: 100 }}>
                    <AddCircleOutlineIcon />
                </TableCell>
                <TableCell align="left" style={{ width: 100 }}>
                    {index + 1}
                </TableCell>
                <TableCell align="left" >
                    <input
                        value={item?.NAME_ENG}
                        onChange={(e) => {
                            _onSearchList(e.target.value, index);
                        }}
                        onClick={() => handleClick(true)}
                        onBlur={() => onBlurClose(active)}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35
                        }}
                    />

                    {showBox && (
                        <div
                            style={{
                                overflowY: "scroll",
                                height: 200,
                                paddingTop: 5,
                                paddingLeft: 10,
                                position: 'absolute',
                                backgroundColor: 'white',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {searchResult.length > 0 ? (
                                <>
                                    {searchResult?.map((data, index1) => {
                                        return (
                                            <>
                                                <span
                                                    key={index1}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                                                    }}
                                                    onClick={() => { getNameList(data?.CHART_ID, data?.CURRENCY_UID) }}
                                                    onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                                                    onMouseLeave={() => setActive(null)}
                                                >
                                                    {data?.CHART_NAME_ENG}
                                                </span>
                                                <ComponentList
                                                    children={data?.children}
                                                    active={active}
                                                    level={30}
                                                    getNameList1={getNameList}
                                                    setActive={setActive}
                                                />
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {listchart_of_account && listchart_of_account?.map((data, index1) => {

                                        return (
                                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }} key={index1}>
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                                                    }}
                                                    onClick={() => {
                                                        getNameList(data?.CHART_ID, data?.CURRENCY_UID
                                                        )
                                                    }}
                                                    onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                                                    onMouseLeave={() => setActive(null)}
                                                >
                                                    {data?.CHART_NAME_ENG}

                                                </span>
                                                <ComponentList
                                                    children={data?.children}
                                                    active={active}
                                                    level={30}
                                                    getNameList1={getNameList}
                                                    setActive={setActive}

                                                />
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    )}

                </TableCell>
                <TableCell align="right" >
                    <input
                        value={item?.DEBIT}
                        onBlur={(e) => blurHandler(e.target.value, "DEBIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
                        onChange={(e) => changeText(e.target.value, "DEBIT", index)}
                        disabled={data[index]?.CREDIT ? true : false}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35,
                            textAlign: "right"
                        }}
                    />
                </TableCell>
                <TableCell align="right" >
                    <input
                        value={item?.CREDIT}
                        onBlur={(e) => blurHandler(e.target.value, "CREDIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
                        onChange={(e) => changeText(e.target.value, "CREDIT", index)}
                        disabled={data[index]?.DEBIT ? true : false}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35,
                            textAlign: "right"
                        }}
                    />
                </TableCell>
                <TableCell align="right" >
                    <input
                        value={item?.DESCRIPTIONS}
                        // onClick={() => handleClickFalse()}
                        onChange={(e) => changeText(e.target.value, "DESCRIPTIONS", index)}
                        style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            paddingLeft: 10,
                            borderRadius: 3,
                            height: 35
                        }}
                    />
                </TableCell>
                <TableCell align="right" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <input
                            value={item?.DEPARTMENT_NAME}
                            onChange={(e) => changeText(e.target.value, "DEPARTMENT_NAME", index)}
                            style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                paddingLeft: 10,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                borderRight: 'none',
                                height: 35
                            }}
                        />
                        <div style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                            backgroundColor: '#f8f9fa',
                            cursor: 'pointer',
                        }}
                        >

                            <Options_department
                                handleCloseDepartment={handleCloseDepartment}
                                anchorElTax_dep={anchorElTax_dep}
                                getindex={getindex}
                                listdepartment={listdepartment}
                                select_options_department={select_options_department}
                                data={data}
                                show={show}
                                handleShowbox={handleShowbox}

                            />
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { handleClick_OptionsDepartment(event, index) }}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="right" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <input
                            value={item?.EMPLOYEE}
                            onChange={(e) => changeText(e.target.value, "EMPLOYEE", index)}
                            style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                paddingLeft: 10,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                borderRight: 'none',
                                height: 35
                            }}
                        />
                        <div style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                            backgroundColor: '#f8f9fa',
                            cursor: 'pointer',
                        }}
                        >

                            <Option_select
                                handleClose={handleClose}
                                showDrawer={showDrawer}
                                anchorEl={anchorEl}
                                getindex={getindex}
                                listuser={listuser}
                                select_options={select_options}
                                data={data}
                            />
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { handleClick_Options(event, index) }}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="right" >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <input
                            value={item?.TAX}
                            onChange={(e) => changeText(e.target.value, "TAX", index)}
                            style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                paddingLeft: 10,
                                borderTopLeftRadius: 3,
                                borderBottomLeftRadius: 3,
                                borderRight: 'none',
                                height: 35
                            }}
                        />
                        <div style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                            backgroundColor: '#f8f9fa',
                            cursor: 'pointer',
                        }}

                        >
                            <Options_taxs
                                handleCloseTaxs={handleCloseTaxs}
                                anchorElTax={anchorElTax}
                                getindex={getindex}
                                listtransactions_type={listtransactions_type}
                                select_options_taxs={select_options_taxs}
                                data={data}
                                transactions_type={transactions_type}
                                setTransactions_type={setTransactions_type}
                                show={show}
                                handleShow={handleShow}
                            />
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => { handleClick_OptionsTax(event, index) }}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>

                </TableCell>
                <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                    onClick={() => {
                        deletechange(index); setCheckcondition('1')
                    }}
                >
                    <DeleteIcon style={{ height: 35, color: "green" }} />
                </TableCell>
            </TableRow>
        </>

    );
}
function ComponentList({ children, level, getNameList1, setActive, active }) {

    if (children?.length === 0) return <></>;
    return (
        <>
            {children?.map((data, index) => {
                return (
                    <>
                        <span key={index}
                            style={{
                                paddingLeft: level,
                                cursor: "pointer",
                                fontWeight:
                                    active === data?.CHART_NAME_ENG ? "bold" : "",
                            }}
                            onClick={() => { getNameList1(data?.CHART_ID, data?.CURRENCY_UID) }}
                            onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                            onMouseLeave={() => setActive(null)}
                        >{data?.CHART_NAME_ENG}</span>
                        <ComponentList
                            children={data?.children}
                            level={level * 2 - 20}
                            getNameList1={getNameList1}
                            setActive={setActive}
                            active={active}

                        />
                    </>
                );
            })}
        </>
    );
}

