import React, { useState, useContext, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import { LoginContext } from "../page/contexts/LoginContext";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import SettingsIcon from "@material-ui/icons/Settings";
import Slide from '@material-ui/core/Slide';
import axios from "axios";
import moment from "moment";
import { Spinner, } from "react-bootstrap";
import { Flex, Spin, Skeleton } from 'antd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { message } from 'antd';
import Create_user from "./Create_user";
import Option_select from "./Option_select";
import Cookies from 'js-cookie';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Options_taxs from "./Options_taxs";
import Options_department from "./Options_department";
import Add_department from "./Add_department";
import Add_customize_department from "./Add_customize_department";
import setAuthToken from "../setAuthToken";
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 440, // Adjust the height as needed
    maxWidth: '100%', // Adjust the width as needed
    overflow: 'auto', // Ensure scrolling
  },
  table: {
    minWidth: 650,
    tableLayout: 'fixed', // Ensures fixed layout for columns
  },
  stickyHeader: {
    // position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.appBar,
  },
  parentDiv: {
    width: '100%',
    height: '500px', // Set the height you want for the parent div
    overflow: 'auto', // Make the div scrollable
    marginTop: 100
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const FullScreenDialog = (props) => {
  const {
    listallaccountchildren, listchart_of_account, listcurrency,
    onLoaduser,
    company_uid,
    user_session,
    settax,
    listuser,
    listtransactions_type,
    count_number,
    journalNo,
    setJournalNo,
    listdepartment,
    onLoadTransactions_List,
    OnlaodGl,
    getcount,
    setCheckConditions

  } = useContext(LoginContext);
  const classes = useStyles();
  const [currency_id, setCurrency_id] = useState(false);
  const [exchage_rate, setExchage_rate] = useState('')
  const [currency, setCurrency] = useState("LAK");
  const [currencystatus, setCurrencystatus] = useState('');
  const [currency_symbol, setcurrency_symbol] = useState('')
  const [conditions_curren, setConditions_curren] = useState('LAK')
  const [array, setArray] = useState([])
  const [thb, setThb] = useState("");
  const [usd, setUsd] = useState("");
  const [warring, setWarring] = useState(false)
  const [warring_select_currency, setWarring_select_currency] = useState(false)
  const [warring_exhange_rate, setWarring_exhange_rate] = useState(false)
  const [warring_debit_credit, setWarring_debit_credit] = useState(false)
  const [netTotalDebit, setNetTotalDebit] = useState("")
  const [netTotalCrebit, setNetTotalCrebit] = useState("")
  const [defaultValue, setDefaultValue] = useState("")
  // const [journalNo, setJournalNo] = useState('')
  const [showcp, setShowcp] = useState(false)
  const [copy, setCopy] = useState("")
  const [onFucused, setOnFocused] = useState(false);
  const [onFucusednew, setOnFucusednew] = useState(false)
  const [isLoading, setIsLoading,] = useState(false);
  const [isLoading_new, setIsLoading_new,] = useState(false);
  const [getvaluesoptions, setGetvaluesoptions] = useState('')
  const [conditions, setConditions] = useState(0)
  const [selectedImages, setSelectedImages] = useState([])
  const [active, setActive] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayname, setDisplayname] = useState()
  const [title, setTitle] = useState('')
  const [first_name, setFirst_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [uploading, setUploading] = useState(false)
  const [images, setImage] = useState([])
  const [email, setEmail] = useState('')
  const [phonenumber, setPhonenumber] = useState('')
  const [mobilenumber, setMobilenumber] = useState('')
  const [village, setVillage] = useState('')
  const [district, setDistrict] = useState('')
  const [province, setProvince] = useState('')
  const [notes, setNotes] = useState('')
  const [attachments, setAttachments] = useState('')
  const [payment, setPayment] = useState('')
  const [terms, setTerms] = useState('')
  const [registertaxs, setRegistertaxs] = useState('')
  const [opening, setOpening] = useState('')
  const [openingdate, setOpeningdate] = useState('')
  const [id, setID] = useState('')
  const [options_name, setOptions_name] = useState('')
  const [birthday, setBirthday] = useState('')
  const [gender, setGender] = useState('')
  const [account_no, setAccount_no] = useState('')
  const [default_account, setDefault_account] = useState('')
  const [social_insurance_no, setSocial_insurance_no] = useState('')
  const [add_currency, setAdd_Currency] = useState()
  const [employee_ID_number, setEmployee_ID_number] = useState('')
  const [employee_id, setEmployee_id] = useState('')
  const [customer_type, setCustomer_type] = useState('')
  const [getindex, setGeindex] = useState()
  const [openDrawerTax, setOpenDrawerTax] = useState('')
  const [anchorElTax, setAnchorElTax] = useState('')
  const [anchorElTax_dep, setAnchorElTax_dep] = useState('')
  const [transactions_type, setTransactions_type] = useState('')
  const [show, setShow] = useState(false);
  const [listimage, setListimage] = useState([])
  const [select_currency, setSelect_currency] = useState('')
  const [checked, setChecked] = useState('')
  const [checked_logo, setChecked_logo] = useState('')
  const [checkedallTrue, setCheckedallTrue] = useState('')
  const [company, setCompany] = useState('')
  const [other, setOther] = useState('')
  const [getcurrency, setGetcurrency] = useState('')
  const [currencies_name, setCurrencies_name] = useState('')
  const [account_number, setAccount_numner] = useState('')
  const [checkedconditions, setCheckedconditions] = useState(false)
  const [showboxdepartment, setShowboxdepartment] = useState(false)
  const [department, setDepartment] = useState('')
  const [allNotEmpty, setAllNotEmpty] = useState(false)




  const handleOpenDrawer = () => {
    setAnchorEl(null);
    setOpenDrawer(true)
  };
  const handleOpenDrawerTaxs = () => {
    setAnchorElTax(null)
    setOpenDrawerTax(true)
  }
  const handleOpenDrawerDepartment = () => {
    setAnchorElTax_dep(null)

  }
  const CloseTransactions_typ = () => {
    setShow(false);
  };
  const handleShow = () => {

    setShow(true)
  };
  const handleClosebox = () => {
    setShowboxdepartment(false)
  }
  const handleShowbox = () => {

    setShowboxdepartment(true)
  }
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setDisplayname('')
    setTitle('')
    setFirst_name('')
    setLast_name('')
  }
  const handleClick_Options = (event, index) => {
    setAnchorEl(event.currentTarget);
    setGeindex(index)
  };
  const handleClick_OptionsTax = (event, index) => {
    setAnchorElTax(event.currentTarget);
    setGeindex(index)
  };
  const handleClick_OptionsDepartment = (event, index) => {
    setAllNotEmpty(false)
    setAnchorElTax_dep(event.currentTarget);
    setGeindex(index)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseTaxs = () => {
    setAnchorElTax(null);
  };
  const handleCloseDepartment = () => {
    setAnchorElTax_dep(null);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },

  ]);
  const [clearData, setClearData] = useState([
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
  ])
  const initialData = [
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
    { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" },
  ];

  const addMore = () => {
    setData([...data, {}]);
  };
  const resetlines = () => {
    let oldarray = [...data]
    oldarray.splice(data, 1)
    setData([...oldarray])
  }
  const ClearAllLines = () => {
    setCurrency_id('')
    setThb('')
    setUsd('')
    setData([...clearData])
    setNetTotalCrebit('')
    setNetTotalDebit('')
  }
  const options = (e) => {
    setGetvaluesoptions(e)
    setConditions(1)
    setSelectedImages([])
    setDisplayname('')
    setTitle('')
    setFirst_name('')
    setLast_name('')
  }
  const ClaseJournal_entries = () => {
    setData(initialData)
    setJournalNo('')
    setCurrency('')
    setChecked('')
    setNetTotalCrebit('')
    setNetTotalDebit('')
    setCurrencystatus('')
    setConditions_curren('LAK')
    setExchage_rate('')
    setCurrency_id(false)
    setWarring(false)
    setWarring_exhange_rate(false)
    setWarring_select_currency(false)
    setWarring_debit_credit(false)
    setChecked_logo('')
    props.handleClose();
    setCheckedallTrue('')
  }
  const select_options = (cus_id, cus_name, currency, index, da) => {
    da[index]['EMPLOYEE_ID'] = cus_id.toString()
    da[index]['EMPLOYEE'] = cus_name + '-' + currency.toString();
    da[index]['EMPLOYEE_CURRENCY'] = currency.toString();
    setAnchorEl(null);

    if (currencystatus) {
      let list_result = data.map(entry => {
        return {
          CURRENCY_CODE: entry.CURRENCY_CODE,
          EMPLOYEE_CURRENCY: entry.EMPLOYEE_CURRENCY
        };
      });
      const filteredData = list_result.filter(entry => entry.CURRENCY_CODE !== undefined && entry.CURRENCY_CODE !== "");
      const allMatchs = filteredData.every(entry => entry.EMPLOYEE_CURRENCY === currencystatus);
      if (allMatchs === false) {
        setCheckedconditions(true)
        return;
      } else {
        setCheckedconditions(false)
      }


    }

  }

  const select_options_taxs = (cus_id, cus_name, index, da) => {
    da[index]['TAXS_ID'] = cus_id.toString()
    da[index]['TAX'] = cus_name
    setAnchorElTax(null);
  }
  const select_options_department = (department_id, department_name, index, da) => {
    da[index]['DEPARTMENT_ID'] = department_id.toString()
    da[index]['DEPARTMENT_NAME'] = department_name
    setAnchorElTax_dep(null);
  }
  const onSelectFile = async (event) => {
    const selectedFiles = event.target.files;
    const file_attachments = Array.from(selectedFiles);
    setSelectedImages((prevImages) => [...prevImages, ...file_attachments]);
    let formData = new FormData();
    for (const key of Object.keys(selectedFiles)) {
      formData.append("file_attachment", selectedFiles[key]);
    }
    formData.append("file_attachment", selectedFiles);
    setUploading(true)
    let profileImageReturnName = await axios.post("/accounting/api/ledger_entries/upload", formData);
    const result = profileImageReturnName.data.files.map(file => ({ NAME_IMAGE: file }));
    setImage(result)
    setUploading(false)
  };
  const fileRemove = (file) => {
    const updatedList = [...selectedImages];
    updatedList.splice(selectedImages.indexOf(file), 1);
    setSelectedImages(updatedList);
  }
  const OnloadSelectCurrencies = (e) => {
    setCurrency(e)
    setWarring(false)
    setWarring_select_currency(false)
    let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(e))
    if (filter[0].CURRENCY_NAME !== 'LAK') {
      setListimage(filter[0].attachments[0])
      setSelect_currency(filter[0].CURRENCY_NAME)
    }

    const uniqueEntries = Object.values(
      data.reduce((acc, entry) => {
        if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
          acc[entry.CURRENCY_CODE] = entry;
        }
        return acc;
      }, {})
    );
    const currencyCodes = uniqueEntries.map(entry => entry.CURRENCY_CODE).filter(CURRENCY_CODE => CURRENCY_CODE !== undefined);
    let resu = currencyCodes.every(item => item === filter[0].CURRENCY_NAME);
    if (resu) {
      if (filter[0].CURRENCY_NAME == 'LAK') {
        setCurrency_id(false)
      } else {
        setCurrency_id(true)
      }
    } else {
      setCurrency_id(true)
    }
    setCurrencystatus(filter[0].CURRENCY_NAME)
    setcurrency_symbol(filter[0].CURRENCY_SYMBOL)
    setChecked_logo(filter[0].CURRENCY_NAME)
    setConditions_curren(filter[0].CURRENCY_NAME)

  }
  const onChangeTextCurrency = (value) => {
    setWarring_exhange_rate(false)
    let formattedValue = 0
    formattedValue = formatMoney(value);
    setExchage_rate(formattedValue)
  }
  const changeText = (value, key, index) => {
    const object = { ...data[index] };

    if (key == 'DEBIT' || key == 'CREDIT') {
      object[key] = value.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      object[key] = value;
    }
    const cloneData = [...data];
    cloneData[index] = { ...object };

    setData([...cloneData]);
  };
  const deletechange = (index) => {
    setCurrency_id('')
    const cloneData = [...data]
    cloneData.splice(index, 1, { NAME_ENG: "", NAME_ENG_CODITIONS: "", DEBIT: "", CREDIT: "", DESCRIPTIONS: "", TAXS_ID: "", TAX: "", EMPLOYEE_ID: "", EMPLOYEE: "", EMPLOYEE_CURRENCY: "", DEPARTMENT_ID: "", DEPARTMENT_NAME: "" })
    setData([...cloneData])
  }
  const copyData = (index) => {
    setShowcp(true)
    const cloneData = [...data][index]
    setCopy(cloneData)
  }
  const paste = (index) => {
    setShowcp(false)
    const cloneData = [...data]
    cloneData.splice(index, 1, copy)
    setData([...cloneData])
  }





  const blurHandler = (value, key, index, exchage_rate, agconvertdebit, agconvertcredit) => {

    const object = { ...data[index] };
    if (key == 'DEBIT' || key == 'CREDIT') {
      let number = value.replaceAll(',', '')
      let format_number = new Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
      object[key] = value != '' ? format_number.replaceAll('$', '') : ''
    } else {
      object[key] = value;
    }
    const cloneData = [...data];
    cloneData[index] = { ...object };
    setData([...cloneData]);
    if (key == 'DEBIT') {
      const convert_usd = exchage_rate.replaceAll(',', '')
      let TotalDebit = parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertdebit)
      setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
      // if (!usd) {
      // } else {
      //   const convert_usd = usd.replaceAll(',', '')
      //   let TotalDebit = parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertdebit)
      //   setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
      // }
      // if (!thb) {
      // } else {
      //   const convert_thb = thb.replaceAll(',', '')
      //   let TotalDebit = parseFloat(convert_thb.replaceAll(',', '')) * parseFloat(agconvertdebit)
      //   setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
      // }
    } else {
      const convert_usd = exchage_rate.replaceAll(',', '')
      let TotalCredit = (parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertcredit))
      setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
      // if (!usd) {
      // } else {
      //   const convert_usd = usd.replaceAll(',', '')
      //   let TotalCredit = (parseFloat(convert_usd.replaceAll(',', '')) * parseFloat(agconvertcredit))
      //   setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
      // }
      // if (!thb) {
      // } else {
      //   const convert_thb = thb.replaceAll(',', '')
      //   let TotalCredit = (parseFloat(convert_thb.replaceAll(',', '')) * parseFloat(agconvertcredit))
      //   setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))
      // }
    }
  }
  const _searchStartdate = (e) => {
    console.log("e=", e)
    let date = moment(e).format('DD-MM-YYYY')
    console.log("e=", date)
    setDefaultValue(date)
  }
  const onloadDate = () => {
    if (defaultValue == "") {
      setDefaultValue(moment(new Date()).format("DD-MM-YYYY"))

    }
  }


  const formatMoney = (value) => {
    // Remove any non-digit characters except decimal points
    const cleanedValue = value.replace(/[^\d.]/g, '');
    // Split the value into integer and decimal parts
    const parts = cleanedValue.split('.');
    // Format the integer part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Join the integer and decimal parts and return the result
    return parts.join('.');
  };
  const onBlurCurrency = (value, x, y) => {
    let number = value.toString().replaceAll(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    const formattedValue = formatMoney(value);
    let exchange = number.replaceAll(',', '')
    setExchage_rate(formattedValue)
    let convert_x = x.replaceAll(',', '')
    let TotalDebit = (parseFloat(exchange) * parseFloat(convert_x))
    let convert_y = y.replaceAll(',', '')
    let TotalCredit = (parseFloat(exchange) * parseFloat(convert_y))
    setNetTotalDebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalDebit))
    setNetTotalCrebit(Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(TotalCredit))

  }
  const sumData = (key) => {
    let initialValue = 0
    let sum = data?.reduce(function (previousValue, currentValue) {
      return parseFloat(previousValue) + (currentValue[key] != undefined && currentValue[key] != '' ? parseFloat(currentValue[key].replaceAll(',', '')) : 0)
    }, initialValue)
    return sum;
  }
  let debit = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sumData('DEBIT'))

  let credit = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(sumData('CREDIT'))
  let convertdebit = debit.replaceAll('$', '')
  let convertcredit = credit.replaceAll('$', '')
  let agconvertdebit = convertdebit.replaceAll(',', '')
  let agconvertcredit = convertcredit.replaceAll(',', '')
  const addLines = (index) => {
    const cloneData = [...data]
    cloneData.splice(index, 0,)
    setData([...cloneData])
  }

  const createdata = async (condition_save) => {
    let maincurrency
    const currentDate = new Date();
    const debit = sumData('DEBIT')
    const credit = sumData('CREDIT')
    let journaldata;
    if (!currencystatus) {
      setWarring_select_currency(true)
      return;
    }
    if (currencystatus !== 'LAK') {
      if (!exchage_rate) {
        setWarring_exhange_rate(true)
        return;
      }
    }
    if (!exchage_rate) {
      maincurrency = "1"
    } else {
      maincurrency = exchage_rate
    }
    if (!exchage_rate)
      if (!currencystatus) {
        setWarring_select_currency(true)
        return;
      }
    const filteredList = data.filter(item => item.NAME_ENG !== "");
    function checkTaxAndEmployeeId(data) {
      return data.map(item => {
        const isTaxIdMissing = !item.TAXS_ID || item.TAXS_ID === 'null'
        const isEmployeeIdMissing = !item.EMPLOYEE_ID || item.EMPLOYEE_ID === 'null'

        return isTaxIdMissing || isEmployeeIdMissing;
      });
    }

    let checkResults = checkTaxAndEmployeeId(filteredList);

    const allTrue = checkResults.every(result => result === true);

    const uniqueEntries = Object.values(
      data.reduce((acc, entry) => {
        if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
          acc[entry.CURRENCY_CODE] = entry;
        }
        return acc;
      }, {})
    );
    const currencyCodes = uniqueEntries.map(entry => entry.CURRENCY_CODE).filter(CURRENCY_CODE => CURRENCY_CODE !== undefined);

    let result = currencyCodes.filter(item => item !== 'LAK');
    let result_lak = currencyCodes.filter(item => item === 'LAK');
    if (result.length > 1) {
      setWarring(true)
      return;
    }
    if (result_lak.length !== 1) {
      let result_all = result.concat(result);
      let resu = result_all.includes(currencystatus);
      if (!resu) {
        setWarring(true)
        return;
      }
    } else {
      let result_con = result.concat(result_lak);

      let resu = result_con.includes(currencystatus);
      if (!resu) {
        setWarring(true)
        return;
      }
    }
    if (allTrue === true) {
      setCheckedallTrue(true)
      return;
    }
    setCheckedallTrue('')
    let list_result = data.map(entry => {
      return {
        DEPARTMENT_ID: entry.DEPARTMENT_ID,
        CURRENCY_CODE: entry.CURRENCY_CODE,
        EMPLOYEE_CURRENCY: entry.EMPLOYEE_CURRENCY
      };
    });
    const filteredData = list_result.filter(entry => entry.CURRENCY_CODE !== undefined && entry.CURRENCY_CODE !== "");
    const allMatchs = filteredData.every(entry => entry.EMPLOYEE_CURRENCY === currencystatus);

    if (allMatchs === false) {
      setCheckedconditions(true)
      return;
    } else {
      setCheckedconditions(false)
    }
    const allNotEmpty = filteredData.every(entry => entry.DEPARTMENT_ID !== "");
    if (allNotEmpty === false) {
      setAllNotEmpty(true)
      return;
    } else {
      setAllNotEmpty(false)
    }

    if (debit != credit) {
      setWarring_debit_credit(true)
      return;
    } else {
      const padZero = (num) => (num < 10 ? '0' + num : num);
      const formattedTime = `${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;
      const check_datas = { data };
      const listdatas = check_datas.data;
      let array = [];
      for (let i = 0; i < listdatas.length; i++) {
        if (listdatas[i].ACCOUNT_CREATESTATUS !== undefined) {
          array.push(listdatas[i].ACCOUNT_CREATESTATUS);
        }
      }

      if (array.every(status => status === 'Ex' || status === 'In')) {
        let dataClone = [...data];
        for (let i = 0; i < dataClone.length; i++) {
          const object = dataClone[i];
          object['BS_STATUS'] = 5;
        }
        setData(dataClone);
      } else {
        let dataClone = [...data];
        for (let i = 0; i < dataClone.length; i++) {
          const object = dataClone[i];
          object['BS_STATUS'] = 6;
        }
        setData(dataClone);
      }
      if (condition_save == 'save') {
        setIsLoading(true)
      } else {
        setIsLoading_new(true)
      }
      const date = moment(defaultValue, 'DD-MM-YYYY', true);
      journaldata = {
        journal_no: journalNo,
        createdate: defaultValue,
        createdate_exchange_rate: defaultValue + ':' + formattedTime,
        money_rate: maincurrency.replaceAll(',', ''),
        createby: user_session,
        currency: currencystatus,
        currency_symbol: currency_symbol,
        company_uid: company_uid,
        for_year: date.format('YYYY'),
        informdata: data,
        file_attachment: images
      }

      axios.post("/accounting/api/ledger_entries/Bank/Insert", journaldata).then((data) => {
        onLoadTransactions_List()
        OnlaodGl()
        getcount()
        if (condition_save == 'save') {
          setIsLoading(false)
          setJournalNo('')
          setCurrency('')
          setThb('')
          setUsd('')
          setData(initialData)
          setCurrency_id('')
          setNetTotalCrebit('')
          setNetTotalDebit('')
          setCurrencystatus('')
          setcurrency_symbol('')
          props.selectedImages([])
          setImage([])
          setWarring(false)
          setWarring_debit_credit(false)
          setWarring_select_currency(false)
          setWarring_exhange_rate(false)
          setExchage_rate('')
          setCheckedallTrue('')
        } else {
          setIsLoading_new(false)
          setJournalNo('')
          setCurrency('')
          setThb('')
          setUsd('')
          setData(initialData)
          setCurrency_id('')
          setNetTotalCrebit('')
          setNetTotalDebit('')
          setCurrencystatus('')
          setcurrency_symbol('')
          props.handleClose();
          props.selectedImages([])
          setWarring_debit_credit(false)
          setWarring_select_currency(false)
          setWarring_exhange_rate(false)
          setExchage_rate('')
          setCheckedallTrue('')
        }
      }).catch((err) => {
        console.log(err)
      }
      )
    }
  }
  const Oncreate_user = (index, da) => {
    if (!getcurrency) {
      alert("currency")
      return;
    }
    da[index]['EMPLOYEE'] = displayname + '-' + currencies_name
    let user_conditions;
    if (conditions == 0) {
      user_conditions = 'Customers'
    } else {
      user_conditions = getvaluesoptions
    }
    const data = {
      customers_type_id: customer_type,
      customers_name: displayname,
      customers_genders: gender,
      customers_email: email,
      customers_phone_number: phonenumber,
      customers_village: village,
      customers_district: district,
      customers_provinces: province,
      image_and_file: images,
      status_role: user_conditions,
      company_uid: company_uid,
      company_name: company,
      currency_id: currencies_name,
      accountnumber: account_number,
      get_currency: getcurrency
    }
    axios.post('/accounting/api/customer/Bank/Insert', data).then((respone) => {
      da[index]["EMPLOYEE_ID"] = respone?.data?.result.toString()
      onLoaduser()
      setCustomer_type('')
      setDisplayname('')
      setGender('')
      setEmail('')
      setPhonenumber('')
      setVillage('')
      setProvince('')
      setCurrencies_name('')
      setGetcurrency('')
      setAccount_numner('')
      setCompany('')
      setGetvaluesoptions('')
      handleCloseDrawer()
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    let users = Cookies.get("user");
    if (!users) {
      window.location.assign('https://psvsystem.com/');
    } else {
      let data = JSON.parse(users)
      if (data.token) {
        setAuthToken(data.token, setCheckConditions)

      }
    }
    onloadDate()
  }, [])
  return (
    <div>
      {contextHolder}
      <Dialog fullScreen open={props.open_journal_entries} onClose={props.handleClose} TransitionComponent={Transition}>

        <div style={{ width: '100%', position: 'fixed', }}>
          <Add_customize_department
            open={showboxdepartment}
            handleClosebox={handleClosebox}



          />

          <Create_user
            openDrawer={openDrawer}
            handleCloseDrawer={handleCloseDrawer}
            displayname={displayname}
            setDisplayname={setDisplayname}
            title={title}
            setTitle={setTitle}
            first_name={first_name}
            setFirst_name={setFirst_name}
            last_name={last_name}
            setLast_name={setLast_name}
            uploading={uploading}
            onSelectFile={onSelectFile}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            active={active}
            setActive={setActive}
            fileRemove={fileRemove}
            email={email}
            setEmail={setEmail}
            phonenumber={phonenumber}
            setPhonenumber={setPhonenumber}
            mobilenumber={mobilenumber}
            setMobilenumber={setMobilenumber}
            village={village}
            setVillage={setVillage}
            district={district}
            setDistrict={setDistrict}
            province={province}
            setProvince={setProvince}
            notes={notes}
            setNotes={setNotes}
            attachments={attachments}
            setAttachments={setAttachments}
            payment={payment}
            setPayment={setPayment}
            terms={terms}
            setTerms={setTerms}
            registertaxs={registertaxs}
            setRegistertaxs={setRegistertaxs}
            opening={opening}
            setOpening={setOpening}
            openingdate={openingdate}
            setOpeningdate={setOpeningdate}
            id={id}
            setID={setID}
            options_name={options_name}
            setOptions_name={setOptions_name}
            birthday={birthday}
            setBirthday={setBirthday}
            gender={gender}
            setGender={setGender}
            account_no={account_no}
            setAccount_no={setAccount_no}
            default_account={default_account}
            setDefault_account={setDefault_account}
            social_insurance_no={social_insurance_no}
            setSocial_insurance_no={setSocial_insurance_no}
            add_currency={add_currency}
            setAdd_Currency={setAdd_Currency}
            employee_ID_number={employee_ID_number}
            setEmployee_ID_number={setEmployee_ID_number}
            employee_id={employee_id}
            setEmployee_id={setEmployee_id}
            customer_type={customer_type}
            setCustomer_type={setCustomer_type}
            getvaluesoptions={getvaluesoptions}
            setGetvaluesoptions={setGetvaluesoptions}
            conditions={conditions}
            setConditions={setConditions}
            options={options}
            Oncreate_user={Oncreate_user}
            getindex={getindex}
            data={data}
            company={company}
            setCompany={setCompany}
            other={other}
            setOther={setOther}
            getcurrency={getcurrency}
            setGetcurrency={setGetcurrency}
            setCurrencies_name={setCurrencies_name}
            account_number={account_number}
            setAccount_numner={setAccount_numner}
          />
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            backgroundColor: "#f8f9fa",
            paddingTop: 20,
            paddingBottom: 20
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <RestoreIcon style={{ marginLeft: 10, marginTop: 4 }} />
              {
                count_number.length === 0 ? (<>
                  <span style={{ fontSize: 20, fontWeight: 'bold' }}>Journal EntryRecent no {journalNo}</span>
                </>) : (<>
                  <span style={{ fontSize: 20, fontWeight: 'bold' }}>Journal EntryRecent no {count_number[0].COUNTNUMER}</span>
                </>)
              }


            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <div style={{ marginRight: 10 }}>
                <SettingsIcon />
              </div>
              <div style={{ marginRight: 10 }}>
                <HelpOutlineIcon />
              </div>
              <div onClick={() => { ClaseJournal_entries() }} style={{ cursor: 'pointer' }}>
                <CloseIcon style={{ marginRight: 10 }} />
              </div>
            </div>
          </div>

        </div>

        <div style={{ paddingTop: 100, marginLeft: 10 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: "100%",
          }}>
            {/* {JSON.stringify(data)} */}
            {/* {listimage} */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <small style={{ fontSize: 15, marginBottom: 5 }}>Currency</small>
              <select
                style={{
                  border: '1px solid #ccc',
                  height: 30,
                  borderRadius: 3,
                  width: 235,
                  paddingLeft: 10,
                  outline: 'none'
                }}
                onChange={(e) => {
                  OnloadSelectCurrencies(e.target.value);
                }}
                value={currency}
              >
                <option>Please Choose Currency</option>
                {listcurrency &&
                  listcurrency?.map((data, index) => {
                    return (
                      <option key={index} value={data?.CURRENCY_UID}>{data?.CURRENCY_NAME} - {data?.CURRENCY_CODE}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div style={{ paddingTop: 20, }}>
              {
                currency_id ? (<>
                  {
                    currency_id === true ? (<>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <div style={{ marginTop: 5, paddingLeft: 10 }}>1 {currency_id}</div>
                        <div style={{ marginLeft: 5 }}>
                          {
                            currency_id === true ? (
                              <>
                                {
                                  checked_logo !== 'LAK' ? (<>
                                    <img alt="Logo" src={listimage} style={{ width: 40, height: 20, marginTop: 5 }} /> {select_currency}
                                  </>) : (<>
                                    <img alt="Logo" src={listimage} style={{ width: 40, height: 20, marginTop: 5 }} /> {select_currency}
                                  </>)
                                }
                              </>
                            ) : null
                          }
                        </div>
                        <div style={{ marginTop: 5 }}>=</div>
                        <input
                          value={exchage_rate}
                          style={{
                            border: '1px solid #ccc',
                            height: 30,
                            borderRadius: 3,
                            width: 150,
                            paddingLeft: 10,
                            outline: 'none',
                            textAlign: "right"
                          }}
                          onChange={(e) => onChangeTextCurrency(e.target.value)}


                          onBlur={(e) => onBlurCurrency(e.target.value, agconvertdebit, agconvertcredit)}
                        />
                        <div style={{ marginLeft: 5 }}>
                          <img alt="Logo" src="/assets/images/laos.png" style={{ width: 40, height: 20, marginTop: 5 }} />
                        </div>
                        <div style={{ marginTop: 5, paddingLeft: 10 }}>LAK</div>
                      </div>
                    </>) : null
                  }
                </>) : null
              }

              <div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
          <div style={{ width: '15%', marginLeft: 10, display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <small style={{ fontSize: 15, marginBottom: 5 }}>Journal date</small>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 10,
                position: 'relative',
                width: '100%'
              }}>
                <input
                  type="text"
                  value={defaultValue}
                  onChange={(e) => { setDefaultValue(e.target.value) }}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: 4,
                    fontSize: 16,
                    outline: 'none',
                    fontSize: 16,
                    height: 30,
                    marginLeft: 2,
                    width: '100%',
                  }}
                />
                <input
                  type="date"
                  onChange={(e) => { _searchStartdate(e.target.value) }}
                  style={{
                    height: 20,
                    borderRadius: 3,
                    width: 25,
                    position: 'absolute',
                    right: 10,
                    outline: 'none',
                    border: "none",
                    top: 6,
                    cursor: 'pointer',
                    paddingLeft: 10,
                  }}
                />

              </div>
            </div>


          </div>
          {
            warring === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Transactions can have only one foreign currency at a time</small>
              </div>

            </>) : warring_exhange_rate === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Please Inter Exchange Rate</small>
              </div>

            </>) : warring_select_currency === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Please Choose Currency</small>
              </div>
            </>) : warring_debit_credit === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Please check balance debits and credits</small>
              </div>
            </>) : checkedallTrue === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>You must choose a supplier or customer in the Name filed,Tranactons Type filed</small>
              </div>

            </>) : checkedconditions === true ? (<>
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Please must choose a supplier or customer for currency the same accounting currency</small>
              </div>

            </>) : allNotEmpty === true ? (
              <div style={{ width: '50%', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 20, marginLeft: 10, color: 'red', fontWeight: 'bold' }}>
                  Something's not quite right
                </small>
                <small style={{ marginLeft: 10 }}>Please must choose Department</small>
              </div>
            ) : null
          }
          <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <small style={{ marginBottom: 5, fontSize: 15 }}>Journal no</small>
              <input
                style={{
                  border: '1px solid #ccc',
                  height: 30,
                  borderRadius: 3,
                  width: 400,
                  paddingLeft: 10,
                  outline: 'none'
                }}
                onChange={(e) => {
                  setJournalNo(e.target.value);
                }}
                value={journalNo}
              />
            </div>
          </div>
        </div>
        <div style={{ height: 15, width: '100%' }}></div>
        <TableContainer className={classes.tableContainer} >
          <Table aria-label="sticky table" size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.stickyHeader} style={{ width: 50, fontWeight: 'bold' }}>#</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 50, fontWeight: 'bold' }} align="left">No</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Account</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Debit</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Credit</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Desription</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Department</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 250, fontWeight: 'bold' }} align="right">Name</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 200, fontWeight: 'bold' }} align="right">Transactions Type</TableCell>
                <TableCell className={classes.stickyHeader} style={{ width: 100, fontWeight: 'bold' }} align="right">Action</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                data && data?.map((item, index) => {
                  return (
                    <RowComponent
                      listallaccountchildren={listallaccountchildren}
                      listchart_of_account={listchart_of_account}
                      item={item}
                      key={index}
                      index={index}
                      data={data}
                      setData={setData}
                      setCurrency_id={setCurrency_id}
                      setArray={setArray}
                      array={array}
                      company_uid={company_uid}
                      currency={currency}
                      thb={thb}
                      usd={usd}
                      setCurrency={setCurrency}
                      addLines={addLines}
                      changeText={changeText}
                      deletechange={deletechange}
                      copyData={copyData}
                      paste={paste}
                      showcp={showcp}
                      blurHandler={blurHandler}
                      currency_id={currency_id}
                      agconvertdebit={agconvertdebit}
                      agconvertcredit={agconvertcredit}
                      setActive={setActive}
                      active={active}
                      settax={settax}
                      handleShow={handleShow}
                      sumData={sumData}
                      setNetTotalDebit={setNetTotalDebit}
                      setNetTotalCrebit={setNetTotalCrebit}
                      handleShowbox={handleShowbox}

                      showDrawer={handleOpenDrawer}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      handleClose={handleClose}

                      showDrawerTax={handleOpenDrawerTaxs}
                      anchorElTax={anchorElTax}
                      setAnchorElTax={setAnchorElTax}

                      handleCloseTaxs={handleCloseTaxs}

                      anchorElTax_dep={anchorElTax_dep}
                      setAnchorElTax_dep={setAnchorElTax_dep}
                      handleClick_OptionsDepartment={handleClick_OptionsDepartment}
                      handleCloseDepartment={handleCloseDepartment}
                      handleOpenDrawerDepartment={handleOpenDrawerDepartment}
                      handleClick_Options={handleClick_Options}
                      handleClick_OptionsTax={handleClick_OptionsTax}

                      getindex={getindex}
                      listuser={listuser}
                      select_options={select_options}
                      select_options_taxs={select_options_taxs}
                      select_options_department={select_options_department}
                      listtransactions_type={listtransactions_type}
                      listdepartment={listdepartment}
                      transactions_type={transactions_type}
                      setTransactions_type={setTransactions_type}
                      show={show}
                      setListimage={setListimage}
                      exchage_rate={exchage_rate}
                      setExchage_rate={setExchage_rate}
                      listcurrency={listcurrency}
                      setSelect_currency={setSelect_currency}

                      setChecked={setChecked}
                      setWarring={setWarring}
                      setChecked_logo={setChecked_logo}
                    />)
                })
              }

            </TableBody>
            <TableRow>
              <TableCell colSpan={2}>Total</TableCell>
              <TableCell ></TableCell>
              <TableCell align="right">{debit.replaceAll('$', '')}</TableCell>
              <TableCell align="right">{credit.replaceAll('$', '')}</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>

            </TableRow>
            {conditions_curren !== 'LAK' ? (
              <TableRow>
                <TableCell colSpan={2}>TotalLAK</TableCell>
                <TableCell ></TableCell>
                {
                  netTotalDebit.replaceAll('$', '') === 'NaN' ? (
                    <>
                      <TableCell align="right">0.00</TableCell>
                    </>) : (<>

                      <TableCell align="right">{netTotalDebit.replaceAll('$', '')}</TableCell>

                    </>)
                }
                {
                  netTotalCrebit.replaceAll('$', '') === 'NaN' ? (
                    <>
                      <TableCell align="right">0.00</TableCell>
                    </>) : (<>

                      <TableCell align="right">{netTotalCrebit.replaceAll('$', '')}</TableCell>

                    </>)
                }
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>

              </TableRow>
            ) : null
            }

          </Table>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <button style={{
              border: '1px solid #ccc',
              borderRadius: 3,
              paddingLeft: 20, paddingRight: 20,
              backgroundColor: 'white'
            }} onClick={addMore}>Add lines</button>
            <button style={{
              border: '1px solid #ccc',
              borderRadius: 3,
              paddingLeft: 20, paddingRight: 20,
              marginLeft: 20, backgroundColor: 'white'
            }} onClick={resetlines}>Clear lines </button>
            <button style={{
              border: '1px solid #ccc',
              borderRadius: 3,
              paddingLeft: 20, paddingRight: 20,
              marginLeft: 20, backgroundColor: 'white'
            }} onClick={ClearAllLines}>Clear All </button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
            <small>Memo</small>
            <textarea style={{ width: 350, height: 50, outline: 'none', border: '1px solid #ccc' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, marginTop: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <label style={{ display: 'flex', flexDirection: 'row' }}>
                <AttachFileIcon />
                <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                <input
                  type="file"
                  name="images"
                  onChange={onSelectFile}
                  multiple
                  style={{ display: "none" }}
                />

              </label>
              <div style={{ marginLeft: 20 }}>
                <small>Maximum size:20MB</small>
              </div>
              {
                uploading ? (<>
                  <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                    <Spin size="smal" />
                  </Flex>

                </>) : null
              }
            </div>
            <div style={{ border: '1px solid #ccc', width: '50%', height: 150, textAlign: "right", marginTop: 5, marginBottom: 30 }}>
              {
                uploading ? (<>
                  <LinearProgress />
                </>) : null
              }
              {selectedImages &&
                selectedImages.map((data, index) => {
                  let sizes = parseFloat(data?.size / 1024).toFixed(2)
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>
                      <div style={{ backgroundColor: "red", borderRadius: '50%', color: active == sizes ? '#ccc' : 'white', cursor: 'pointer', marginRight: 10 }}
                        onMouseOver={() => setActive(sizes)}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { fileRemove(data) }}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  );
                })}

            </div>
          </div>
        </TableContainer>

        <div style={{
          display: 'block',
          padding: '20px',
          height: '60px',
          width: '100%',
        }}>
          <div style={{
            backgroundColor: "#3f51b5",
            borderTop: "1px solid #E7E7E7",
            textAlign: "center",
            padding: "20px",
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "60px",
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between"
          }}>
            <div>
              <div style={{
                border: '1px solid #ccc',
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginLeft: 20,
                height: 30,
                color: "white",
                cursor: "pointer",
                paddingTop: 5
              }}
              >Cancel</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button
                onClick={() => { createdata('save') }}
                onMouseOver={() => setOnFocused(true)}
                onMouseLeave={() => setOnFocused(false)}
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  padding: '10px 30px 10px 30px',
                  opacity: onFucused ? 0.9 : 2,
                  height: 35,
                  color: "black",
                  borderRadius: 3,
                  marginRight: 10
                }} >
                {!isLoading ? (
                  <>
                    Save
                  </>
                ) : (
                  <>
                    {
                      <Spinner animation="border" variant="primary" size='sm' />
                    }
                  </>)
                }
              </button>
              <button
                onClick={() => { createdata('save&new') }}
                onMouseOver={() => setOnFucusednew(true)}
                onMouseLeave={() => setOnFucusednew(false)}
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  padding: '10px 30px 10px 30px',
                  opacity: onFucusednew ? 0.9 : 2,
                  height: 35,
                  color: "black",
                  borderRadius: 3,
                }} >
                {!isLoading_new ? (
                  <>
                    Save and new
                  </>
                ) : (
                  <>
                    {
                      <Spinner animation="border" variant="primary" size='sm' />
                    }
                  </>)
                }
              </button>

            </div>
          </div >

        </div>
      </Dialog>
    </div>
  );
}
function RowComponent({ item, listchart_of_account, index, copyData, paste, showcp,
  data, blurHandler, changeText, deletechange, addLines, setCurrency_id, usd, thb, agconvertdebit, agconvertcredit, settax, setActive, active, sumData, setNetTotalCrebit, setNetTotalDebit, company_uid, showDrawer, anchorEl, handleClose, handleClick_Options, handleClick_OptionsTax, getindex, listuser, select_options,
  anchorElTax,
  anchorElTax_dep,

  handleClick_OptionsDepartment,
  handleCloseDepartment,
  select_options_taxs,
  select_options_department,
  handleCloseTaxs,
  listtransactions_type,
  transactions_type,
  setTransactions_type,
  listdepartment,
  show = { show },
  handleShow = { handleShow },
  setListimage,
  exchage_rate,
  listcurrency,
  setSelect_currency,
  setChecked,
  setWarring,
  setChecked_logo,
  handleShowbox

}) {
  const [searchResult, setSearchResult] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const handleClick = () => {
    setActive(null)
    setShowBox(!showBox);
  };
  const onBlurClose = (active) => {
    if (active == null) {
      setShowBox(false);
    }
  }
  const handleClickFalse = () => {
    setShowBox(false)
  }
  const getNameList = (c_id, CURRENCY_UID) => {
    setWarring(false)
    let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(CURRENCY_UID))
    if (filter[0].CURRENCY_NAME !== 'LAK') {
      setListimage(filter[0].attachments[0])
      setSelect_currency(filter[0].CURRENCY_NAME)
    }
    axios.get(`/accounting/api/chartAccount/bank/listchart_of_account/${c_id}`).then((respone) => {
      if (respone?.data?.result.length > 0) {
        const chart_name = respone?.data?.result.map((item) => {
          return item.CHART_NAME_ENG;
        });
        chart_name.reverse();
        if ((index % 2 != 0) && index > 0 && data[index - 1]?.DEBIT) {
          data[index]['CREDIT'] = data[index - 1]?.DEBIT;
          const credit = sumData('CREDIT')
          let exchange = exchage_rate.replaceAll(',', '')
          let number = parseFloat(exchange) * parseFloat(credit)
          let creditnumber = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
          let convertcredit = creditnumber.replaceAll('$', '')
          setNetTotalCrebit(convertcredit)
        } else if ((index % 2 != 0) && index > 0) {
          data[index]['DEBIT'] = data[index - 1]?.CREDIT
          const debit = sumData('DEBIT')
          let exchange = exchage_rate.replaceAll(',', '')
          let number = parseFloat(exchange) * parseFloat(debit)
          let creditnumber = Intl.NumberFormat("en-US", { style: 'currency', currency: 'USD' }).format(number)
          let convertdebit = creditnumber.replaceAll('$', '')
          setNetTotalDebit(convertdebit)
        }
        data[index]["CHART_ID"] = respone?.data?.result[0].CHART_ID
        data[index]["CURRENCY_CODE"] = respone?.data?.result[0].CHART_CURRENCY_CODE
        data[index]["NAME_ENG"] = respone?.data?.result[0].CHART_NAME_ENG
        data[index]['NAME_ENG_CODITIONS'] = chart_name.join(":")
        data[index]["AC_SYMBOL"] = respone?.data?.result[0].ACCOUNT_TYPE_AC_SYMBOL
        data[index]["ACCOUNT_CREATESTATUS"] = respone?.data?.result[0].ACCOUNT_CREATESTATUS
        data[index]['CHART_PARENTS'] = respone?.data?.result[0].CHART_PARENTS
        data[index]['BS_STATUS'] = 0
        changeText(chart_name.join(":"), "NAME_ENG");
        const uniqueEntries = Object.values(
          data.reduce((acc, entry) => {
            if (entry.CURRENCY_CODE && !acc[entry.CURRENCY_CODE]) {
              acc[entry.CURRENCY_CODE] = entry;
            }
            return acc;
          }, {})
        );
        const usdData = uniqueEntries.filter(item => item.CURRENCY_CODE !== 'LAK');
        if (usdData.length === 0) {

          setCurrency_id(false)
          setChecked('LAK')
        } else {
          setChecked(usdData[0].CURRENCY_CODE)
          setChecked_logo(usdData[0].CURRENCY_CODE)
          setCurrency_id(true)
        }
        setShowBox(!showBox);
        if (usdData.length > 1) {
          setWarring(true)
          return;
        }
      }
    });

  };
  const filterdata_sub = async (e, index) => {
    data[index]['NAME_ENG_CODITIONS'] = e
    if (!e) {
      setSearchResult([]);
    } else {
      try {
        axios.get(`/accounting/api/chartAccount/bank/filterlistjournal/${e}/${company_uid}`).then((data) => {
          let list = [...data?.data?.result];
          const generateTreeSecond = (items) => {
            const buildTree = (items, parentId) => {
              const currentNode = items.find(item => item.CHART_ID === parentId);
              if (!currentNode) {
                return null; // Return null for non-existent parent (or handle accordingly)
              }
              const node = {
                CHART_ID: currentNode.CHART_ID,
                CHART_NAME_ENG: currentNode.CHART_NAME_ENG,
                CHART_PARENTS: parentId === 0 ? null : parentId, // Set to null for root nodes
                children: [],
                CURRENCY_UID: currentNode.CURRENCY_UID
              };
              const children = items.filter(item => item.CHART_PARENTS === parentId);
              children.forEach(child => {
                const childNode = buildTree(items, child.CHART_ID);
                if (childNode) {
                  node.children.push(childNode);
                }
              });
              return node;
            };
            const roots = items.filter(item => !items.some(other => other.CHART_ID === item.CHART_PARENTS));
            const result = roots.map(root => buildTree(items, root.CHART_ID));
            return result;
          };
          // const results = generateTreeSecond(list);
          setSearchResult(generateTreeSecond(list));
        }).catch((err) => {
          console.log(err)
        })
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (

    <TableRow >
      <TableCell align="left" style={{ width: 100 }}>
        <AddCircleOutlineIcon />
      </TableCell>
      <TableCell align="left" style={{ width: 100 }}>
        {index + 1}
      </TableCell>
      <TableCell align="left" >
        <input
          value={item?.NAME_ENG_CODITIONS}
          onChange={(e) => { filterdata_sub(e.target.value, index) }}
          onClick={() => handleClick(true)}
          onBlur={() => onBlurClose(active)}
          style={{
            border: '1px solid #ccc',
            outline: 'none',
            paddingLeft: 10,
            borderRadius: 3,
            height: 35
          }}
        />

        {showBox && (
          <div
            style={{
              overflowY: "scroll",
              height: 200,
              paddingTop: 5,
              paddingLeft: 10,
              position: 'absolute',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {searchResult.length > 0 ? (
              <>
                {searchResult?.map((data, index1) => {
                  return (
                    <>
                      <span
                        key={index1}
                        style={{
                          cursor: "pointer",
                          fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                        }}
                        onClick={() => { getNameList(data?.CHART_ID, data?.CURRENCY_UID) }}
                        onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                        onMouseLeave={() => setActive(null)}
                      >
                        {data?.CHART_NAME_ENG}
                      </span>
                      <ComponentList
                        children={data?.children}
                        active={active}
                        level={30}
                        getNameList1={getNameList}
                        setActive={setActive}
                      />
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {listchart_of_account && listchart_of_account?.map((data, index1) => {

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }} key={index1}>
                      <span
                        style={{
                          cursor: "pointer",
                          fontWeight: active === data?.CHART_NAME_ENG ? "bold" : "",
                        }}
                        onClick={() => {
                          getNameList(data?.CHART_ID, data?.CURRENCY_UID
                          )
                        }}
                        onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
                        onMouseLeave={() => setActive(null)}
                      >
                        {data?.CHART_NAME_ENG}

                      </span>
                      <ComponentList
                        children={data?.children}
                        active={active}
                        level={30}
                        getNameList1={getNameList}
                        setActive={setActive}

                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}

      </TableCell>
      <TableCell align="right" >
        <input
          value={item?.DEBIT}
          onBlur={(e) => blurHandler(e.target.value, "DEBIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
          onChange={(e) => changeText(e.target.value, "DEBIT", index)}
          disabled={data[index]?.CREDIT ? true : false}
          style={{
            border: '1px solid #ccc',
            outline: 'none',
            paddingLeft: 10,
            borderRadius: 3,
            height: 35,
            textAlign: "right"
          }}
        />
      </TableCell>
      <TableCell align="right" >
        <input
          value={item?.CREDIT}
          onBlur={(e) => blurHandler(e.target.value, "CREDIT", index, exchage_rate, agconvertdebit, agconvertcredit)}
          onChange={(e) => changeText(e.target.value, "CREDIT", index)}
          disabled={data[index]?.DEBIT ? true : false}
          style={{
            border: '1px solid #ccc',
            outline: 'none',
            paddingLeft: 10,
            borderRadius: 3,
            height: 35,
            textAlign: "right"
          }}
        />
      </TableCell>
      <TableCell align="right" >
        <input
          value={item?.DESCRIPTIONS}
          onClick={() => handleClickFalse()}
          onChange={(e) => changeText(e.target.value, "DESCRIPTIONS", index)}
          style={{
            border: '1px solid #ccc',
            outline: 'none',
            paddingLeft: 10,
            borderRadius: 3,
            height: 35
          }}
        />
      </TableCell>
      <TableCell align="right" >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <input
            value={item?.DEPARTMENT_NAME}
            onChange={(e) => changeText(e.target.value, "DEPARTMENT_NAME", index)}
            style={{
              border: '1px solid #ccc',
              outline: 'none',
              paddingLeft: 10,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              borderRight: 'none',
              height: 35
            }}
          />
          <div style={{
            border: '1px solid #ccc',
            outline: 'none',
            height: 35,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            backgroundColor: '#f8f9fa',
            cursor: 'pointer',
          }}
          >

            <Options_department
              handleCloseDepartment={handleCloseDepartment}
              anchorElTax_dep={anchorElTax_dep}
              getindex={getindex}
              listdepartment={listdepartment}
              select_options_department={select_options_department}
              data={data}
              show={show}
              handleShowbox={handleShowbox}

            />
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => { handleClick_OptionsDepartment(event, index) }}

            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
      </TableCell>
      <TableCell align="right" >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <input
            value={item?.EMPLOYEE}
            onChange={(e) => changeText(e.target.value, "EMPLOYEE", index)}
            style={{
              border: '1px solid #ccc',
              outline: 'none',
              paddingLeft: 10,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              borderRight: 'none',
              height: 35
            }}
          />
          <div style={{
            border: '1px solid #ccc',
            outline: 'none',
            height: 35,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            backgroundColor: '#f8f9fa',
            cursor: 'pointer',
          }}
          >

            <Option_select
              handleClose={handleClose}
              showDrawer={showDrawer}
              anchorEl={anchorEl}
              getindex={getindex}
              listuser={listuser}
              select_options={select_options}
              data={data}
            />
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => { handleClick_Options(event, index) }}

            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
      </TableCell>
      <TableCell align="right" >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <input
            value={item?.TAX}
            onChange={(e) => changeText(e.target.value, "TAX", index)}
            style={{
              border: '1px solid #ccc',
              outline: 'none',
              paddingLeft: 10,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              borderRight: 'none',
              height: 35
            }}
          />
          <div style={{
            border: '1px solid #ccc',
            outline: 'none',
            height: 35,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            backgroundColor: '#f8f9fa',
            cursor: 'pointer',
          }}

          >

            <Options_taxs
              handleCloseTaxs={handleCloseTaxs}
              anchorElTax={anchorElTax}
              getindex={getindex}
              listtransactions_type={listtransactions_type}
              select_options_taxs={select_options_taxs}
              data={data}
              transactions_type={transactions_type}
              setTransactions_type={setTransactions_type}
              show={show}
              handleShow={handleShow}

            />
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => { handleClick_OptionsTax(event, index) }}

            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>

      </TableCell>
      <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <DeleteIcon style={{ height: 35, color: "green", cursor: 'pointer' }} onClick={() => { deletechange() }} />
      </TableCell>
    </TableRow>

  );
}
function ComponentList({ children, level, getNameList1, setActive, active }) {

  if (children?.length === 0) return <></>;
  return (
    <>
      {children?.map((data, index) => {
        return (
          <>
            <span key={index}
              style={{
                paddingLeft: level,
                cursor: "pointer",
                fontWeight:
                  active === data?.CHART_NAME_ENG ? "bold" : "",
              }}
              onClick={() => { getNameList1(data?.CHART_ID, data?.CURRENCY_UID) }}
              onMouseOver={() => setActive(data?.CHART_NAME_ENG)}
              onMouseLeave={() => setActive(null)}
            >{data?.CHART_NAME_ENG}</span>
            <ComponentList
              children={data?.children}
              level={level * 2 - 20}
              getNameList1={getNameList1}
              setActive={setActive}
              active={active}

            />
          </>
        );
      })}
    </>
  );
}