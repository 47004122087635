import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";

import { LoginContext } from "./contexts/LoginContext";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import DataTable from "react-data-table-component";
import { Detail_type } from "../modal/Detail_Type";
import axios from "axios";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function DetailType() {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false)
    let [loading, setLoading] = useState(false);
    const [successffuly, setsuccessffuly] = useState(false);
    const [accountuid, setAccountuid] = useState('')
    const [name_eng, setName_eng] = useState("");
    const [uid, setUid] = useState("");
    const [main_type_name, setMain_type_name] = useState("");
    const [showUpdate, setShowUpdate] = useState(false);
    const [disblebtn, setDisblebtn] = useState(true);
    const [titlecashflow, setTitlecashflow] = useState('')
    const [parent, setParent] = useState(0)
    const [dettailType, setDettailType] = useState('')
    const [listdetailType, setListdetailType] = useState([])
    const [accountType, setAccountType] = useState('')

    const { listtitle, listaccount } =
        useContext(LoginContext);
    const [records, setRecords] = useState(listtitle)


    const handleClose = () => {
        setShow(false);
        setTitlecashflow('')
        setAccountType('')
        setDettailType('')



    };
    const handleClosedel = () => {
        setShows(false);
        setMain_type_name('');
        setUid('');
    }
    const handleChange = event => {
        if (event.target.checked) {
            setDisblebtn(false);
            setParent(1)
        } else {
            setDisblebtn(true);
            setParent(0)
        }
    };

    const CreateNewDetailType = () => {
        let data = {
            ACCOUNT_TYPE_UID: accountType,
            DETAIL_TYPE_NAME_ENG: dettailType,
            CASHFLOWS_ID: titlecashflow
        }
        axios.post("/accounting/api/detailType/Insert", data).then((data) => {

            OnloadDetailType()
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleShow = () => {
        setShow(true)
        setTitlecashflow('')
        setAccountType('')
        setDettailType('')
    };
    const OnDelete=(id)=>{
        console.log("id=",id)
        let data={
            DETAIL_TYPE_ID:id
        }
        console.log("DETAIL_TYPE_ID=",data)
        axios.post('/accounting/api/detailType/Deleted',data).then((data)=>{
            OnloadDetailType()

        }).catch((err)=>{
            console.log(err)
        })

    }
    const columns = [
        {
            name: "Name Detail Type",
            selector: row => row.DETAIL_TYPE_NAME_ENG,
            sortable: true
        },

        {
            name: "Accounts Type",
            selector: row => row.ACCOUNT_TYPE_NAME_ENG,
            sortable: true
        },
        {
            name: "Title Cash Flows",
            selector: row => row.CASHFLOWS_TITLE_ENG,
            sortable: true
        },
        {
            name: "Last Date",
            selector: row => moment(row.CREATE_DATE_TIME).format('YYYY-MM-DD'),
            sortable: true,
            
        },
        {
            name: 'actions',
            cell: row => (
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <small style={{cursor:'pointer'}}
                    onClick={()=>{OnDelete(row.DETAIL_TYPE_ID)}}
                    >Deleted</small>
                </div>
            )
        },
    ]
    const OnloadDetailType = () => {
        axios.get('/accounting/api/detailType').then((data) => {
            console.log("result=", data)
            setListdetailType([...data?.data?.result])
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadDetailType()
    }, [])
    return (
        <div style={{ width: "100%" }}>
            <Detail_type
                show={show}
                handleClose={handleClose}
                handleChange={handleChange}
                disblebtn={disblebtn}
                dettailType={dettailType}
                setDettailType={setDettailType}
                accountType={accountType}
                setAccountType={setAccountType}
                CreateNewDetailType={CreateNewDetailType}
                titlecashflow={titlecashflow}
                setTitlecashflow={setTitlecashflow}
                listaccount={listaccount}
                listtitle={listtitle}

            />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginTop: 10,
                }}
            >

                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginTop: 2, marginLeft: 5 }}>Detail Type</small>
                <div style={{
                    borderRadius: 2,
                    width: '17%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginLeft: 10
                }}
                    onClick={() => {
                        handleShow();

                    }}
                >
                    <AddIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold' }}>Add New Title Cash Flows</small>
                </div>
            </div>
            <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
            <div style={{ paddingTop: 20, width: '100%' }}>
                <DataTable
                    columns={columns}
                    data={listdetailType}
                    selectableRows
                    fixedHeader
                    pagination
                />
            </div>
        </div>
    );
}
