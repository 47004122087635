import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from "@material-ui/icons/Settings";
import { LoginContext } from "../page/contexts/LoginContext";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Flex, Spin, Skeleton } from 'antd';
import axios from "axios";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function Cashflows() {
    const classes = useStyles();
    const [ispageloading, setIspageloading] = useState(false)
    const [isloadsearch, setIsloadsearch] = useState(false)
    const [getvalues, setGetvalues] = useState('')
    const [defaultValue, setDefaultValue] = useState("")
    const [defaultValue1, setDefaultValue1] = useState("")
    const [listcashflows,setListcashflows]=useState({})
    const { setStart_date, setEnd_date ,company_uid} = useContext(LoginContext);
    const Today = moment(new Date()).format("DD-MM-YYYY")
    const _onShow = (e) => {
        setGetvalues(e)
    }
    const _searchstartdate = (e) => {
        setDefaultValue(e)
        setStart_date(e)
        if (defaultValue === "") {
            setDefaultValue(Today)
        } else {
            setDefaultValue(moment(e).format("DD-MM-YYYY"))
        }

    }
    const _searchEnddate = (e) => {
        setDefaultValue1(e)
        setEnd_date(e)
        if (defaultValue1 === "") {
            setDefaultValue1(Today)
        } else {
            setDefaultValue1(moment(e).format("DD-MM-YYYY"))
        }
    }
    const Search = () => {
    }

    // const processCashFlowsData = (data) => {
    //     const idMapping = data.reduce((acc, el, i) => {
    //         acc[el.CASHFLOWS_ID] = i;
    //         return acc;
    //     }, {});

    //     let root = [];

    //     data.forEach(el => {
    //         if (el.PARENTS === 0) {
    //             root.push(el);
    //         } else {
    //             const parentEl = data[idMapping[el.PARENTS]];
    //             parentEl.children = [...(parentEl.children || []), el];
    //         }
    //     });

    //     return root;
    // };
    const processCashFlowsData = (data) => {
        // Create a mapping from CASHFLOWS_ID to array index for quick lookup
        const idMapping = data.reduce((acc, el, i) => {
            acc[el.CASHFLOWS_ID] = i;
            return acc;
        }, {});
    
        // Initialize the root array
        let root = [];
    
        // Iterate through each element to build the tree structure
        data.forEach(el => {
            if (el.PARENTS === 0) {
                // If the element has no parent, add it to the root array
                root.push(el);
            } else {
                // If the element has a parent, add it to the parent's children array
                const parentEl = data[idMapping[el.PARENTS]];
                parentEl.children = [...(parentEl.children || []), el];
            }
        });
    
        // Add children: [{}] to elements without children
        data.forEach(el => {
            if (!el.children || el.children.length === 0) {
                el.children = [{}];
            }
        });
    
        return root;
    };

    let list_first_data = [
        { CASHFLOWS_ID: 5, CASHFLOWS_TITLE_ENG: 'Cash flows from operating activities', PARENTS: 0 },
        { CASHFLOWS_ID: 6, CASHFLOWS_TITLE_ENG: 'Adjustments for non-cash income and expenses', PARENTS: 5 },
        { CASHFLOWS_ID: 7, CASHFLOWS_TITLE_ENG: 'Cash flows from investing activities', PARENTS: 0 },
        { CASHFLOWS_ID: 8, CASHFLOWS_TITLE_ENG: 'Cash flows from financing activites', PARENTS: 0 }
    ]
    const result = processCashFlowsData(list_first_data);
   
    const Onload_cashflows=()=>{
        axios.get(`/accounting/api/cashflow/cashflows/${company_uid}`).then((response)=>{
            console.log("response=",response)
            setListcashflows(response)

        }).catch((err)=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        Onload_cashflows()

    },[])

    

    return (
        <div style={{ width: '100%' }}>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginLeft: 5 }}>Cash Flows</small>
            </div>
            {
                ispageloading == true ? (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>) : (isloadsearch == true) ? (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>) : (<>
                    <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
                </>)
            }
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginTop: 10,
                justifyContent: 'space-between'
            }}>
                <div
                    style={{
                        display: 'flex',
                        width: '90%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'

                    }}
                >
                    <select
                        onChange={(e) => _onShow(e.target.value)}
                        value={getvalues}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '20%',

                        }}
                    >
                        <option value="all"> This Year-to-date</option>
                        <option value="all">All Dates</option>
                        <option value="today">Today</option>
                        <option value="custom">Custom</option>
                    </select>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%',
                        marginLeft: 5

                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue}
                            onChange={(e) => setDefaultValue(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                height: 30,
                                width: '100%',

                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchstartdate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <span style={{ fontSize: 20, marginLeft: 5, marginRight: 0 }}>To</span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%'
                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue1}
                            onChange={(e) => setDefaultValue1(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                fontSize: 16,
                                height: 30,
                                width: '100%',
                                marginLeft: 2
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchEnddate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '8%'
                    }}
                        onClick={() => Search()}
                    >
                        <SearchIcon />
                        <small>Search</small>
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '8%'
                    }}
                    >
                        <RotateLeftIcon />
                        <small>Reset</small>
                    </div>

                    <div style={{
                        borderRadius: 2,
                        width: '9%',
                        backgroundColor: '#3f51b5',
                        height: 30,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        marginLeft: 5
                    }}
                    >
                        <RefreshIcon style={{ color: 'white' }} />
                        <small style={{ color: 'white', fontWeight: 'bold' }}>Refresh</small>
                    </div>
                    {
                        ispageloading == true ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>
                        </>) : null
                    }
                    {
                        isloadsearch == true ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>

                        </>) : null
                    }
                </div>
                <SettingsIcon
                    style={{
                        marginRight: 10,
                        cursor: 'pointer',
                        marginTop: 5
                    }}
                />
            </div>
            <div style={{ height: 30 }}>
            </div>
            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            result.map((data, index) => {
                                return (
                                    <>
                                        <GLRowComponent
                                            key={index}
                                            CASHFLOWS_ID={data?.CASHFLOWS_ID}
                                            CASHFLOWS_TITLE_ENG={data?.CASHFLOWS_TITLE_ENG}
                                            CHILDREN={data?.children}
                                            floor={listcashflows?.data?.floor}
                                            children={listcashflows?.data?.children}

                                        />
                                    </>
                                )
                            })
                        }
                    </TableBody>
                </Table>


            </TableContainer>

        </div>
    );
}
function GLRowComponent({ CASHFLOWS_ID, CASHFLOWS_TITLE_ENG, CHILDREN,floor,children }) {
    console.log("floor=",floor)


    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow >
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                    {CASHFLOWS_TITLE_ENG}
                </TableCell>
                {open ? (<>
                    <TableCell align="right"></TableCell>
                </>) : (
                    <>
                        <TableCell align="right" style={{ fontWeight: "bold" }}> ₭</TableCell>
                    </>)}
            </TableRow>
            {open ? (
                <>
                    < Componentchild
                        CASHFLOWS_ID={CASHFLOWS_ID}
                        size={30}
                        CHILDREN={CHILDREN}
                        floor={floor}
                        children={children}
                    />
                    <TableRow>
                        <TableCell component="th" scope="row" style={{paddingLeft:25}} >
                            {
                                CASHFLOWS_ID == 5 ? (<>
                                    Net cash from operating activites

                                </>) : CASHFLOWS_ID == 7 ? (<>
                                    Net cash used in investing

                                </>) : (<>
                                    Net cash used in financing activities
                                </>)
                            }

                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>10000₭</TableCell>
                    </TableRow>
                </>
            ) : null
            }
        </>
    )
}
function Componentchild({ CASHFLOWS_ID, CHILDREN, size,floor,children }) {
    const filter = CHILDREN.filter((el) => el.PARENTS == CASHFLOWS_ID);

    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((item, index) => {
                    return (
                        <>
                            < Component
                                item={item}
                                size={size}
                                index={index}
                                floor={floor}
                                children={children}
                           
                        
                            />
                        </>
                    )
                })
            }
        </>
    )
}
function Component({item, size,index, }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <TableRow key={index}>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ paddingLeft: size, cursor: "pointer" }} >{open ? <ExpandLess /> : <ExpandMore />}
                    {item?.CASHFLOWS_TITLE_ENG}
                </TableCell>
                {
                    open ? (<>
                        <TableCell align="right"></TableCell>
                    </>) : (<>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>0000₭</TableCell>
                    </>)
                }
            </TableRow>
            {/* {open ? (
                <>
                    <Componentsub
                        id={id}
                        childrenFirst={childrenFirst}
                        childrenSecond={childrenSecond}
                        subject_name={item?.BS_NAME}
                        NetTotal={NetTotal}




                    />
                </>
            ) : null

            } */}
        </>
    )
}


