import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getFormatNumber } from "../constants/functions";
import { LoginContext } from "../page/contexts/LoginContext";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import moment from 'moment';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
function createData(id, name_eng, balance,) {
    return { id, name_eng, balance };
}

const rows = [
    createData(10, 'Tax', 0.00,),
];
export default function Profitandloss() {
    let componentRef = useRef(null)
    const navigate = useNavigate();

    const {
        start_date, setStart_date, end_date, setEnd_date, setConditionsbydate, conditionsbydate,
        ex_conditions, setEx_conditions, setDetail_profitandloss, company_uid
    } = useContext(LoginContext);
    const [getvalues, setGetvalues] = useState('')
    const [defaultValue, setDefaultValue] = useState("")
    const [defaultValue1, setDefaultValue1] = useState("")
    const [pro_startDate, setPro_startDate] = useState("")
    const [pro_endDate, setPro_endDate] = useState("")
    const Today = moment(new Date()).format("DD-MM-YYYY")
    const classes = useStyles();
    const [headingprofi, setHeadingprofi] = useState({})
    const [incomeandcost, setIncomeandcost] = useState(0)
    const [expenses, setExpenses] = useState(0)
    const [listcondition, setListcondition] = useState([])
    const [showSetting, setShowSetting] = useState(false)
    const [loading, setLoading] = useState(false);
    const [gain, setGain] = useState(false);

    const Gotodetailaccount = (ACCOUNT_TYPE_UID, CHART_UID) => {
        navigate(`/DetailFitandLoss/${ACCOUNT_TYPE_UID}/${CHART_UID}`);
    }
    const onloadProfilandloss = () => {
        axios.get(`/accounting/api/profilandloss/${company_uid}`).then((data) => {
            setHeadingprofi({ ...data?.data })
            setLoading(true)
            setStart_date('')
            setEnd_date('')
        }).catch((err) => {
            console.log(err)
        })
    }
    const onloadProfilandloss_byDate = () => {
        let data = {
            start_date: moment(start_date).format('DD-MM-YYYY'),
            end_date: moment(end_date).format('DD-MM-YYYY'),
            company_uid: company_uid,
            for_year: moment(start_date).format('YYYY'),
            ex_conditions: ex_conditions
        }
        axios.post('/accounting/api/profilandloss/search', data).then((data) => {
            setHeadingprofi({ ...data?.data })
            setStart_date('')
            setEnd_date('')
            setConditionsbydate(false)
            setEx_conditions(false)
            setLoading(true)
        }).catch((err) => {
            console.log(err)
        })
    }
    const profilandloss_bySearch = () => {
        let data = {
            start_date: moment(pro_startDate).format('DD-MM-YYYY'),
            end_date: moment(pro_endDate).format('DD-MM-YYYY'),
            company_uid: 1,
            for_year: moment(pro_startDate).format('YYYY'),
            ex_conditions: ex_conditions
        }
        axios.post('/accounting/api/profilandloss/search', data).then((data) => {
            setHeadingprofi({ ...data?.data })
            setDetail_profitandloss(1)
        }).catch((err) => {
            console.log(err)
        })
    }
    const Setting = () => {
        setShowSetting(!showSetting)
    }
    const GoExchanage = (e) => {
        navigate(`/ExchangeRate/${e}`)
    }

    const _searchstartdate = (e) => {
        setDefaultValue(e)
        setPro_startDate(e)
        if (defaultValue === "") {
            setDefaultValue(Today)
        } else {
            setDefaultValue(moment(e).format("DD-MM-YYYY"))
        }

    }

    const _searchEnddate = (e) => {
        setDefaultValue1(e)
        setPro_endDate(e)
        if (defaultValue1 === "") {
            setDefaultValue1(Today)
        } else {
            setDefaultValue1(moment(e).format("DD-MM-YYYY"))
        }
    }
    const _onShow = (e) => {
        setGetvalues(e)
    }
    const ViewUnrealised = () => {
        navigate("/ViewUnrealisedgain_or_loss");
    }
    const OnGain = () => {
        setGain(!gain)
    }
    useEffect(() => {
        if (conditionsbydate == false) {
            onloadProfilandloss()
        } else {
            onloadProfilandloss_byDate()
        }
        _searchstartdate()
        _searchEnddate()

    }, [])
    return (
        <>
            <div style={{ width: '100%' }}>
                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowBackIcon style={{ color: '#3f51b5' }} />
                    </div>
                    <small style={{ fontSize: 20, marginLeft: 5 }}>Profit and Loss Report</small>
                </div>

                {
                    loading == true ? (<>

                        <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>

                    </>) : (<>
                        <LinearProgress style={{ marginTop: 10 }} />
                    </>)
                }
            </div>
            <div style={{
                display: 'flex',
                flexDirection: "row",
                width: "100%",
                justifyContent: 'space-between',
                marginTop: 10
            }} >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <select
                        onChange={(e) => _onShow(e.target.value)}
                        value={getvalues}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '50%',
                            paddingLeft: 0
                        }}
                    >
                        <option value="all">All Dates</option>
                        <option value="today">Today</option>
                        <option value="custom">Custom</option>
                    </select>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',



                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue}
                            onChange={(e) => setDefaultValue(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                fontSize: 16,
                                height: 30,
                                width: '100%',
                                marginLeft: 2
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchstartdate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />

                    </div>

                    <span style={{ marginLeft: 10, paddingTop: 5 }}>To</span>
                    {/* <input
                        type="text"
                        defaultValue={defaultValue1}
                        onChange={(e) => setDefaultValue1(e.target.value)}
                        style={{
                            border: '1px solid #ccc',
                            height: 30,
                            borderRadius: 3,
                            width: 100,
                            paddingLeft: 10,
                            marginLeft: 25,
                            textAlign: "right",
                            borderRight: "none",
                        }}
                    />
                    <input
                        type="date"
                        onChange={(e) => _searchEnddate(e.target.value)}
                        style={{
                            border: '1px solid #ccc',
                            height: 30,
                            borderRadius: 3,
                            width: 30,
                            paddingLeft: 10,
                        }}
                    /> */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '40%'
                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue1}
                            onChange={(e) => setDefaultValue1(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                fontSize: 16,
                                height: 30,
                                width: '100%',
                                marginLeft: 2
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchEnddate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <div>

                    </div>
                    {/* <Button variant="contained" color="primary"
                        style={{

                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            paddingLeft: 10,
                            paddingRight: 10,
                            color: "white",
                            alignItems: "center",
                            marginLeft: 10,
                        }}
                        onClick={() => { profilandloss_bySearch() }}
                    >
                        Run report
                    </Button> */}
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '20%'
                    }}
                        onClick={() => profilandloss_bySearch()}
                    >
                        <SearchIcon />
                        <small>Search</small>
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: "#3f51b5",
                        border: "none",
                        height: 30,
                        borderRadius: 2,
                        color: "white",
                        alignItems: "center",
                        justifyContent: 'center',
                        marginLeft: 5,
                        width: '20%'

                    }}>
                        <RotateLeftIcon />
                        <small>RESET</small>
                    </div>
                    {
                        loading ? (<>
                      

                        </>) : (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>
                        </>)
                    }
                </div>
                <div>
                    <button
                        onClick={() => { Setting() }}
                        // onBlur={() => { onBlurSetting() }}
                        style={{
                            backgroundColor: "#3f51b5",
                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            paddingLeft: 10,
                            paddingRight: 10,
                            color: "white",
                            alignItems: "center",
                            marginLeft: 10,
                        }}
                    >
                        <SettingsIcon style={{ cursor: 'pointer' }}
                        />
                    </button>
                    {showSetting ?
                        (
                            <>
                                <div style={{
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    right: 30,
                                    border: '1px solid #ccc',
                                    borderRadius: 3,
                                    width: 200,
                                    height: 150,
                                }}>
                                    <h5 style={{ marginTop: 20, marginLeft: 10 }}>Display density</h5>
                                    <div style={{ height: 10 }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}>
                                        <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
                                            <input type="checkbox"
                                                onClick={() => { OnGain() }}
                                            // onMouseLeave={() => { setLeave(null) }} 
                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Gain</small>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 2, marginRight: 5 }}>
                                            <input type="checkbox"
                                            // onClick={() => { Oncredit() }}
                                            // onMouseLeave={() => { setLeave(null) }}

                                            />
                                            <small style={{ marginLeft: 5, marginTop: 2 }}>Losses</small>
                                        </div>
                                    </div>


                                </div>
                            </>
                        ) : null
                    }
                </div>
            </div>
            {
                loading ? (
                    <>
                        <TableContainer component={Paper} ref={(el) => (componentRef = el)}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right">TOTAL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        headingprofi?.headerIncome
                                        && headingprofi?.headerIncome
                                            .map((data, index) => {
                                                return (
                                                    <>
                                                        <ComponentHeadingIn
                                                            data={data}
                                                            key={index}
                                                            childrenFirstFloor={headingprofi?.children_fist}
                                                            childrenSecondFloor={headingprofi?.children_second}
                                                            Gotodetailaccount={Gotodetailaccount}
                                                            setIncomeandcost={setIncomeandcost}
                                                            ViewUnrealised={ViewUnrealised}
                                                        />
                                                    </>
                                                )
                                            })
                                    }
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ cursor: 'pointer', paddingLeft: 30, fontWeight: 'bold' }}>
                                            GROSS PROFIT
                                        </TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(incomeandcost)}₭</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer component={Paper} ref={(el) => (componentRef = el)}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {
                                        headingprofi?.headerExpenses
                                        && headingprofi?.headerExpenses.map((data, index) => {
                                            return (
                                                <>
                                                    <ComponentHeading
                                                        data={data}
                                                        key={index}
                                                        childrenFirstFloor={headingprofi?.children_fist}
                                                        childrenSecondFloor={headingprofi?.children_second}
                                                        Gotodetailaccount={Gotodetailaccount}
                                                        setExpenses={setExpenses}
                                                        ViewUnrealised={ViewUnrealised}


                                                    />
                                                </>
                                            )
                                        })
                                    }
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>
                                            NET EARNINGS
                                        </TableCell>
                                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(parseFloat(incomeandcost) + parseFloat(expenses))}₭</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>) : (<>
                        <Skeleton />
                    </>)
            }


        </>

    )
}
function ComponentHeadingExShow({ totalgain }) {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" style={{ cursor: 'pointer' }}>
                    Expenses
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => {
                    handleClick()
                }} style={{ cursor: 'pointer' }}>{open ? <ExpandLess /> : <ExpandMore />}
                    Other Expenses
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                </TableCell>
            </TableRow>
        </>
    )
}

function ComponentHeadingIn({ data, childrenFirstFloor, childrenSecondFloor, Gotodetailaccount, setIncomeandcost }) {
    const filter_first = childrenFirstFloor.filter((el) => el.BS_ID == data?.BS_ID);
    const filter_second = childrenSecondFloor.filter((el) => el.BS_ID == data?.BS_ID);
    const sum_firstfloor = filter_first.reduce((accumulator, currentItem) => accumulator + parseFloat(currentItem.BALANCES), 0);
    const sum_seondfloor = filter_second.reduce((accumulator, currentItem) => accumulator + parseFloat(currentItem.BALANCES), 0);


    const First_filteredData_income = childrenFirstFloor.filter(item => item.BS_ID === 6);
    const Second_filteredData_income = childrenSecondFloor.filter(item => item.BS_ID === 6);

    const First_totalBalances_income = First_filteredData_income.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances_income = Second_filteredData_income.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);

    const total_income = parseFloat(First_totalBalances_income) + parseFloat(Second_totalBalances_income)

    const First_filteredData_sales = childrenFirstFloor.filter(item => item.BS_ID === 7);
    const Second_filteredData_sales = childrenSecondFloor.filter(item => item.BS_ID === 7);


    const First_totalBalances_salse = First_filteredData_sales.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances_salse = Second_filteredData_sales.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const total_sales = parseFloat(First_totalBalances_salse) + parseFloat(Second_totalBalances_salse)
    setIncomeandcost(parseFloat(total_income) - parseFloat(total_sales))
    const total = (sum_firstfloor + sum_seondfloor)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}>{open ? <ExpandLess /> : <ExpandMore />}
                    {data?.BS_NAME}
                </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {
                        open ? (<></>) : (<>
                            {getFormatNumber(total)}₭
                        </>)
                    }
                </TableCell>
            </TableRow>
            {
                open ? (<>
                    <ComponentIncomeFirst
                        id={data?.BS_ID}
                        childrenFirstFloor={childrenFirstFloor}
                        childrenSecondFloor={childrenSecondFloor}
                        Gotodetailaccount={Gotodetailaccount}
                    />
                    <TableRow>
                        <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                            Total: {data?.BS_NAME}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(total)}₭</TableCell>

                    </TableRow>


                </>) : null
            }

        </>
    )

}
function ComponentIncomeFirst({ id, childrenFirstFloor, childrenSecondFloor, Gotodetailaccount }) {
    if (childrenFirstFloor === null) return <></>
    const filter = childrenFirstFloor.filter((el) => el.BS_ID == id);
    if (filter.length === 0) return <></>;
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }
        </>
    )
}

function TableCellComponentIncome({ data, id, childrenSecondFloor, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 30 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>{open ? <ExpandLess /> : <ExpandMore />}</>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    open ? (
                        <>
                            {
                                data?.BALANCES == 0.00 ? (<>
                                    <TableCell align="right" style={{ cursor: 'pointer' }}></TableCell>

                                </>) : (
                                    <>
                                        <TableCell align="right" style={{ cursor: 'pointer' }}>{getFormatNumber(data?.BALANCES)}</TableCell>

                                    </>)
                            }

                        </>) : (<>
                            <TableCell align="right">{getFormatNumber(net_total)}₭</TableCell>
                        </>)
                }
            </TableRow>
            {open ? (
                <>
                    <TableCellComponentIncome1
                        id={data?.CHART_ID}
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer', fontWeight: 'bold', paddingLeft: 30 }}>
                                    Total: {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>

                            </TableRow>
                        </>)
                    }

                </>



            ) : null
            }
        </>
    )

}
function TableCellComponentIncome1({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome2
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )
}
function TableCellComponentIncome2({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 40 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell >
                {
                    open ? (<>

                        <TableCell align="right" style={{ cursor: 'pointer' }}> {getFormatNumber(data?.BALANCES)}</TableCell>

                    </>) : (<>

                        <TableCell align="right" style={{ cursor: 'pointer' }} > {getFormatNumber(net_total)}₭</TableCell>
                    </>)
                }
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentIncome3
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}
                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 40, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentIncome3({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome4
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }
        </>
    )

}
function TableCellComponentIncome4({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 50 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }}>
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }
                </TableCell>


            </TableRow>
            {
                open ? (<>
                    <TableCellComponentIncome5
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 50, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>


                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentIncome5({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome6
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }
        </>
    )

}
function TableCellComponentIncome6({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)

    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 60 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }} >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }
                </TableCell>


            </TableRow>
            {
                open ? (<>
                    <TableCellComponentIncome7
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}
                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 60, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentIncome7({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome8
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}
                            />
                        </>
                    )
                })
            }
        </>
    )

}
function TableCellComponentIncome8({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)


    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 70 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }} >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }
                </TableCell>


            </TableRow>
            {
                open ? (<>
                    <TableCellComponentIncome9
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentIncome9({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome10
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.c_id}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentIncome10({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 80 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }}>
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }
                </TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentIncome11
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 80, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }
        </>
    )

}
function TableCellComponentIncome11({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentIncome12
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentIncome12({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 90 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }}>
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}
                        </>) : (<>
                            {getFormatNumber(net_total)}
                        </>)
                    }
                </TableCell>
            </TableRow>

        </>
    )
}

function ComponentHeading({ data, childrenFirstFloor, childrenSecondFloor, Gotodetailaccount, setExpenses, ViewUnrealised }) {
    const filter_first = childrenFirstFloor.filter((el) => el.BS_ID == data?.BS_ID);
    const filter_second = childrenSecondFloor.filter((el) => el.BS_ID == data?.BS_ID);
    const sum_firstfloor = filter_first.reduce((accumulator, currentItem) => accumulator + parseFloat(currentItem.BALANCES), 0);
    const sum_seondfloor = filter_second.reduce((accumulator, currentItem) => accumulator + parseFloat(currentItem.BALANCES), 0);
    const total = (sum_firstfloor + sum_seondfloor)

    const First_filteredData_Other_income = childrenFirstFloor.filter(item => item.BS_ID === 8);
    const Second_filteredData_Other_income = childrenSecondFloor.filter(item => item.BS_ID === 8);

    const First_totalBalances_Other_income = First_filteredData_Other_income.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances_Other_income = Second_filteredData_Other_income.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const total_Other_income = parseFloat(First_totalBalances_Other_income) + parseFloat(Second_totalBalances_Other_income)

    const First_filteredData_expense = childrenFirstFloor.filter(item => item.BS_ID === 9 || item.BS_ID === 10);
    const Second_filteredData_expense = childrenSecondFloor.filter(item => item.BS_ID === 9 || item.BS_ID === 10);
    const First_totalBalances_expense = First_filteredData_expense.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const Second_totalBalances_expense = Second_filteredData_expense.reduce((sum, item) => {
        return sum + item.BALANCES;
    }, 0);
    const total_expenses = -1 * (parseFloat(First_totalBalances_expense) + parseFloat(Second_totalBalances_expense))

    setExpenses(parseFloat(total_Other_income) + parseFloat(total_expenses))
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}>{open ? <ExpandLess /> : <ExpandMore />}
                    {data?.BS_NAME}
                </TableCell>

                <TableCell align="right">



                    {
                        open ? (<>

                        </>) : (<>
                            {getFormatNumber(total)}₭
                        </>)
                    }



                </TableCell>
            </TableRow>


            {
                open ? (<>
                    <ComponentExpenseFirst
                        id={data?.BS_ID}
                        childrenFirstFloor={childrenFirstFloor}
                        childrenSecondFloor={childrenSecondFloor}
                        Gotodetailaccount={Gotodetailaccount}
                        ViewUnrealised={ViewUnrealised}

                    />

                    <TableRow>
                        <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                            Total:{data?.BS_NAME}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>{getFormatNumber(total)}₭</TableCell>

                    </TableRow>
                </>) : null
            }

        </>
    )
}
function UnrealisedGainorLoss({ totalgain }) {

    return (<>
        <TableRow>
            <TableCell component="th" scope="row" style={{ cursor: 'pointer', paddingLeft: 30 }}>
                Unrealised Gain or Loss
            </TableCell>
            {
                totalgain === null ? (<>
                    <TableCell align="right">0.00</TableCell>
                </>) : (<>
                    <TableCell align="right">0.00</TableCell>
                </>)
            }

        </TableRow>
    </>)
}
function ComponentExpenseFirst({ id, childrenFirstFloor, childrenSecondFloor, Gotodetailaccount, ViewUnrealised }) {
    if (childrenFirstFloor === null) return <></>
    const filter = childrenFirstFloor.filter((el) => el.BS_ID === id);


    return (
        <>

            {
                filter && filter.map((data, index) => {

                    return (
                        <>
                            <TableCellComponentExpense
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}
                                ViewUnrealised={ViewUnrealised}

                            />
                        </>
                    )
                })
            }
        </>
    )
}

function TableCellComponentExpense({ data, childrenSecondFloor, id, Gotodetailaccount, ViewUnrealised }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    return (
        <>
            <TableRow>

                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 30 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }
                    {data?.CHART_NAME_ENG}
                </TableCell>
                {
                    data?.STATU_AUTO_GAINANDLOSS == 1 || data?.STATU_AUTO_GAINANDLOSS == 2 ? (
                        <>
                            <TableCell align="right" style={{ cursor: 'pointer', cursor: 'pointer' }}
                                onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                            >
                                {
                                    open ? (
                                        <>
                                            {getFormatNumber(data?.BALANCES)}₭
                                        </>
                                    ) : (<>
                                        {getFormatNumber(net_total)}₭
                                    </>)
                                }
                            </TableCell>

                        </>
                    ) : (
                        <>
                            <TableCell align="right" style={{ cursor: 'pointer', cursor: 'pointer' }}
                                onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                            >

                                {
                                    open ? (
                                        <>
                                            {getFormatNumber(data?.BALANCES)}₭
                                        </>
                                    ) : (<>
                                        {getFormatNumber(net_total)}₭
                                    </>)
                                }
                            </TableCell>

                        </>
                    )
                }




            </TableRow>
            {open ? (
                <>
                    <TableCellComponentExpense1
                        id={data?.CHART_ID}
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        Gotodetailaccount={Gotodetailaccount}
                    />

                    <TableRow>
                        <TableCell component="th" scope="row" onClick={() => { handleClick() }} style={{ cursor: 'pointer', fontWeight: 'bold', paddingLeft: 30 }}>
                            Total: {data?.CHART_NAME_ENG}
                        </TableCell>

                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            {getFormatNumber(net_total)}₭
                        </TableCell>
                    </TableRow>


                </>
            ) : null
            }
        </>
    )

}
function TableCellComponentExpense1({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);

    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense2
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense2({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 40 }}>
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell >
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}

                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }
                </TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense3
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}
                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 40, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}₭
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense3({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {

    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);


    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {

                    return (
                        <>
                            <TableCellComponentExpense4
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense4({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 50 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense5
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 50, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense5({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense6
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense6({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 70 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }₭</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense7
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 70, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense7({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);

    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense8
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense8({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 80 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}
                        </>) : (<>
                            {getFormatNumber(net_total)}
                        </>)
                    }₭</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense9
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 80, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense9({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);

    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense10
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}
                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense10({ data, childrenSecondFloor, TotaldrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)

    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 90 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }

                    ₭</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense11
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 90, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(data?.net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense11({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);
    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense12
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.c_id}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }


        </>
    )

}
function TableCellComponentExpense12({ data, childrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick(); }} style={{ cursor: 'pointer', paddingLeft: 100 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}
                    onClick={() => { Gotodetailaccount(data?.ACCOUNT_TYPE_UID, data?.CHART_UID) }}
                >
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }₭</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense13
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}

                        Gotodetailaccount={Gotodetailaccount}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 100, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
function TableCellComponentExpense13({ childrenSecondFloor, setCheckvalues, id, Gotodetailaccount }) {
    if (childrenSecondFloor === null) return <></>
    const filter = childrenSecondFloor.filter((el) => el.CHART_PARENTS == id);

    if (filter.length === 0) return <></>;
    if (filter.length === 0) {
        setCheckvalues(0)
    } else {
        setCheckvalues('')
    }
    return (
        <>
            {
                filter && filter.map((data, index) => {
                    return (
                        <>
                            <TableCellComponentExpense14
                                data={data}
                                key={index}
                                childrenSecondFloor={childrenSecondFloor}

                                id={data?.CHART_ID}
                                Gotodetailaccount={Gotodetailaccount}

                            />
                        </>
                    )
                })
            }
        </>
    )

}
function TableCellComponentExpense14({ data, childrenSecondFloor, TotaldrenSecondFloor, id, Gotodetailaccount }) {
    const [checkvalues, setCheckvalues] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    function sumBalances(childrenSecondFloor, parentId) {
        const children = childrenSecondFloor.filter(item => item.CHART_PARENTS === parentId);
        if (children.length === 0) {
            return 0;
        }
        let totalBalance = 0;
        for (const child of children) {
            totalBalance += parseFloat(child.BALANCES) + parseFloat(sumBalances(childrenSecondFloor, child.CHART_ID));
        }
        return totalBalance;
    }
    const sum = sumBalances(childrenSecondFloor, id);
    const net_total = parseFloat(sum) + parseFloat(data?.BALANCES)
    return (
        <>
            <TableRow>
                <TableCell onClick={() => { handleClick() }} style={{ cursor: 'pointer', paddingLeft: 110 }}>

                    {
                        checkvalues === 0 ? (<></>) : (<>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </>)
                    }

                    {data?.CHART_NAME_ENG}
                </TableCell>
                <TableCell align="right" style={{ cursor: 'pointer' }}>
                    {
                        open ? (<>
                            {getFormatNumber(data?.BALANCES)}₭
                        </>) : (<>
                            {getFormatNumber(net_total)}₭
                        </>)
                    }₭</TableCell>
            </TableRow>
            {
                open ? (<>
                    <TableCellComponentExpense11
                        childrenSecondFloor={childrenSecondFloor}
                        setCheckvalues={setCheckvalues}
                        id={data?.CHART_ID}
                        TotaldrenSecondFloor={TotaldrenSecondFloor}
                    />
                    {
                        checkvalues === 0 ? (<></>) : (<>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ paddingLeft: 110, fontWeight: "bold" }} >
                                    Total:  {data?.CHART_NAME_ENG}
                                </TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                    {getFormatNumber(net_total)}
                                </TableCell>
                            </TableRow>
                        </>)
                    }
                </>) : null
            }

        </>
    )

}
