import React, { useState, useContext, useEffect } from "react";
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Flex, Spin } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import { LoginContext } from "../page/contexts/LoginContext";
export default function Create_user(props) {
  const {
    listcurrency,


  } = useContext(LoginContext);

  const [open, setOpen] = useState(true)
  const [openaddress, setOpenaddress] = useState(true)
  const [opentNotes, setOpentNotes] = useState(true)
  const [openpayment, setOpenpayment] = useState(true)
  const [openadditional, setOpenadditional] = useState(true)
  const [defaultgetcurrency, setDefaultgetcurrency] = useState(0)
  const [active, setActive] = useState()
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleOpenAddress = () => {
    setOpenaddress(!openaddress)
  }
  const handleOpenOpentNotes = () => {
    setOpentNotes(!opentNotes)
  }
  const handlePayment = () => {
    setOpenpayment(!openpayment)
  }
  const handleadditional = () => {
    setOpenadditional(!openadditional)
  }

  const optionsCurrency = (e) => {
    props.setGetcurrency(e)
    let filter = listcurrency.filter((el) => el.CURRENCY_UID.includes(e))
    props.setCurrencies_name(filter[0].CURRENCY_NAME)

    setDefaultgetcurrency(1)
  }
  const changeText_title = (e) => {
    console.log("eeee=", e)
    const newTitle = e.target.value;
    props.setTitle(newTitle);
    props.setDisplayname(`${newTitle || ''} ${props.first_name || ''} ${props.last_name || ''}`.trim());
  };

  const changeText_firstname = (e) => {
    const newFirstName = e.target.value;
    props.setFirst_name(newFirstName);
    props.setDisplayname(`${props.title || ''} ${newFirstName || ''} ${props.last_name || ''}`.trim());
  };
  const changeText_lastname = (e) => {
    const newLastname = e.target.value;
    props.setLast_name(newLastname);
    props.setDisplayname(`${props.title || ''} ${props.first_name || ''} ${newLastname || ''}`.trim());
  }

  return (
    <Drawer
      anchor="right"
      open={props.openDrawer}
      onClose={props.handleCloseDrawer}
    >
      <div style={{ width: 600 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}>
            <small style={{ fontSize: 20, marginTop: 10 }}>{
              props.conditions == 0 ? (<>
                Customers
              </>) : (<>
                {props.getvaluesoptions}

              </>)
            }</small>
          </div>
          <div style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
            <small style={{ marginRight: 10, fontSize: 20, marginTop: 10, cursor: 'pointer' }} onClick={() => { props.handleCloseDrawer() }}>  <CloseIcon /></small>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <small style={{ marginLeft: 10 }}>Contact type</small>
            <select style={{
              marginLeft: 10,
              marginRight: 10,
              border: '1px solid #ccc',
              outline: 'none',
              height: 35,
              borderRadius: 3
            }}
              onChange={(e) => props.options(e.target.value)}
              value={props.getvaluesoptions}
            >
              <option value='Customers'>Customer</option>
              <option value='Supplier'>Supplier</option>
              {/* <option value='Employee'>Employee</option> */}

            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <small style={{ marginLeft: 10 }}>Currency</small>
            <select style={{
              marginLeft: 10,
              marginRight: 10,
              border: '1px solid #ccc',
              outline: 'none',
              height: 35,
              borderRadius: 3
            }}
              onChange={(e) => optionsCurrency(e.target.value)}
              value={props.getcurrency}
            >
              <option >Choose Currency</option>
              {listcurrency &&
                listcurrency?.map((data, index) => {
                  return (
                    <option key={index} value={data?.CURRENCY_UID}>{data?.CURRENCY_NAME} - {data?.CURRENCY_CODE}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {
          props.conditions == 0 ? (<>

            <div style={{
              display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #ccc',
              borderRadius: 5
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                cursor: 'pointer',
                height: 50,
                borderRadius: 3,
              }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                  <small style={{ marginTop: 3, fontSize: 15 }}>Name and Contact</small>
                </div>
                <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpen() }}>
                  {
                    open ? (<>
                      <KeyboardArrowUpIcon />
                    </>) : (<>
                      <ExpandMoreIcon />
                    </>)
                  }
                </div>
              </div>
              {
                open ? (<>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '20%' }}>
                      <small>Title</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={changeText_title}
                        value={props.title}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                      <small>First Name</small>
                      <input type='text' style={{
                        border: '1px solid #ccc',
                        outline: 'none',
                        borderRadius: 3,
                        height: 30
                      }}
                        onChange={changeText_firstname}
                        value={props.first_name}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                      <small>Last Name</small>
                      <input type='text' style={{
                        border: '1px solid #ccc',
                        outline: 'none',
                        borderRadius: 3,
                        height: 30,
                        marginRight: 5
                      }}
                        onChange={changeText_lastname}
                        value={props.last_name}

                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      {
                        props.conditions == 0 ? (
                          <>
                            <small>Customers display Name</small>
                          </>) : (<>
                            <small>{props.getvaluesoptions} display Name</small>
                          </>)
                      }
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <input type='text'
                          style={{
                            border: '1px solid #ccc',
                            outline: 'none',
                            borderTopLeftRadius: 3,
                            borderBottomLeftRadius: 3,
                            height: 30,
                            borderRight: 'none',
                            width: '90%'
                          }}
                          onChange={(e) => { props.setDisplayname(e.target.value) }}
                          value={props.displayname}
                        />
                        <div style={{ width: '10%', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                          <ExpandMoreIcon />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      <small>Company name</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setCompany(e.target.value) }}
                        value={props.company}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Email</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setEmail(e.target.value) }}
                        value={props.email}
                      />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Phone Number</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setPhonenumber(e.target.value) }}
                        value={props.phonenumber}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Mobile number</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setMobilenumber(e.target.value) }}
                        value={props.mobilenumber}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Account number</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setAccount_numner(e.target.value) }}
                        value={props.account_number}
                      />
                    </div>
                  </div>

                </>) : null
              }
              <div style={{ height: 10 }}>
              </div>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #ccc',
              borderRadius: 5
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                cursor: 'pointer',
                height: 50,
                borderRadius: 3,


              }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                  <small style={{ marginTop: 3, fontSize: 15 }}>Address</small>
                </div>
                <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenAddress() }}>
                  {
                    openaddress ? (<>
                      <KeyboardArrowUpIcon />
                    </>) : (<>
                      <ExpandMoreIcon />
                    </>)
                  }
                </div>
              </div>
              {
                openaddress ? (<>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>village</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setVillage(e.target.value) }}
                        value={props.village}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>District</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setDistrict(e.target.value) }}
                        value={props.district}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Provinces</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                        onChange={(e) => { props.setProvince(e.target.value) }}
                        value={props.province}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                      <small>Post Code</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30
                        }}
                      />
                    </div>
                  </div>
                </>) : null
              }
              <div style={{ height: 10 }}>
              </div>

            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #ccc',
              borderRadius: 5
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                cursor: 'pointer',
                height: 50,
                borderRadius: 3,


              }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                  <small style={{ marginTop: 3, fontSize: 15 }}>Notes and attachments</small>
                </div>
                <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenOpentNotes() }}>
                  {
                    opentNotes ? (<>
                      <KeyboardArrowUpIcon />
                    </>) : (<>
                      <ExpandMoreIcon />
                    </>)
                  }
                </div>
              </div>
              {
                opentNotes ? (<>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
                      <small>Notes</small>
                      <textarea style={{
                        border: '1px solid #ccc',
                        outline: 'none',
                        borderRadius: 3,

                      }}
                        onChange={(e) => { props.setNotes(e.target.value) }}
                        value={props.notes}

                      />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                      <label style={{ display: 'flex', flexDirection: 'row' }}>
                        <AttachFileIcon />
                        <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                        <input
                          type="file"
                          name="images"
                          onChange={props.onSelectFile}
                          multiple
                          style={{ display: "none" }}
                        />
                        {
                          props.uploading ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                              <Spin size="smal" />
                            </Flex>

                          </>) : null
                        }

                      </label>

                    </div>
                    <div style={{
                      display: 'flex',
                      border: '1px solid #ccc',
                      marginTop: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      justifyContent: 'flex-end',
                      flexDirection: 'column'
                    }}>
                      {
                        props.uploading ? (<>
                          <LinearProgress />
                        </>) : null
                      }
                      {props.selectedImages &&
                        props.selectedImages.map((data, index) => {
                          let sizes = parseFloat(data?.size / 1024).toFixed(2)
                          return (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                              <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>
                              <div style={{ backgroundColor: "red", borderRadius: '50%', color: props.active == sizes ? '#ccc' : 'white', cursor: 'pointer', marginRight: 10 }}
                                onMouseOver={() => props.setActive(sizes)}
                                onMouseLeave={() => props.setActive(null)}
                                onClick={() => { props.fileRemove(data) }}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          );
                        })}

                    </div>
                  </div>


                </>) : null
              }
              <div style={{ height: 10 }}>
              </div>

            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #ccc',
              borderRadius: 5
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                cursor: 'pointer',
                height: 50,
                borderRadius: 3,


              }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                  <small style={{ marginTop: 3, fontSize: 15 }}>Payment</small>
                </div>
                <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handlePayment() }}>
                  {
                    openpayment ? (<>
                      <KeyboardArrowUpIcon />
                    </>) : (<>
                      <ExpandMoreIcon />
                    </>)
                  }
                </div>
              </div>
              {
                openpayment ? (<>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      <small>Primary payment method</small>
                      <select
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30

                        }}
                        onChange={(e) => { props.setPayment(e.target.value) }}
                        value={props.payment}

                      >
                        <option >Choose Payment Method</option>
                        <option value='Cash'>Cash</option>
                        <option value='Cheque'>Cheque</option>
                        <option value='Credit Card'>Credit Card</option>
                        <option value='Direct Debit'>Direct Debit</option>
                      </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      <small>Terms</small>
                      <select
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30

                        }}
                        onChange={(e) => { props.setTerms(e.target.value) }}
                        value={props.terms}
                      >
                        <option>Choose Terms</option>
                        <option value='15'>15 day</option>
                        <option value='Due on receipt'>Due on receipt</option>
                        <option value='Net 15'>Net 15</option>
                        <option value='Net 30'>Net 30</option>
                        <option value='Net 60'>Net 60</option>
                      </select>
                    </div>
                  </div>
                </>) : null
              }
              <div style={{ height: 10 }}>
              </div>

            </div>

            <div style={{
              display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
              border: '2px solid #ccc',
              borderRadius: 5
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                cursor: 'pointer',
                height: 50,
                borderRadius: 3,


              }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                  <small style={{ marginTop: 3, fontSize: 15 }}>Additional Informations</small>
                </div>
                <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleadditional() }}>
                  {
                    openadditional ? (<>
                      <KeyboardArrowUpIcon />
                    </>) : (<>
                      <ExpandMoreIcon />
                    </>)
                  }
                </div>
              </div>
              {
                openadditional ? (<>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      <small>Customer Type</small>
                      <select
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30

                        }}
                        onChange={(e) => { props.setCustomer_type(e.target.value) }}
                        value={props.customer_type}

                      >
                        <option >Type</option>
                        <option value='Regular customer'>Regular customer</option>
                        <option value='General customers'>General customers</option>

                      </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                      <small>Sales tax registration</small>
                      <input type='text'
                        style={{
                          border: '1px solid #ccc',
                          outline: 'none',
                          borderRadius: 3,
                          height: 30,

                        }}
                        onChange={(e) => { props.setRegistertaxs(e.target.value) }}
                        value={props.registertaxs}
                      />

                    </div>
                  </div>

                  {
                    defaultgetcurrency === 0 ? (<>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <small style={{ marginLeft: 10 }}>Opening Balance</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,
                                marginLeft: 10,
                                marginRight: 10
                              }}
                            />

                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <small >As of.</small>
                            <input type='date'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,
                                marginRight: 10
                              }}
                            />

                          </div>

                        </div>

                      </div>
                    </>) : (<>
                      {
                        props.getcurrency === 'LAK' ? (<>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                <input type='text'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                  onChange={(e) => { props.setOpening(e.target.value) }}
                                  value={props.opening}
                                />

                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small >As of.</small>
                                <input type='date'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginRight: 10
                                  }}
                                  onChange={(e) => { props.setOpeningdate(e.target.value) }}
                                  value={props.openingdate}
                                />

                              </div>

                            </div>

                          </div>
                        </>) : null
                      }

                    </>)
                  }


                </>) : null
              }




              <div style={{ height: 10 }}>
              </div>

            </div>

          </>) : (
            <>
              {
                props.getvaluesoptions === 'Customers' ? (<>
                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #ccc',
                    borderRadius: 5
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      height: 50,
                      borderRadius: 3,
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                        <small style={{ marginTop: 3, fontSize: 15 }}>Name and Contact</small>
                      </div>
                      <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpen() }}>
                        {
                          open ? (<>
                            <KeyboardArrowUpIcon />
                          </>) : (<>
                            <ExpandMoreIcon />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      open ? (<>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '20%' }}>
                            <small>Title</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={changeText_title}
                              value={props.title}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                            <small>First Name</small>
                            <input type='text' style={{
                              border: '1px solid #ccc',
                              outline: 'none',
                              borderRadius: 3,
                              height: 30
                            }}
                              onChange={changeText_firstname}
                              value={props.first_name}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                            <small>Last Name</small>
                            <input type='text' style={{
                              border: '1px solid #ccc',
                              outline: 'none',
                              borderRadius: 3,
                              height: 30,
                              marginRight: 5
                            }}
                              onChange={changeText_lastname}
                              value={props.last_name}

                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            {
                              props.conditions == 0 ? (
                                <>
                                  <small>Customers display Name</small>
                                </>) : (<>
                                  <small>{props.getvaluesoptions} display Name</small>
                                </>)
                            }
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderTopLeftRadius: 3,
                                  borderBottomLeftRadius: 3,
                                  height: 30,
                                  borderRight: 'none',
                                  width: '90%'
                                }}
                                onChange={(e) => { props.setDisplayname(e.target.value) }}
                                value={props.displayname}
                              />
                              <div style={{ width: '10%', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <ExpandMoreIcon />
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            <small>Company name</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setCompany(e.target.value) }}
                              value={props.company}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Email</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setEmail(e.target.value) }}
                              value={props.email}
                            />

                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Phone Number</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setPhonenumber(e.target.value) }}
                              value={props.phonenumber}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Mobile number</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setMobilenumber(e.target.value) }}
                              value={props.mobilenumber}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Account number</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setAccount_numner(e.target.value) }}
                              value={props.account_number}
                            />
                          </div>
                        </div>

                      </>) : null
                    }
                    <div style={{ height: 10 }}>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #ccc',
                    borderRadius: 5
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      height: 50,
                      borderRadius: 3,


                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                        <small style={{ marginTop: 3, fontSize: 15 }}>Address</small>
                      </div>
                      <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenAddress() }}>
                        {
                          openaddress ? (<>
                            <KeyboardArrowUpIcon />
                          </>) : (<>
                            <ExpandMoreIcon />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      openaddress ? (<>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>village</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setVillage(e.target.value) }}
                              value={props.village}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>District</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setDistrict(e.target.value) }}
                              value={props.district}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Provinces</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                              onChange={(e) => { props.setProvince(e.target.value) }}
                              value={props.province}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                            <small>Post Code</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                            />
                          </div>
                        </div>
                      </>) : null
                    }
                    <div style={{ height: 10 }}>
                    </div>

                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #ccc',
                    borderRadius: 5
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      height: 50,
                      borderRadius: 3,


                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                        <small style={{ marginTop: 3, fontSize: 15 }}>Notes and attachments</small>
                      </div>
                      <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenOpentNotes() }}>
                        {
                          opentNotes ? (<>
                            <KeyboardArrowUpIcon />
                          </>) : (<>
                            <ExpandMoreIcon />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      opentNotes ? (<>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
                            <small>Notes</small>
                            <textarea style={{
                              border: '1px solid #ccc',
                              outline: 'none',
                              borderRadius: 3,

                            }}
                              onChange={(e) => { props.setNotes(e.target.value) }}
                              value={props.notes}

                            />

                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                            <label style={{ display: 'flex', flexDirection: 'row' }}>
                              <AttachFileIcon />
                              <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                              <input
                                type="file"
                                name="images"
                                onChange={props.onSelectFile}
                                multiple
                                style={{ display: "none" }}
                              />
                              {
                                props.uploading ? (<>
                                  <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                                    <Spin size="smal" />
                                  </Flex>

                                </>) : null
                              }

                            </label>

                          </div>
                          <div style={{
                            display: 'flex',
                            border: '1px solid #ccc',
                            marginTop: 10,
                            marginLeft: 10,
                            marginRight: 10,
                            justifyContent: 'flex-end',
                            flexDirection: 'column'
                          }}>
                            {
                              props.uploading ? (<>
                                <LinearProgress />
                              </>) : null
                            }
                            {props.selectedImages &&
                              props.selectedImages.map((data, index) => {
                                let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                return (
                                  <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>
                                    <div style={{ backgroundColor: "red", borderRadius: '50%', color: props.active == sizes ? '#ccc' : 'white', cursor: 'pointer', marginRight: 10 }}
                                      onMouseOver={() => props.setActive(sizes)}
                                      onMouseLeave={() => props.setActive(null)}
                                      onClick={() => { props.fileRemove(data) }}
                                    >
                                      <CloseIcon />
                                    </div>
                                  </div>
                                );
                              })}

                          </div>
                        </div>


                      </>) : null
                    }
                    <div style={{ height: 10 }}>
                    </div>

                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #ccc',
                    borderRadius: 5
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      height: 50,
                      borderRadius: 3,


                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                        <small style={{ marginTop: 3, fontSize: 15 }}>Payment</small>
                      </div>
                      <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handlePayment() }}>
                        {
                          openpayment ? (<>
                            <KeyboardArrowUpIcon />
                          </>) : (<>
                            <ExpandMoreIcon />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      openpayment ? (<>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            <small>Primary payment method</small>
                            <select
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30

                              }}
                              onChange={(e) => { props.setPayment(e.target.value) }}
                              value={props.payment}

                            >
                              <option value='Cash'>Choose Payment Method</option>
                              <option value='Cash'>Cash</option>
                              <option value='Cheque'>Cheque</option>
                              <option value='Credit Card'>Credit Card</option>
                              <option value='Direct Debit'>Direct Debit</option>
                            </select>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            <small>Terms</small>
                            <select
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30

                              }}
                              onChange={(e) => { props.setTerms(e.target.value) }}
                              value={props.terms}
                            >
                              <option>Choose Terms</option>
                              <option value='15'>15 day</option>
                              <option value='Due on receipt'>Due on receipt</option>
                              <option value='Net 15'>Net 15</option>
                              <option value='Net 30'>Net 30</option>
                              <option value='Net 60'>Net 60</option>
                            </select>
                          </div>
                        </div>
                      </>) : null
                    }
                    <div style={{ height: 10 }}>
                    </div>

                  </div>

                  <div style={{
                    display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #ccc',
                    borderRadius: 5
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      height: 50,
                      borderRadius: 3,


                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                        <small style={{ marginTop: 3, fontSize: 15 }}>Additional Informations</small>
                      </div>
                      <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleadditional() }}>
                        {
                          openadditional ? (<>
                            <KeyboardArrowUpIcon />
                          </>) : (<>
                            <ExpandMoreIcon />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      openadditional ? (<>


                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            <small>Customer Type</small>
                            <select
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30

                              }}
                              onChange={(e) => { props.setCustomer_type(e.target.value) }}
                              value={props.customer_type}

                            >
                              <option value='Regular customer'>Type</option>
                              <option value='Regular customer'>Regular customer</option>
                              <option value='General customers'>General customers</option>

                            </select>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                            <small>Sales tax registration</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,

                              }}
                              onChange={(e) => { props.setRegistertaxs(e.target.value) }}
                              value={props.registertaxs}
                            />

                          </div>
                        </div>


                        {
                          defaultgetcurrency === 0 ? (<>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                  <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                  <input type='text'
                                    style={{
                                      border: '1px solid #ccc',
                                      outline: 'none',
                                      borderRadius: 3,
                                      height: 30,
                                      marginLeft: 10,
                                      marginRight: 10
                                    }}
                                  />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                  <small >As of.</small>
                                  <input type='date'
                                    style={{
                                      border: '1px solid #ccc',
                                      outline: 'none',
                                      borderRadius: 3,
                                      height: 30,
                                      marginRight: 10
                                    }}
                                  />

                                </div>

                              </div>

                            </div>
                          </>) : (<>
                            {
                              props.getcurrency === 'LAK' ? (<>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                      <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                      <input type='text'
                                        style={{
                                          border: '1px solid #ccc',
                                          outline: 'none',
                                          borderRadius: 3,
                                          height: 30,
                                          marginLeft: 10,
                                          marginRight: 10
                                        }}
                                        onChange={(e) => { props.setOpening(e.target.value) }}
                                        value={props.opening}
                                      />

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                      <small >As of.</small>
                                      <input type='date'
                                        style={{
                                          border: '1px solid #ccc',
                                          outline: 'none',
                                          borderRadius: 3,
                                          height: 30,
                                          marginRight: 10
                                        }}
                                        onChange={(e) => { props.setOpeningdate(e.target.value) }}
                                        value={props.openingdate}
                                      />

                                    </div>

                                  </div>

                                </div>
                              </>) : null
                            }

                          </>)
                        }


                      </>) : null
                    }




                    <div style={{ height: 10 }}>
                    </div>

                  </div>
                </>) : props.getvaluesoptions === 'Employee' ? (
                  <>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,
                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Name and Contact</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpen() }}>
                          {
                            open ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        open ? (<>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '20%' }}>
                              <small>Title</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={changeText_title}
                                value={props.title}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                              <small>First Name</small>
                              <input type='text' style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                                onChange={changeText_firstname}
                                value={props.first_name}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                              <small>Last Name</small>
                              <input type='text' style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,
                                marginRight: 5
                              }}
                                onChange={changeText_lastname}
                                value={props.last_name}

                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              {
                                props.conditions == 0 ? (
                                  <>
                                    <small>Customers display Name</small>
                                  </>) : (<>
                                    <small>{props.getvaluesoptions} display Name</small>
                                  </>)
                              }
                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <input type='text'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderTopLeftRadius: 3,
                                    borderBottomLeftRadius: 3,
                                    height: 30,
                                    borderRight: 'none',
                                    width: '90%'
                                  }}
                                  onChange={(e) => { props.setDisplayname(e.target.value) }}
                                  value={props.displayname}
                                />
                                <div style={{ width: '10%', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                  <ExpandMoreIcon />
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Company name</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setCompany(e.target.value) }}
                                value={props.company}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Email</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setEmail(e.target.value) }}
                                value={props.email}
                              />

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Phone Number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setPhonenumber(e.target.value) }}
                                value={props.phonenumber}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Mobile number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setMobilenumber(e.target.value) }}
                                value={props.mobilenumber}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Account number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setAccount_numner(e.target.value) }}
                                value={props.account_number}
                              />
                            </div>

                          </div>

                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Address</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenAddress() }}>
                          {
                            openaddress ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        openaddress ? (<>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Village</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setVillage(e.target.value) }}
                                value={props.village}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>District</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setDistrict(e.target.value) }}
                                value={props.district}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Provinces</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setProvince(e.target.value) }}
                                value={props.province}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Postal Code</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                              />
                            </div>
                          </div>


                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Notes and attachments</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenOpentNotes() }}>
                          {
                            opentNotes ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        opentNotes ? (<>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
                              <small>Notes</small>
                              <textarea style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,

                              }}
                                onChange={(e) => { props.setNotes(e.target.value) }}
                                value={props.notes}
                              />

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                              <label style={{ display: 'flex', flexDirection: 'row' }}>
                                <AttachFileIcon />
                                <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                                <input
                                  type="file"
                                  name="images"
                                  onChange={props.onSelectFile}
                                  multiple
                                  style={{ display: "none" }}
                                />
                                {
                                  props.uploading ? (<>
                                    <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                                      <Spin size="smal" />
                                    </Flex>

                                  </>) : null
                                }

                              </label>

                            </div>
                            <div style={{
                              display: 'flex',
                              border: '1px solid #ccc',
                              marginTop: 10,
                              marginLeft: 10,
                              marginRight: 10,
                              justifyContent: 'flex-end',
                              flexDirection: 'column'
                            }}>
                              {
                                props.uploading ? (<>
                                  <LinearProgress />
                                </>) : null
                              }
                              {props.selectedImages &&
                                props.selectedImages.map((data, index) => {
                                  let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                  return (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                      <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>
                                      <div style={{ backgroundColor: "red", borderRadius: '50%', color: props.active == sizes ? '#ccc' : 'white', cursor: 'pointer', marginRight: 10 }}
                                        onMouseOver={() => props.setActive(sizes)}
                                        onMouseLeave={() => props.setActive(null)}
                                        onClick={() => { props.fileRemove(data) }}
                                      >
                                        <CloseIcon />
                                      </div>
                                    </div>
                                  );
                                })}

                            </div>
                          </div>


                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Additional Informations</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handlePayment() }}>
                          {
                            openpayment ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        openpayment ? (<>

                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Employee ID number</small>
                              <input type="text"
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setEmployee_ID_number(e.target.value) }}
                                value={props.employee_ID_number}

                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Employee ID</small>
                              <input type="text"
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setEmployee_id(e.target.value) }}
                                value={props.employee_id}

                              />
                            </div>



                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Date of birth</small>
                              <input type="date"
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setBirthday(e.target.value) }}
                                value={props.birthday}

                              />

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Gender</small>
                              <select
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30

                                }}
                                onChange={(e) => { props.setGender(e.target.value) }}
                                value={props.gender}

                              >
                                <option>Prefer not to answer</option>
                                <option value='Female'>Female</option>
                                <option value='Male'>Male</option>
                              </select>
                            </div>


                          </div>


                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>
                  </>) : (<>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,
                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Name and Contact</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpen() }}>
                          {
                            open ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        open ? (<>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '20%' }}>
                              <small>Title</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={changeText_title}
                                value={props.title}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                              <small>First Name</small>
                              <input type='text' style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30
                              }}
                                onChange={changeText_firstname}
                                value={props.first_name}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '40%' }}>
                              <small>Last Name</small>
                              <input type='text' style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,
                                marginRight: 5
                              }}
                                onChange={changeText_lastname}
                                value={props.last_name}

                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              {
                                props.conditions == 0 ? (
                                  <>
                                    <small>Customers display Name</small>
                                  </>) : (<>
                                    <small>{props.getvaluesoptions} display Name</small>
                                  </>)
                              }
                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <input type='text'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderTopLeftRadius: 3,
                                    borderBottomLeftRadius: 3,
                                    height: 30,
                                    borderRight: 'none',
                                    width: '90%'
                                  }}
                                  onChange={(e) => { props.setDisplayname(e.target.value) }}
                                  value={props.displayname}
                                />
                                <div style={{ width: '10%', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                  <ExpandMoreIcon />
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, width: '50%', marginRight: 5 }}>
                              <small>Company name</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setCompany(e.target.value) }}
                                value={props.company}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Email</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setEmail(e.target.value) }}
                                value={props.email}
                              />

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Phone Number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setPhonenumber(e.target.value) }}
                                value={props.phonenumber}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Mobile number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setMobilenumber(e.target.value) }}
                                value={props.mobilenumber}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Account number</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setAccount_numner(e.target.value) }}
                                value={props.account_number}
                              />
                            </div>

                          </div>

                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Address</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenAddress() }}>
                          {
                            openaddress ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        openaddress ? (<>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>village</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setVillage(e.target.value) }}
                                value={props.village}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>District</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setDistrict(e.target.value) }}
                                value={props.district}
                              />
                            </div>


                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Provinces</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}
                                onChange={(e) => { props.setProvince(e.target.value) }}
                                value={props.province}
                              />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '50%' }}>
                              <small>Post Code</small>
                              <input type='text'
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRadius: 3,
                                  height: 30
                                }}

                              />
                            </div>


                          </div>


                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Notes and attachments</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handleOpenOpentNotes() }}>
                          {
                            opentNotes ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        opentNotes ? (<>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
                              <small>Notes</small>
                              <textarea style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,

                              }}
                                onChange={(e) => { props.setNotes(e.target.value) }}
                                value={props.notes}

                              />

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                              <label style={{ display: 'flex', flexDirection: 'row' }}>
                                <AttachFileIcon />
                                <small style={{ fontSize: 15, fontWeight: "bold" }}>Attachments</small>
                                <input
                                  type="file"
                                  name="images"
                                  onChange={props.onSelectFile}
                                  multiple
                                  style={{ display: "none" }}
                                />
                                {
                                  props.uploading ? (<>
                                    <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                                      <Spin size="smal" />
                                    </Flex>

                                  </>) : null
                                }

                              </label>

                            </div>
                            <div style={{
                              display: 'flex',
                              border: '1px solid #ccc',
                              marginTop: 10,
                              marginLeft: 10,
                              marginRight: 10,
                              justifyContent: 'flex-end',
                              flexDirection: 'column'
                            }}>
                              {
                                props.uploading ? (<>
                                  <LinearProgress />
                                </>) : null
                              }
                              {props.selectedImages &&
                                props.selectedImages.map((data, index) => {
                                  let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                  return (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                      <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>
                                      <div style={{ backgroundColor: "red", borderRadius: '50%', color: props.active == sizes ? '#ccc' : 'white', cursor: 'pointer', marginRight: 10 }}
                                        onMouseOver={() => props.setActive(sizes)}
                                        onMouseLeave={() => props.setActive(null)}
                                        onClick={() => { props.fileRemove(data) }}
                                      >
                                        <CloseIcon />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginTop: 10, backgroundColor: 'white',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                      border: '2px solid #ccc',
                      borderRadius: 5
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        height: 50,
                        borderRadius: 3,


                      }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5, marginTop: 10, marginRight: 5 }}>
                          <small style={{ marginTop: 3, fontSize: 15 }}>Additional Informations</small>
                        </div>
                        <div style={{ cursor: 'pointer', marginTop: 10, marginRight: 10 }} onClick={() => { handlePayment() }}>
                          {
                            openpayment ? (<>
                              <KeyboardArrowUpIcon />
                            </>) : (<>
                              <ExpandMoreIcon />
                            </>)
                          }
                        </div>
                      </div>
                      {
                        openpayment ? (<>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <small style={{ marginLeft: 10 }}>Taxes</small>
                            <small style={{ marginLeft: 10, marginTop: 10 }}>Business ID NO./Social Insurance No.</small>
                            <input type='text'
                              style={{
                                border: '1px solid #ccc',
                                outline: 'none',
                                borderRadius: 3,
                                height: 30,
                                marginLeft: 10,
                                marginRight: 10

                              }}

                              onChange={(e) => { props.setSocial_insurance_no(e.target.value) }}
                              value={props.social_insurance_no}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <small style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}>Payment</small>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small style={{ marginLeft: 10 }}>Terms</small>
                                <select
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginLeft: 10,
                                    marginRight: 10

                                  }}
                                  onChange={(e) => { props.setPayment(e.target.value) }}
                                  value={props.payment}

                                >
                                  <option value='15'>15 day</option>
                                  <option value='Due on receipt'>Due on receipt</option>
                                  <option value='Net 15'>Net 15</option>
                                  <option value='Net 30'>Net 30</option>
                                  <option value='Net 60'>Net 60</option>
                                </select>

                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small >Account No.</small>
                                <input type='text'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginRight: 10
                                  }}
                                  onChange={(e) => { props.setAccount_no(e.target.value) }}
                                  value={props.account_no}
                                />

                              </div>

                            </div>

                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <small style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}>Accounting</small>
                            <small style={{ marginLeft: 10, marginTop: 10 }}>Default expense category</small>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                              <input type='text' placeholder="Choose account"
                                style={{
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  borderRight: 'none',
                                  borderTopLeftRadius: 3,
                                  borderBottomLeftRadius: 3,
                                  height: 30,
                                  marginLeft: 10,
                                  width: '100%',


                                }}

                              />
                              <div style={{ border: '1px solid #ccc', marginRight: 10, cursor: 'pointer', borderTopRightRadius: 3, borderBottomRightRadius: 3 }}>
                                <ExpandMoreIcon />
                              </div>
                            </div>

                          </div>
                          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <small style={{ marginLeft: 10, marginTop: 20, fontWeight: 'bold' }}>Opening Balance</small>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                <input type='text'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                />

                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <small >As of.</small>
                                <input type='date'
                                  style={{
                                    border: '1px solid #ccc',
                                    outline: 'none',
                                    borderRadius: 3,
                                    height: 30,
                                    marginRight: 10
                                  }}
                                />

                              </div>

                            </div>

                          </div> */}
                          {
                            defaultgetcurrency === 0 ? (<>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                    <input type='text'
                                      style={{
                                        border: '1px solid #ccc',
                                        outline: 'none',
                                        borderRadius: 3,
                                        height: 30,
                                        marginLeft: 10,
                                        marginRight: 10
                                      }}
                                      onChange={(e) => { props.setOpening(e.target.value) }}
                                      value={props.opening}

                                    />

                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <small >As of.</small>
                                    <input type='date'
                                      style={{
                                        border: '1px solid #ccc',
                                        outline: 'none',
                                        borderRadius: 3,
                                        height: 30,
                                        marginRight: 10
                                      }}
                                      onChange={(e) => { props.setOpeningdate(e.target.value) }}
                                      value={props.openingdate}
                                    />

                                  </div>

                                </div>

                              </div>
                            </>) : (<>
                              {
                                props.getcurrency === 'LAK' ? (<>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <small style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Opening Balance</small>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <small style={{ marginLeft: 10 }}>Opening Balance</small>
                                        <input type='text'
                                          style={{
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            borderRadius: 3,
                                            height: 30,
                                            marginLeft: 10,
                                            marginRight: 10
                                          }}
                                          onChange={(e) => { props.setOpening(e.target.value) }}
                                          value={props.opening}
                                        />

                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <small >As of.</small>
                                        <input type='date'
                                          style={{
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            borderRadius: 3,
                                            height: 30,
                                            marginRight: 10
                                          }}
                                          onChange={(e) => { props.setOpeningdate(e.target.value) }}
                                          value={props.openingdate}
                                        />

                                      </div>

                                    </div>

                                  </div>
                                </>) : null
                              }

                            </>)
                          }


                        </>) : null
                      }
                      <div style={{ height: 10 }}>
                      </div>

                    </div>

                  </>)
              }

            </>)
        }
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div style={{
            backgroundColor: '#3f51b5',
            marginRight: 10,
            marginTop: 20,
            width: '15%',
            marginBottom: 10,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: 18,
            fontWeight: 'bold',
            borderRadius: 3,
            cursor: 'pointer',
            boxShadow: active === 'save' ? '1px 2px 9px #F4AAB9' : '',

          }}
            onMouseOver={() => setActive('save')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { props.Oncreate_user(props.getindex, props.data) }}
          >
            <small >Save</small>
          </div>

        </div>

      </div>
    </Drawer>

  );
}
