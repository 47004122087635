import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Form } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";

const ITEM_HEIGHT = 48;
export default function Options_taxs(props) {


    const open = Boolean(props.anchorElTax);

    return (
        <div>


            <Menu
                id="long-menu"
                anchorEl={props.anchorElTax}
                keepMounted
                open={open}
                onClose={props.handleCloseTaxs}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25%',
                        marginTop: 50,
                    },
                }}
            >
                {/* <Form>
                    <MenuItem>
                        <div style={{
                            borderRadius: 2,
                            backgroundColor: '#3f51b5',
                            height: 30,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer',
                            justifyContent: 'center',
                            marginTop: 4,

                        }}
                        onClick={()=>{props.handleShow()}}

                        >
                            <AddIcon style={{ color: 'white' }} />
                            <small style={{ color: 'white', fontWeight: 'bold', marginRight: 10 }}>Add New</small>
                        </div>
                    </MenuItem>
                    {
                        props.show ? (<>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input type='text'
                                    style={{
                                        marginLeft: 20,
                                        border: '1px solid #ccc',
                                        borderRadius: 3,
                                        height: 30,
                                        outline: 'none',
                                        width: '70%'
                                    }} 

                                    />
                                <div style={{
                                    backgroundColor: '#3f51b5',
                                    width: '30%',
                                    marginRight: 20,
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                             
                                >
                                    <small> Add Type </small>
                                </div>
                            </div>

                        </>) : null
                    }

                </Form> */}
                {props.listtransactions_type.map((item, index) => (
                    <MenuItem
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => {
                            props.select_options_taxs(
                                item?.TRANSACTIONS_TYPE_ID,
                                item?.TRANSACTIONS_NAME,
                                props.getindex,
                                props.data
                            );
                        }}
                    >
                        <small>
                           {item?.TRANSACTIONS_NAME}
                        </small>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
