import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import ClipLoader from "react-spinners/ClipLoader";
import SearchIcon from "@material-ui/icons/Search";
import { LoginContext } from "./contexts/LoginContext";
import { Button } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Toast } from "react-bootstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import moment from "moment";
import DataTable from "react-data-table-component";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export default function AccountCategory() {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false)
  let [loading, setLoading] = useState(false);
  const [successffuly, setsuccessffuly] = useState(false);
  const [accountuid, setAccountuid] = useState('')
  const [name_eng, setName_eng] = useState("");
  const [uid, setUid] = useState("");
  const [main_type_name, setMain_type_name] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const { listaccount,list,onloadaccountType} =
    useContext(LoginContext);
  const [records, setRecords] = useState(listaccount)
  function handleFilter(event) {
    const newData = listaccount.filter(row => {
      return row.ACCOUNT_TYPE_NAME_ENG.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setRecords(newData)
  }

  const handleClose = () => {
    setShow(false);
    setMain_type_name('');
    setUid('');
  };
  const handleClosedel = () => {
    setShows(false);
    setMain_type_name('');
    setUid('');
  }
  const Create=()=>{

  }


  
  const handleShow = () => setShow(true);
  const columns = [
    {
      name: "ACCOUNT TYPE",
      selector: row => row.ACCOUNT_TYPE_NAME_ENG,
      sortable: true
    },
    {
      name: "Type",
      selector: row => row.ACCOUNT_NAME_ENG,
      sortable: true
    },
    {
      name: "Last Date",
      selector: row => moment(row.ACCOUNT_TYPE_CREATEDATE).format('YYYY-MM-DD'),
      sortable: true
    }
  ]
  useEffect(() => {

  }, [])
  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ paddingTop: 50 }}>
        <Modal.Header closeButton>
          <Modal.Title>Add new Account</Modal.Title>
          <div style={{ paddingTop: 10, position: "absolute", display: 'flex', paddingLeft: 100 }}>
            <Toast onClose={() => setsuccessffuly(false)} show={successffuly} delay={2000} autohide style={{ height: 70, width: 200 }}>
              <div style={{ paddingTop: 20, paddingLeft: 25, width: 100 }}>
                <CheckCircleIcon style={{ color: "green" }} /> Successfully
              </div>
            </Toast>
          </div>
        </Modal.Header>
        < Spinner color="primary" />
        <Modal.Body>
          <div style={{ paddingTop: 10, paddingLeft: 200, position: "absolute", display: 'flex' }}>
            <ClipLoader color={"#36D7B7"} loading={loading} size={50} />
          </div>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: 20 }}>Select Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setAccountuid(e.target.value)}
                value={accountuid}
              >
                {list &&
                  list.map((data, index) => {
                    return (
                      <option key={index} value={data?.ACCOUNT_UID}>
                        {data?.ACCOUNT_NAME_ENG}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: 20 }}>Category</Form.Label>
              <Form.Control

                type="text"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>
            Close
          </Button>
          <Button color="primary" variant="contained">
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={shows} onHide={handleClosedel} style={{ paddingTop: 50 }} size="sm">
        <Modal.Header>
          < WarningIcon style={{ color: "red" }} />
          <span style={{ fontSize: 14, paddingTop: 10 }}>
            Are you sure you want to delete this? </span>
        </Modal.Header>
        <Modal.Footer>
          {/* <Button onClick={handleClosedel}>
            No
          </Button> */}
          {/* <Button onClick={() => { deletedccountType(uid) }}>
            Yes
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Breadcrumbs aria-label="breadcrumb">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p
            style={{
              fontSize: 20,
              color: "black",
              fontFamily: "Phetsarath OT",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <ArrowBackIcon style={{ color: "#3f51b5" }} />
            Category
          </p>
          <button
            style={{
              backgroundColor: "#3f51b5",
              border: "none",
              height: 35,
              borderRadius: 2,
              flexDirection: "row",
              marginLeft: 10,
              paddingLeft: 10,
              paddingRight: 10,
              color: "white",
              fontFamily: "Phetsarath OT",
            }}
            onClick={() => {
              handleShow();
              setAccountuid('');
              setName_eng('');
              setShowUpdate(false);
              setUid('')
            }}
          >
            <AddIcon />
            Add new
          </button>
        </div>
      </Breadcrumbs>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <div
        style={{
          height: 5,
          backgroundColor: "#3f51b5",
        }}
      ></div>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <div style={{ paddingTop: 20, width: '100%' }}>
        < div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 10,

        }}>
          <input type="text" style={{
            borderRadius: 5,
            border: '1px solid gray',
            height: 30,
            width: 200
          }}
          onChange={handleFilter}
          
          />
        </div>
        <DataTable
          columns={columns}
          data={records}
          selectableRows
          fixedHeader
          pagination
        />
      </div>
    </>
  );
}
