import { Modal } from "react-bootstrap";
import ErrorIcon from '@material-ui/icons/Error';
export const Already_exists = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.openingsetting} onHide={props.handleCloseOpenting} style={{ paddingTop: 50 }} >
                <Modal.Header closeButton>
                </Modal.Header>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10,
                    marginLeft: 10
                }}>
                    <div style={{ border: '3px solid red', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <ErrorIcon style={{ color: 'red', marginLeft: 10, marginTop: 10 }} />
                    </div>
                    <small style={{ marginLeft: 10, marginTop: 15, fontSize: 20, fontWeight: 'bold' }}>Something’s not quite right</small>
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginLeft: 10
                }}>
                    {
                        props.conditions_status === 401 ? (
                            <>
                                <small style={{ fontSize: 20 }}>For sub-accounts,you must select the same currency as their parent.</small>
                            </>) : props.conditions_status === 400 ? (

                                <>
                                    <small style={{ fontSize: 20 }}>Another account is already using this name. Please use a different name.</small>
                                </>) : props.conditions_status === 100 ? (
                                    <>

                                        <small style={{ fontSize: 20 }}>Please select account type.</small>
                                    </>) : props.conditions_status === 101 ? (
                                        <>
                                            <small style={{ fontSize: 20 }}>Please inter account name.</small>

                                        </>) : props.conditions_status === 402 ? (<>
                                            <small style={{ fontSize: 20 }}>Please select currency.</small>
                                        </>) : null
                    }
                </div>
            </Modal>
        </div>
    )
}
