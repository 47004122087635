import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';

export default function AlertDialog(props) {
    console.log("ddd")



    return (
        <div>

            <Dialog
                open={props.open}
                onClose={props.handleCloseClickOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red',

                }}><ErrorIcon style={{ fontSize: 50 }} /></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCloseClickOpen} color="primary">
                        No
                    </Button>
                    <Button
                        onClick={() => { props.deleteTransaction(props.tr_id) }}
                        color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
