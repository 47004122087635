
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
export const Detail_type = (props) => {

    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.show} onHide={props.handleClose} style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Detail Type</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                    
                            <Form.Select aria-label="Default select example"
                            onChange={(e) => {props.setAccountType(e.target.value)}}
                            value={props.accountType}
                            >
                                <>
                                    <option>Account Type</option>
                                    {props.listaccount &&
                                        props.listaccount?.map((data, index) => {
                                            return (
                                                <option key={index} value={data?.ACCOUNT_TYPE_UID}>
                                                    {data?.ACCOUNT_TYPE_NAME_ENG}
                                                </option>
                                            );
                                        })}
                                </>
                            </Form.Select>

                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
          
                            <Form.Select
                                aria-label="Default select example"
        
                            onChange={(e) => {props.setTitlecashflow(e.target.value)}}
                            value={props.titlecashflow}
                            >
                                <>
                                    <option>Title Cash Flows</option>
                                    {props.listtitle &&
                                        props.listtitle?.map((data, index) => {
                                            return (
                                                <option key={index} value={data?.CASHFLOWS_ID}>
                                                    {data?.CASHFLOWS_TITLE_ENG}
                                                </option>
                                            );
                                        })}
                                </>
                            </Form.Select>

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Detail Type"
                                onChange={(e) => props.setDettailType(e.target.value)}
                                value={props.subtitlecashflow}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <div style={{
                    width: '100%',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',

                    justifyContent: 'flex-end',
                    marginTop: 4,

                }}

                >
                    <div style={{
                        display: 'flex',
                        borderRadius: 2,
                        flexDirection: 'row',
                        width: '20%',
                        backgroundColor: '#3f51b5',
                        marginRight: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'

                    }}
                        onClick={() => { props.CreateNewDetailType() }}
                    >
                        <small style={{ color: 'white', fontWeight: 'bold' }}>Save and Close</small>

                    </div>


                </div>
                <div style={{ height: 20 }}></div>

            </Modal>

        </div>
    )
}
