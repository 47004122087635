import React, { useState, useContext, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import Box_department_edit from "../modal/Box_department_edit";
import AddIcon from "@material-ui/icons/Add";
import Add_department from "../modal/Add_department"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import { Boxnotification } from "../modal/Boxnotification";
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { LoginContext } from "../page/contexts/LoginContext";
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';
import Cookies from 'js-cookie';

import setAuthToken from "../setAuthToken";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
export default function Department() {
    const {
        listdepartment,
        OnloadDepartment,
        setCheckConditions
    } = useContext(LoginContext);

    const classes = useStyles();
    const [list, setList] = useState([])
    const [datafilter, setDatafilter] = useState([])
    const [show, setShow] = useState(false)
    const [showupdate, setShowupdate] = useState(false)
    const [openingDeactivate, setOpeningDeactivate] = useState(false)
    const [inactive, setInactive] = useState('')
    const [inactive_id, setInactive_id] = useState('')
    const [isLoading_reduces, setIsLoading_reduces] = useState(false)

    const [ispageloading, setIspageloading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [department, setDepartment] = useState('')
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClosebox = () => {
        setShow(false)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseReduces_usage = () => {
        setOpeningDeactivate(false)
    }
    const reduces_usage = (department_name, department_id) => {
        setInactive(department_name)
        setInactive_id(department_id)
        setOpeningDeactivate(true)
    }

    const handleShow = () => {
        setAnchorEl(null);
        setShow(true)
    }
    const Delete_Department = (reduces_chart_id) => {
        setIsLoading_reduces(true)
        let datauser = {
            V_DEPARTMENT_ID: reduces_chart_id,
        }
        axios.post('/accounting/api/department/Deleted', datauser).then((data) => {
            setIsLoading_reduces(false)
            handleCloseReduces_usage()
            OnloadDepartment()
        }).catch((err) => {
            console.log(err)
        })
    }
    const filterSearch = async (e) => {
        setDatafilter(e);
        if (!e) {

        } else {

        }
    };
    const Create_department = () => {
        setIspageloading(true)
        const data = {
            department: department,
        }
        axios.post('/accounting/api/department/Insert', data).then((respone) => {
            OnloadDepartment()
            setIspageloading(false)
            handleClosebox()

        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        let users = Cookies.get("user");
        console.log("users=", users)

        if (!users) {
            window.location.assign('https://psvsystem.com/');
        } else {
            let data = JSON.parse(users)
            if (data.token) {


                setAuthToken(data.token, setCheckConditions)

            }
        }

    }, [])

    return (
        <>
            <Add_department
                show={show}
                handleClosebox={handleClosebox}
                setDepartment={setDepartment}
                department={department}
                showupdate={showupdate}
                Create_department={Create_department}
            />
            <Boxnotification
                openingDeactivate={openingDeactivate}
                handleCloseReduces_usage={handleCloseReduces_usage}
                inactive={inactive}
                inactive_id={inactive_id}
                Delete_Department={Delete_Department}
                isLoading_reduces={isLoading_reduces}
            />

            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginLeft: 5 }}>Department List</small>
                <div style={{
                    borderRadius: 2,
                    width: '14%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginLeft: 10,

                }}
                    onClick={() => { handleShow() }}
                >
                    <AddIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold' }}>New Department</small>
                    {
                        ispageloading == true ? (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10 }}>
                                <Spin size="smal" />
                            </Flex>
                        </>) : null
                    }
                </div>
            </div>
            {
                ispageloading == true ? (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>) : (<>
                    <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>

                </>)
            }
            <div style={{ paddingTop: 20, paddingBottom: 50 }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    position: 'relative',
                }}>
                    <div style={{ position: 'absolute', left: 10, top: 6 }}>
                        < SearchIcon style={{ color: '#ccc' }} />
                    </div>
                    <input type="text"
                        placeholder="Filter by name or number"
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 35,
                            width: '30%',
                            paddingLeft: 40
                        }}
                        onChange={(e) => filterSearch(e.target.value)}
                        value={datafilter}
                    />
                </div>

                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Last date</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (listdepartment > 0
                                    ? listdepartment?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : listdepartment
                                )?.map((item, index) => {
                                    return (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell>{item?.DEPARTMENT_NAME}</TableCell>
                                                <TableCell align="right">{moment(item?.DEPARTMENT_DATE).format('DD-MM-YYYY')}</TableCell>
                                                <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <small style={{ color: 'blue', cursor: "pointer" }} onClick={() => { reduces_usage(item?.DEPARTMENT_NAME, item?.DEPARTMENT_ID) }}>Make Inactive</small>||
                                                    <Box_department_edit
                                                        handleShow={handleShow}
                                                        department_id={item?.DEPARTMENT_ID}
                                                        department_name={item?.DEPARTMENT_NAME}
                                                        anchorEl={anchorEl}
                                                        handleClick={handleClick}
                                                        handleClose={handleClose}

                                                    />
                                                </TableCell>


                                            </TableRow>

                                        </>
                                    )
                                })
                            }

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{ fontWeight: 'bold', marginLeft: 10, marginTop: 5 }}>LimitPage:</small>
                                    <select style={{ marginLeft: 5, width: 60, border: '1px solid #ccc', borderRadius: 3, outline: 'none' }}

                                    >
                                        <option value="100">10</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={list?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

            </div>

        </>

    );
}
function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

