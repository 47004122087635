import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import { LoginContext } from "./contexts/LoginContext";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Table, Modal, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from 'js-cookie';
import { Add_transactions_type } from "../modal/Add_Transactions_type";
import setAuthToken from "../setAuthToken";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function Transactions_Type() {
    const [show, setShow] = useState(false);
    const [listdata, setListdata] = useState([])
    const [active, setActive] = useState(null)
    const [transactions_name, setTransactions_name] = useState('')
    const [descriptions, setDescriptions] = useState('')
    const [taxs_amount, setTaxs_amount] = useState('')
    const [account_uid, setAccount_uid] = useState('')
    const [sales_conditions, setSales_conditions] = useState(false)
    const [purchases_conditions, setPurchases_conditions] = useState(false)
    const [transactions_type_edit, setTransactions_type_edit] = useState('')

    const [conditions, setConditions] = useState('')
    const [update, setUpdate] = useState(false)
    const {
        company_uid,
        user_session, listtransactions_type, setCheckConditions, setListtransactions_type } =
        useContext(LoginContext);
    const handleClose = () => {
        setShow(false);
    };
    // console.log("listtransactions_type=", listtransactions_type)
    const handleShow = () => {
        setShow(true)
        setTransactions_name('')
        setDescriptions('')
        setTaxs_amount('')
        setAccount_uid('')
    };
    const onClickIndexupdate = (TRANSACTIONS_TYPE_ID, TRANSACTIONS_NAME, DESRIPTIONS) => {
        setShow(true)
        setUpdate(true)
        setTransactions_type_edit(TRANSACTIONS_TYPE_ID)
        setTransactions_name(TRANSACTIONS_NAME,)
        setDescriptions(DESRIPTIONS)
    }
    const CreateTaxs = () => {
        let data = {
            V_TRANSACTIONS_NAME: transactions_name,
            V_DESCRIPTIONS: descriptions,
            V_COMPANY_UID: company_uid,
            V_CREATE_BY: user_session,
        }
        axios.post("/accounting/api/transactionsType/Insert", data).then((data) => {
            Onload_Transactions_Type()
            handleClose()
            setTransactions_name('')
            setDescriptions('')
        }).catch((err) => {
            console.log(err)
        })
    }
    const Ondeletetransactions_type = (TRANSACTIONS_TYPE_ID) => {
        let data = {
            V_TRANSACTIONS_TYPE_ID: TRANSACTIONS_TYPE_ID
        }
        axios.post('/accounting/api/transactionsType/Deleted', data).then((data) => {
            Onload_Transactions_Type()

        }).catch((err) => {
            console.log(err)
        })

    }
    const Updatetransactions_type = () => {
        let data = {
            V_TRANSACTIONS_TYPE_ID: transactions_type_edit,
            V_TRANSACTIONS_NAME: transactions_name,
            V_DESCRIPTIONS: descriptions,
        }
        axios.post('/accounting/api/transactionsType/Update', data).then((data) => {
            Onload_Transactions_Type()
            setUpdate(false)
            handleClose()
        }).catch((err) => {
            console.log(err)
        })

    }
    const Onload_Transactions_Type = () => {
        axios.get(`/accounting/api/transactionsType/${company_uid}`).then((data) => {
            setListtransactions_type([...data?.data?.result])
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        let users = Cookies.get("user");
        console.log("users=", users)

        if (!users) {
            window.location.assign('https://psvsystem.com/');
        } else {
            let data = JSON.parse(users)
            if (data.token) {


                setAuthToken(data.token, setCheckConditions)

            }
        }
    }, [])

    return (
        <div style={{ width: "100%" }}>
            <Add_transactions_type
                show={show}
                handleClose={handleClose}
                transactions_name={transactions_name}
                setTransactions_name={setTransactions_name}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                taxs_amount={taxs_amount}
                setTaxs_amount={setTaxs_amount}
                account_uid={account_uid}
                setAccount_uid={setAccount_uid}
                CreateTaxs={CreateTaxs}
                sales_conditions={sales_conditions}
                setSales_conditions={setSales_conditions}
                purchases_conditions={purchases_conditions}
                setPurchases_conditions={setPurchases_conditions}
                Updatetransactions_type={Updatetransactions_type}
                conditions={conditions}
                setConditions={setConditions}
                update={update}
            />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginTop: 10,
                }}
            >

                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginTop: 2, marginLeft: 5 }}>Transactions Type</small>
                <div style={{
                    borderRadius: 2,
                    width: '20%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginLeft: 10,
                    boxShadow: active === 'add' ? '1px 2px 9px #F4AAB9' : '',
                }}
                    onClick={() => {
                        handleShow();
                    }}
                    onMouseOver={() => setActive('add')}
                    onMouseLeave={() => setActive(null)}
                >
                    <AddIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold' }}>Add New Transasctions Type</small>
                </div>
            </div>
            <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
            <div style={{ paddingTop: 20, width: '100%' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Transactions Type</th>
                            <th>Desriptions</th>
                            <th>Last update</th>
                            <th
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingRight: 50,
                                }}
                            >
                                action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listtransactions_type &&
                            listtransactions_type.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{ width: 300 }}> {data?.TRANSACTIONS_NAME}</td>
                                        <td style={{ width: 300 }}>
                                            {data?.DESRIPTIONS}
                                        </td>
                                        <td>{moment(data?.CREATEDATE).format('DD-MM-YYYY')}</td>
                                        <td
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <button
                                                style={{
                                                    backgroundColor: "#FF4747",
                                                    borderRadius: 3,
                                                    border: "none",
                                                    paddingBottom: 5,
                                                    color: "white",
                                                }}
                                                onClick={() => {
                                                    onClickIndexupdate(
                                                        data?.TRANSACTIONS_TYPE_ID,
                                                        data?.TRANSACTIONS_NAME,
                                                        data?.DESRIPTIONS
                                                    )
                                                }}
                                            >
                                                <EditIcon style={{ color: "white" }} />
                                            </button>
                                            <button
                                                style={{
                                                    backgroundColor: "#3f51b5",
                                                    borderRadius: 3,
                                                    marginLeft: 10,
                                                    border: "none",
                                                    paddingBottom: 5,
                                                    color: "white",
                                                }}
                                                onClick={() => { Ondeletetransactions_type(data?.TRANSACTIONS_TYPE_ID) }}
                                            >
                                                <DeleteIcon style={{ color: "white" }} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
