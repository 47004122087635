import React, { useContext, useState, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { LoginContext } from "./contexts/LoginContext";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from "moment";

export default function Currencies() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currency_name, setCurrency_name] = useState();
  const [currency_conde, setCurrency_conde] = useState('')
  const [selectedImages, setSelectedImages] = useState([])
  const [uploading, setUploading] = useState(false)
  const [images, setImage] = useState([])
  const [currency_symbol, setCurrency_symbol] = useState('')
  const [list, setList] = useState([])

  const { listcurrency, onloadCurrency } = useContext(LoginContext)

  const onSelectFile = async (event) => {
    const selectedFiles = event.target.files;
    const file_attachments = Array.from(selectedFiles);
    console.log("file_attachments=", file_attachments)
    setSelectedImages((prevImages) => [...prevImages, ...file_attachments]);
    let formData = new FormData();
    for (const key of Object.keys(selectedFiles)) {
      formData.append("file_attachment", selectedFiles[key]);
    }
    formData.append("file_attachment", selectedFiles);
    setUploading(true)
    let profileImageReturnName = await axios.post("/accounting/api/currency/upload", formData);
    const result = profileImageReturnName.data.files.map(file => ({ NAME_IMAGE: file }));
    setImage(result)
    setUploading(false)
  };
  const Create_Currencies = () => {
    let data = {
      Currencyname: currency_name,
      Currency_code: currency_conde,
      Image_name: images[0].NAME_IMAGE,
      Currencysymbol: currency_symbol
    }
    console.log("insert", data)
    axios.post("/accounting/api/currency/Bank/Insert", data).then((data) => {
      OnLoad()
      setCurrency_name('')
      setCurrency_conde('')
      setCurrency_symbol('')
      setSelectedImages([])
      handleClose(false)
    })
      .catch((err) => {
        console.log(err);
      });
  };
  const CurrenDeleted = (id) => {
    console.log(id)
    axios
      .delete(`/accounting/api/currencies/delete/${id}`)
      .then((data) => {

      })
      .catch((err) => {
        console.log(err);
      });

  };
  const OnLoad = () => {
    axios.get('/accounting/api/ledger_entries/homecurrencies').then((data) => {

      setList([...data?.data?.result])

    }).catch((err) => {

    })

  }
  useEffect(() => {
    OnLoad()
  }, [])
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <p
          style={{
            fontSize: 20,
            color: "black",
            fontFamily: "Phetsarath OT",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <ArrowBackIcon style={{ color: "#3f51b5" }} />
          ສະກຸນເງີນ
        </p>
        <button
          style={{
            backgroundColor: "#3f51b5",
            border: "none",
            height: 35,
            borderRadius: 2,
            flexDirection: "row",
            marginLeft: 10,
            paddingLeft: 10,
            paddingRight: 10,
            color: "white",
            fontFamily: "Phetsarath OT",
          }}
          onClick={handleShow}
        >
          <AddIcon />
          ເພີ່ມສະກຸນເງີນ
        </button>
      </div>
      <div
        style={{
          height: 10,
        }}
      ></div>
      <div
        style={{
          height: 5,
          backgroundColor: "#3f51b5",
        }}
      ></div>
      <div
        style={{
          height: 10,
        }}
      ></div>

      <Modal
        show={show}
        onHide={handleClose}
        style={{ paddingTop: 50 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Currencies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ paddingLeft: 10 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "space-between",
                  paddingTop: 10,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Currencies Code</label>
                  <input
                    type={"text"}
                    onChange={(e) => setCurrency_name(e.target.value)}
                    value={currency_name}
                    style={{
                      width: '100%',
                      outline: "none",
                      border: "1px solid #DBDBDB",
                      height: 35,
                      paddingLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Currencies Name*</label>
                  <input
                    type={"text"}
                    onChange={(e) => setCurrency_conde(e.target.value)}
                    value={currency_conde}
                    style={{
                      width: '100%',
                      outline: "none",
                      border: "1px solid #DBDBDB",
                      height: 35,
                      paddingLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Currency Symbol*</label>
                  <input
                    type={"text"}
                    onChange={(e) => setCurrency_symbol(e.target.value)}
                    value={currency_symbol}
                    style={{
                      width: '100%',
                      outline: "none",
                      border: "1px solid #DBDBDB",
                      height: 35,
                      paddingLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                  <label>
                    <AttachFileIcon /> <small>Picture</small>
                    <input
                      type="file"
                      name="images"
                      onChange={onSelectFile}

                      style={{ display: "none" }}
                    />

                  </label>
                </div>
                <div style={{ border: '1px solid #ccc', width: '100%', height: 150, textAlign: "right", marginTop: 5, marginBottom: 30 }}>
                  {
                    uploading ? (<>
                      <LinearProgress />
                    </>) : null
                  }
                  {selectedImages &&
                    selectedImages.map((data, index) => {
                      let sizes = parseFloat(data?.size / 1024).toFixed(2)
                      return (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                          <small style={{ fontSize: 15, marginRight: 10 }}>{data?.name}({sizes})kb</small>

                        </div>
                      );
                    })}

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              Create_Currencies();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Currency Name</th>
            <th>Currency Code</th>
            <th>Currency Symbol</th>
            <th>Last update</th>
            <th
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 50,
              }}
            >
              action
            </th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((data, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: 300 }}> {data?.CURRENCY_NAME}</td>
                  <td style={{ width: 300 }}>
                    <img alt="Logo" src={data?.attachments} style={{ width: 40, height: 20, marginTop: 5 }} />
                  </td>
                  <td>{data?.CURRENCY_SYMBOL}</td>
                  <td>{moment(data?.CREATEDATE).format('DD-MM-YYYY')}</td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#FF4747",
                        borderRadius: 3,
                        border: "none",
                        paddingBottom: 5,
                        color: "white",
                      }}
                    >
                      <EditIcon style={{ color: "white" }} />
                    </button>
                    <button
                      style={{
                        backgroundColor: "#3f51b5",
                        borderRadius: 3,
                        marginLeft: 10,
                        border: "none",
                        paddingBottom: 5,
                        color: "white",
                      }}
                      onClick={() => CurrenDeleted(data?.CURRENCY_UID
                      )}
                    >
                      <DeleteIcon style={{ color: "white" }} />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}
