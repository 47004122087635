import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from "@material-ui/icons/Settings";

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <SettingsIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '40ch',
                        marginTop: 60
                    },
                }}
            >

                <h4 style={{ marginTop: 20, marginLeft: 10 }}>Display density</h4>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: 10,
                            }}
                        >
                            <input type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20 }}
                                onClick={() => { props.Ondebit() }}
                                checked={props.showdebit}
                            />
                            <small style={{ marginLeft: 5, marginTop: 1 }}
                            >Debit</small>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10

                            }}>
                            <input type='checkbox' style={{ paddingLeft: 10, height: 20, width: 20 }}
                                onClick={() => { props.OnForeigndebit() }}
                                checked={props.foreigndebit}
                            />
                            <small style={{ marginLeft: 5, marginTop: 1 }}>Foreign Debit</small>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10

                            }}

                        >
                            <input
                                type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20 }}
                                onClick={() => { props.Onforeignamount() }}
                                checked={props.foreignamount}


                            />
                            <small style={{ marginLeft: 5, marginTop: 1 }}>Foreign Amount</small>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10
                            }}>
                            <input type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20 }}
                                onClick={() => { props.OnRate() }}
                                checked={props.rate}
                            />
                            <small style={{ marginLeft: 5, marginTop: 1 }}>Rate</small>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10
                            }}>
                            <input
                                type='checkbox' style={{ paddingLeft: 10, height: 20, width: 20 }}
                                onClick={() => { props.OnGain_loss() }}
                                checked={props.gain_Loss}

                            />
                            <small style={{ marginLeft: 5, marginTop: 1 }}>Gain/Loss</small>
                        </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <input type='checkbox' style={{ paddingLeft: 10, height: 20, width: 20, marginTop: 10 }}
                                checked={props.showcredit}
                                onClick={() => { props.Oncredit() }}
                            />
                            <small style={{ marginLeft: 5, marginTop: 10 }}>Credit</small>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <input type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20, marginTop: 10 }}
                                onClick={() => { props.Onforeigncredit() }}
                                checked={props.foreigncredit}
                            />
                            <small style={{ marginLeft: 5, marginTop: 10 }}>Foreign Credit</small>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <input type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20, marginTop: 10 }}
                                onClick={() => { props.OnForeignbalance() }}
                                checked={props.foreignbalance}
                            />
                            <small style={{ marginLeft: 5, marginTop: 10 }}>Foreign Balance</small>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <input type='checkbox' style={{ paddingLeft: 10, height: 20, width: 20, marginTop: 10 }} />
                            <small style={{ marginLeft: 5, marginTop: 10 }}>Report Exchange</small>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <input type='checkbox'
                                style={{ paddingLeft: 10, height: 20, width: 20, marginTop: 10 }}
                                onClick={() => { props.OnCurrentBalance() }}
                                checked={props.currentbalance}
                            />
                            <small style={{ marginLeft: 5, marginTop: 10 }}>Current balance</small>
                        </div>

                    </div>
                </div>

            </Menu>
        </div>
    );
}
