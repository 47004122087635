import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const ITEM_HEIGHT = 48;

export default function SecondLongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>

            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '40ch',
                    },
                }}
            >
                <MenuItem onClick={() => {
                    setAnchorEl(null);
                    props.handleShow();
                    props.setName(props.name_eng);
                    props.setShowUpdate(true);
                    props.editbeginningbalancefirst(
                        props.chart_id,
                        props.balances,
                        props.createdate,
                        props.account_ac_symbol,
                    );
                    props._onsearchaccountid(props.account_type_uid, props.company_uid)
                    props._ongetCurrencyvalues(props.currency_uid)
                    props._ondetail_Type(props.detail_Type_id)
                }} >Edit</MenuItem>
                {/* <MenuItem onClick={() => { props.Gotodetailaccount(props.chart_id) }} style={{ cursor: "pointer" }}>Run report</MenuItem> */}
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        props.reduces_usage(props.chart_id)
                    }}
                >Make inactive (reduces usage)</MenuItem>
            </Menu>
        </div>
    );
}
