import React from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';

const ExportToExcel = () => {
  const handleExport = () => {
    const data = [
      {
        Name: 'Mr.Customer', Account: 'Share', total_Debit: 0.00, total_Credit: 359148.00, total_Amount: 359148.00,
        children: [
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Customer', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          },
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Customer', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          },
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Customer', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          }
        ]
      },
      {
        Name: 'Mr.Bounmeeher xiong', total_Debit: 0.00, total_Credit: 359148.00, total_Amount: 359148.00,
        children: [
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Bounmeeher xiong', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          },
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Bounmeeher xiong', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          },
          {
            TR_ID: 1, CUSTOMERS_ID: 81, CUSTOMERS_NAME: 'Mr.Bounmeeher xiong', CREATEDATE: '2024-06-16T17:00:00.000Z', DEBIT: 900000, AMOUNT: 359148.00,
            DESRIPTIONS: null, EXCHNAGE_RATE: 9000, FOREIGN_AMOUNT: 100, TRANSACTIONS_CURRRENCY: "USD", TRANSACTIONS_NUMBER: "52", TRANSACTIONS_TYPE: "opex"
          }
        ]
      }
    ];

    const processData = (data) => {
      const flatData = [];
      data.forEach(parent => {
        // flatData.push({
        //   Name: parent.Name,
        //   Account: '',
        //   Debit: '',
        //   Credit: '',
        //   Amount: ''
        // });
        parent.children.forEach(child => {
          flatData.push({
            Name: child.CUSTOMERS_NAME,
            Date: moment(child.CREATEDATE).format('DD-MM-YYYY'),
            'Transaction Type': child.TRANSACTIONS_TYPE,
            Number: child.TRANSACTIONS_NUMBER,
            Description: child.DESRIPTIONS || '',
            Account: parent.Account || '',
            Debit: child.DEBIT,
            Credit: child.AMOUNT,
            Amount: child.AMOUNT
          });
        });
        flatData.push({
          Name: `Total for ${parent.Name}`,
          Account: '',
          Debit: parent.total_Debit,
          Credit: parent.total_Credit,
          Amount: parent.total_Amount
        });
      });
      return flatData;
    };

    const exportData = processData(data);

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'ExportedData.xlsx');
  };

  return (
    <div>
      <button onClick={handleExport}>Export to Excel</button>
    </div>
  );
};

export default ExportToExcel;
