import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import { LoginContext } from "./contexts/LoginContext";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Add_Taxs } from "../modal/Add_Taxs";
import axios from "axios";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function Taxes() {
    const [show, setShow] = useState(false);
    const [listdata, setListdata] = useState([])
    const [active, setActive] = useState(null)
    const [taxname, setTaxname] = useState('')
    const [descriptions, setDescriptions] = useState('')
    const [taxs_amount, setTaxs_amount] = useState('')
    const [account_uid, setAccount_uid] = useState('')
    const [sales_conditions, setSales_conditions] = useState(false)
    const [purchases_conditions, setPurchases_conditions] = useState(false)
    const [conditions, setConditions] = useState('')
    const {
        company_uid,
        user_session, OnChecked,onLoadTaxs } =
        useContext(LoginContext);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true)
        setTaxname('')
        setDescriptions('')
        setTaxs_amount('')
        setAccount_uid('')

    };

    const CreateTaxs = () => {
        let data = {
            V_AMOUNT_TAXS: taxs_amount,
            V_COMPANY_UID: company_uid,
            V_CREATE_BY: user_session,
            V_ACCOUNT_UID: account_uid,
            V_TAXS_NAME: taxname,
            V_DESCRIPTIONS: descriptions,
            V_CONDITIONS: conditions
        }
        console.log("insert=", data)

        axios.post("/accounting/api/taxs/Insert", data).then((data) => {
            Onload_Taxs()
            OnChecked()
            handleClose()
            setTaxname('')
            setDescriptions('')
            setTaxs_amount('')
            setAccount_uid('')
            setSales_conditions(false)
            setPurchases_conditions(false)
            onLoadTaxs()
     
        }).catch((err) => {
            console.log(err)
        })
    }

    const OnDelete = (id) => {
        console.log("id=", id)
        let data = {
            DETAIL_TYPE_ID: id
        }
        console.log("DETAIL_TYPE_ID=", data)
        axios.post('/accounting/api/detailType/Deleted', data).then((data) => {


        }).catch((err) => {
            console.log(err)
        })

    }
    const columns = [
        {
            name: "Taxs Name",
            selector: row => row.TAXS_NAME,
            sortable: true
        },
        {
            name: "Desriptions",
            selector: row => row.DESCIPTIONS,
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.CREATESTATUS,
            sortable: true
        },

        {
            name: "Taxs ",
            // selector: row => row.SALE_TAXS,
            // sortable: true

            cell: row => (
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    {row.TAXS_AMOUNT === null ? (<> <small style={{ cursor: 'pointer' }}
                    >0</small></>) : (<>
                        <small style={{ cursor: 'pointer' }}
                        >{row.TAXS_AMOUNT} % </small>

                    </>)}

                </div>
            )
        },



        {
            name: "Last Date",
            selector: row => moment(row.CREATE_DATE).format('YYYY-MM-DD'),
            sortable: true,

        },
        {
            name: 'actions',
            cell: row => (
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <small style={{ cursor: 'pointer' }}
                        onClick={() => { OnDelete(row.TAXS_ID) }}
                    >Deleted</small>
                </div>
            )
        },
    ]

    const Onload_Taxs = () => {
        axios.get(`/accounting/api/taxs/${company_uid}`).then((data) => {
            setListdata([...data?.data?.result])
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        Onload_Taxs()
    }, [])
    return (
        <div style={{ width: "100%" }}>
            <Add_Taxs
                show={show}
                handleClose={handleClose}
                taxname={taxname}
                setTaxname={setTaxname}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                taxs_amount={taxs_amount}
                setTaxs_amount={setTaxs_amount}

                account_uid={account_uid}
                setAccount_uid={setAccount_uid}
                CreateTaxs={CreateTaxs}
                sales_conditions={sales_conditions}
                setSales_conditions={setSales_conditions}
                purchases_conditions={purchases_conditions}
                setPurchases_conditions={setPurchases_conditions}
                conditions={conditions}
                setConditions={setConditions}
            />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginTop: 10,
                }}
            >

                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginTop: 2, marginLeft: 5 }}>Taxes</small>
                <div style={{
                    borderRadius: 2,
                    width: '10%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginLeft: 10,
                    boxShadow: active === 'add' ? '1px 2px 9px #F4AAB9' : '',
                }}
                    onClick={() => {
                        handleShow();

                    }}
                    onMouseOver={() => setActive('add')}
                    onMouseLeave={() => setActive(null)}
                >
                    <AddIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold' }}>Add New Taxs</small>
                </div>
            </div>
            <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>
            <div style={{ paddingTop: 20, width: '100%' }}>
                <DataTable
                    columns={columns}
                    data={listdata}
                    selectableRows
                    fixedHeader
                    pagination
                />
            </div>
        </div>
    );
}
