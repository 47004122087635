import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import { Flex, Spin, Skeleton } from 'antd';
import Cookies from 'js-cookie';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import { LoginContext } from "./contexts/LoginContext";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import Add_Currency from "../modal/Add_Currency";
import { Boxnotificationcurrency } from "../modal/Boxnotificationscurrency";
import setAuthToken from "../setAuthToken";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export default function SettingCurrency() {
  const classes = useStyles();
  const [currency_id, setCurrency_id] = useState();
  const [listhome, setListhome] = useState([])
  const [anchorElTax, setAnchorElTax] = useState('')
  const [ispageloading, setIspageloading] = useState(false)
  const [openingDeactivate, setOpeningDeactivate] = useState(false)
  const [isLoading_reduces, setIsLoading_reduces] = useState(false)
  const handleCloseTaxs = () => {
    setAnchorElTax(null);
  };
  const { user_session, company_uid, setCheckConditions, listcurrency, onloadCurrency } = useContext(LoginContext)
  const handleClick_Close = () => {
    setAnchorElTax(null);
  };
  const handleClick_addcurrency = (event) => {
    setAnchorElTax(event.currentTarget);

  };
  const handleCloseReduces_usage = () => {
    setOpeningDeactivate(false)
  }
  const reduces_usage = () => {

    setOpeningDeactivate(true)
  }
  const Create_Currencies = () => {
    let data = {
      CurrencyUid: currency_id,
      Create_by: user_session,
      Company_uid: company_uid
    }
    axios.post("/accounting/api/settingcurrency/Bank/Insert", data).then((data) => {
      onloadCurrency()
      handleClick_Close()
    })
      .catch((err) => {
        console.log(err);
      });
  };
  const CurrenDeleted = (id) => {
    console.log(id)
    axios
      .delete(`/accounting/api/currencies/delete/${id}`)
      .then((data) => {

      })
      .catch((err) => {
        console.log(err);
      });

  };
  const OnLoad_Type = () => {
    setIspageloading(true)
    axios.get(`/accounting/api/ledger_entries/homecurrencies`).then((data) => {
      setListhome([...data?.data?.result])
      setIspageloading(false)
    }).catch((err) => {

    })
  }
  useEffect(() => {
    let users = Cookies.get("user");
    console.log("users=", users)

    if (!users) {
      window.location.assign('https://psvsystem.com/');
    } else {
      let data = JSON.parse(users)
      if (data.token) {

        setAuthToken(data.token, setCheckConditions)
      }
    }
    OnLoad_Type()
  }, [])


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: 'space-between',

          width: "100%",
        }}
      >
        <p
          style={{
            fontSize: 20,
            color: "black",
            fontFamily: "Phetsarath OT",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <ArrowBackIcon style={{ color: "#3f51b5" }} />
          Home currency:LAK - Laos Kip
        </p>
        <button
          style={{
            backgroundColor: "#3f51b5",
            border: "none",
            height: 35,
            borderRadius: 2,
            flexDirection: "row",
            marginLeft: 10,
            paddingLeft: 10,
            paddingRight: 10,
            color: "white",
            fontFamily: "Phetsarath OT",
          }}
          onClick={handleClick_addcurrency}
        >
          <AddIcon />
          Add currency
        </button>
      </div>
      <div
        style={{
          height: 10,
        }}
      ></div>
      {
        ispageloading == true ? (<>
          <LinearProgress style={{ marginTop: 10 }} />
        </>) : (<>
          <div style={{ backgroundColor: '#f59e0b', width: '100%', height: 3, marginTop: 10 }}></div>

        </>)
      }

      <div
        style={{
          height: 10,
        }}
      ></div>
      <Add_Currency
        anchorElTax={anchorElTax}
        handleCloseTaxs={handleCloseTaxs}
        listhome={listhome}
        handleClick_Close={handleClick_Close}
        currency_id={currency_id}
        setCurrency_id={setCurrency_id}
        Create_Currencies={Create_Currencies}

      />
      <Boxnotificationcurrency
        openingDeactivate={openingDeactivate}
        handleCloseReduces_usage={handleCloseReduces_usage}
        isLoading_reduces={isLoading_reduces}


      />
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">Default Rate</TableCell>
              <TableCell align="right">Last update</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              listcurrency && listcurrency?.map((row, index) => {
                return (<>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.CURRENCY_NAME} - {row?.CURRENCY_CODE}
                    </TableCell>
                    <TableCell align="right">{row?.CURRENCY_NAME}</TableCell>
                    <TableCell align="right">{moment(row?.CREATE_DATE).format('DD-MM-YYYY')}</TableCell>
                    <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <small style={{ color: 'blue', marginRight: 10, cursor: 'pointer' }} onClick={() => { reduces_usage() }}>Make Inactive</small>
                    </TableCell>
                  </TableRow>
                </>)
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}
