import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getFormatNumber } from "../constants/functions";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import moment from 'moment';
import axios from "axios";
import { LoginContext } from "./contexts/LoginContext";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Table } from "react-bootstrap";
import { Flex, Spin, Skeleton } from 'antd';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function ReportTrialbalances() {
    let componentRef = useRef(null)
    const navigate = useNavigate();
    const {
        listaccountname,
        company_uid
    } = useContext(LoginContext);
    let open_debit = 0;
    let open_credit = 0;
    let credit = 0;
    let debit = 0;
    let net_total_debit = 0;
    let net_total_credit = 0
    const [start_date, setStart_date] = useState(new Date());
    const [showdate, setShowdate] = useState(false)
    const [getvalues, setGetvalues] = useState([]);
    const [listTrailbalance, setListTrailbalance] = useState({})
    const Today = moment(new Date()).format("DD-MM-YYYY")
    const [defaultValue, setDefaultValue] = useState("")
    const [defaultValue1, setDefaultValue1] = useState("")
    const date = moment(new Date).format("DD-MM-YYYY")

    const [nameShow, setNameShow] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [showBox, setShowBox] = useState(false);
    const [open, setOpen] = useState(true);
    const [ch_id, setCh_id] = useState("");
    const [active, setActive] = useState("");
    const [loading, setLoading] = useState(false)
    const handleClick = () => {
        setOpen(!open);
    };

    const classes = useStyles();
    const goback = () => {
        // navigate("/ChartAccount");
    }
    const Gotodetailaccount = (id) => {
        navigate(`/DetailReportTrialbalances/${id}`);
    }
    const onloadreportTrailbalance = () => {
        axios.get(`/accounting/api/trialbalanaces/${company_uid}`).then((data) => {
            console.log("result=",data)

            setListTrailbalance({ ...data?.data })
            setLoading(true)
        })
    }
    const _onShow = (e) => {
        if (e == "custom") {
            setShowdate(true)
        } else if (e == "all") {
            onloadreportTrailbalance()
        } else if (e == "today") {
            console.log("today")
            const start = defaultValue
            const end = defaultValue1
            let data = {
                start,
                end
            }
            axios.post("/accounting/api/reportbydate/searchreport", data).then((data) => {
                setListTrailbalance([...data?.data.result])
                setListTrailbalance({ ...data?.data })

            }).catch((err) => {
                console.log(err)
            })
        }
        setGetvalues(e)
    }
    const _searchstartdate = (e) => {
        setDefaultValue(e)
        setStart_date(e)
        if (defaultValue === "") {
            setDefaultValue(Today)
        } else {
            setDefaultValue(moment(e).format("DD-MM-YYYY"))
        }

    }
    const _searchbydate = (e) => {
        setDefaultValue1(e)
        if (defaultValue1 == "") {
            setDefaultValue1(date)

        } else {
            setDefaultValue1(moment(e).format("DD-MM-YYYY"))
        }
    }
    const _onSearchList = (e) => {
        setNameShow(e);
        let searchName = listaccountname.filter((el) => el.name_eng.includes(e));
        if (!e) {
            setSearchResult([]);
        } else {
            setSearchResult([...searchName]);
        }
    };
    const Search = () => {
        if (getvalues == "account") {
            const start = defaultValue
            const end = defaultValue1
            let data = {
                start,
                end,
                ch_id
            }
            axios.post("/accounting/api/report/reportbyaccount", data).then((data) => {
            }).catch((err) => {
                console.log(err)
            })
        } else {
            const start = defaultValue
            const end = defaultValue1
            let data = {
                start_date: start,
                end_date: end,
                company_uid: company_uid,
                for_year: moment(defaultValue).format('YYYY')
            }
            axios.post("/accounting/api/trialbalanaces/search", data).then((data) => {
                console.log("search=", data)
                setListTrailbalance({ ...data?.data })
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const Onloadreset = () => {
        window.location.reload();
    }
    const getNameList = (c_id) => {
        axios.get(`/accounting/api/chartofaccounts/all/parents/${c_id}`).then((data) => {
            if (data?.data?.message.length > 0) {
                setCh_id(data?.data.message[0].c_id);
                const names = data?.data?.message.map((item) => {
                    return item.name_eng;
                });
                names.reverse();
                setNameShow(names.join(":"));
                setShowBox(!showBox);
            }
        });
    };
    useEffect(() => {
        onloadreportTrailbalance()
        _searchstartdate();
        _searchbydate();
    }, [])
    return (
        <div style={{ width: '100%' }}>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ border: '3px solid #3f51b5', borderRadius: '50%', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: '#3f51b5' }} />
                </div>
                <small style={{ fontSize: 20, marginLeft: 5 }}>Trial Balance Report</small>
            </div>

            {
                loading ? (<>

                    <div style={{ backgroundColor: '#3f51b5', width: '100%', height: 3, marginTop: 10 }}></div>

                </>) : (<>
                    <LinearProgress style={{ marginTop: 10 }} />
                </>)
            }

         

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginTop: 10,
                justifyContent: 'space-between'
            }} >
                <div
                    style={{
                        display: 'flex',
                        width: '90%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <select
                        onChange={(e) => _onShow(e.target.value)}
                        value={getvalues}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: 16,
                            outline: 'none',
                            fontSize: 16,
                            height: 30,
                            width: '20%',
                        }}
                    >
                        <option value="all"> This Year-to-date</option>
                        <option value="all">All Dates</option>
                        <option value="today">Today</option>
                        <option value="custom">Custom</option>
                    </select>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%',
                        marginLeft: 5
                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue}
                            onChange={(e) => setDefaultValue(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                height: 30,
                                width: '100%',
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchstartdate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <span style={{ fontSize: 20, marginLeft: 5, marginRight: 0 }}>To</span>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        position: 'relative',
                        width: '15%'
                    }}>
                        <input
                            type="text"
                            defaultValue={defaultValue1}
                            onChange={(e) => setDefaultValue1(e.target.value)}
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: 4,
                                fontSize: 16,
                                outline: 'none',
                                fontSize: 16,
                                height: 30,
                                width: '100%',
                                marginLeft: 2
                            }}
                        />
                        <input
                            type="date"
                            onChange={(e) => { _searchbydate(e.target.value) }}
                            style={{
                                height: 20,
                                borderRadius: 3,
                                width: 25,
                                position: 'absolute',
                                right: 10,
                                outline: 'none',
                                border: "none",
                                top: 6,
                                cursor: 'pointer',
                                paddingLeft: 10,
                            }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '10%'
                    }}
                        onClick={() => Search()}
                    >
                        <SearchIcon />
                        <small>Search</small>
                    </div>
                    <div style={{
                        display: 'flex',
                        backgroundColor: '#3f51b5',
                        border: 'none',
                        height: 30,
                        borderRadius: 2,
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        cursor: 'pointer',
                        width: '10%'
                    }}
                    >
                        <RotateLeftIcon />
                        <small>Reset</small>
                    </div>
                    {
                        loading ? (<>


                        </>) : (<>
                            <Flex align="center" gap="middle" style={{ marginLeft: 10, marginTop: -5 }}>
                                <Spin size="smal" />
                            </Flex>
                        </>)
                    }
                </div>
                <div style={{
                    borderRadius: 2,
                    width: '8%',
                    backgroundColor: '#3f51b5',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    marginLeft: 5,
                }}
                >
                    <RefreshIcon style={{ color: 'white' }} />
                    <small style={{ color: 'white', fontWeight: 'bold', marginRight: 10 }}>Refresh</small>
                </div>

                {/* <select
                    onChange={(e) => _onShow(e.target.value)}
                    value={getvalues}
                    style={{
                        border: '1px solid #ccc',
                        height: 30,
                        borderRadius: 3,
                        width: 200,
                        outline: 'none'
                    }}
                >
                    <option value="all">All Dates</option>
                    <option value="custom">Custom</option>
                    <option value="today">Today</option>

                </select>
                <input
                    type="text"
                    defaultValue={defaultValue}
                    onChange={(e) => setDefaultValue(e.target.value)}
                    style={{
                        border: '1px solid #ccc',
                        height: 30,
                        borderRadius: 3,
                        width: 100,
                        paddingLeft: 10,
                        marginLeft: 25,
                        textAlign: "right",
                        borderRight: "none",
                    }}
                />
                <input
                    type="date"
                    onChange={(e) => _searchstartdate(e.target.value)}
                    style={{
                        border: '1px solid #ccc',
                        height: 30,
                        borderRadius: 3,
                        width: 30,
                        paddingLeft: 10,
                    }}
                />
                <span style={{ marginLeft: 10, paddingTop: 5 }}>To</span>
                <input
                    type="text"
                    defaultValue={defaultValue1}
                    onChange={(e) => setDefaultValue1(e.target.value)}
                    style={{
                        border: '1px solid #ccc',
                        height: 30,
                        borderRadius: 3,
                        width: 100,
                        paddingLeft: 10,
                        marginLeft: 25,
                        textAlign: "right",
                        borderRight: "none",
                    }}
                />
                <input
                    type="date"
                    onChange={(e) => _searchbydate(e.target.value)}
                    style={{
                        border: '1px solid #ccc',
                        height: 30,
                        borderRadius: 3,
                        width: 30,
                        paddingLeft: 10,
                    }}
                />
             

                <button
                    style={{
                        backgroundColor: "#3f51b5",
                        border: "none",
                        height: 30,
                        borderRadius: 2,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "white",
                        alignItems: "center",
                        marginLeft: 10,
                    }}
                    onClick={() => Search()}
                >
                    <SearchIcon />
                </button>
                <button
                    style={{
                        backgroundColor: "#3f51b5",
                        border: "none",
                        height: 30,
                        borderRadius: 2,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "white",
                        alignItems: "center",
                        marginLeft: 10,
                    }}
                    onClick={() => Onloadreset()}
                >
                    Reset
                </button>

                <ReactToPrint
                    trigger={() => <button
                        style={{
                            backgroundColor: "#3f51b5",
                            border: "none",
                            height: 30,
                            borderRadius: 2,
                            paddingLeft: 10,
                            paddingRight: 10,
                            color: "white",
                            alignItems: "center",
                            marginLeft: 10,
                        }}

                    >
                        < PrintIcon />
                    </button>}
                    content={() => componentRef}
                    style={{ marginRight: 10 }}
                /> */}

            </div>
            {showBox && (
                <>
                    <Card style={{
                        overflowY: "scroll",
                        width: 520,
                        position: "absolute",
                        height: 300,
                        marginLeft: 560
                    }}>
                        <CardActionArea>
                            <CardContent>
                                {searchResult && searchResult.length > 0 ? (
                                    <>
                                        {searchResult && searchResult.map((data, index) => {
                                            return (
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        key={index}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight:
                                                                active === data?.name_eng ? "bold" : "",
                                                        }}
                                                        onClick={() => getNameList(data?.c_id)}
                                                        onMouseOver={() => setActive(data?.name_eng)}
                                                        onMouseLeave={() => setActive(null)}
                                                    >
                                                        {data?.name_eng}- {data?.currencies_name}
                                                    </Typography>
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {listaccountname && listaccountname.map((data, index) => {
                                            return (
                                                <>
                                                    <Typography
                                                        key={index}
                                                        variant="body2"
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight:
                                                                active === data?.name_eng ? "bold" : "",
                                                        }}
                                                        onClick={() => getNameList(data?.c_id)}
                                                        onMouseOver={() => setActive(data?.name_eng)}
                                                        onMouseLeave={() => setActive(null)}
                                                    >
                                                        {data?.name_eng}
                                                    </Typography>
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </>
            )}
            <div style={{ height: 20 }}></div>
            {
                loading ? (<>
                    <Table responsive="sm" style={{ border: '1px solid gray', textAlign: 'center' }}>
                        <thead>
                            <tr>
                                <th rowspan={2} style={{ border: '1px solid gray', width: '30%', position: 'relative', top: -20 }} >Name</th>
                                <th colspan={2} style={{ border: '1px solid gray' }} >Opening Balance</th>
                                <th colspan={2} style={{ border: '1px solid gray' }}>Activities List</th>
                                <th colspan={2} style={{ border: '1px solid gray' }}>Closing Balance</th>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid gray' }}>Debit</td>
                                <td style={{ border: '1px solid gray' }}>Credit</td>
                                <td style={{ border: '1px solid gray' }}>Debit</td>
                                <td style={{ border: '1px solid gray' }}>Credit</td>
                                <td style={{ border: '1px solid gray' }}>Debit</td>
                                <td style={{ border: '1px solid gray' }}>Credit</td>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                listTrailbalance?.result && listTrailbalance.result.map((data, index) => {
                                    open_debit += data?.DEBIT_OPENG
                                    open_credit += data?.CREDITE_OPENG
                                    credit += data?.DEBIT
                                    debit += data?.CREDITE
                                    net_total_debit += data?.TOTAL_DEBIT
                                    net_total_credit += data?.TOTAL_CREDIT
                                    return (
                                        <tr key={index}>
                                            <td align='left'>{data?.CHART_NAME_ENG.slice(0, 20)}:
                                                <ComponentTableCell
                                                    secondFloor={listTrailbalance.secondFloor}
                                                    id={data?.CHART_PARENTS}
                                                />
                                            </td>
                                            <td align='right'>{getFormatNumber(data?.DEBIT_OPENG)}</td>
                                            <td align='right'>{getFormatNumber(data?.CREDITE_OPENG)}</td>
                                            <td align='right'>{getFormatNumber(data?.DEBIT)}</td>
                                            <td align='right'>{getFormatNumber(data?.CREDITE)}</td>
                                            <td align='right'>{getFormatNumber(data?.TOTAL_DEBIT)}</td>
                                            <td align='right'>{getFormatNumber(data?.TOTAL_CREDIT)}</td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <thead>
                            <tr>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }}>Total</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(open_debit)}</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(open_credit)}</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(credit)}</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(debit)}</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(net_total_debit)}</td>
                                <td style={{ border: '1px solid gray', fontWeight: 'bold' }} align='right'>{getFormatNumber(net_total_credit)}</td>
                            </tr>
                        </thead>
                    </Table>

                </>) : (<Skeleton />)
            }




            {/* <TableContainer>
                <Table className={classes.table} aria-label="simple table" style={{border:'1px solid gray'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Account</TableCell>
                            <TableCell align="left">Opening Balances</TableCell>
                            <TableCell align="left">Activities List</TableCell>
                            <TableCell align="left">Closing Balances</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            listTrailbalance.result && listTrailbalance.result.map((data, index) => {
                                let balance = data?.balances
                                let usingObjectAssign = Object.assign([], balance);
                                let createstatus = data?.createstatus
                                let loss=data?.statu_auto_GainAndLoss
                                let condition = usingObjectAssign[0]
                                if (createstatus == 'As' || createstatus == 'Ex') {
                                    if (condition == "-") {
                                        if (loss == '1'){
                                        creditAllTotal += parseFloat(data?.balances)
                                         }else{
                                        creditAllTotal += parseFloat(data?.balances.replace('-', ''))
                                         }
                                 
                                    } else {

                                        debitAllTotal += parseFloat(data?.balances)
                                    }
                                } else {

                                    if (condition != "-") {
                                        creditAllTotal += parseFloat(data?.balances.replace('-', ''))
                                    } else {

                                        debitAllTotal += parseFloat(data?.balances.replace('-', ''))
                                    }
                                }
                                return (
                                    <>
                                        <TableRow key={index}>
                        
                                            <TableCell style={{cursor:'pointer'}} onClick={()=>{Gotodetailaccount(data?.c_uid)}}>
                                                <ComponentTableCell
                                                    secondFloor={listTrailbalance.secondFloor}
                                                    id={data?.parents}
                                                />
                                                : {data.name_eng.slice(0, 50)}
                                            </TableCell>
                                            {
                                                createstatus == "As" || createstatus == "Ex" ? (<>
                                                    {
                                                        condition !== '-' ? (
                                                            <>
                                                                <TableCell style={{cursor:'pointer'}} onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">{getFormatNumber(data?.balances)}</TableCell>
                                                                <TableCell onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">0.00</TableCell>
                                                            </>) : (
                                                            <>
                                                                <TableCell onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">0.00</TableCell>
                                                                <TableCell style={{cursor:'pointer'}} onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">{getFormatNumber(data?.balances).replace('-', '')}</TableCell>
                                                            </>
                                                        )
                                                    }
                                                </>) : (<>
                                                    {
                                                        condition != '-' ? (
                                                            <>
                                                                <TableCell onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">0.00</TableCell>
                                                                <TableCell style={{cursor:'pointer'}} onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">{getFormatNumber(data?.balances)}</TableCell>
                                                            </>) : (
                                                            <>
                                                                <TableCell style={{cursor:'pointer'}} onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">{getFormatNumber(data?.balances).replace('-', '')}</TableCell>
                                                                <TableCell onClick={()=>{Gotodetailaccount(data?.c_uid)}} align="right">0.00</TableCell>
                                                            </>
                                                        )
                                                    }
                                                </>)
                                            }
                                        </TableRow>
                                    </>
                                )
                            })}
                    </TableBody>
                   
                   
                </Table>
            </TableContainer> */}
        </div>
    )
}
function ComponentTableCell({ secondFloor, id }) {
    if (secondFloor === null) return <></>
    const filter = secondFloor.filter((el) => el.CHART_ID == id);
    return (
        <>
            {
                filter.map((data, index) => {
                    return (
                        <>
                            <ComponentTableCell1
                                id={data?.CHART_PARENTS}
                                secondFloor={secondFloor}
                            />
                            <small key={index}>
                                :{data?.CHART_NAME_ENG.slice(0, 15)}
                            </small>
                        </>
                    )
                })
            }
        </>
    )
}
function ComponentTableCell1({ secondFloor, id }) {
    if (secondFloor === null) return <></>
    const filter = secondFloor.filter((el) => el.CHART_ID == id);
    return (
        <>
            {
                filter.map((data, index) => {
                    return (
                        <>
                            <ComponentTableCell2
                                id={data?.CHART_PARENTS}
                                secondFloor={secondFloor}
                            />
                            <small key={index}>
                                {data?.CHART_NAME_ENG.slice(0, 30)}
                            </small>
                        </>
                    )
                })
            }
        </>
    )
}
function ComponentTableCell2({ secondFloor, id }) {
    if (secondFloor === null) return <></>
    const filter = secondFloor.filter((el) => el.c_id == id);
    return (
        <>
            {
                filter.map((data, index) => {
                    return (
                        <>
                            <ComponentTableCell3
                                id={data?.CHART_PARENTS}
                                secondFloor={secondFloor}
                            />
                            <small key={index}>
                                :{data?.CHART_NAME_ENG.slice(0, 30)}
                            </small>
                        </>
                    )
                })
            }
        </>
    )
}
function ComponentTableCell3({ secondFloor, id }) {
    if (secondFloor === null) return <></>
    const filter = secondFloor.filter((el) => el.c_id == id);
    return (
        <>
            {
                filter.map((data, index) => {
                    return (
                        <>
                            <ComponentTableCell4
                                id={data?.CHART_PARENTS}
                                secondFloor={secondFloor}
                            />
                            <small key={index}>
                                :{data?.CHART_NAME_ENG.slice(0, 30)}
                            </small>
                        </>
                    )
                })
            }
        </>
    )
}
function ComponentTableCell4({ secondFloor, id }) {
    if (secondFloor === null) return <></>
    const filter = secondFloor.filter((el) => el.c_id == id);
    return (
        <>
            {
                filter.map((data, index) => {
                    return (
                        <>
                            <ComponentTableCell1
                                id={data?.CHART_PARENTS}
                                secondFloor={secondFloor}
                            />
                            <small key={index}>
                                :{data?.CHART_NAME_ENG.slice(0, 30)}
                            </small>
                        </>
                    )
                })
            }
        </>
    )
}